import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationType, User } from "../models/types";
import { AuthRepository } from "../repositories/auth-repository";
import { RepositoryFactory } from "../repositories/repository.factory";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private repo: AuthRepository;

  constructor(repoFactory: RepositoryFactory) {
    this.repo = repoFactory.getAuthRepository();
  }

  public login(username: string, password: string): Observable<User> {
    return this.repo.login(username, password);
  }

  public logout(): Observable<void> {
    return this.repo.logout();
  }

  public resetPassword(
    code: string,
    confirmPassword: string,
    username: string,
    password: string,
  ): Observable<any> {
    return this.repo.resetPassword(code, confirmPassword, username, password);
  }

  public forgotPassword(username: string): Observable<User> {
    return this.repo.forgotPassword(username);
  }

  public changePassword(
    oldPassword: string,
    password: string,
  ): Observable<any> {
    return this.repo.changePassword(oldPassword, password);
  }

  public completeNewPassword(password: string): Observable<void> {
    return this.repo.completeNewPassword(password);
  }

  public forgotPasswordSubmit(
    username: string,
    otp: string,
    password: string,
  ): Observable<void> {
    return this.repo.forgotPasswordSubmit(username, otp, password);
  }

  public setNotificationPreference(type: NotificationType): Observable<void> {
    return this.repo.setNotificationPreference(type);
  }

  verifyLoginOtp(otp: string): Observable<void> {
    return this.repo.verifyLoginOtp(otp);
  }
}
