import { Component, Input } from "@angular/core";
import { Session } from "../../../../models/session";
import { TradingPartner } from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

@Component({
  selector: "app-business-information",
  templateUrl: "./business-information.component.html",
  styleUrls: ["./business-information.component.sass"],
})
export class BusinessInformationComponent {
  @Input() tradingPartner: TradingPartner;
  constructor(
    private dialog: DialogService,
    private tradingPartnerService: TradingPartnerService,
    private session: Session,
  ) {}

  getAddress() {
    let postalAddress = this.tradingPartner?.addresses.find(
      (x) => x.type == "Postal Address",
    );

    if (!postalAddress) {
      postalAddress = this.tradingPartner?.addresses[0];
    }
    return postalAddress;
  }

  getTelNo() {
    const telNo = this.tradingPartner?.contact?.telNo;
    if (telNo?.charAt(0) == "0") {
      return "+27" + telNo.substring(1);
    }
    return telNo;
  }

  getFaxNo() {
    const faxNo = this.tradingPartner?.contact?.faxNo;
    if (faxNo?.charAt(0) == "0") {
      return "+27" + faxNo.substring(1);
    }
    return faxNo;
  }

  editBusinessInformation() {
    const update = this.dialog.editBusinessInfo(this.tradingPartner);

    update.afterClosed().subscribe((updated) => {
      if (updated) {
        this.loadTradingPartner();
      }
    });
  }

  loadTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      this.tradingPartnerService
        .getTradingPartner(this.session.getSupplierGln())
        .subscribe((x) => {
          if (!x) return;
          this.tradingPartner = x;
        });
    });
  }
}
