<div style="display: none" class="m-l-auto datePicker">
  <form [formGroup]="invoiceGraphsFormGroup">
    <div
      class="datePickOrder p-0 round noMargin fx-ai-center fx-jc-center fx-row flex"
    >
      <mat-form-field class=" ">
        <input
          readonly
          matInput
          [min]="fromMinDate"
          [max]="toMaxDate"
          [matDatepicker]="fromDate"
          formControlName="date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromDate"
        ></mat-datepicker-toggle>
        <mat-datepicker
          (closed)="onClosed()"
          #fromDate
          [touchUi]="true"
        ></mat-datepicker>
      </mat-form-field>
      <button mat-raised-button #open (click)="fromDate.open()">Open</button>
    </div>
  </form>
</div>
<!-- <button mat-raised-button (click)="cancel()">Close</button> -->
