import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { DialogService } from "../../../../app/services/dialog.service";
import { Session } from "../../../models/session";
import {
  TradingPartner,
  TradingPartnerType,
  TradingProcess,
  TradingProcessActions,
} from "../../../models/types";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { PartnerMarketPlaceService } from "../../../services/partner-market-place.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";

export interface MarketplacePartnerType {
  id: TradingPartnerType;
  name: string;
}

@Component({
  selector: "app-partner-marketplace",
  templateUrl: "./partner-marketplace.component.html",
  styleUrls: ["./partner-marketplace.component.sass"],
})
export class PartnerMarketplaceComponent implements OnInit {
  marketplacePartnerTypes: MarketplacePartnerType[] = [];
  displayFn = 20;
  PartnerMarketplaceForm: UntypedFormGroup;
  partners: TradingPartner[] = [];
  tradingPartnerWith = true;
  tradingPartner: Observable<TradingPartner>;

  constructor(
    private dialog: DialogService,
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private marketTradePartnerService: PartnerMarketPlaceService,
    private notificationService: NotificationService,
    private tradingPartnerService: TradingPartnerService,
    private partnerMarketPlaceService: PartnerMarketPlaceService,
    private session: Session,
  ) {}

  ngOnInit() {
    this.setTradingPartner();
    this.setupInputControls();
    this.getTradingPartners(true, null);
    this.loadPartnerTypes();
    // this.hookSearchFilter();
  }

  private setTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      this.tradingPartner = this.tradingPartnerService.getTradingPartner(
        this.session.getSupplierGln(),
      );
    });
  }

  loadPartnerTypes() {
    this.marketplacePartnerTypes = [
      { id: TradingPartnerType.tradingWith, name: "My Partners" },
      { id: TradingPartnerType.notTradingWith, name: "New Partners" },
    ];

    this.PartnerMarketplaceForm.controls.partnerType.setValue(
      TradingPartnerType.tradingWith,
    );
  }

  getTradingPartners(tradingWith: boolean, searchText?: string) {
    const loader = this.dialog.loader();
    this.tradingPartnerService
      .search(this.session.getSupplierGln(), tradingWith, searchText, null)
      .subscribe(
        (x) => {
          if (!x) return;

          //this.setNumberOfstores(x);
          this.partners = x;
          loader.close();
          this.tradingPartnerWith = tradingWith;
        },
        (error) => {
          loader.close();
          this.notificationService.showError(error);
        },
      );
  }

  setNumberOfstores(tradePartners: TradingPartner[]) {
    tradePartners.forEach((tradingPartner) => {
      this.tradingPartnerService
        .getTradingPartner(tradingPartner.gln)
        .subscribe((tp) => {
          if (!tp) return;

          tradingPartner.numberOfStores = tp.numberOfStores;
        });
    });
  }

  viewInfomation(partner: TradingPartner) {
    this.tradingPartnerService.setCurrentTradingPartner(partner);
    this.navigationService.navigateToPartnerMarketplaceDetails();
  }

  setupInputControls() {
    this.PartnerMarketplaceForm = this.formBuilder.group({
      searchMarketplacePartner: ["", null],
      marketPartnerEdit: ["New Partners", null],
      partnerType: ["", null],
    });
  }

  connectPartner(partner: TradingPartner) {
    this.partnerMarketPlaceService.connect(partner.gln, null).subscribe(
      () => {
        this.notificationService.showSuccess("Connection request sent");
      },
      (error) => {
        this.notificationService.showError(error);
      },
    );
  }

  filterTradingPartners(type) {
    if (type.value == 0) {
      this.getTradingPartners(false);
    } else if (type.value == 1) {
      this.getTradingPartners(true);
    }
  }

  getAvailableMessages(tradingProcess: TradingProcess): string {
    let process = "";
    const separator = ", ";
    if (tradingProcess?.acknowledgeOrder) {
      process += TradingProcessActions.Acknowledge + separator;
    }
    if (tradingProcess?.confirmOrder) {
      process += TradingProcessActions.Confirm + separator;
    }
    if (tradingProcess?.rejectOrder) {
      process += TradingProcessActions.Reject + separator;
    }
    if (tradingProcess?.invoiceOrder) {
      process += TradingProcessActions.Invoice;
    }
    return process;
  }

  search() {
    if (
      this.PartnerMarketplaceForm.controls.searchMarketplacePartner.value
        .length <= 0
    ) {
      this.getTradingPartners(this.tradingPartnerWith, undefined);
    } else {
      this.getTradingPartners(
        this.tradingPartnerWith,
        this.PartnerMarketplaceForm.controls.searchMarketplacePartner.value,
      );
    }
  }

  clearSearch() {
    if (
      this.PartnerMarketplaceForm.controls.searchMarketplacePartner.value
        .length <= 0
    ) {
      this.getTradingPartners(this.tradingPartnerWith, undefined);
    } else {
      this.getTradingPartners(
        this.tradingPartnerWith,
        this.PartnerMarketplaceForm.controls.searchMarketplacePartner.value,
      );
    }
  }

  hookSearchFilter() {
    this.PartnerMarketplaceForm.controls.searchMarketplacePartner.valueChanges.subscribe(
      (x) => {
        if (x.length <= 0) {
          this.getTradingPartners(this.tradingPartnerWith, undefined);
        } else {
          this.getTradingPartners(this.tradingPartnerWith, x);
        }
      },
    );
  }
}
