<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <div class="flex m-t-10 width-100">
      <div class="flex fx-row">
        <p class="noMargin m-b-30 title">Active User Reports</p>
        <mat-form-field
          class="m-l-auto orderSearchField p-l-10 p-r-10 width-10 tradeCard round"
        >
          <mat-label>Inactive Days Range</mat-label>
          <mat-select [(ngModel)]="selectedRange">
            <mat-option *ngFor="let range of inactiveDates" [value]="range">{{
              range
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <div class="flex fx-ai-center fx-row round p-r-20 m-b-30">
          <div class="bl-l p-l-10 fx-ai-center fx-row"></div>
        </div>
        <div
          *ngIf="loadingReports"
          class="loadingReports width-100 height-100 flex fx-jc-center fx-ai-center"
        >
          <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
        </div>
        <div *ngIf="!loadingReports" class="grayBanner flex p-20 m-b-20 fx-row">
          <div class="flex fx-row">
            <p class="p-b p-r-10">{{ userReports.length }}</p>
            <p class="flex p-b">Reports</p>
          </div>

          <div class="m-l-auto flex fx-ai-center manageButtons fx-row">
            <button
              matRipple
              class="downloadAllBtn fx-row fx-ai-center flex selectedItem"
              (click)="downloadReport()"
            >
              <span class="noWrap flex fx-row">
                {{ "Download Report" }}
                <img
                  src="assets/icons/downloadIcon.svg"
                  width="20"
                  class="animate__animated"
                />
              </span>
            </button>
          </div>
        </div>

        <div>
          <div
            class="flex fx-row fx-jc-center fx-ai-center"
            *ngIf="userReports.length < 1"
          >
            <h3>There are no reports generated currently</h3>
          </div>
          <table
            *ngIf="!loadingReports && userReports.length > 0"
            matTableFilter
            matTableExporter
            mat-table
            [dataSource]="dataSource"
            class="width-100"
            #exporter="matTableExporter"
          >
            <!-- username Column -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>Username</th>
              <td mat-cell *matCellDef="let element">{{ element.username }}</td>
            </ng-container>

            <!-- supplierName Column -->
            <ng-container matColumnDef="supplierName">
              <th mat-header-cell *matHeaderCellDef>SupplierName</th>
              <td mat-cell *matCellDef="let element">
                {{ getArrayAsString(element.supplierNames) }}
              </td>
            </ng-container>

            <!-- userType Column -->
            <ng-container matColumnDef="userType">
              <th mat-header-cell *matHeaderCellDef>UserType</th>
              <td mat-cell *matCellDef="let element">
                {{ setRolesAsString(element.roles) }}
              </td>
            </ng-container>

            <!-- name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- surname Column -->
            <ng-container matColumnDef="surname">
              <th mat-header-cell *matHeaderCellDef>Surname</th>
              <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
            </ng-container>

            <!-- active Column -->
            <ng-container matColumnDef="active">
              <th mat-header-cell *matHeaderCellDef>Active</th>
              <td mat-cell *matCellDef="let element">
                {{ getActiveStatus(element.lastActivityDate) }}
              </td>
            </ng-container>

            <!-- noOfDaysInactive Column -->
            <ng-container matColumnDef="noOfDaysInactive">
              <th mat-header-cell *matHeaderCellDef>No of Days Inactive</th>
              <td mat-cell *matCellDef="let element">
                {{ getNoOfDaysInactive(element.lastActivityDate) }}
              </td>
            </ng-container>

            <!-- lastDayInactive Column -->
            <ng-container matColumnDef="lastDayInactive">
              <th mat-header-cell *matHeaderCellDef>Last Activity Date</th>
              <td mat-cell *matCellDef="let element">
                {{ formatDate(element.lastActivityDate) }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
