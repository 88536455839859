<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 m-t-30">
  <div class="width-90 myPr ofileCard">
    <div class="w idth-90 m-t-30 p-30 tradeCard">
      <app-users
        *ngIf="!loadTradingPartner"
        [tradingPartnerGln]="tradingPartnerGln"
        class="mpUsers tradeCard"
      ></app-users>
    </div>
  </div>
</div>
