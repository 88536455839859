<div class="flex p-50 fx-jc-center fx-ai-center">
  <div mat-dialog-content class="mat-typography">
    <h3 class="p-40">{{ message }}</h3>
  </div>
  <div mat-dialog-actions class="width-100 p-b-50 fx-jc-center">
    <button
      mat-button
      class="btn width-40 p-b-50 round primaryBtn"
      (click)="confirm()"
    >
      {{ buttonTxt }}
    </button>
  </div>
</div>
