<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <form class="flex m-t-10 width-100" [formGroup]="AdminReportsForm">
      <div class="flex fx-row">
        <div>
          <p class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center">
            Report for:
          </p>
          <p class="title">{{ supplierName }} - {{ supplierGln }}</p>
          <div class="flex fx-row">
            <p
              (click)="goToReportView()"
              class="noMargin selectedItem breadcrumb fw-700 paragraph"
            >
              Reports
            </p>
            <img
              class="icon flex p-r-10 p-l-10"
              width="200px"
              src="./assets/icons/rightArrowBlack.svg"
            />
            <p
              class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center fx-ai-center"
            >
              Report Items
            </p>
          </div>
        </div>
        <div
          class="flex fx-ai-center m-l-auto fx-row round tradeCard width-30 p-t-10 p-r-20 p-l-20"
        >
          <mat-form-field class="width-65">
            <input
              matInput
              (blur)="clearSearch()"
              (keyup.enter)="search($event.target.value)"
              (blur)="clearSearch()"
              formControlName="searchReports"
            />
            <mat-label><mat-icon>search</mat-icon> Search Report </mat-label>
          </mat-form-field>
          <div class="bl-l p-l-10 fx-ai-center fx-row"></div>
        </div>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <div class="flex fx-row">
          <p class="heading flex p-b-10">Report Items</p>
          <div class="m-l-auto flex fx-ai-center manageButtons fx-row">
            <button
              matRipple
              class="downl oadAllBtn fx-row fx-ai-center flex selectedItem"
              (click)="downloadExcelFile()"
            >
              {{ "Download Excel" | uppercase }}
              <img
                src="assets/icons/downloadIcon.svg"
                width="20"
                class="animate__animated"
              />
            </button>
          </div>
        </div>

        <div class="grayBanner flex p-20 m-b-20 fx-row">
          <div class="flex fx-row">
            <p class="p-b p-r-10">{{ orderReports.length }}</p>
            <p class="flex p-b">Order Report Items</p>
          </div>
        </div>

        <div>
          <div
            *ngIf="loader"
            class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
          >
            <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
          </div>
          <div
            #TABLE
            class="scroll"
            style="border: 1px lightgray solid; border-radius: 10px !important"
          >
            <table
              #table
              *ngIf="!loader"
              matTableFilter
              matTableExporter
              mat-table
              [dataSource]="dataSource"
              class="width-100"
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="orderNumber">
                <th mat-header-cell *matHeaderCellDef>Order Number</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.OrderNumber }}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderDate">
                <th mat-header-cell *matHeaderCellDef>Order Date</th>
                <td mat-cell *matCellDef="let element" class="noWrap">
                  {{ element.OrderDate | date : dateFormat }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
              </ng-container>

              <ng-container matColumnDef="invoiceDate">
                <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                <td mat-cell *matCellDef="let element" class="noWrap">
                  {{ element.InvoiceDate | date : dateFormat }}
                </td>
              </ng-container>

              <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef>Sender</th>
                <td mat-cell *matCellDef="let element">{{ element.Sender }}</td>
              </ng-container>

              <ng-container matColumnDef="receiver">
                <th mat-header-cell *matHeaderCellDef>Receiver</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Receiver }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unitBarcode">
                <th mat-header-cell *matHeaderCellDef>Unit Barcode</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.UnitBarcode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="packBarcode">
                <th mat-header-cell *matHeaderCellDef>Pack Barcode</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.PackBarcode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="packSize">
                <th mat-header-cell *matHeaderCellDef>Pack Size</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.PackSize }}
                </td>
              </ng-container>

              <ng-container matColumnDef="productDescription">
                <th mat-header-cell *matHeaderCellDef>Product Description</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.ProductDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderedQuantity">
                <th mat-header-cell *matHeaderCellDef>Ordered Quantity</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.OrderedQuantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="invoiceQuantity">
                <th mat-header-cell *matHeaderCellDef>Invoice Quantity</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.InvoiceQuantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>Price</th>
                <td mat-cell *matCellDef="let element">{{ element.Price }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>

          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </form>
  </div>
</div>
