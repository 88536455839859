<p class="subHeading flex p-b-10">Last performed actions</p>
<div class="grayBanner flex m-b-20">
  <div class="flex fx-row">
    <div class="flex width-100">
      <p class="flex">This week</p>
    </div>
  </div>
  <div
    *ngFor="let action of lastPerformedActions"
    class="flex bl-t-l p-l-10 m-t-20 width-100 fx-row"
    [ngStyle]="borderStyle(action.status)"
  >
    <div class="flex width-100">
      <div class="flex m-t-20 width-100">
        <p class="flex nowrap fx-row">
          <span class="p-r-10 fw-600"> {{ action.status }} </span> an order from
          <span class="p-l-10"> {{ action.retailer }} </span>
        </p>
        <p class="flex">
          {{ action.orderDate | date : dateFormat }} at
          {{ action.orderDate | date : "HH:mm" }}
        </p>
      </div>
    </div>
    <div class="flex m-l-auto width-100">
      <div class="flex m-t-20 width-100">
        <p class="flex nowrap fx-row">
          <span class="p-r-10 fw-600">
            {{ action.status === "Invoiced" ? "Invoice" : "Order" }} number:
          </span>
          1234567
        </p>
        <p class="flex nowrap fx-row">
          By <span class="p-l-10 fw-600">[{{ action.byUser }}]</span>
        </p>
      </div>
    </div>
  </div>
</div>
