import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { MarketplacePartnerType } from "../partner-marketplace/partner-marketplace.component";
import { TradingPartner, TradingPartnerSummary } from "./../../../models/types";
import { DialogService } from "./../../../services/dialog.service";
import { NavigationService } from "./../../../services/navigation.service";
import { NotificationService } from "./../../../services/notification.service";
import { TradingPartnerService } from "./../../../services/trading-partner.service";
import { Settings } from "./../../../settings";

const ELEMENT_DATA: TradingPartner[] = [];
@Component({
  selector: "app-trading-partners",
  templateUrl: "./trading-partners.component.html",
  styleUrls: ["./trading-partners.component.sass"],
})
export class TradingPartnersComponent implements OnInit {
  marketplacePartnerTypes: MarketplacePartnerType[] = [];
  loader = false;
  tradingPartnerTypes: any[] = [
    { name: "All", value: "" },
    { name: "Retailer", value: "Retailer" },
    { name: "Supplier", value: "Supplier" },
  ];
  displayedColumns: string[] = ["gln", "name", "type", "view"];
  dataSource = new MatTableDataSource<TradingPartnerSummary>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  tradingPartners: TradingPartnerSummary[] = [];
  PartnerMarketplaceForm: UntypedFormGroup;
  defaultItemsPerPage = 0;
  tradingPartnerWith = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private settings: Settings,
    private tradingPartnerService: TradingPartnerService,
    private navigate: NavigationService,
    private dialogService: DialogService,
    private notification: NotificationService,
  ) {}

  ngOnInit() {
    this.defaultItemsPerPage = this.settings.defaultItemsPerPage;
    this.setupInputControls();
    this.getTradingPartners();
    this.tradingPartnerService.tradingPartnerBreadcrumb = [];
  }

  setupInputControls() {
    this.PartnerMarketplaceForm = this.formBuilder.group({
      searchMarketplacePartner: ["", null],
      searchTradingPartner: ["", null],
      partnerType: ["", null],
    });
  }

  getTradingPartners() {
    this.tradingPartners = [];

    this.loader = true;
    let count = 0;

    const retailerSubscription: Subscription = this.tradingPartnerService
      .getRetailers()
      .subscribe((x) => {
        if (x) {
          this.tradingPartners = this.tradingPartners.concat(x);
          this.dataSource.data = this.tradingPartners;
          this.dataSource.paginator = this.paginator;
          count++;
          count === 2 ? (this.loader = false) : "";
          retailerSubscription.unsubscribe();
        }
      });
    const supplierSubscription: Subscription = this.tradingPartnerService
      .getSuppliers()
      .subscribe((x) => {
        if (x) {
          this.tradingPartners = this.tradingPartners.concat(x);
          this.dataSource.data = this.tradingPartners;
          this.dataSource.paginator = this.paginator;
          count++;
          count === 2 ? (this.loader = false) : "";
          supplierSubscription.unsubscribe();
        }
      });
  }

  clearSearch() {
    this.PartnerMarketplaceForm.controls.searchTradingPartner.value == "";
    this.PartnerMarketplaceForm.controls.searchTradingPartner.value == ""
      ? (this.search(""), (this.paginator = this.dataSource.paginator))
      : {};
  }

  newTradingPartner() {
    this.loader = true;
    this.dialogService
      .addTradingPartner(null, "Add")
      .afterClosed()
      .subscribe(
        (response) => {
          this.loader = false;
          if (response.response) {
            this.notification.showSuccess("Trading Partner Successfully Added");
            this.goToTradingPartner(response.tp.gln);
          }
        },
        () => {
          this.notification.showError(
            new Error("Error Adding Trading Partner"),
          );
          this.loader = false;
        },
      );
  }

  search(filterValue: string) {
    this.dataSource.data = this.tradingPartners.filter(
      (x) =>
        (x.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
          x.gln.toLowerCase().includes(filterValue.trim().toLowerCase())) &&
        x.type
          .toLowerCase()
          .includes(
            this.PartnerMarketplaceForm.controls.partnerType.value
              .trim()
              .toLowerCase(),
          ),
    );
  }

  goToTradingPartner(gln: string) {
    this.navigate.navigateToTradingPartnerDetails(gln);
  }

  filterTradingPartners() {
    this.dataSource.data = this.tradingPartners.filter((x) =>
      x.type.includes(this.PartnerMarketplaceForm.controls.partnerType.value),
    );
  }
}
