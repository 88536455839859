<div class="flexableWidth flex fx-jc-center fx-ai-center grayBG m-b-70 scroll">
  <div class="width-95 flex m-20 fx-jc-center">
    <div class="fx-row flex">
      <p class="m-b-30 title">{{ (tradingPartner | async)?.name }}</p>
      <div class="m-l-auto">
        <button
          mat-flat-button
          class="primaryBtn btn round m-l-auto"
          (click)="viewInvoices()"
        >
          View Reports
        </button>
      </div>
    </div>
    <div
      class="fx-ai-center fx-row m-t-10 m-b-10 flex"
      (click)="navigateToGetFinanced()"
    >
      <!-- <button mat-flat-button class="flex breadCrumb fx-ai-center m-l-10" (click)="goBack()">
             </button> -->
      <p class="flex tpInfoP breadCrumb fx-ai-center m-l-10">
        Receivables Finance
      </p>
    </div>
    <div class="flex fx-row">
      <div class="autoWidth m-10 tradeCard p-20">
        <form
          class="fx-row flex fx-jc-spasce-between blb-l m-b-10"
          [formGroup]="invoiceGraphsFormGroup"
        >
          <p class="subHeading info">
            Invoices Sold
            <span
              class="blue-i"
              matTooltip="You have successfully sold these invoices."
              matTooltipClass="tooltipWhite"
              >i</span
            >
          </p>
          <div class="m-l-auto datePicker width-75">
            <div
              [ngClass]="{
                'tradeCard animate__animated animate__fadeIn': !showAllOrders
              }"
              class="m-l-auto width-50 datePickOrder p-0 round fx-ai-center fx-jc-center fx-row flex"
            >
              <mat-form-field class=" ">
                <mat-label>From Date</mat-label>
                <input
                  matInput
                  [max]="toMaxDate"
                  [matDatepicker]="fromDate"
                  formControlName="fromDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="fromDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="">
                <mat-label>To Date</mat-label>
                <input
                  matInput
                  [min]="fromMinDate"
                  [max]="todaysDate"
                  [matDatepicker]="toDate"
                  formControlName="toDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="toDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </form>
        <app-invoices-sold
          [fromMinDate]="fromMinDate"
          [toMaxDate]="toMaxDate"
        ></app-invoices-sold>
      </div>
      <div class="autoWidth m-10 tradeCard p-20">
        <p class="subHeading info blb-l m-b-20 p-b-20">
          Invoices Pending
          <span
            class="blue-i"
            matTooltip="You made a sell offer for these invoices, but the funder has not yet accepted the offer."
            matTooltipClass="tooltipWhite"
            >i</span
          >
        </p>
        <app-invoice-pending
          [fromMinDate]="fromMinDate"
          [toMaxDate]="toMaxDate"
        ></app-invoice-pending>
      </div>
    </div>
    <!-- <div class="flex fx-row">
        <div [ngClass]="{'autoWidth m-10 tradeCard p-20': isDataVisible}" class="">
            <p *ngIf="isDataVisible" class="subHeading info blb-l m-b-20 p-b-20">Invoices Rejected <span class="blue-i">i</span></p> 
            <app-invoice-rejected [fromMinDate]="fromMinDate" (visibility)="isVisible($event)" [toMaxDate]="toMaxDate"></app-invoice-rejected>
        </div>
        <div class="autoWidth m-10 p-20">
           
        </div>
    </div> -->
    <div>
      <div class="p-20 m-10 tradeCard">
        <app-invoices-available
          [fromMinDate]="fromMinDate"
          [toMaxDate]="toMaxDate"
        ></app-invoices-available>
      </div>
    </div>
  </div>
</div>
