<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 scroll">
  <div class="width-90 flex m-20 fx-jc-center">
    <p class="m-b-30 title">{{ (tradingPartner | async)?.name }}</p>

    <div
      class="fx-ai-center fx-row m-t-20 m-b-20 flex"
      (click)="navigateToGetFinanced()"
    >
      <!-- <button mat-flat-button class="flex breadCrumb fx-ai-center m-l-10" (click)="goBack()">
             </button> -->
      <div>
        <mat-icon class="icon">arrow_back_ios</mat-icon>
      </div>
      <p class="flex tpInfoP breadCrumb fx-ai-center m-l-10">
        Receivables Finance
      </p>
    </div>

    <div>
      <p class="paragraph">
        If your company trades in goods and services, you can unlock valuable
        cash trapped in unpaid invoices. For a small fee, your can sell some or
        all of your invoices to a funder for early payment.
      </p>
      <p class="paragraph">
        Most importantly, this financing is not counted as debt and stays of
        your company’s balance sheet, so your debt ratio or other outstanding
        lines of credit are not affected.
      </p>
      <p class="paragraph">
        Choose which invoices you would like to submit for early payment and get
        fresh capital for your company.
      </p>
    </div>

    <div class="p-30 m-t-30 tradeCard">
      <p class="tpInfoP">Here’s how invoice funding can help you:</p>
      <div class="flex fx-row fx-ai-center">
        <ul class="listP">
          <li>On-demand liquidity</li>
          <li>Competitive rates based on your credit rating</li>
          <li>It’s paperless</li>
          <li>No involvement from customers required</li>
        </ul>
        <div class="m-l-auto">
          <img
            class="m-l-auto"
            src="assets/images/vectors/heresHowInvoiceImage.svg"
            width="200"
            class="animate__animated"
          />
        </div>
      </div>
    </div>

    <div class="p-30 m-t-30 m-b-10 tradeCard">
      <p class="tpInfoP">Information we need from you:</p>
      <div class="flex fx-row fx-ai-center">
        <ul class="listP">
          <li>Tenor or Payment terms with your buyer(s)</li>
          <li>Information about your business</li>
          <li>IInformation about you</li>
        </ul>
        <div class="m-l-auto">
          <img
            class="m-l-auto"
            src="assets/images/vectors/InfoWeNeedImage.svg"
            width="200"
            class="animate__animated"
          />
        </div>
      </div>
    </div>

    <!-- <div class="m-b-10"></div> -->

    <div
      style="bottom: 0px"
      class="widsth-95 p-20 m-t-30 fx-ai-center tradeCard flex fx-row"
    >
      <p class="P-l">
        Would you like to instantly receive working capital for your outstanding
        invoices?
      </p>
      <div class="m-l-auto">
        <button
          mat-flat-button
          class="primaryBtn btn round m-l-auto"
          (click)="apply()"
        >
          Apply Now
        </button>
      </div>
    </div>
  </div>
</div>
