import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Session } from "../models/session";
import { DetailedSupplierReport, SupplierReport, User } from "../models/types";
import { newClient } from "../services/api-gateway.service.js";
import { ReportRepository } from "./report-repository";

const apigClient = newClient();

@Injectable()
export class AwsReportRepository implements ReportRepository {
  constructor(private session: Session) {}

  generateTradingPartnerDetailedSupplierReports(
    svGln: string,
    dateFrom: string,
    dateTo: string,
    suppliers: string[],
  ): Observable<DetailedSupplierReport[]> {
    const retObject = new Subject<DetailedSupplierReport[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {};
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateFrom: dateFrom,
          dateTo: dateTo,
          suppliers: suppliers,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .reportTradingPartnersDetailedSupplierOrderGet(
          params,
          {},
          additionalParams,
        )
        .then((result) => {
          const analyticResult = result.data;
          retObject.next(analyticResult);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  generateTradingPartnerSupplierReports(
    svGln: string,
    dateFrom: string,
    dateTo: string,
    suppliers: string[],
  ): Observable<SupplierReport[]> {
    const retObject = new Subject<SupplierReport[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {};
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateFrom: dateFrom,
          dateTo: dateTo,
          suppliers: suppliers,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .reportTradingPartnersSupplierOrderGet(params, {}, additionalParams)
        .then((result) => {
          const analyticResult = result.data;
          retObject.next(analyticResult);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  generateActiveUserReports(svGln: string): Observable<User[]> {
    const usersResponse = new Subject<User[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .reportUsersGet({}, null, additionalParams)
        .then((getUserResult) => {
          const userResult = getUserResult.data as User[];
          usersResponse.next(userResult);
        })
        .catch(({ data }) => {
          usersResponse.error(data);
        });
    });

    return usersResponse;
  }
}
