import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

import { EditTradingPartnerComponent } from "../../retailer-information/edit-trading-partner/edit-trading-partner.component";
import {
  TradingPartner,
  TradingPartnerSummary,
} from "./../../../../../../models/types";
import { NotificationService } from "./../../../../../../services/notification.service";
import { TradingPartnerService } from "./../../../../../../services/trading-partner.service";

const ELEMENT_DATA: TradingPartnerSummary[] = [];

@Component({
  selector: "app-link-retailer",
  styleUrls: ["./link-retailer.component.sass"],
  templateUrl: "./link-retailer.component.html",
})
export class LinkRetailerComponent implements OnInit {
  allRetailers: TradingPartnerSummary[] = [];
  dataSource = new MatTableDataSource<TradingPartnerSummary>(ELEMENT_DATA);
  displayedColumns: string[] = ["gln", "image", "name", "link"];
  linkedRetailers: TradingPartnerSummary[] = [];
  linkedTradingPartnerForm: UntypedFormGroup;
  loader = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  retailer: TradingPartner;
  searchForm: UntypedFormGroup;
  tradingPartners: TradingPartner[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private dialogRef: MatDialogRef<EditTradingPartnerComponent>,
    private notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.retailer = data.retailer;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getTradingPartners() {
    this.tradingPartnerService.getRetailers().subscribe((x) => {
      if (x) {
        this.allRetailers = x;
        this.dataSource.data = x;
      }
    });
  }

  isLinked(gln: string) {
    return this.linkedRetailers.some((x) => x.gln == gln);
  }

  linkedTypesLabel(types: string) {
    const typesArray = {
      Retailer: "Stores",
      Supplier: "Retailers",
    };
    return typesArray[types];
  }

  linkRetailer(retailer) {
    const linkedTradingPartner = this.linkedRetailers.find((ltp) => {
      return ltp?.gln == retailer.gln;
    });
    if (linkedTradingPartner) {
      this.linkedRetailers.splice(
        this.linkedRetailers.indexOf(linkedTradingPartner),
        1,
      );
    } else {
      this.linkedRetailers.push(
        this.allRetailers.find((r) => {
          return r.gln == retailer.gln;
        }),
      );
    }
  }

  ngOnInit(): void {
    this.getTradingPartners();
    this.setLinkedRetailers();
    this.setupInputControls();
  }

  refreshData() {
    this.getTradingPartners();
    this.setLinkedRetailers();
  }

  search(filterValue: string) {
    this.dataSource.data = this.allRetailers.filter(
      (x) =>
        x.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.gln.toLowerCase().includes(filterValue.trim().toLowerCase()),
    );
  }

  setLinkedRetailers() {
    this.linkedRetailers = [];

    this.linkedRetailers = this.retailer?.linkedTradingPartners;

    this.dataSource.paginator = this.paginator;
  }

  setupInputControls() {
    this.searchForm = this.formBuilder.group({
      searchTradingPartner: ["", null],
    });
    this.linkedTradingPartnerForm = this.formBuilder.group({});
  }

  submit() {
    this.loader = true;

    const tradingPartner = {} as TradingPartner;

    tradingPartner.gln = this.retailer.gln;
    tradingPartner.linkedTradingPartners = this.linkedRetailers;

    this.tradingPartnerService.editTradingPartner(tradingPartner).subscribe(
      (res) => {
        if (res) {
          this.notification.showSuccess("Retailers linked successfully");
          this.loader = false;
          this.dialogRef.close({ response: true, tp: tradingPartner });
        }
      },
      (error) => {
        this.notification.showError(error);
        this.loader = false;
      },
    );
  }
}
