<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 scroll">
  <div class="width-90 flex m-20 fx-jc-center">
    <p class="m-b-30 title">{{ (tradingPartner | async)?.name }}</p>

    <div
      class="fx-ai-center fx-row m-t-20 m-b-20 flex"
      (click)="navigateToGetFinanced()"
    >
      <!-- <button mat-flat-button class="flex breadCrumb fx-ai-center m-l-10" (click)="goBack()">
             </button> -->
      <div>
        <mat-icon class="icon">arrow_back_ios</mat-icon>
      </div>
      <p class="flex tpInfoP breadCrumb fx-ai-center m-l-10">
        Receivables Finance
      </p>
    </div>

    <div class="p-30 m-t-10 tradeCard">
      <p class="tpInfoP">What happens next?</p>
      <div class="flex fx-row fx-ai-center">
        <ul class="whatHappensNext">
          <!-- <li>You will meet & greet your support team</li>
              <li>We will engage the Funders for credit approval on your program</li>
              <li>You may need to provide us with additional information</li>
              <li>We will finalise and confirm the terms and conditions with you</li>
              <li>Once all contracting is complete, the Funder will onboard you as a client</li>
              <li>We will support your platform set up & training requirements</li>
              <li>Success! You are ready to trade invoices for cash</li> -->
          <li>You meet our implementation partner, Addendum</li>
          <li>You provide the necessary information to Addendum</li>
          <li>Funder(s) are engaged to approve a facility for you</li>
          <li>
            You may need to provide the funder(s) with additional information
            during the credit process
          </li>
          <li>
            Once a facility is approved, the terms and conditions are agreed
            between yourself and the funder
          </li>
          <li>
            You enter into an agreement with the funder (for your facility) as
            wellas Addendum (for use of their platform)
          </li>
          <li>The funder and Addendum onboard you as a client</li>
          <li>Your Receivables Finance programme is set up on the platform</li>
          <li>You receive training, if needed</li>
          <li>Success! You are ready to sell your invoices</li>
        </ul>
        <div class="m-l-auto">
          <img
            class="m-l-auto"
            src="assets/images/vectors/nextStepsImage.svg"
            width="400"
            class="animate__animated"
          />
        </div>
      </div>
    </div>

    <div class="p-30 m-t-30 m-b-10 tradeCard">
      <p class="fw-600 redText">FAQs</p>
      <h3 class="fw-700">Everything you need to know…</h3>
      <div class="flex">
        <mat-accordion class="example-headers-alifgn" multi>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="">WHAT IS VODALEND TRADE FINANCE?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="p-20">
              Trade finance is quite simply, finance for your business. It
              describes the different ways to finance goods or services in a
              transaction. A third party like a bank or financier supports your
              business to bridge the gap between the purchase of product and
              payment by the end customer to put you in a better cash flow
              position.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="">
                  WHAT IS THE NEXT STEP AFTER PRODUCT ONBOARDING ON VODATRADE?
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="p-20">
              After applying for the programme, you would have received an email
              confirming your application. The support team will contact you to
              navigate you through the process.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="">WHO IS ADDENDUM?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="p-20">
              VodaLend and Addendum (Africa’s supply chain finance (SCF) leader)
              have partnered to provide you with a selective receivable
              financing solution. Their innovative multi-bank platform and
              award-winning service have helped customers free up billions of
              Rands in working capital, strengthening supply chains and
              improving supplier relationships along the way. To read more,
              <a
                class="clickMe"
                (click)="navToAddendum()"
                href="#/invoiceProcessing/nextSteps"
                >view their website</a
              >.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="">WHAT DO I NEED TO APPLY?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="p-20">
              We have most of your information already; we might just need some
              additional information based on the banks’ credit application
              process. Addendum will guide you in what is needed every step of
              the way
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="">WHAT CAN I USE THE FUNDING FOR?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="p-20">
              You can use the funding for anything your business needs. This can
              be for bridging receivables gaps, managing cash flow, buying
              inventory and equipment, hiring employees, etc. Anything your
              business needs.
            </p>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="">HOW DO I GET IN TOUCH?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="p-20">
              Simply call us on 082 1940 or email
              <a
                href="mailto:0821940@vodacom.co.za"
                target="_top"
                style="color: red"
                >0821940@vodacom.co.za</a
              >
              and our support team will assist you.
            </p></mat-expansion-panel
          >
        </mat-accordion>
      </div>
    </div>

    <!-- <div class="m-b-10"></div> -->

    <!-- <div style="bottom: 0px;" class="widsth-95 p-20 m-t-30 fx-ai-center tradeCard flex fx-row">
        <p class="P-l">Would you like to instantly receive working capital for your outstanding invoices?</p>
        <div class=" m-l-auto">
            <button mat-flat-button class="primaryBtn btn round m-l-auto" (click)="apply()">Apply Now</button>
        </div>
    </div> -->
  </div>
</div>
