import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Order } from "../models/order";
import {
  Address,
  Contact,
  Document,
  NotificationType,
  TradingPartner,
} from "../models/types";
import * as MockData from "../repositories/mockData/UsersMockData.json";
import { DocumentsRepository } from "./documents.repository";

@Injectable()
export class MockDocumentRepository implements DocumentsRepository {
  getOrder(): Observable<Order> {
    throw new Error("Method not implemented.");
  }

  getOrders(): Observable<Order[]> {
    throw new Error("Method not implemented.");
  }

  getMockData() {
    return MockData;
  }

  getOrderAddress(): import("rxjs").Observable<
    import("../models/types").Address[]
  > {
    const retObj = new Subject<Address[]>();
    retObj.next([
      {
        // line1: MockData.new[1].name,
        line2: "Crown Mines",
        line3: "Johannesburg",
        postalCode: "2092",
      },
    ]);
    return retObj;
  }

  getContact(): import("rxjs").Observable<import("../models/types").Contact[]> {
    const retObject = new Subject<Contact[]>();
    retObject.next([
      {
        faxNo: "",
        telNo: "011 495-6928",
      },
    ]);
    return retObject;
  }

  getTradingPartner(): import("rxjs").Observable<
    import("../models/types").TradingPartner[]
  > {
    const reObject = new Subject<TradingPartner[]>();
    let addr: any;
    let contactVal: any;
    this.getOrderAddress().subscribe((x) => {
      addr = x[0];
    });
    this.getContact().subscribe((x) => {
      contactVal = x[0];
    });
    reObject.next([
      {
        name: "EDCON (PROPRIETARY) LIMITED",
        vatNo: "4460236773",
        imageURL:
          "https://vodacom-ecs-vodatrade-web.s3-eu-west-1.amazonaws.com/images/tradingPartner/Edcon.jpg",
        addresses: addr,
        contact: contactVal,
        gln: "123",
        vatCountryCode: "",
        events: [],
      },
    ]);

    return reObject;
  }

  searchDocuments(): import("rxjs").Observable<Document[]> {
    const retObj = new Subject<Document[]>();

    retObj.next([
      {
        id: "0",
        documentType: "ORDER",
        documentNumber: "12345",
        status: "NEW",
        documentDate: new Date(),
        receiver: "",
        sender: "",
      },
    ]);

    return retObj;
  }

  public acknowledgeOrder(): Observable<void> {
    return null;
  }

  public rejectOrder(): Observable<void> {
    return null;
  }

  public confirmOrder(): Observable<void> {
    return null;
  }

  public newOrder(): Observable<void> {
    return null;
  }

  public invoiceOrder(): Observable<void> {
    return null;
  }

  public editOrder(): Observable<void> {
    return null;
  }

  public getRetailers(): Observable<TradingPartner[]> {
    const retObj = new BehaviorSubject<TradingPartner[]>(null);

    retObj.next([
      {
        addresses: [
          {
            line1: "Private Bag 1/2/3",
            line2: "Crown Mines",
            line3: "Crown Mines",
            line4: "Gauteng",
            postalCode: "2025",
            type: "Postal Address",
          },
        ],
        contact: {
          telNo: "012 469 6850",
        },
        contacts: [
          {
            type: "Fax Number",
          },
          {
            number: "011 495 6000",
            type: "Telephone Number",
          },
        ],
        events: [],
        gln: "6094618002931",
        imageURL:
          "https://vodacom-ecs-vodatrade-web-meta.s3.af-south-1.amazonaws.com/images/tradingPartner/6002782000012.png",
        isTaxExempt: !!0,
        linkedTradingPartners: [],
        name: "Test Retailer",
        notificationType: NotificationType.Email,
        notifyNewOrder: !!0,
        tradingProcess: {
          acknowledgeOrder: true,
          confirmOrder: true,
          editOrderLines: true,
          invoiceOrder: true,
          rejectOrder: true,
          sendAcknowledgement: false,
          sendConfirmation: false,
          sendInvoice: true,
          sendRejection: false,
        },
        type: "Retailer",
        vatCountryCode: "ZAF",
        vatNo: "4390253492",
        vatRateCode: "ZAF",
      },
      {
        addresses: [
          {
            line1: "Private Bag 1/2/3",
            line2: "Crown Mines",
            line3: "Crown Mines",
            line4: "Gauteng",
            postalCode: "2025",
            type: "Postal Address",
          },
        ],
        contact: {
          telNo: "011 495 6000",
        },
        contacts: [
          {
            type: "Fax Number",
          },
          {
            number: "011 495 6000",
            type: "Telephone Number",
          },
        ],
        events: [],
        gln: "6002973002931",
        imageURL:
          "https://vodacom-ecs-vodatrade-web-meta.s3.af-south-1.amazonaws.com/images/tradingPartner/6002782000012.png",
        isTaxExempt: !!0,
        linkedTradingPartners: [],
        name: "Foschini",
        notificationType: NotificationType.Email,
        notifyNewOrder: !!0,
        tradingProcess: {
          acknowledgeOrder: true,
          confirmOrder: false,
          editOrderLines: false,
          invoiceOrder: true,
          rejectOrder: true,
          sendAcknowledgement: false,
          sendConfirmation: false,
          sendInvoice: true,
          sendRejection: false,
        },
        type: "Retailer",
        vatCountryCode: "ZAF",
        vatNo: "4390229492",
        vatRateCode: "ZAF",
      },

      {
        addresses: [
          {
            line1: "18 Hennie Alberts Street",
            line2: "Alrode South",
            line3: "South Africa",
            postalCode: "1456",
            type: "Postal Address",
          },
        ],
        connectionRequests: [],
        contact: {
          faxNo: "+27 11 900 2645 ",
          telNo: "+27 11 868 5637",
        },
        contacts: [
          {
            number: "+27 11 900 2645",
            type: "Fax Number",
          },
          {
            number: "+27 11 868 5637",
            type: "Telephone Number",
          },
        ],
        events: [],
        gln: "6004930000318",
        imageURL:
          "https://vodacom-ecs-vodatrade-web.s3-eu-west-1.amazonaws.com/images/tradingPartner/6006073000019.png",
        isTaxExempt: !!0,
        linkedTradingPartners: [
          {
            gln: "6002782000012",
            imageURL:
              "https://vodacom-ecs-vodatrade-web.s3-eu-west-1.amazonaws.com/images/tradingPartner/Edcon.png",
            name: "Edcon (Proprietary) Limited",
          },
          {
            gln: "6002973002931",
            imageURL:
              "https://vodacom-ecs-vodatrade-web.s3-eu-west-1.amazonaws.com/images/tradingPartner/Foschini.png",
            name: "Foschini",
          },
        ],
        name: "DEV INC TEST",
        notificationType: NotificationType.Email,
        notifyNewOrder: !!0,
        tradingProcess: {
          acknowledgeOrder: true,
          confirmOrder: true,
          editOrderLines: true,
          invoiceOrder: true,
          rejectOrder: true,
          sendAcknowledgement: true,
          sendConfirmation: true,
          sendInvoice: true,
          sendRejection: true,
        },
        type: "Supplier",
        vatCountryCode: "ZAF",
        vatNo: "1234567890",
        vatRateCode: "ZAF",
      },

      {
        addresses: [
          {
            line1: "Private Bag 1/2/3",
            line2: "Crown Mines",
            line3: "Crown Mines",
            line4: "Gauteng",
            postalCode: "2025",
            type: "Postal Address",
          },
        ],
        contact: {
          telNo: "011 495 6000",
        },
        contacts: [
          {
            type: "Fax Number",
          },
          {
            number: "011 495 6000",
            type: "Telephone Number",
          },
        ],
        events: [],
        gln: "6002782000012",
        imageURL:
          "https://vodacom-ecs-vodatrade-web-meta.s3.af-south-1.amazonaws.com/images/tradingPartner/6002782000012.png",
        isTaxExempt: !!0,
        linkedTradingPartners: [],
        name: "Edgars",
        notificationType: NotificationType.Email,
        notifyNewOrder: !!0,
        tradingProcess: {
          acknowledgeOrder: true,
          confirmOrder: false,
          editOrderLines: false,
          invoiceOrder: true,
          rejectOrder: true,
          sendAcknowledgement: false,
          sendConfirmation: false,
          sendInvoice: true,
          sendRejection: false,
        },
        type: "Retailer",
        vatCountryCode: "ZAF",
        vatNo: "4390229492",
        vatRateCode: "ZAF",
      },

      {
        addresses: [
          {
            line1: "Tom Jones Street",
            line4: "BENONI",
            type: "Business Address",
          },
        ],
        contact: {
          telNo: "825789120",
        },
        contacts: [
          {
            type: "Fax Number",
          },
          {
            number: "825789120",
            type: "Telephone Number",
          },
        ],
        events: [],
        gln: "6002782002450",
        imageURL:
          "https://vodacom-ecs-vodatrade-web-meta.s3.af-south-1.amazonaws.com/images/tradingPartner/unknown.png",
        isTaxExempt: !!0,
        linkedTradingPartners: [],
        name: "RSQ BENONI LA",
        notificationType: NotificationType.Email,
        notifyNewOrder: !!0,
        parentGln: "6002782000012",
        tradingProcess: {
          acknowledgeOrder: !!0,
          confirmOrder: !!0,
          editOrderLines: !!0,
          invoiceOrder: !!0,
          rejectOrder: !!0,
          sendAcknowledgement: !!0,
          sendConfirmation: !!0,
          sendInvoice: !!0,
          sendRejection: !!0,
        },
        type: "Store",
        vatCountryCode: "ZAF",
        vatNo: "4460236773",
        vatRateCode: "ZAF",
      },
    ]);

    return retObj;
  }
}
