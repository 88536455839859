<div
  *ngIf="loadingTradingPartner"
  class="animate__loadingFlash animate__animated animate__slow animate__infinite width-100 flex height-90 flex fx-jc-center"
>
  <div class="width-50 m-b-30 flex fx-row">
    <div class="loadBar height-5 p-5 m-b-10 width-10"></div>
    <div class="loadBar height-5 p-5 m-b-10 width-25"></div>
  </div>
  <div class="width-50 flex">
    <div class="loadBar height-5 p-5 m-b-10 width-30"></div>
    <div class="loadBar height-5 p-5 m-b-10 width-40"></div>
    <div class="loadBar height-5 p-5 m-b-10 width-15"></div>
    <div class="loadBar height-5 p-5 m-b-10 width-45"></div>
  </div>
  <div class="width-50 m-t-50 flex fx-row fx-ai-center">
    <div class="loadBar height-5 p-5 m-5 width-10"></div>
    <div class="loadBar height-5 p-5 m-5 width-40"></div>
  </div>
  <div class="width-50 flex fx-row fx-ai-center">
    <div class="loadBar height-5 p-5 m-5 width-10"></div>
    <div class="loadBar height-5 p-5 m-5 width-40"></div>
  </div>
  <div class="width-50 flex fx-row fx-ai-center">
    <div class="loadBar height-5 p-5 m-5 width-10"></div>
    <div class="loadBar height-5 p-5 m-5 width-40"></div>
  </div>
</div>

<div
  *ngIf="!loadingTradingPartner && tradingPartnerObj !== null"
  class="animate__animated animate__fadeIn"
>
  <h3 class="fw-500">
    {{ tradingPartnerObj.name }}
  </h3>

  <p class="p-t-10 addressP">
    {{ tradingPartnerObj?.addresses?.[0]?.line1 }}&nbsp;
  </p>
  <p class="addressP">{{ tradingPartnerObj?.addresses?.[0]?.line2 }}&nbsp;</p>
  <p class="addressP">{{ tradingPartnerObj?.addresses?.[0]?.line3 }}&nbsp;</p>
  <p class="addressP">{{ tradingPartnerObj?.addresses?.[0]?.line4 }}&nbsp;</p>
  <p class="addressP">
    {{ tradingPartnerObj?.addresses?.[0]?.postalCode }}&nbsp;
  </p>
  <p class="flex p-t-30 addressP fw-900 fx-row">
    Tel no:
    <span class="fw-300 p-l-20"> {{ tradingPartnerObj.contact?.telNo }} </span>
  </p>
  <p class="flex addressP fw-900 fx-row">
    Fax no:
    <span class="fw-300 p-l-20"> {{ tradingPartnerObj.contact?.faxNo }} </span>
  </p>
  <p class="flex addressP fw-900 fx-row">
    VAT no: <span class="fw-300 p-l-20">{{ tradingPartnerObj?.vatNo }}</span>
  </p>
</div>
