import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Session } from "../../../models/session";
import { TradingPartnerSummary } from "../../../models/types";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";
import { Settings } from "./../../../settings";

const ELEMENT_DATA: TradingPartnerSummary[] = [];
@Component({
  selector: "app-supplier-view",
  templateUrl: "./supplier-view.component.html",
  styleUrls: ["./supplier-view.component.sass"],
})
export class SupplierViewComponent implements OnInit {
  tradingPartnerType: any = [
    {
      type: "Supplier",
    },
  ];
  loadTradingPartners = false;
  tradingPartnerSelected = false;
  stickyHeading = false;
  allTradingPartners: TradingPartnerSummary[] = [];
  filteredTradingPartners: TradingPartnerSummary[] = [];
  defaultItemsPerPage = 0;
  displayedColumns: string[] = ["GLN", "name", "type", "view"];
  dataSource = new MatTableDataSource<TradingPartnerSummary>(ELEMENT_DATA);
  selection = new SelectionModel<TradingPartnerSummary>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  tradingPartnerControlForm: UntypedFormGroup;
  selectedRetailerType: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private settings: Settings,
    private notificationService: NotificationService,
    private tradingPartnerService: TradingPartnerService,
    private navigationService: NavigationService,
    private session: Session,
  ) {}

  ngOnInit(): void {
    this.defaultItemsPerPage = this.settings.defaultItemsPerPage;
    // this.session.resetDates();
    this.tradingPartnerService.supplierTradingPartnerSelected.next(false);
    this.dataSource.data = [];
    // this.allTradingPartners = [];
    this.stickyHeading = false;
    this.dataSource.paginator = this.paginator;
    this.setupInputControls();
    // this.getRetailerTradingPartners();
    this.getSupplierTradingPartners();
    this.selectTradingPartner();
  }

  getSupplierTradingPartners() {
    this.loadTradingPartners = true;
    this.tradingPartnerService.getSuppliers().subscribe(
      (tp) => {
        if (tp) {
          this.loadTradingPartners = false;
          this.allTradingPartners = this.allTradingPartners.concat(tp);
          this.filteredTradingPartners = this.allTradingPartners;
          this.dataSource.data = this.filteredTradingPartners;
          this.paginator = this.dataSource.paginator;
        }
      },
      (error) => {
        this.loadTradingPartners = false;
        this.notificationService.showError(error);
      },
    );
  }

  setupInputControls() {
    this.tradingPartnerControlForm = this.formBuilder.group({
      tradingPartnerType: ["Supplier", null],
      searchTradingPartners: ["", null],
    });
  }

  search() {
    this.searchSupplier(
      this.tradingPartnerControlForm.controls.searchTradingPartners.value,
    );
    this.paginator = this.dataSource.paginator;
  }

  selectTradingPartner() {
    const retailer =
      this.tradingPartnerControlForm.controls.tradingPartnerType.value;
    this.filterTradingPartner(retailer);
    this.searchSupplier(
      this.tradingPartnerControlForm.controls.searchTradingPartners.value,
    );
  }

  filterTradingPartner(searchText: string) {
    this.selectedRetailerType =
      searchText == null || searchText == "All" ? "" : searchText;
    this.filteredTradingPartners =
      searchText == null || searchText == "All"
        ? this.allTradingPartners
        : this.allTradingPartners.filter(
            (searchResult) => searchResult.type == this.selectedRetailerType,
          );
    this.dataSource.data = this.filteredTradingPartners;
  }

  getArrayLength(): number {
    return this.filteredTradingPartners.length;
  }

  clearSearch() {
    this.searchSupplier(
      this.tradingPartnerControlForm.controls.searchTradingPartners.value,
    );
    this.paginator = this.dataSource.paginator;
  }

  searchSupplier(searchText: string) {
    this.filteredTradingPartners = this.allTradingPartners.filter(
      (searchResult) =>
        searchResult.name
          .toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        searchResult.gln
          .toLowerCase()
          .includes(searchText.toLowerCase().trim()),
    );
    this.dataSource.data = this.filteredTradingPartners;
    this.paginator = this.dataSource.paginator;
  }

  goToSupplier(order: TradingPartnerSummary) {
    this.session.getUser();
    this.session.setSupplierGln(order.gln);
    this.tradingPartnerService.supplierTradingPartnerSelected.next(true);
    this.navigationService.navigateDashboard();
  }
}
