<!-- <app-nav-menu></app-nav-menu> -->
<!-- <div class="p-30"></div> -->
<div class="flex center-item height fx-row noOverFlow">
  <div class="flex loginBg fx-jc-center p-30 width-60 artBG">
    <div class="flex height-50">
      <img width="250" src="./assets/icons/Vodacom_fin_services.svg" />
    </div>
    <div class="flex height-100">
      <img
        class="artmin-width"
        width="500"
        src="./assets/images/undraw_authentication.svg"
      />
    </div>
    <div class="flex">
      <p class="loginCol p-l subheadingTxt">
        © 2020 Vodacom Financial Services
      </p>
    </div>
    <!-- <img 
        class="artmin-width flex fx-ai-end" width="500" src='{{artworkpath+artwork}}'> -->
  </div>
  <div class="width-100 flex fx-row whiteBG">
    <div class="flex center-item fx-jc-center width-30 min-width">
      <div>
        <h3 class="flex p-b-20 logintxt fx-jc-center fx-ai-center">
          Forgot your password
        </h3>
        <form
          class="center-item flex fx-jc-center fx-ai-center"
          [formGroup]="forgotPasswordForm"
        >
          <mat-form-field
            class="width-90 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <mat-label>Email address</mat-label>
            <input
              matInput
              placeholder="Email address"
              formControlName="email"
            />
            <mat-error
              *ngIf="forgotPasswordForm.controls.email.hasError('required')"
            >
              Email is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="forgotPasswordForm.controls.email.hasError('email')"
            >
              Please enter a <strong>valid</strong> email address
            </mat-error>
          </mat-form-field>

          <div class="flex fx-ai-center fx-jc-center m-t-60 width-80">
            <button
              mat-flat-button
              class="primaryBtn width-100 round"
              [disabled]="!this.forgotPasswordForm.valid"
              (click)="submit()"
            >
              Submit
            </button>
          </div>

          <div class="bottomLinks m-t-20">
            <a mat-flat routerLink="." (click)="cancel()" class="m-0 fw-300"
              >Cancel?
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
