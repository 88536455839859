export enum DocumentTypes {
  "ORDER" = "ORDER",
}

export interface Document {
  id: string;
  documentNumber: string;
  documentType: string;
  status: string;
  documentDate: Date;
  sender: string;
  receiver: string;
}

export interface Address {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  type?: string;
  postalCode?: string;
}

export enum AddressType {
  postalAddress = "Postal Address",
}

export class TradingPartner {
  gln?: string;
  name?: string;
  vatNo?: string;
  imageURL?: string;
  addresses?: Address[];
  contact?: Contact;
  contacts?: Contacts[];
  tradingProcess?: TradingProcess;
  connectionStatus?: string;
  numberOfStores?: string;
  vatCountryCode?: string;
  vatRateCode?: string;
  parentGln?: string;
  isTaxExempt?: boolean;
  connectionRequests?: any[];
  type?: TradePartnerType;
  notificationType?: NotificationType;
  notifyNewOrder?: boolean;
  linkedTradingPartners?: TradingPartnerSummary[];
  events?: TradingPartnerEvent[];

  features?: Features;
}

export const TradePartnerTypes = {
  Retailer: "Retailer",
  Store: "Store",
  Supplier: "Supplier",
};

type TradePartnerType =
  (typeof TradePartnerTypes)[keyof typeof TradePartnerTypes];

export interface Features {
  selectiveReceivableFinance?: boolean;
}

export class TradingPartnerEvent {
  description: string;
  dateTime: Date;
  user: VodaTradeUserSummary;
  oldValue: string;
  newValue: string;
}

export class VodaTradeUser {
  name = "";
  surname = "";
  tradingPartnerGln = "";
  username?: string = "";
  email?: string = "";
  mobile?: string = "";
  token?: string = "";
  notifyNewOrder?: boolean;
  notificationType?: EmailNotificationType;
  roles: string[] = [];
  isMfa = false;
}

export interface VodaTradeUserSummary {
  name: string;
  surname: string;
  email: string;
  tradingPartnerGln: string;
}

export enum EmailNotificationType {
  Email = "Email",
  Sms = "Sms",
  None = "None",
}

// export interface LinkedTradingPartners{
//     gln?: string;
//     imageURL?: string;
//     name?: string;
// }

export interface LinkedRetailerRequestModel {
  gln: string;
  imageURL?: string;
  name: string;
  linked: boolean;
}

export class TradingPartnerSummary {
  gln?: string;
  name?: string;
  imageURL?: string;
  type?: string;
}

export class OrderSummary {
  public id: string;
  public interchangeNumber?: string;
  public orderNumber?: string;
  public total?: number;
  public dateOfOrder?: string;
  public earliestDelivery?: string;
  public latestDelivery?: string;
  public status?: string;
  public orderFrom?: TradingPartnerSummary;
  public deliverTo?: TradingPartnerSummary;
}

export interface Contact {
  faxNo?: string;
  telNo: string;
  emailAddress?: string;
  contactName?: string;
  contactSurname?: string;
}

export interface Contacts {
  type?: string;
  number?: string;
}

export interface Partner {
  businessType: string;
  nameSurname: string;
  emailAddress: string;
  contactNumber: string;
  tradingPartnerGln: string;
}

export interface TradingProcess {
  acknowledgeOrder: boolean;
  sendAcknowledgement: boolean;
  confirmOrder: boolean;
  sendConfirmation: boolean;
  rejectOrder: boolean;
  sendRejection: boolean;
  invoiceOrder: boolean;
  sendInvoice: boolean;
  editOrderLines: boolean;
}

export enum TradingProcessActions {
  Acknowledge = "Acknowledge",
  Confirm = "Confirm",
  Reject = "Reject",
  Invoice = "Invoice",
}

export enum TradingPartnerType {
  tradingWith = 1,
  notTradingWith = 0,
}

export interface User {
  userId: string;
  name?: string;
  surname?: string;
  username: string;
  email: string;
  mobile: string;
  isMfa: boolean;
  token?: string;
  accessToken?: string;
  refreshToken?: string;
  tradingPartnerGln?: string;
  tradingPartnerGlns?: string[];
  supplierNames?: string[];
  notificationType?: NotificationType;
  newPasswordRequired: boolean;
  otpRequired: boolean;
  userType?: string;
  lastActivityDate: string;
  lastActivityType: string;

  roles: string[];
}

export interface ManageUserModel {
  userId: string;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  username: string;
  roles: string[];
  isMfa: boolean;
  // tradingPartnerGln?: string,
  tradingPartnerGlns?: string[];
}

export interface BusinessInfoMoldel {
  addresses: Address[];
  contact: Contact;
}

export interface TradingPartnerInfo {
  addresses: Address[];
  contact: Contact;
}

export interface QueryResult {
  count: number;
  scannedCount: number;
  lastEvaluatedKey: any;
  data: OrderSummary[];
}

export interface LoginResult {
  username: string;
  email: string;
  mobile: string;
  token: string;
  tradingPartnerGln: string;
}

export enum routes {
  login = "login",
  dashboard = "dashboard",
  orders = "orders",
  newOrders = "newOrders",
  myProducts = "myProducts",
  partnerMarketplaceComponent = "partnerMarketplaceComponent",
  partnerMarketplaceDetails = "partnerMarketplaceDetails",
  myProfile = "myProfile",
  vodalend = "vodalend",
  financingTerms = "financingTerms",
  financingSuccess = "financingSuccess",
  forgotPassword = "forgotPassword",
  resetPassword = "resetPassword",
}

export enum Roles {
  admin = "admin",
  customer = "customer",
  customerAdmin = "customerAdmin",
}

export class TradingPartnerBreakdown {
  dailyBreakdownChangePercentage: string;
  dayBeforeYesterdayTotal: number;
  monthlyBreakdown: MonthlyBreakdown[] = [];
  yesterdayTotal: number;
}

export class MonthlyBreakdown {
  month = "";
  total = 0;
}

export class MonthlyBreakdowns {
  name = "";
  data: MonthlyBreakdown[];
}

export enum UserChallenges {
  SMS_MFA = "SMS_MFA",
  TOTP = "TOTP",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

export enum MfaTypes {
  SMS = "SMS",
  TOTP = "TOTP",
  NOMFA = "NOMFA",
}

export enum NotificationType {
  Email = "Email",
  Sms = "Sms",
  None = "None",
}

export interface breadCrumbModel {
  tradingPartner: TradingPartner;
  index: number;
}

export interface ChangeHistory {
  date: Date;
  user: string;
  property: string;
  oldValue: string;
  newValue: string;
}

export class AnalyticResult {
  type: AnalyticType = AnalyticType.OrderValueByMonthPerRetailer;
  resultData: any = {};
}

export enum AnalyticType {
  OrderValueByMonthPerRetailer = "OrderValueByMonthPerRetailer",
  OrdersInvoicedByRetailer = "OrdersInvoicedByRetailer",
  TopPerformingProducts = "TopPerformingProducts",
}

export class OrderAndInvoice {
  name = "";
  orders: number;
  invoicedOrders: number;
}

export class TopPerformingProduct {
  data: TopPerformingProductItem[];
}

export class TopPerformingProductItem {
  productEan = "";
  quantity: number;
}

export class EmailTemplate {
  name = "";
  surname = "";
  email = "";
  description = "";
}

export class ActivityLogs {
  dateTme = "";
  requestDescription = "";
  username = "";
  serviceRequestNumber = "";
}

export class VTEvent {
  tradingPartnerGln = "";
  uniqueHash = "";

  type: EventType = EventType.order;
  typeUniqueId = "";

  eventTime = new Date();
  description = "";
  user: VodaTradeUserSummary;
}

export enum EventType {
  order = "Order number",
  invoice = "Invoice number",
  connectionRequest = "Connection request",
  supportCall = "Service request number",
}

export class SupplierReport {
  supplierGln = "";
  supplierName?: string = "";
  reportData: OrderReportForCsv[] = [];
}

export class DetailedSupplierReport {
  supplierGln = "";
  supplierName?: string = "";
  reportData: DetailedOrderReportForCsv[] = [];
}

export class OrderReportForCsv {
  Retailer = "";
  OrderNumber = "";
  Status = "";
  OrderDate = "";
  OrderValue = 0;
  InvoiceValue = 0;
}

export class DetailedOrderReportForCsv {
  OrderNumber?: string = "";
  OrderDate?: string = "";
  Status?: string = "";
  InvoiceDate?: string = "";
  Sender?: string = "";
  Receiver?: string = "";
  UnitBarcode?: string = "";
  PackBarcode?: string = "";
  PackSize?: string = "";
  ProductDescription?: string = "";
  OrderedQuantity?: number = 0;
  InvoiceQuantity?: number = 0;
  Price?: number = 0;
}

export enum EditType {
  edit = "edit",
  view = "view",
  assign = "assign",
  add = "add",
}

export class InvoicesAvailable {
  Retailer = "";
  InvoiceNumber = "";
  Value = "";
  InvoiceDate = "";
  MaturityDate = "";
}

export enum ApplicationStatus {
  pending = "InProgress",
  rejected = "Rejected",
  accepted = "Complete",
  new = "New",
}

// export interface Order extends Document {
//     interchangeNumber: string;
//     orderNumber: string;
//     dateOfOrder: Date;
//     earliestDelivery: Date;
//     latestDelivery: Date;
//     total: number;
//     vat: number;
//     discount: number;
//     // supplier: TradingPartner;
//     // orderFrom: TradingPartner;
//     // deliverTo: TradingPartner;
//     supplierGln: string;
//     orderFromGln: string;
//     deliverToGln: string;
//     lines: OrderLine[];
//     status: string;

// }
