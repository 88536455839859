<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 scroll">
  <div class="width-90 flex m-20 fx-jc-center">
    <p class="m-b-30 title">{{ (tradingPartner | async)?.name }}</p>
    <!-- <div *ngIf="!loadTradingPartner" class="animate__loadingFlash animate__animated animate__slow m-t-20 animate__infinite width-30 fx-ai-center flex fx-row">
                <div class="loadBar p-15 m-b-20 width-20"></div>
                <div class="loadBar p-15 m-b-20 m-l-10 width-30"></div>
            </div> -->
    <p class="subHeading flex width-50">Finance Options</p>
    <p class="p-b-30">
      Whether you need to fund moving into a bigger office, buy equipment or
      property or just to get the cash flow you need to act quickly, we can make
      it happen.
    </p>

    <p class="subHeading flex" *ngIf="financeProducts.length > 0">
      Trade Finance
    </p>
    <p class="p-b-30 flex" *ngIf="financeProducts.length > 0">
      Trade Finance is an umbrella term that covers all the ways a third party
      like a bank might finance transactions between buyers and sellers to
      provide the seller with working capital.
    </p>

    <div
      *ngIf="loadProducts"
      class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
    >
      <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
    </div>

    <div>
      <mat-card
        *ngFor="let product of financeProducts"
        class="financed-cards flex m-b-70"
      >
        <div class="image-holder">
          <img
            mat-card-image
            src="./assets/images/selective-financing-img.png"
            alt="Photo of a Shiba Inu"
          />
          <div class="card-img-overlay">
            <h2 class="card-title">Receivables Finance</h2>
            <!-- <h2 class="card-title">{{product.name}}</h2> -->
            <p class="card-text">
              Sell your debtor invoices to increase your cash flow
            </p>
          </div>
        </div>
        <mat-card-content>
          <p>
            If you trade in goods and services, you can unlock valuable cash
            trapped in your unpaid invoices…
            <span
              *ngIf="applicationInProgress() && !applicationRejected()"
              class="blueText clickme"
              (click)="viewFAQ()"
              >more info</span
            >
          </p>
        </mat-card-content>
        <mat-card-actions>
          <p
            *ngIf="applicationInProgress() && !applicationRejected()"
            class="noMargin fw-600"
          >
            Application In Progress...
          </p>
          <p *ngIf="applicationRejected()" class="noMargin fw-600">
            Apply Again In {{ formatDates() }}
          </p>
          <button
            *ngIf="
              !applicationInProgress() &&
              !applicationRejected() &&
              applicationInCompleted()
            "
            class="fw-700"
            (click)="moreInfo()"
            mat-button
          >
            More Info <img src="./assets/images/chevron-red-button.png" />
          </button>
          <button
            *ngIf="!applicationInCompleted()"
            class="fw-700"
            (click)="viewFAQ()"
            mat-button
          >
            More Info <img src="./assets/images/chevron-red-button.png" />
          </button>
          <!-- <button *ngIf="applicationInProgress() && !applicationRejected()" (click)="viewFAQ()" mat-button>More Info <img src="./assets/images/chevron-red-button.png"></button> -->
        </mat-card-actions>
      </mat-card>
    </div>

    <!-- <mat-card  class="financed-cards flex m-b-70">   
                <div class="image-holder">
                    <img mat-card-image src="./assets/images/selective-financing-img.png" alt="Photo of a Shiba Inu">
                    <div class="card-img-overlay">
                        <h2 class="card-title">Selective receivables financing</h2>
                        <p class="card-text">Sell invoices to increase your cash flow </p>
                    </div>
                </div>
                <mat-card-content>
                    <p>If your company trades in goods and services, you will be able to unlock valuable cash trapped in as unpaid invoices… <span *ngIf="applicationInProgress() && !applicationRejected()" class="blueText clickme" (click)="viewFAQ()">more info</span></p>
                </mat-card-content>
                <mat-card-actions>
                    <p *ngIf="applicationInProgress() && !applicationRejected()" class="noMargin fw-600">Application In Progress...</p>
                    <p *ngIf="applicationRejected()" class="noMargin fw-600">Apply Again On {{financeProduct?.nextProcessDate}}</p>
                    <button *ngIf="!applicationInProgress() && !applicationRejected()"  (click)="moreInfo()" mat-button>More Info <img src="./assets/images/chevron-red-button.png"></button>
                    <button *ngIf="applicationInProgress() && !applicationRejected()" (click)="viewFAQ()" mat-button>More Info <img src="./assets/images/chevron-red-button.png"></button>
                </mat-card-actions>
            </mat-card> -->

    <p class="subHeading flex">Other Finance Options</p>

    <div class="flex fx-row fx-sm-column fx-ai-stretch">
      <mat-card class="financed-cards flex m-b-70">
        <div class="image-holder">
          <img
            mat-card-image
            src="./assets/images/businessCashAdvance.png"
            alt="Photo of a Shiba Inu"
          />
          <div class="card-img-overlay">
            <h2 class="card-title">Business Cash Advance</h2>
            <p class="card-text">Flexible repayments from your POS device</p>
          </div>
        </div>
        <mat-card-content>
          <p>
            Perfect for registered VodaPay merchants that earn R3 000 a month
            with a 3 month record...
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-button
            (click)="navToBusinessCashAdvance()"
            class="fw-700"
          >
            More Info <img src="./assets/images/chevron-red-button.png" />
          </button>
        </mat-card-actions>
      </mat-card>

      <mat-card class="financed-cards flex m-b-70">
        <div class="image-holder">
          <img
            mat-card-image
            src="./assets/images/fund-your-business-img.png"
            alt="Photo of a Shiba Inu"
          />
          <div class="card-img-overlay">
            <h2 class="card-title">Business Term Advance</h2>
            <p class="card-text">Finance options - 3 to 12 months</p>
          </div>
        </div>
        <mat-card-content>
          <p>
            If your South African business makes R500 000 or more a year and has
            operated for at least 12 months...
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="navToFundYourBusiness()" class="fw-700">
            More Info <img src="./assets/images/chevron-red-button.png" />
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
