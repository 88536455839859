<h2 mat-dialog-title>{{ message }}</h2>
<div
  *ngIf="isLoading"
  class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
>
  <mat-spinner></mat-spinner>
</div>
<mat-dialog-content>
  <form
    *ngIf="!isLoading"
    class="partnerMarketplaceArea scroll"
    [formGroup]="addUserForm"
  >
    <div
      *ngIf="manageUserType !== 'assign'"
      class="flex bl-t-l round xs-card-t p-t-10 p-r-20 p-l-20 m-t-10"
    >
      <mat-form-field>
        <select matNativeControl placeholder="Role" formControlName="role">
          <option *ngFor="let role of roles" [value]="role.name">
            {{ role.name }}
          </option>
        </select>
        <mat-error *ngIf="addUserForm.controls.role.hasError('required')">
          Role is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div
      *ngIf="manageUserType !== 'assign'"
      class="flex round xs-card-t p-t-10 p-r-20 p-l-20 m-t-10"
    >
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="addUserForm.controls.name.hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field
      *ngIf="manageUserType !== 'assign'"
      class="m-t-10 p-t-10 .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <mat-label>Surname</mat-label>
      <input matInput placeholder="Surname" formControlName="surname" />
      <mat-error *ngIf="addUserForm.controls.surname.hasError('required')">
        Surname is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="manageUserType !== 'assign'"
      class="m-t-10 p-t-10 .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <mat-label>Country</mat-label>
      <select
        matNativeControl
        placeholder="Select Country"
        formControlName="country"
      >
        <option *ngFor="let country of countries" [value]="country.code">
          {{ country.name }}
        </option>
      </select>
      <mat-error *ngIf="addUserForm.controls.country.hasError('required')">
        Country is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="manageUserType !== 'assign'"
      class="m-t-10 p-t-10 .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <mat-label>Cellphone number</mat-label>
      <input
        matInput
        formControlName="contactNumber"
        pattern="^[\d ()+-]+$"
        [maxlength]="20"
      />
      <mat-error
        *ngIf="addUserForm.controls.contactNumber.hasError('required')"
      >
        Contact number is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="addUserForm.controls.contactNumber.hasError('pattern')">
        Numeric value <strong>only</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="manageUserType !== 'assign'"
      class="m-t-10 p-t-10 .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <mat-label>Email address</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="addUserForm.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="addUserForm.controls.email.hasError('email')">
        Please enter a <strong>valid</strong> email address
      </mat-error>
    </mat-form-field>

    <div
      *ngIf="manageUserType !== 'assign'"
      class="flex m-t-10 m-b-30 fx-jc-left fx-ai-left"
    >
      <mat-slide-toggle formControlName="enableMFA" color="warn"
      >Enable 2factor authentication
      </mat-slide-toggle
      >
    </div>
    <div></div>

    <div
      *ngIf="showAssignSuppliers()"
      [ngClass]="{ 'bl-t-l': manageUserType !== 'assign' }"
      class="manageSuppliers flex"
    >
      <app-assign-suppliers
        [manageUserType]="manageUserType"
        [alreadySelectedSuppliers]="alreadySelectedSuppliers"
        (selectedSuppliers)="getSelectedSuppliers($event)"
        class="m-t-30 m-l-20 p-20 tradeCard"
      ></app-assign-suppliers>
    </div>
  </form>
</mat-dialog-content>

<div class="flex fx-row fx-jc-space-between">
  <div mat-dialog-actions class="width-100">
    <button
      mat-flat-button
      class="btn width-90 p-b-50 round cancelBtn"
      (click)="close(false)"
    >
      Cancel
    </button>
  </div>
  <div mat-dialog-actions class="width-100">
    <button
      [disabled]="!formValidity()"
      mat-flat-button
      class="btn primaryBtn width-90 p-b-50 round"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</div>
