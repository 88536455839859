import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-password-reset-confirm",
  templateUrl: "./password-reset-confirm.component.html",
  styleUrls: ["./password-reset-confirm.component.sass"],
})
export class PasswordResetConfirmComponent {
  message: string;
  buttonTxt: string;
  constructor(
    public dialogRef: MatDialogRef<PasswordResetConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    (this.message = data.message), (this.buttonTxt = data.buttonTxt);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
