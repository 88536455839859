<div class="flex fx-jc-center fx-ai-center grayBG m-b-70">
  <div class="width-90 flex m-20 fx-jc-center">
    <form [formGroup]="financeEditGroup" class="flex m-t-10 width-100">
      <div class="flex fx-row">
        <h1 class="fw-500 m-b-30 title">Tiger Brands</h1>
      </div>

      <div class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <h2 class="subTitle flex">Funding information</h2>

        <div class="grayBanner flex p-10 m-b-20 fx-r ow">
          <div class="flex fx-row">
            <div class="flex width-100">
              <span class="flex fw-500">How much do you need?</span>
            </div>
            <div class="flex width-10">
              <mat-form-field class="example-form-field outline p-l-20 p-r-10">
                <input matInput class="" formControlName="loanAmount" />
                <span matPrefix>R&nbsp;</span>
                <button
                  *ngIf="loanInputTrigger"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    setChangedLoan(financeEditGroup.controls.loanAmount.value)
                  "
                >
                  <mat-icon>check</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div class="flex fx-jc-center">
            <mat-slider
              class="mat-slide-1 width-100"
              [disabled]="disabled"
              [invert]="invert"
              [max]="sliderMax"
              [min]="sliderMin"
              [step]="sliderStep"
              [thumbLabel]="thumbLabel"
              [tickInterval]="getSliderTickInterval()"
              formControlName="sliderValue"
              [vertical]="vertical"
            >
            </mat-slider>
            <div class="width-100 flex fx-row">
              <span class="flex width-100">{{
                sliderMin | currency : "R" : "symbol-narrow" : "4.2-2"
              }}</span>
              <span class="flex m-l-auto">{{
                sliderMax | currency : "R" : "symbol-narrow" : "4.2-2"
              }}</span>
            </div>
          </div>
          <div class="flex fx-row m-t-50">
            <div class="flex width-100">
              <span class="flex fw-500">Estimated factor rate</span>
            </div>
            <div class="flex width-10">
              <mat-form-field class="example-form-field outline p-l-20 p-r-10">
                <input matInput class="" formControlName="rate" />
                <span matSuffix>%</span>
                <button
                  *ngIf="rateInputTrigger"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="setRate(financeEditGroup.controls.rate.value)"
                >
                  <mat-icon>check</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div class="flex fx-jc-center">
            <mat-slider
              class="mat-slide-1 width-100"
              [disabled]="disabled"
              [invert]="invert"
              [max]="rateMax"
              [min]="rateMin"
              [step]="rateStep"
              [thumbLabel]="thumbLabel2"
              [tickInterval]="getRateTickInterval()"
              formControlName="rateValue"
              [vertical]="vertical"
            >
            </mat-slider>
            <div class="width-100 flex fx-row">
              <span class="flex">{{ rateMin / 100 | percent }}</span>
              <span class="flex m-l-auto">{{ rateMax / 100 | percent }}</span>
            </div>
            <div class="flex m-l-auto fx-jc-center fx-row m-t-30">
              <div
                matRipple
                class="flex clickme fx-jc-center fx-row btn-total-t m-l-20"
              >
                <span class="flex fx-ai-center m-l-10 nowrap"
                  >Total Repayment:
                  {{
                    getTotalRepayment()
                      | currency : "R" : "symbol-narrow" : "4.2-2"
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-l-20 p-r-20 p-t-30 m-t-30">
        <h2 class="subTitle flex">Estimated payment period</h2>
        <div class="grayBanner flex p-10 m-b-20 fx-r ow">
          <div class="flex fx-row">
            <div class="flex width-100 pMargins">
              <p class="flex">
                Daily repayment amount will be 10% of your daily turnover
                processed through all your owned point of sale terminals
              </p>
              <p class="flex">* (QR Code, POS Device, E-commerce Switch)</p>
              <p class="flex">
                * Repayment period is estimated based on your predicted
                turnover, and the period may be extended or shortened as the
                advance is repaid
              </p>
            </div>
          </div>
          <div class="flex fx-row m-t-50">
            <div class="flex width-100 fx-ai-center">
              <mat-slider
                class="mat-slide-1 width-85"
                [disabled]="disabled"
                [invert]="invert"
                [max]="periodMax"
                [min]="periodMin"
                [step]="periodStep"
                [thumbLabel]="thumbLabel3"
                [tickInterval]="getPaymentTickInterval()"
                formControlName="loanPeriod"
                [vertical]="vertical"
              >
              </mat-slider>
              <div class="width-100 flex fx-row">
                <p
                  *ngFor="let month of getPaymentPeriod()"
                  [ngClass]="{
                    selectedMonth:
                      month === financeEditGroup.controls.loanPeriod.value
                  }"
                  class="flex width-100"
                  style="text-align: center"
                >
                  {{ month }} month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tradeCard m-t-50 p-t-30">
        <h2 class="subTitle p-l-20 flex">Application summary</h2>
        <div class="grayBanner flex m-b-20 fx-r ow">
          <div class="flex fx-r ow">
            <div class="flex p-10 fx-row">
              <span class="flex p-l-20">Funding amount</span>
              <span class="flex m-l-auto fw-500 p-r-20">{{
                sliderValue | currency : "R" : "symbol-narrow" : "4.2-2"
              }}</span>
            </div>
            <div class="flex p-10 fx-row">
              <span class="flex p-l-20">Repayment frequency</span>
              <span class="flex m-l-auto fw-500 p-r-20">Daily</span>
            </div>
            <div class="flex p-10 fx-row">
              <span class="flex p-l-20">Approximate Repayment term</span>
              <span class="flex m-l-auto fw-500 p-r-20"
                >{{ periodValue }} months</span
              >
            </div>
            <div class="flex p-10 fx-row">
              <span class="flex p-l-20">Approximate daily repayment</span>
              <span class="flex m-l-auto fw-500 p-r-20"
                >{{ daysInMonth * periodValue }} days x
                {{ repaymentAmount | currency : "R" }}</span
              >
            </div>
            <div class="flex p-10 fx-row selectedRow">
              <span class="flex p-l-20">Fixed Fee</span>
              <span class="flex m-l-auto fw-500 p-r-20">{{
                fixedFee | currency : "R"
              }}</span>
            </div>
            <div class="flex p-10 fx-row">
              <span class="flex p-l-20">Total repayment</span>
              <span class="flex m-l-auto fw-500 p-r-20">{{
                getTotalRepayment() | currency : "R" : "symbol-narrow" : "4.2-2"
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex fx-jc-center fx-row m-t-30">
        <button
          mat-flat-button
          (click)="submit()"
          class="flex width-20 fx-ai-center round btn primaryBtn"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
