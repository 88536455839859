<div class="m-t-20 m-b-40 bl-b-l">
  <h1 class="fw-500 m-t-20 m-b-20">
    {{ (action | uppercase) + " product" | uppercase }}
  </h1>
</div>
<form
  class="p-l-10 p-r-10 flex manageProduct m-b-40"
  [formGroup]="manageProductForm"
>
  <mat-form-field
    class="manageProductsLine1 p-t-5 p-l-20 p-r-20 noMargin round xs-card-t"
  >
    <mat-label>Barcode</mat-label>
    <input
      matInput
      class="noMargin"
      formControlName="barCode"
      placeholder="Barcode"
      pattern="^[0-9]+([,.][0-9]+)?$"
    />
    <mat-error *ngIf="manageProductForm.controls.barCode.hasError('required')">
      Bar Code is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="manageProductForm.controls.barCode.hasError('pattern')">
      Numeric values <strong>only</strong>
    </mat-error>
    <!-- <mat-error
          *ngIf="manageProductForm.controls.barCode.hasError('minlength')">
          Barcode has to be <strong>14</strong> characters
      </mat-error> -->
  </mat-form-field>

  <mat-form-field
    class="manageProductsLine1 p-t-5 round p-l-20 p-r-20 xs-card-t noMargin"
  >
    <mat-label>Product code</mat-label>
    <input
      matInput
      class="noMargin"
      formControlName="productCode"
      placeholder="Product code"
    />
    <mat-error
      *ngIf="manageProductForm.controls.productCode.hasError('required')"
    >
      Product Code is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="manageProductsLine1 tradeCard p-t-5 round p-l-20 p-r-20 xs-card-t noMargin"
  >
    <mat-select placeholder="Retailer" formControlName="retailer">
      <mat-option *ngFor="let retailer of retailers" [value]="retailer.gln">
        {{ retailer.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="productDescription p-t-5 round p-l-20 p-r-20 xs-card-t noMargin"
  >
    <mat-label>Product description</mat-label>
    <input
      matInput
      class="noMargin"
      formControlName="productDescription"
      placeholder="Product description"
    />
    <mat-error
      *ngIf="manageProductForm.controls.productDescription.hasError('required')"
    >
      Product description is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <input
    style="display: none"
    type="file"
    formControlName="selectedFile"
    multiple
    accept="image/x-png,image/jpeg"
    #fileInput
  />
  <button
    mat-dialog-actions
    mat-button
    class="flex p-t-10 p-b-10 round btn selectedItem cancelBtn"
    (click)="cancel()"
  >
    Cancel
  </button>
  <div class="manageProductsLine1"></div>
  <button
    [disabled]="!manageProductForm.valid"
    [ngClass]="{ primaryBtn: manageProductForm.valid }"
    mat-dialog-actions
    mat-button
    class="flex p-t-10 p-b-10 round btn selectedItem"
    (click)="addProduct()"
  >
    {{ action }} Product
  </button>
</form>
