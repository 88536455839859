import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ChartDataset, ChartOptions } from "chart.js";
import { Observable } from "rxjs";

import {
  MonthlyBreakdowns,
  OrderSummary,
  TradingPartnerBreakdown,
} from "../../../../models/types";
import { OrdersService } from "../../../../services/orders.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

const graphColors = {
  New: "#00B0CA",
  Acknowledged: "#9C2AA0",
  Confirmed: "#005765",
  Rejected: "#FF0000",
  Invoiced: "#BD0000",
};

@Component({
  selector: "app-order-values",
  templateUrl: "./order-values.component.html",
  styleUrls: ["./order-values.component.sass"],
})
export class OrderValuesComponent implements OnInit, OnChanges {
  @Input() toMaxDate: Date;
  @Input() fromMinDate: Date;
  @Input() orderValueByMonthPerRetailer: MonthlyBreakdowns[];

  @ViewChild("myCanvas")
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType = "line";
  public chartData: ChartDataset<"line">[];
  public chartLabels: any[];
  public chartColors: any[] = [
    {
      backgroundColor: graphColors["Rejected"],
      borderColor: graphColors["Rejected"],
    },
    {
      backgroundColor: graphColors["New"],
      borderColor: graphColors["New"],
    },
  ];
  public chartOptions: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom",
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 10000,
        },
      },
    },
  };

  loadOrder = false;
  orders: OrderSummary[];
  retailerMonthlyBreakdowns: MonthlyBreakdowns[] = [];
  loadMonthlyBreakdown = false;
  months = [];
  customerName = [];
  insightData$: Observable<TradingPartnerBreakdown>;

  constructor(
    private orderService: OrdersService,
    private tradingPartnerService: TradingPartnerService,
  ) {}

  ngOnChanges(): void {
    this.getMonthlyBreakdown();
    this.plotGraph();
  }

  ngOnInit() {
    this.getMonthlyBreakdown();
    this.plotGraph();
  }

  getMonthlyBreakdown() {
    this.loadMonthlyBreakdown = true;

    if (this.orderValueByMonthPerRetailer) {
      this.retailerMonthlyBreakdowns = this.orderValueByMonthPerRetailer;
      this.loadMonthlyBreakdown = false;

      if (this.retailerMonthlyBreakdowns.length > 0) {
        this.monthlyBreakdownValues();
      }
    }
  }

  monthDiff(d1: Date, d2: Date) {
    let months;

    months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    months -= new Date(d1).getMonth();
    months += new Date(d2).getMonth();

    return months <= 0 ? 0 : months;
  }

  monthlyBreakdownValues() {
    this.months = [];
    this.chartLabels = [];
    this.chartData = [
      {
        data: [],
        fill: false,
      },
    ]; // default chartData item needed for FE component

    for (let i = 0; i < this.retailerMonthlyBreakdowns.length; i++) {
      const tempTotalAmounts = [];

      this.retailerMonthlyBreakdowns[i].data.forEach((data, index) => {
        // Prepend months before data date
        if (index == 0) {
          for (
            let x = 0;
            x <
            this.monthDiff(this.fromMinDate, new Date(Date.parse(data.month)));
            x++
          ) {
            const date = new Date(
              new Date(new Date(this.fromMinDate)).getFullYear(),
              new Date(new Date(this.fromMinDate)).getMonth() + x,
              new Date(new Date(this.fromMinDate)).getUTCDay(),
            );
            this.months.includes(
              this.formatDateDisplay(new Date(Date.parse(data.month))),
            )
              ? {}
              : this.months.push(this.formatDateDisplay(date));
            tempTotalAmounts.push(0);
          }
        }

        if (i == 0) {
          this.months.push(
            this.formatDateDisplay(new Date(Date.parse(data.month))),
          );
        }

        // Prepend months after last date with data
        tempTotalAmounts.push(data.total.toFixed(2));

        if (this.retailerMonthlyBreakdowns[i].data.length == index + 1) {
          const enddate = new Date(
            new Date(new Date(this.toMaxDate)).getFullYear(),
            new Date(new Date(this.toMaxDate)).getMonth(),
            new Date(new Date(this.toMaxDate)).getUTCDay(),
          );

          for (
            let x = 0;
            x < this.monthDiff(new Date(Date.parse(data.month)), enddate);
            x++
          ) {
            const date = new Date(
              new Date(new Date(Date.parse(data.month))).getFullYear(),
              new Date(new Date(Date.parse(data.month))).getMonth() + x,
              new Date(new Date(Date.parse(data.month))).getUTCDay(),
            );
            this.months.includes(this.formatDateDisplay(date))
              ? {}
              : this.months.push(this.formatDateDisplay(date));
            tempTotalAmounts.push(0);
          }
        }
      });

      const chartColor = this.chartColors[i];

      this.chartData.push({
        ...(chartColor && {
          backgroundColor: chartColor.backgroundColor,
          borderColor: chartColor.borderColor,
        }),
        data: [...tempTotalAmounts],
        fill: false,
        label: this.retailerMonthlyBreakdowns[i].name,
        tension: 0.5,
      });
    }

    this.chartData.splice(0, 1); // remove default chartData item
    this.plotGraph();
  }

  formatDateDisplay(date): string {
    return (
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear()
    );
  }

  getMonths() {
    return this.retailerMonthlyBreakdowns;
  }

  plotGraph() {
    this.chartLabels = [...this.months];
  }
}
