import { Component, OnInit } from "@angular/core";
import { Session } from "../../models/session";
import { NavigationService } from "../../services/navigation.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.sass"],
})
export class HomeComponent implements OnInit {
  contactNumbers = "082 1951";
  contactEmail = "0821951@vodacom.co.za";
  loggedIn = true;
  inSRF = false;
  constructor(
    private navigationService: NavigationService,
    private session: Session,
  ) {}

  ngOnInit() {
    this.navigationService.currentRoute() === "home"
      ? this.navigationService.navigateDashboard()
      : "";
  }

  isAdmin(): boolean {
    return this.session.isAdmin();
  }

  moveHelpButton(): number {
    return this.navigationService.currentRoute() === "myProducts" ? 80 : 0;
  }

  adjustOnMoreInfo(): number {
    return this.navigationService.currentRoute() === "moreInfo" ? 100 : 0;
  }

  isInInvoiceRoute(): boolean {
    return this.navigationService.currentRoute().includes("invoiceprocessing")
      ? false
      : true;
  }

  setContactValues() {
    this.navigationService
      .currentRoute()
      .toLocaleLowerCase()
      .includes("invoiceprocessing")
      ? ((this.contactNumbers = "082 1940"),
        (this.contactEmail = "0821940@vodacom.co.za"),
        (this.loggedIn = false),
        (this.inSRF = true))
      : ((this.contactNumbers = "082 1951"),
        (this.contactEmail = "0821951@vodacom.co.za"),
        (this.loggedIn = true),
        (this.inSRF = false));
  }

  getPhoneNumber(): string {
    this.setContactValues();
    return this.contactNumbers;
  }

  getEmail(): string {
    this.setContactValues();
    return this.contactEmail;
  }
}
