<div class="col">
  <!-- <p class="subHeading info blb-l">Invoices Rejected <span class="blue-i">i</span></p>  -->
</div>
<div class="flex fx-jc-center fx-ai-center">
  <p *ngIf="!dataAvailable">No data to display</p>
  <div *ngIf="dataAvailable" class="d-flex">
    <div class="col">
      <div class="flex fx-row fx-jc-space-between">
        <div class="flex fx-row fx-ai-center">
          <div>
            <p class="heading noMargin">{{ total | currency : "R" }}</p>
            <p class="heading noMargin">({{ totalInvoiceCount }})</p>
          </div>
          <div id="legend-container" class="legend-con"></div>
        </div>
        <!-- <div class="p-relative flex fx-row"> -->
        <div class="m-l-40">
          <canvas id="canvas3" (window:resize)="onResize($event)"></canvas>
        </div>
        <div class="flex fx-jc-center fx-ai-center">
          <div class="flex fx-col m-l-40">
            <div
              *ngFor="let invoiceResult of invoicesPending; let i = index"
              class=""
            >
              <div class="m-b-10 flex fx-row fx-jc-center fx-ai-center">
                <p class="noMargin">
                  {{ invoiceResult.amount | currency : "R" }}
                </p>
                <div
                  class="m-l-10 smallSquare"
                  [ngStyle]="{ 'background-color': getColour(i) }"
                ></div>
                <p class="p-l-10 noMargin">( {{ invoiceResult.count }} )</p>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="flex fx-row m-t-10">
        <div
          *ngFor="let retailer of retailers; let i = index"
          class="flex fx-row fx-jc-center fx-ai-center"
        >
          <div
            class="m-l-10 smallSquare"
            [ngStyle]="{ 'background-color': getColour(i) }"
          ></div>
          <p class="p-l-10 noMargin">{{ retailer }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
