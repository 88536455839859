<!-- <div class="p-30"></div> -->
<div
  class="m-20"
  [ngStyle]="{ bottom: moveHelpButton() + 'px' }"
  style="position: fixed; right: 20px; z-index: 10"
>
  <app-help
    [loggedIn]="false"
    [contactNumbers]="'082 1951'"
    [contactEmail]="'0821951@vodacom.co.za'"
  ></app-help>
</div>
<div class="flex center-item height fx-row noOverFlow">
  <div
    class="block loginBg p-30 width-50 artBG"
    style="align-content: space-between !important"
  >
    <div class="flex width-100 height-100">
      <div class="flex height-100">
        <img width="250" src="./assets/icons/Vodacom_fin_services.svg" />
      </div>
      <div class="flex height-95">
        <h2 class="loginCol headingTxt">
          View and manage your business transactions online
        </h2>
        <h3 class="loginCol subheadingTxt">
          All transactions. All devices. One Solution.
        </h3>
      </div>
      <div class="flex">
        <p class="loginCol p-l subheadingTxt">
          © 2020 Vodacom Financial Services
        </p>
      </div>
    </div>
  </div>
  <div class="width-100 flex fx-row whiteBG">
    <div class="flex center-item fx-jc-center width-30 min-width">
      <div>
        <h3
          *ngIf="!forgotPassword && !showOtp"
          class="flex p-b-20 logintxt fx-jc-center fx-ai-center"
        >
          Login to your account
        </h3>
        <form
          *ngIf="!forgotPassword && !showOtp"
          class="center-item flex fx-jc-center fx-ai-center"
          [formGroup]="loginForm"
        >
          <mat-form-field
            class="width-90 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <!-- <mat-label>Username</mat-label> -->
            <input
              matInput
              placeholder="username"
              formControlName="username"
              #focuMe
            />
            <mat-error *ngIf="loginForm.controls.username.hasError('required')">
              Username is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            class="width-90 m-t-20 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <!-- <mat-label>Password</mat-label> -->
            <input
              matInput
              placeholder="enter password"
              formControlName="password"
              [type]="hide ? 'password' : 'text'"
            />
            <mat-error *ngIf="loginForm.controls.password.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-icon matSuffix *ngIf="hide" (click)="hide = !hide"
              >visibility</mat-icon
            >
            <mat-icon matSuffix *ngIf="!hide" (click)="hide = !hide"
              >visibility_off</mat-icon
            >
          </mat-form-field>

          <div class="flex fx-ai-center fx-jc-center m-t-60 width-80">
            <button
              mat-flat-button
              class="btn primaryBtn width-100 round"
              [disabled]="!this.loginForm.valid"
              (click)="login()"
            >
              Login
            </button>
          </div>

          <div class="bottomLinks m-t-20">
            <p>
              By continuing, I agree to the
              <a
                mat-flat
                routerLink="."
                (click)="showTermsAndConditions()"
                class="m-0 fw-300"
                >terms and conditions</a
              >
            </p>
          </div>

          <div class="bottomLinks m-t-20">
            <a
              mat-flat
              routerLink="."
              (click)="onforgotPassword()"
              class="m-0 fw-300"
              >Forgot your password?
            </a>
          </div>

          <div class="bottomLinks flex fx-col p-t-60">
            <mat-error *ngIf="incorrectLogin" class="flex fx-ai-center">
              {{ loginFailureMessage }}
            </mat-error>
            <div *ngIf="incorrectLogin" class="flex m-t-20 fx-ai-center">
              <img
                class="flex animate__animated animate__slow animate__tada"
                src="./assets/icons/redBell.svg"
              />
            </div>
            <mat-error *ngIf="technicalDifficulties" class="flex fx-ai-center">
              We are currently experiencing technical difficulties. Please try
              again later.
            </mat-error>
          </div>
        </form>

        <h3
          *ngIf="showOtp"
          class="flex p-b-20 logintxt fx-jc-center fx-ai-center"
        >
          Enter your OTP
        </h3>
        <form
          *ngIf="showOtp"
          class="center-item flex fx-jc-center fx-ai-center"
          [formGroup]="otpForm"
        >
          <mat-form-field
            class="width-90 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <mat-label>OTP</mat-label>
            <input
              matInput
              placeholder="otp"
              formControlName="OTP"
              pattern="^[0-9]+([0-9]+)?$"
              autofocus
            />
            <mat-error *ngIf="otpForm.controls.OTP.hasError('required')">
              OTP is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="otpForm.controls.OTP.hasError('pattern')">
              numeric values <strong>only</strong>
            </mat-error>
          </mat-form-field>

          <div class="flex fx-ai-center fx-jc-center m-t-60 width-80">
            <button
              mat-flat-button
              class="primaryBtn width-100 round"
              [disabled]="!this.otpForm.valid"
              (click)="verifyOTP()"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
