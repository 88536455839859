<h2 mat-dialog-title class="fw-800 flex fx-ai-center">{{ title }}</h2>
<div mat-dialog-content class="fx-jc-center fx-ai-center flex mat-typography">
  <h3>{{ message }}</h3>
</div>
<div mat-dialog-actions style="justify-content: space-between">
  <button class="btn round selectedItem cancelBtn" (click)="cancel()">
    {{ declineButtonText }}
  </button>
  <button
    mat-button
    class="btn round selectedItem primaryBtn right"
    (click)="confirm()"
  >
    {{ acceptButtonText }}
  </button>
</div>
