<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <form class="flex m-t-10 width-100" [formGroup]="AdminReportsForm">
      <div class="flex fx-row">
        <p class="m-b-30 title">Detailed Supplier Reports</p>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <div class="flex fx-ai-center fx-row round p-r-20 m-b-30">
          <div class="datePicker width-30">
            <div
              class="width-50 datePickOrder round noMargin tradeCard fx-ai-center fx-jc-center fx-row flex"
            >
              <mat-form-field class="">
                <mat-label>From Date</mat-label>
                <input
                  matInput
                  [max]="toMaxDate"
                  [matDatepicker]="fromDate"
                  formControlName="fromDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="fromDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="">
                <mat-label>To Date</mat-label>
                <input
                  matInput
                  [min]="fromMinDate"
                  [max]="todaysDate"
                  [matDatepicker]="toDate"
                  formControlName="toDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="toDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <mat-form-field
            class="m-l-auto orderSearchField p-l-10 p-r-10 width-20 tradeCard round"
          >
            <mat-label>Suppliers</mat-label>
            <mat-select
              formControlName="selectedSuppliers"
              multiple
              (openedChange)="resetFilteredSuppliersList()"
            >
              <mat-form-field
                class="m-l-auto p-l-10 p-r-10 m-t-10 tradeCard round suppliersSearchField"
              >
                <input
                  matInput
                  formControlName="filteredSuppliersInput"
                  (keyup)="filterSuppliers($event.target.value)"
                />
                <mat-label
                  ><mat-icon>search</mat-icon> Filter Suppliers
                </mat-label>
              </mat-form-field>

              <mat-option
                *ngFor="let suppliers of filteredSuppliersList"
                [value]="suppliers.gln"
              >
                {{ suppliers.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="m-l-20 flex fx-ai-center manageButtons fx-row">
            <button
              matRipple
              class="downloadAllBtn fx-row fx-ai-center flex selectedItem"
              (click)="generateReports()"
            >
              {{ buttonText | uppercase }}
            </button>
          </div>
          <div class="bl-l p-l-10 fx-ai-center fx-row"></div>
        </div>
        <div
          *ngIf="loader"
          class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
        >
          <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
        </div>
        <div *ngIf="!loader" class="grayBanner flex p-20 m-b-20 fx-row">
          <div class="flex fx-row">
            <p class="p-b p-r-10">{{ supplierReports.length }}</p>
            <p class="flex p-b">Reports</p>
          </div>

          <div
            *ngIf="supplierReports.length > 1"
            class="m-l-auto flex fx-ai-center manageButtons fx-row"
          >
            <button
              matRipple
              class="downloadAllBtn fx-row fx-ai-center flex selectedItem"
              (click)="downloadAllReports()"
            >
              <span class="noWrap flex fx-row">
                {{ "Download All" }}
                <img
                  src="assets/icons/downloadIcon.svg"
                  width="20"
                  class="animate__animated"
                />
              </span>
            </button>
          </div>
        </div>
        <div>
          <div
            class="flex fx-row fx-jc-center fx-ai-center"
            *ngIf="supplierReports.length < 1"
          >
            <h3>
              There are no reports generated currently, Please select a supplier
              and click on 'Generate Report'
            </h3>
          </div>
          <table
            *ngIf="!loader && supplierReports.length > 0"
            matTableFilter
            matTableExporter
            mat-table
            [dataSource]="dataSource"
            class="width-100"
            #exporter="matTableExporter"
          >
            <!-- productCode Column -->
            <ng-container matColumnDef="supplierGln">
              <th mat-header-cell *matHeaderCellDef>Gln</th>
              <td mat-cell *matCellDef="let element">
                {{ element.supplierGln }}
              </td>
            </ng-container>

            <!-- productCode Column -->
            <ng-container matColumnDef="supplierName">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.supplierName }}
              </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="reportData">
              <th mat-header-cell *matHeaderCellDef>View</th>
              <td mat-cell *matCellDef="let element" style="color: #00b0ca">
                <p class="p-l">
                  <a matRipple (click)="ViewReport(element)" class="pointer">
                    View
                  </a>
                </p>
              </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef>Download</th>
              <td mat-cell *matCellDef="let element">
                <div class="flex fx-ai-center manageButtons fx-row">
                  <button
                    matRipple
                    class="downloadAllBtn fx-row fx-ai-center flex selectedItem"
                    (click)="downloadReport(element)"
                  >
                    <img
                      src="assets/icons/downloadIcon.svg"
                      width="20"
                      class="animate__animated"
                    />
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </form>
  </div>
</div>
