import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  TradingPartner,
  TradingPartnerEvent,
} from "./../../../../models/types";
import { TradingPartnerService } from "./../../../../services/trading-partner.service";
import { Settings } from "./../../../../settings";

const ELEMENT_DATA: TradingPartnerEvent[] = [];
@Component({
  selector: "app-change-history",
  templateUrl: "./change-history.component.html",
  styleUrls: ["./change-history.component.sass"],
})
export class ChangeHistoryComponent implements OnInit {
  displayedColumns: string[] = [
    "date",
    "user",
    "property",
    "oldValue",
    "newValue",
  ];
  changeHistory: TradingPartnerEvent[] = [];
  tradingPartnerEvent: TradingPartnerEvent[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  currentTradingPartner: TradingPartner;
  dateFormat;
  dataSource = new MatTableDataSource<TradingPartnerEvent>(ELEMENT_DATA);
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private location: Location,
    private settings: Settings,
  ) {}

  ngOnInit() {
    this.dateFormat = this.settings.dateFormat;
    this.setTradingPartner();
    this.getChangeHistory();
  }

  setTradingPartner() {
    this.tradingPartnerService.selectedTradingPartner.subscribe((x) => {
      if (x) {
        this.currentTradingPartner = x;
      }
    });
  }

  getChangeHistory() {
    this.tradingPartnerEvent = this.currentTradingPartner.events;
    this.dataSource.data = this.tradingPartnerEvent;
    this.dataSource.paginator = this.paginator;
  }

  navigateBack() {
    this.location.back();
  }

  getLength(): number {
    return this.dataSource.data.length;
  }
}
