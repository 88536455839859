import { Component, Input, OnChanges, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import _ from "underscore";
import {
  Order,
  OrderLine,
  OrderStatus,
  Vat,
} from "../../../../../models/order";
import { OrdersService } from "../../../../../services/orders.service";
import { TradingPartnerService } from "../../../../../services/trading-partner.service";
import { NotificationService } from "./../../../../../services/notification.service";

@Component({
  selector: "app-new-order-table",
  templateUrl: "./new-order-table.component.html",
  styleUrls: ["./new-order-table.component.sass"],
})
export class NewOrderTableComponent implements OnChanges, OnInit {
  @Input() order: Order;
  @Input() headerChangeTrigger: boolean;
  editQuantity = true;
  displayedColumns: string[] = [];
  orderLine: UntypedFormGroup;

  vatRates: Vat[] = [];
  orderLineFormGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private orderService: OrdersService,
    private notificationService: NotificationService,
    private tradingPartnerService: TradingPartnerService,
  ) {}

  ngOnInit() {
    this.initializeFormGroup();
    this.setTempQuantiy();
    this.initializeOrderLineHeaders();
    this.getVatRates();
  }

  updateTempTotal(line: ExtendedOrderLine) {
    if (line.quantity > line.originalQuantity) {
      line.quantity = line.tempQuantity;
      this.notificationService.showError(
        new Error(
          "Amended quantity cannot be greater than the original quantity",
        ),
      );
    } else {
      this.orderService.updateTempTotal.next(this.getTotalLineCostIncVat());
    }
  }

  ngOnChanges(): void {
    this.setTempQuantiy();
    this.initializeOrderLineHeaders();
  }

  initializeFormGroup() {
    this.orderLineFormGroup = this.formBuilder.group({
      vatRate: ["", Validators.required],
    });
  }

  getVatRates() {
    const store = this.order.deliverTo as any;
    const supplier = this.order.supplier as any;

    this.tradingPartnerService.getTradingPartner(store.gln).subscribe(
      (x) => {
        //store exists
        if (x.isTaxExempt) {
          // am tax exempt
          this.orderService.getVat("FRGN").subscribe(
            (rates) => {
              this.vatRates = rates;
              this.setDefaultRates(rates);
            },
            (error) => {
              this.notificationService.showError(error);
            },
          );
        } else if (!x.vatCountryCode) {
          // parent's vat country code
          this.tradingPartnerService
            .getTradingPartner(x.parentGln)
            .subscribe((y) => {
              this.orderService.getVat(y.vatCountryCode).subscribe(
                (rates) => {
                  this.vatRates = rates;
                  this.setDefaultRates(rates);
                },
                (error) => {
                  this.notificationService.showError(error);
                },
              );
            });
        } else {
          // my own vat country code
          this.orderService.getVat(x.vatCountryCode).subscribe(
            (rates) => {
              this.vatRates = rates;
              this.setDefaultRates(rates);
            },
            (error) => {
              this.notificationService.showError(error);
            },
          );
        }
      },
      () => {
        // Store doesnt exist
        this.tradingPartnerService
          .getTradingPartner(supplier.gln)
          .subscribe((y) => {
            this.orderService.getVat(y.vatCountryCode).subscribe(
              (rates) => {
                this.vatRates = rates;
                this.setDefaultRates(rates);
              },
              (error) => {
                this.notificationService.showError(error);
              },
            );
          });
      },
    );
  }

  setDefaultRates(rates?: Vat[]) {
    if (!rates || rates.length <= 0) {
      rates = this.vatRates;
    }

    const rate = this.vatRates.find((x) => x.default);
    _.each(this.order.lines, (line) => {
      line.vat = rate;
    });
  }

  initializeOrderLineHeaders() {
    if (
      // to show original quantity
      this.order.status == OrderStatus.Invoiced ||
      this.order.status == OrderStatus.Confirmed ||
      (this.order.status == OrderStatus.Acknowledged &&
        !this.order.tradingProcess.confirmOrder)
    ) {
      if (this.showVatRate()) {
        // to show vatRate and lineCostIncVat
        this.displayedColumns = [
          "unit",
          "description",
          "quantity",
          "originalQuantity",
          "vatRate",
          "costPrice",
          "lineCostExclVat",
          "lineCostIncVat",
          "narrative",
          "packSize",
        ];
      } else {
        // only if no invoice trading process
        this.displayedColumns = [
          "unit",
          "description",
          "quantity",
          "originalQuantity",
          "costPrice",
          "lineCostExclVat",
          "narrative",
          "packSize",
        ];
      }
    } else {
      // NEW and REJECTED and (ACKNOWLEDGED if confirm trading process exists)
      this.displayedColumns = [
        "unit",
        "description",
        "quantity",
        "costPrice",
        "lineCostExclVat",
        "narrative",
        "packSize",
      ];
    }
  }

  setTempQuantiy() {
    _.each(this.order.lines as ExtendedOrderLine[], (orderLine) => {
      orderLine.tempQuantity = orderLine.quantity;
    });
  }

  getTotalLineCost() {
    return this.order.lines
      .map((t) => parseFloat(t.itemCost as any) * parseInt(t.quantity as any))
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalQty() {
    const total = this.order.lines
      .map((t) => parseInt(t.quantity as any))
      .reduce((acc: number, value: number) => acc + value, 0);
    return total;
  }

  getLineCostIncVat(line: OrderLine) {
    const quantity = parseInt(line.quantity as any);
    const itemCost = parseFloat(line.itemCost as any);
    const lineTotal = itemCost * quantity;
    const vatRate = parseInt(line.vat?.rate as any);

    if (!line.vat || vatRate == 0) return lineTotal;

    return lineTotal + (lineTotal * vatRate) / 100;
  }

  getTotalLineCostIncVat() {
    return this.order.lines
      .map((t) => {
        const itemCost = parseFloat(t.itemCost as any);
        const qty = parseInt(t.quantity as any);
        const lineCost = itemCost * qty;
        const vatRate = parseInt(t.vat?.rate as any);

        if (!vatRate || vatRate == 0) {
          return lineCost;
        }

        return lineCost + (lineCost * vatRate) / 100;
      })
      .reduce((acc, value) => acc + value, 0);
  }

  editQTY() {
    this.editQuantity = true;
  }

  saveLines() {
    this.orderService.update(this.order).subscribe(
      () => {
        this.setTempQuantiy();
        this.notificationService.showSuccess("Order updated successfully");
      },
      () => {
        this.cancel();
        this.notificationService.showError(new Error("Something went wrong"));
      },
    );
  }

  cancel() {
    _.each(this.order.lines as ExtendedOrderLine[], (orderLine) => {
      orderLine.quantity = orderLine.tempQuantity;
    });
  }

  canEdit() {
    if (
      this.order.status == OrderStatus.Acknowledged ||
      this.order.status == OrderStatus.Confirmed
    ) {
      this.editQuantity = false;
      return true;
    }
    this.editQuantity = true;
    return false;
  }

  chooseUnitBarCode(row: ExtendedOrderLine) {
    if (row.productEan) {
      if (row.productEan.trim() != "") {
        return row.productEan;
      }
    }
    return "-";
  }

  choosePackBarCode(row: ExtendedOrderLine) {
    if (row.packBarcode) {
      if (row.packBarcode.trim() != "") {
        return row.packBarcode;
      }
    }
    return "-";
  }

  chooseProductCode(row: ExtendedOrderLine) {
    if (row.productCode) {
      if (row.productCode.trim() != "") {
        return row.productCode;
      }
    }
    return "-";
  }

  showVatRate() {
    // Needed for .html ngIf
    if (this.order.tradingProcess.invoiceOrder) {
      if (
        this.order.status == OrderStatus.Confirmed ||
        (this.order.status == OrderStatus.Acknowledged &&
          !this.order.tradingProcess.confirmOrder)
      ) {
        return true;
      }
    }
    return false;
  }

  vatRateChange(rate, orderLine: ExtendedOrderLine) {
    const line = _.find(
      this.order.lines,
      (line) => line.productCode.trim() == orderLine.productCode.trim(),
    );
    line.vat = rate;
    this.updateTempTotal(orderLine);
  }
}

class ExtendedOrderLine implements OrderLine {
  productEan: string;
  productDescription: string;
  quantity: number;
  vat: Vat;
  originalQuantity: number;
  tempQuantity: number;
  itemCost: number;
  lineItemCost: number;
  packBarcode: string;
  unitBarcode: string;
  orderUnitBarcode: string;
  productCode: string;
  packSize?: any;
  lineNarrative?: any;
}
