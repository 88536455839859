import { Injectable } from "@angular/core";
import { AwsTradingPartnerRepository } from "../repositories/aws-trading-partner.repository";
import { TradingPartnersRepository } from "../repositories/trading-partners.repository";
import { AuthRepository } from "./auth-repository";
import { AwsAuthRepository } from "./aws-auth-repository";
import { AwsDocumentsRepository } from "./aws-documents.repository";
import { AwsEventRepository } from "./aws-event.repository";
import { AwsFinanceRepository } from "./aws-finance.repository";
import { AwsOrderRepository } from "./aws-order.repository";
import { AwsPartnerMarketPlaceRepository } from "./aws-partner-market-place-repository";
import { AwsProductRepository } from "./aws-product-repository";
import { AwsReportRepository } from "./aws-report-repository";
import { AwsSupportRepository } from "./aws-support-repository";
import { AwsUserRepository } from "./aws-user-repository";
import { DocumentsRepository } from "./documents.repository";
import { EventRepository } from "./event.repository";
import { FinanceRepository } from "./finance.repository";
import { MockDocumentRepository } from "./mockDocumentRepository";
import { OrderRepository } from "./order.repository";
import { PartnerMarketPlaceRepository } from "./partner-market-place-repository";
import { ProductRepository } from "./product-repository";
import { ReportRepository } from "./report-repository";
import { SupportRepository } from "./support.repository";
import { UserRepository } from "./user-repository";

@Injectable()
export class RepositoryFactory {
  constructor(
    private awsDocumentsRepository: AwsDocumentsRepository,
    private mockDocumentRepository: MockDocumentRepository,
    private awsTradingPartnerRepository: AwsTradingPartnerRepository,
    private awsOrderRepository: AwsOrderRepository,
    private awsProductRepository: AwsProductRepository,
    private awsPartnerMarketPlaceRepository: AwsPartnerMarketPlaceRepository,
    private awsAuthRepository: AwsAuthRepository,
    private awsUserRepository: AwsUserRepository,
    private awsSupportRepository: AwsSupportRepository,
    private awsEventRepository: AwsEventRepository,
    private awsReportRepository: AwsReportRepository,
    private awsFinanceRepository: AwsFinanceRepository,
  ) {}
  getOrderRepository(): OrderRepository {
    return this.awsOrderRepository;
  }
  getDocumentsRepository(): DocumentsRepository {
    return this.awsDocumentsRepository;
  }

  getTradingPartnerRepository(): TradingPartnersRepository {
    return this.awsTradingPartnerRepository;
  }

  getProductRepository(): ProductRepository {
    return this.awsProductRepository;
  }

  getPartnerMarketRepository(): PartnerMarketPlaceRepository {
    return this.awsPartnerMarketPlaceRepository;
  }

  getAuthRepository(): AuthRepository {
    return this.awsAuthRepository;
  }

  getUserRepository(): UserRepository {
    return this.awsUserRepository;
  }

  getSupportRepository(): SupportRepository {
    return this.awsSupportRepository;
  }

  getEventRepository(): EventRepository {
    return this.awsEventRepository;
  }

  getReportRepository(): ReportRepository {
    return this.awsReportRepository;
  }

  getFinanceRepository(): FinanceRepository {
    return this.awsFinanceRepository;
  }

  getMockTradingRepository(): MockDocumentRepository {
    return this.mockDocumentRepository;
  }
}
