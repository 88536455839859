import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EventRepository } from "../repositories/event.repository";
import { Session } from "./../models/session";
import { VTEvent } from "./../models/types";
import { RepositoryFactory } from "./../repositories/repository.factory";
@Injectable({
  providedIn: "root",
})
export class EventService {
  eventRepo: EventRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.eventRepo = repoFactory.getEventRepository();
  }

  search(): Observable<VTEvent[]> {
    return this.eventRepo.search(this.session.getSupplierGln());
  }
}
