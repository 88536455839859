/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */
import { environment } from "src/environments/environment";

import { apiGatewayClientFactory } from "../../../apiGateway-js-sdk/lib/apiGatewayCore/apiGatewayClient";
import { utils } from "../../../apiGateway-js-sdk/lib/apiGatewayCore/utils";
import { uritemplate } from "../../../apiGateway-js-sdk/lib/url-template/url-template";

export function newClient(config) {
  var apigClient = {};
  if (config === undefined) {
    config = {
      accessKey: "",
      secretKey: "",
      sessionToken: "",
      region: "",
      apiKey: undefined,
      defaultContentType: "application/json",
      defaultAcceptType: "application/json",
    };
  }
  if (config.accessKey === undefined) {
    config.accessKey = "";
  }
  if (config.secretKey === undefined) {
    config.secretKey = "";
  }
  if (config.apiKey === undefined) {
    config.apiKey = "";
  }
  if (config.sessionToken === undefined) {
    config.sessionToken = "";
  }
  if (config.region === undefined) {
    config.region = "us-east-1";
  }
  //If defaultContentType is not defined then default to application/json
  if (config.defaultContentType === undefined) {
    config.defaultContentType = "application/json";
  }
  //If defaultAcceptType is not defined then default to application/json
  if (config.defaultAcceptType === undefined) {
    config.defaultAcceptType = "application/json";
  }

  // extract endpoint and path from url
  var invokeUrl = environment.apiEndpoint;
  var endpoint = /(^https?:\/\/[^/]+)/g.exec(invokeUrl)[1];
  var pathComponent = invokeUrl.substring(endpoint.length);

  var sigV4ClientConfig = {
    accessKey: config.accessKey,
    secretKey: config.secretKey,
    sessionToken: config.sessionToken,
    serviceName: "execute-api",
    region: config.region,
    endpoint: endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType,
  };

  var authType = "NONE";
  if (
    sigV4ClientConfig.accessKey !== undefined &&
    sigV4ClientConfig.accessKey !== "" &&
    sigV4ClientConfig.secretKey !== undefined &&
    sigV4ClientConfig.secretKey !== ""
  ) {
    authType = "AWS_IAM";
  }

  var simpleHttpClientConfig = {
    endpoint: endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType,
  };

  var apiGatewayClient = apiGatewayClientFactory.newClient(
    simpleHttpClientConfig,
    sigV4ClientConfig,
  );

  apigClient.emailsSupportPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var emailsSupportPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/emails/support").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      emailsSupportPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.eventsGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var eventsGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/events").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      eventsGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeInvoiceGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeInvoiceGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance/invoice").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeInvoiceGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeInvoiceAnalyticsPendingGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeInvoiceAnalyticsPendingGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance/invoice/analytics/pending").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeInvoiceAnalyticsPendingGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeInvoiceAnalyticsRejectedGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeInvoiceAnalyticsRejectedGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance/invoice/analytics/rejected").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeInvoiceAnalyticsRejectedGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeInvoiceAnalyticsSoldGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeInvoiceAnalyticsSoldGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance/invoice/analytics/sold").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeInvoiceAnalyticsSoldGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeInvoiceFinancePost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeInvoiceFinancePostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance/invoice/finance").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeInvoiceFinancePostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.financeSrfPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var financeSrfPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/finance/srf").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      financeSrfPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var ordersGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersActiveGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var ordersActiveGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/active").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersActiveGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersPrintPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var ordersPrintPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/print").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersPrintPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdPut = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdPutRequest = {
      verb: "put".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdPutRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdAcknowledgePost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdAcknowledgePostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}/acknowledge").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdAcknowledgePostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdConfirmPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdConfirmPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}/confirm").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdConfirmPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdInvoicePost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdInvoicePostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}/invoice").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdInvoicePostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdNewPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdNewPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}/new").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdNewPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdRejectPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdRejectPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}/reject").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdRejectPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.ordersIdResendPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var ordersIdResendPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/orders/{id}/resend").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      ordersIdResendPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.productsGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var productsGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/products").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      productsGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.productsPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var productsPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/products").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      productsPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.productsBatchPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var productsBatchPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/products/batch").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      productsBatchPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.productsIdGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var productsIdGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/products/{id}").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      productsIdGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.productsIdPut = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var productsIdPutRequest = {
      verb: "put".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/products/{id}").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      productsIdPutRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.productsIdDelete = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["id"], ["body"]);

    var productsIdDeleteRequest = {
      verb: "delete".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/products/{id}").expand(
          utils.parseParametersToObject(params, ["id"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      productsIdDeleteRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.reportTradingPartnersDetailedSupplierOrderGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var reportTradingPartnersDetailedSupplierOrderGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/report/tradingPartners/detailedSupplierOrder").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      reportTradingPartnersDetailedSupplierOrderGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.reportTradingPartnersSupplierOrderGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var reportTradingPartnersSupplierOrderGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/report/tradingPartners/supplierOrder").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      reportTradingPartnersSupplierOrderGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.reportUsersGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var reportUsersGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/report/users").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      reportUsersGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersPut = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersPutRequest = {
      verb: "put".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersPutRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersBatchPost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersBatchPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/batch").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersBatchPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersConnectPost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersConnectPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/connect").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersConnectPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersPrintPost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersPrintPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/print").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersPrintPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersPrintstoresGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var tradingpartnersPrintstoresGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/printstores").expand(
          utils.parseParametersToObject(params, []),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersPrintstoresGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersGlnGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["gln"], ["body"]);

    var tradingpartnersGlnGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/{gln}").expand(
          utils.parseParametersToObject(params, ["gln"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersGlnGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersGlnPut = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["gln"], ["body"]);

    var tradingpartnersGlnPutRequest = {
      verb: "put".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/{gln}").expand(
          utils.parseParametersToObject(params, ["gln"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersGlnPutRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersGlnAnalyticsGet = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["gln"], ["body"]);

    var tradingpartnersGlnAnalyticsGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/{gln}/analytics").expand(
          utils.parseParametersToObject(params, ["gln"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersGlnAnalyticsGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.tradingpartnersGlnUploadimagePost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["gln"], ["body"]);

    var tradingpartnersGlnUploadimagePostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/tradingpartners/{gln}/uploadimage").expand(
          utils.parseParametersToObject(params, ["gln"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      tradingpartnersGlnUploadimagePostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.usersGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var usersGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/users").expand(utils.parseParametersToObject(params, [])),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      usersGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.usersPut = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var usersPutRequest = {
      verb: "put".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/users").expand(utils.parseParametersToObject(params, [])),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      usersPutRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.usersPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var usersPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/users").expand(utils.parseParametersToObject(params, [])),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      usersPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.usersDelete = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var usersDeleteRequest = {
      verb: "delete".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/users").expand(utils.parseParametersToObject(params, [])),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      usersDeleteRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.usersUserIdGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["userId"], ["body"]);

    var usersUserIdGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/users/{userId}").expand(
          utils.parseParametersToObject(params, ["userId"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      usersUserIdGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.usersUserIdLoggedinPost = function (
    params,
    body,
    additionalParams,
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, ["userId"], ["body"]);

    var usersUserIdLoggedinPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/users/{userId}/loggedin").expand(
          utils.parseParametersToObject(params, ["userId"]),
        ),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      usersUserIdLoggedinPostRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  apigClient.vatGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    utils.assertParametersDefined(params, [], ["body"]);

    var vatGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/vat").expand(utils.parseParametersToObject(params, [])),
      headers: utils.parseParametersToObject(params, []),
      queryParams: utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      vatGetRequest,
      authType,
      additionalParams,
      config.apiKey,
    );
  };

  return apigClient;
}
