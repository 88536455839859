<div class="flex fx-row">
  <p class="flex p-l-1">Gln - {{ getGln() }}</p>
  <div class="flex fx-jc-center m-l-auto fx-row">
    <button
      matRipple
      mat-flat-button
      type="button"
      class="cancelBtn flex selectedItem m-r-10"
      (click)="viewHistory()"
    >
      View History
    </button>
  </div>
</div>
<p class="subHeading flex p-b-10">{{ retailer?.type + " information" }}</p>

<div
  *ngIf="loader"
  class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
>
  <mat-spinner strokeWidth="6" [diameter]="50"></mat-spinner>
</div>
<div *ngIf="!loader" class="flex">
  <div class="flex fx-row">
    <div class="flex width-100">
      <p class="flex p-l">Address:</p>
      <div class="flex">
        <p class="flex p-b noMargn">{{ getAddress()?.line1 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.line2 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.line3 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.line4 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.postalCode }}</p>
      </div>
    </div>
    <div class="flex width-100">
      <p class="flex p-l">Contact Name:</p>
      <p class="flex p-b noMargn">
        {{ getNameOfContactPerson() ? getNameOfContactPerson() : "-" }}
      </p>
      <p class="flex p-l">Contact Surname:</p>
      <p class="flex p-b noMargn">
        {{ getSurnameOfContactPerson() ? getSurnameOfContactPerson() : "-" }}
      </p>
      <p class="flex p-l">Email Address:</p>
      <p class="flex p-b noMargn">
        {{ getEmailOfContactPerson() ? getEmailOfContactPerson() : "-" }}
      </p>
    </div>
    <div class="flex width-100">
      <p class="flex p-l">Tel No:</p>
      <p class="flex p-b noMargn">{{ getTelNo() ? getTelNo() : "-" }}</p>
      <p class="flex p-l">Fax No:</p>
      <p class="flex p-b noMargn">{{ getFaxNo() ? getFaxNo() : "-" }}</p>
    </div>
    <div class="flex width-100">
      <p class="flex p-l">VAT No:</p>
      <p class="flex p-b noMargn">
        {{ getVat().vatNo ? getVat().vatNo : "-" }}
      </p>
      <p class="flex p-l">VAT Country Code:</p>
      <p class="flex p-b noMargn">
        {{ getVat().vatCountryCode ? getVat().vatCountryCode : "-" }}
      </p>
      <!-- <p class="flex p-b noMargn">{{'VAT Rate Code: ' + (getVat().vatRateCode ? getVat().vatRateCode : '-')}}</p>   -->
    </div>
  </div>
  <div class="flex m-l-auto fx-jc-center fx-row m-t-30">
    <button
      mat-flat-button
      class="btn cancelBtn flex fx-ai-center flex"
      (click)="editBusinessInformation()"
    >
      Edit
    </button>
  </div>
</div>
