import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import _ from "underscore";
import { Session } from "../../../../models/session";
import { TradingPartnerSummary } from "../../../../models/types";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

@Component({
  selector: "app-set-retailer-dialog",
  templateUrl: "./set-retailer-dialog.component.html",
  styleUrls: ["./set-retailer-dialog.component.sass"],
})
export class SetRetailerDialogComponent implements OnInit {
  suppliers: TradingPartnerSummary[];
  selectedSupplier: TradingPartnerSummary;
  setSupplierForm: UntypedFormGroup;
  loader = false;
  actionText = "Switch";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<SetRetailerDialogComponent>,
    private session: Session,
    private navigationservice: NavigationService,
  ) {}

  ngOnInit(): void {
    if (this.navigationservice.currentRoute() == "login") {
      this.actionText = "Select";
    } else {
      this.actionText = "Switch";
    }
    this.setupFormGroup();
    this.hookSupplierChange();
    this.getSuppliers();
  }

  hookSupplierChange() {
    this.setSupplierForm.controls.supplier.valueChanges.subscribe(
      (supplier) => {
        this.selectedSupplier = supplier;
        this.session.setSupplierGln(this.selectedSupplier.gln);
        this.dialogRef.close(this.selectedSupplier);
      },
    );
  }

  setupFormGroup() {
    this.setSupplierForm = this.formBuilder.group({
      supplier: ["", Validators.required],
    });
  }

  getSuppliers() {
    this.loader = true;
    this.tradingPartnerService.getSuppliers().subscribe(
      (suppliers) => {
        if (!suppliers) return;
        this.loader = false;

        const userGlns = this.session.getUser().tradingPartnerGlns;

        const userSuppliers = _.filter(suppliers, (s) => {
          return userGlns.indexOf(s.gln) !== -1;
        });

        this.suppliers = userSuppliers;
      },
      (error) => {
        this.loader = false;
        this.notificationService.showError(error);
      },
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
