import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { filter } from "lodash";
import { EditType, User } from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { NotificationService } from "../../../../services/notification.service";
import { UserService } from "../../../../services/user.service";
import { Session } from "./../../../../models/session";

const ELEMENT_DATA: User[] = [];
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.sass"],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    "name",
    "surname",
    "email",
    "mobile",
    "remove",
    "editUser",
  ];
  dataSource = new MatTableDataSource<User>(ELEMENT_DATA);
  users: User[] = [];
  filteredUsers: User[] = [];
  loader = false;
  loggedInUser = "";
  searchText = "";
  componentAction = "Manage";

  @Input() tradingPartnerGln: string;
  @Input() viewOnly: boolean;
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private dialog: DialogService,
    private session: Session,
  ) {}

  ngOnInit(): void {
    if (this.viewOnly == true) {
      this.componentAction = "View";
      this.displayedColumns = ["name", "surname", "email", "mobile", "view"];
    } else if (this.session.isAdmin() && this.tradingPartnerGln != "AdminGln") {
      this.componentAction = "Manage";
      this.displayedColumns = [
        "name",
        "surname",
        "email",
        "mobile",
        "view",
        "remove",
        "editUser",
        "assign",
      ];
    } else {
      this.componentAction = "Manage";
      this.displayedColumns = [
        "name",
        "surname",
        "email",
        "mobile",
        "remove",
        "editUser",
      ];
    }

    this.loadUsers();
    this.loggedInUser = this.session.getUser()?.email;
  }

  isAdmin(): boolean {
    // html bool check
    return this.session.isAdmin() || this.session.isCustomerAdmin();
  }

  loadUsers() {
    this.loader = true;
    this.userService.search(this.tradingPartnerGln).subscribe(
      (x) => {
        let users = x;

        if (this.tradingPartnerGln === "") {
          // came from manage users component - dont show admins
          users = filter(x, (u) => {
            return u.tradingPartnerGlns.indexOf("AdminGln") === -1;
          });
        }

        this.users = users;
        this.filteredUsers = this.users;
        this.dataSource.data = this.users;
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.notificationService.showError(error);
      },
    );
  }

  addUser() {
    this.dialog
      .manageUserDialog(this.tradingPartnerGln, null, EditType.add)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loadUsers();
        }
      });
  }

  editUser(user: User) {
    this.dialog
      .manageUserDialog(this.tradingPartnerGln, user, EditType.edit)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loadUsers();
        }
      });
  }

  AssignUser(user: User) {
    this.dialog
      .manageUserDialog(this.tradingPartnerGln, user, EditType.assign)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loadUsers();
        }
      });
  }

  ViewUser(user: User) {
    this.dialog
      .manageUserDialog(this.tradingPartnerGln, user, EditType.view)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loadUsers();
        }
      });
  }

  removeUser(userId: string) {
    this.dialog
      .showConfirmationDialog(
        "Confirm User Delete",
        "Are you sure you want to delete user?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.userService.delete(userId).subscribe(
            () => {
              this.notificationService.showSuccess("User deleted successfully");
              this.loadUsers();
            },
            (error) => {
              this.notificationService.showError(error);
            },
          );
        }
      });
  }

  getLoggedInUser(user) {
    return user.email === this.loggedInUser;
  }

  searchUsers() {
    this.dataSource.data = this.users.filter(
      (user) =>
        user.email
          .toLowerCase()
          .includes(this.searchText.toLowerCase().trim()) ||
        user.mobile
          .toLowerCase()
          .includes(this.searchText.toLowerCase().trim()) ||
        user.username
          .toLowerCase()
          .includes(this.searchText.toLowerCase().trim()) ||
        user.name
          .toLowerCase()
          .includes(this.searchText.toLowerCase().trim()) ||
        user.surname
          .toLowerCase()
          .includes(this.searchText.toLowerCase().trim()),
    );
  }
}
