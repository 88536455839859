import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import ExcelJS from "exceljs";
import * as saveAs from "file-saver";
import { isEmpty } from "lodash";
import { parse } from "papaparse";

import type {
  Address,
  Contact,
  TradingPartner,
} from "./../../../../../models/types";
import {
  TradePartnerTypes,
  TradingPartnerSummary,
} from "./../../../../../models/types";
import { DialogService } from "./../../../../../services/dialog.service";
import { NavigationService } from "./../../../../../services/navigation.service";
import { NotificationService } from "./../../../../../services/notification.service";
import { TradingPartnerService } from "./../../../../../services/trading-partner.service";

function parseTradePartnersFile(file: File) {
  return new Promise((resolve, reject) =>
    parse(file, {
      complete: (result) => {
        const {
          data,
          meta: { fields: headers },
        }: {
          data: Record<string, unknown>;
          meta: {
            fields: string[];
          };
        } = result;

        try {
          validateHeaders(headers);
        } catch (e) {
          reject(e);
        }

        resolve(data);
      },
      header: true,
      skipEmptyLines: true,
    }),
  );
}

function validateHeaders(headers: string[]) {
  const errorPrefix = "File format is invalid,";

  if (headers.length < 1) {
    throw new Error(`${errorPrefix} error parsing file`);
  }

  if (headers[0] !== "parentGln") {
    throw new Error(`${errorPrefix} Parent GLN column missing`);
  }

  if (headers[1] !== "gln") {
    throw new Error(`${errorPrefix} GLN column missing`);
  }

  if (headers[2] !== "type") {
    throw new Error(`${errorPrefix} Type column missing`);
  }

  if (headers[3] !== "name") {
    throw new Error(`${errorPrefix} Name column missing`);
  }
}

const ELEMENT_DATA: TradingPartnerSummary[] = [];
@Component({
  selector: "app-linked-store",
  templateUrl: "./linked-store.component.html",
  styleUrls: ["./linked-store.component.sass"],
})
export class LinkedStoreComponent implements OnInit {
  displayedColumns: string[] = ["gln", "name", "view"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() retailer: TradingPartner;
  tradingPartners: TradingPartner[] = [];
  linkedTradingPartnerForm: UntypedFormGroup;
  linkedTradingPartners: TradingPartnerSummary[] = [];
  loader = false;
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.setLinkedStores();
    this.setupInputControls();
  }

  linkedTypesLabel(types: string) {
    const typesArray = {
      Retailer: "Stores",
      Supplier: "Retailers",
    };
    return typesArray[types];
  }

  setLinkedStores() {
    this.loader = true;
    this.linkedTradingPartners = [];
    const subscription = this.tradingPartnerService
      .getStores(this.retailer.gln)
      .subscribe((x) => {
        this.linkedTradingPartners = x;
        this.dataSource.data = this.linkedTradingPartners;
        this.dataSource.paginator = this.paginator;
        this.loader = false;
        subscription.unsubscribe();
      });
  }

  goToStore(gln: string) {
    this.tradingPartnerService.selectedTradingPartner.next(null);

    this.navigationService.navigateToTradingPartnerDetails(gln);
  }

  setupInputControls() {
    this.linkedTradingPartnerForm = this.formBuilder.group({
      searchTradingPartner: ["", null],
    });
  }

  clearSearch() {
    this.linkedTradingPartnerForm.controls.searchTradingPartner.value == ""
      ? (this.search(""), (this.paginator = this.dataSource.paginator))
      : {};
  }

  search(filterValue: string) {
    this.dataSource.data = this.linkedTradingPartners.filter(
      (x) =>
        x.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.gln.toLowerCase().includes(filterValue.trim().toLowerCase()),
    );
  }

  addStores() {
    this.dialogService
      .addTradingPartner(this.retailer.gln, "AddStore")
      .afterClosed()
      .subscribe((x) => {
        if (x.response) {
          const tpSummary = new TradingPartnerSummary();

          tpSummary.gln = x.tp?.gln;
          tpSummary.name = x.tp?.name;
          tpSummary.type = x.tp?.type;

          this.linkedTradingPartners.push(tpSummary);
          this.dataSource.data = this.linkedTradingPartners;
        }
      });
  }

  downloadFile() {
    const loader = this.dialogService.loader();

    this.tradingPartnerService.getPrintStores(this.retailer.gln).subscribe(
      async (x) => {
        const wb = new ExcelJS.Workbook();

        wb.addWorksheet(this.retailer.gln + " Stores");
        const ws = wb.getWorksheet(this.retailer.gln + " Stores");

        ws.addRow([
          "parentGln",
          "gln",
          "type",
          "name",
          "telno",
          "faxno",
          "vatno",
          "vatcountrycode",
          "line1",
          "line2",
          "line3",
          "line4",
          "postalcode",
        ]);

        x.forEach((linkedTP) => {
          ws.addRow([
            linkedTP.parentGln,
            linkedTP.gln,
            linkedTP.type,
            linkedTP.name,
            linkedTP.contact?.telNo,
            linkedTP.contact?.faxNo,
            linkedTP.vatNo,
            linkedTP.vatCountryCode,
            linkedTP.addresses[0]?.line1,
            linkedTP.addresses[0]?.line2,
            linkedTP.addresses[0]?.line3,
            linkedTP.addresses[0]?.line4,
            linkedTP.addresses[0]?.postalCode,
          ]);
        });

        wb.csv
          .writeBuffer({ formatterOptions: { delimiter: "," } })
          .then((x) => {
            const file = new File([x], this.retailer.gln + " Stores.csv", {
              type: "text/csv;charset=utf-8",
            });
            saveAs(file);
          })
          .catch();

        loader.close();
      },
      () => {
        loader.close();
      },
    );
  }

  uploadFile() {
    this.dialogService
      .showUploadTradingPartnersDialog()
      .afterClosed()
      .subscribe(
        (result) => {
          if (result && !isEmpty(result)) {
            this.parseTradePartnersFiles(result);
          }
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  parseTradePartnersFiles(files: File[]) {
    const tradePartners: TradingPartner[] = [];

    Promise.all(
      files.map((file) =>
        parseTradePartnersFile(file)
          .then((data: Record<string, string>[]) => {
            console.debug("Got lines", data);
            return data.map((line) => this.processTradeParterFileLine(line));
          })
          .then((fileTradePartners) =>
            fileTradePartners.map((fileTradePartner) => {
              fileTradePartner.parentGln = this.retailer.gln;
              tradePartners.push(fileTradePartner);
            }),
          ),
      ),
    )
      .then(() => this.addTradingPartners(tradePartners))
      .catch((error) => this.notificationService.showError(error));
  }

  processTradeParterFileLine(line: Record<string, string>) {
    this.validateLine(line);

    const faxNo = line.faxno.trim();
    const gln = line.gln.trim();
    const line1 = line.line1.trim();
    const line2 = line.line2.trim();
    const line3 = line.line3.trim();
    const line4 = line.line4.trim();
    const name = line.name.trim();
    const postalCode = line.postalcode.trim();
    const telNo = line.telno.trim();
    const type = line.type.trim();
    const vatCountryCode = line.vatcountrycode.trim();
    const vatNo = line.vatno.trim();

    const contact = [faxNo, telNo].some((x) => Boolean(x))
      ? ({
          ...(faxNo && { faxNo }),
          ...(telNo && { telNo }),
        } as Contact)
      : undefined;

    const addresses = [line1, line2, line3, line4, postalCode].some((x) =>
      Boolean(x),
    )
      ? ([
          {
            ...(line1 && { line1 }),
            ...(line2 && { line2 }),
            ...(line3 && { line3 }),
            ...(line4 && { line4 }),
            ...(postalCode && { postalCode }),
          },
        ] as Address[])
      : undefined;

    return {
      ...(addresses && { addresses }),
      ...(contact && { contact }),
      ...(gln && { gln }),
      ...(name && { name }),
      parentGln: this.retailer.gln,
      ...(type && { type }),
      ...(vatCountryCode && { vatCountryCode }),
      ...(vatNo && { vatNo }),
    } as TradingPartner;
  }

  addTradingPartners(tradingPartners) {
    console.debug("Got trade partners", tradingPartners);

    if (!tradingPartners) {
      throw new Error(
        "No Trading Partners found in the Trading Partners batch file",
      );
    }

    const loader = this.dialogService.loader();

    this.tradingPartnerService
      .batchInsertTradingPartner(tradingPartners)
      .subscribe(
        () => {
          this.notificationService.showSuccess(
            "Trading Partners added successfully",
          );
          loader.close();
          this.setLinkedStores();
        },
        (error) => {
          loader.close();
          this.notificationService.showError(error);
        },
      );
  }

  validateLine(line: Record<string, string>) {
    const type = line.type.toLowerCase();
    const { name } = line;

    if (this.retailer.gln !== line.parentGln) {
      throw new Error(
        "ParentGln does not match current retailer for store: " + name,
      );
    }

    if (!line.parentGln.trim() && type === "store") {
      throw new Error("ParentGln is required for Stores");
    }

    if (!line.gln.trim()) {
      throw new Error("Gln is required");
    }

    if (
      !Object.values(TradePartnerTypes)
        .map((tp) => tp.toLocaleLowerCase())
        .includes(type)
    ) {
      throw new Error("Type must be Store/Retailer/Supplier");
    }
  }
}
