import { Component } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NavigationService } from "../../../services/navigation.service";
@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.sass"],
})
export class ErrorComponent {
  constructor(private navigationservice: NavigationService) {}

  forgotPasswordForm: UntypedFormGroup;

  navigateHome() {
    this.navigationservice.navigateDashboard();
  }

  cancel() {
    this.navigationservice.navigateToLogin();
  }
}
