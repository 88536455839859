import { Observable } from "rxjs";
import { TradingPartner, TradingProcess } from "./types";

export class Order {
  id: string;
  interchangeNumber: string;
  orderNumber: string;
  dateOfOrder: Date;
  earliestDelivery: Date;
  latestDelivery: Date;
  transactionCode: string;
  total: number;
  vat?: number;
  discount: number;
  supplier: Observable<TradingPartner>;
  orderFrom: Observable<TradingPartner>;
  deliverTo: Observable<TradingPartner>;
  supplierGln: string;
  orderFromGln: string;
  deliverToGln: string;
  lines: OrderLine[];
  status: string;
  tradingProcess?: TradingProcess;
  narrative?: string;
  invoiceId?: string;
  invoiceDate?: Date;
}

export interface OrderLine {
  productEan: string;
  productDescription: string;
  quantity: number;
  vat: Vat;
  originalQuantity: number;
  itemCost: number;
  lineItemCost: number;
  packBarcode: string;
  unitBarcode: string;
  orderUnitBarcode: string;
  productCode: string;
  packSize?: any;
  lineNarrative?: any;
  lineSequenceNo?: string;
}

export interface Vat {
  rate: number;
  rateCode: string;
  countryCode: string;
  countryName: string;
  default: boolean;
}

export enum OrderStatus {
  "New" = "New",
  "Acknowledged" = "Acknowledged",
  "Confirmed" = "Confirmed",
  "Rejected" = "Rejected",
  "Invoiced" = "Invoiced",
}
