import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AwsDocumentsRepository } from "./repositories/aws-documents.repository";
import { AwsTradingPartnerRepository } from "./repositories/aws-trading-partner.repository";
import { MockDocumentRepository } from "./repositories/mockDocumentRepository";
import { DocumentsService } from "./services/documents.service";
import { TradingPartnerService } from "./services/trading-partner.service";

import { ScrollingModule } from "@angular/cdk/scrolling";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GoogleChartsModule } from "angular-google-charts";
import { MatTableExporterModule } from "mat-table-exporter";
import { MatTableFilterModule } from "mat-table-filter";
import { NgChartsModule } from "ng2-charts";
import { MaterialModule } from "../material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminReportViewComponent } from "./components/home/admin-report/admin-report-view/admin-report-view.component";
import { AdminReportComponent } from "./components/home/admin-report/admin-report.component";
import { DetailedSupplierReportComponent } from "./components/home/admin-report/detailed-supplier-report/detailed-supplier-report.component";
import { SupplierReportViewComponent } from "./components/home/admin-report/detailed-supplier-report/supplier-report-view/supplier-report-view.component";
import { DashboardComponent } from "./components/home/dashboard/dashboard.component";
import { OrderToInvoiceComponent } from "./components/home/dashboard/order-to-invoice/order-to-invoice.component";
import { OrderValuesComponent } from "./components/home/dashboard/order-values/order-values.component";
import { TopPerformingProductsComponent } from "./components/home/dashboard/top-performing-products/top-performing-products.component";
import { TotalOrdersComponent } from "./components/home/dashboard/total-orders/total-orders.component";
import { GetFinancedComponent } from "./components/home/get-financed/get-financed.component";
import { InvoicePendingComponent } from "./components/home/get-financed/invoice-processing/invoice-pending/invoice-pending.component";
import { InvoiceProcessingComponent } from "./components/home/get-financed/invoice-processing/invoice-processing.component";
import { InvoiceRejectedComponent } from "./components/home/get-financed/invoice-processing/invoice-rejected/invoice-rejected.component";
import { InvoicesAvailableComponent } from "./components/home/get-financed/invoice-processing/invoices-available/invoices-available.component";
import { RedirectPopupComponent } from "./components/home/get-financed/invoice-processing/invoices-available/redirect-popup/redirect-popup.component";
import { InvoicesSoldComponent } from "./components/home/get-financed/invoice-processing/invoices-sold/invoices-sold.component";
import { MoreInfoComponent } from "./components/home/get-financed/more-info/more-info.component";
import { NextStepsComponent } from "./components/home/get-financed/next-steps/next-steps.component";
import { SrfAdditionalInformationComponent } from "./components/home/get-financed/srf-additional-information/srf-additional-information.component";
import { TellUsAboutYourBusinessComponent } from "./components/home/get-financed/tell-us-about-your-business/tell-us-about-your-business.component";
import { HomeComponent } from "./components/home/home.component";
import { AssignSuppliersComponent } from "./components/home/manage-users/assign-suppliers/assign-suppliers.component";
import { ManageUsersComponent } from "./components/home/manage-users/manage-users/manage-users.component";
import { AddProductComponent } from "./components/home/my-products/add-product/add-product.component";
import { MyProductsComponent } from "./components/home/my-products/my-products.component";
import { UploadProductComponent } from "./components/home/my-products/upload-product/upload-product.component";
import { ActivityLogsComponent } from "./components/home/my-profile/activity-logs/activity-logs.component";
import { BusinessInformationComponent } from "./components/home/my-profile/business-information/business-information.component";
import { EditBusinessInfoComponent } from "./components/home/my-profile/business-information/edit-business-info/edit-business-info.component";
import { EditNotificationsComponent } from "./components/home/my-profile/edit-notifications/edit-notifications.component";
import { LastPerformedActionComponent } from "./components/home/my-profile/last-performed-action/last-performed-action.component";
import { MyProfileComponent } from "./components/home/my-profile/my-profile.component";
import { ManageUserComponent } from "./components/home/my-profile/users/manage-user/manage-user.component";
import { UsersComponent } from "./components/home/my-profile/users/users.component";
import { NavMenuComponent } from "./components/home/nav-menu/nav-menu.component";
import { SideMenuComponent } from "./components/home/nav-menu/side-menu/side-menu.component";
import { InvoiceComponent } from "./components/home/orders/order-new/invoice/invoice.component";
import { NewOrderTableComponent } from "./components/home/orders/order-new/new-order-table/new-order-table.component";
import { OrderNewComponent } from "./components/home/orders/order-new/order-new.component";
import { ConnectPartnerComponent } from "./components/home/partner-marketplace/connect-partner/connect-partner.component";
import { PartnerMarketplaceDetailsComponent } from "./components/home/partner-marketplace/partner-marketplace-details/partner-marketplace-details.component";
import { PartnerMarketplaceComponent } from "./components/home/partner-marketplace/partner-marketplace.component";
import { ReportsComponent } from "./components/home/reports/reports.component";
import { UserReportComponent } from "./components/home/reports/user-report/user-report.component";
import { SupplierViewComponent } from "./components/home/supplier-view/supplier-view.component";
import { ChangeHistoryComponent } from "./components/home/trading-partners/change-history/change-history.component";
import { LinkRetailerComponent } from "./components/home/trading-partners/trading-partner-details/linked-retailer/link-retailer/link-retailer.component";
import { LinkedRetailerComponent } from "./components/home/trading-partners/trading-partner-details/linked-retailer/linked-retailer.component";
import { LinkedStoreComponent } from "./components/home/trading-partners/trading-partner-details/linked-store/linked-store.component";
import { EditTradingPartnerComponent } from "./components/home/trading-partners/trading-partner-details/retailer-information/edit-trading-partner/edit-trading-partner.component";
import { RetailerInformationComponent } from "./components/home/trading-partners/trading-partner-details/retailer-information/retailer-information.component";
import { SupplierFeaturesComponent } from "./components/home/trading-partners/trading-partner-details/supplier-features/supplier-features.component";
import { TradingPartnerDetailsComponent } from "./components/home/trading-partners/trading-partner-details/trading-partner-details.component";
import { TradingProcessComponent } from "./components/home/trading-partners/trading-partner-details/trading-process/trading-process.component";
import { TradingPartnersComponent } from "./components/home/trading-partners/trading-partners.component";
import { UploadTradingPartnersComponent } from "./components/home/trading-partners/upload-trading-partners/upload-trading-partners.component";
import { FinancingSuccessComponent } from "./components/home/vodalend/financing-success/financing-success.component";
import { FinancingTermsComponent } from "./components/home/vodalend/financing-terms/financing-terms.component";
import { VodalendComponent } from "./components/home/vodalend/vodalend.component";
import { OrderTableComponent } from "./components/orders/order-table/order-table.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { FooterComponent } from "./components/portal/footer/footer.component";
import { ForgotPasswordComponent } from "./components/portal/forgot-password/forgot-password.component";
import { LandingComponent } from "./components/portal/landing/landing.component";
import { LoginComponent } from "./components/portal/login/login.component";
import { ResetPasswordComponent } from "./components/portal/reset-password/reset-password.component";
import { SearchDocumentComponent } from "./components/search-document/search-document.component";
import { ConnectPartnerDialogComponent } from "./components/shared/dialogs/connect-partner-dialog/connect-partner-dialog.component";
import { DatePickerComponent } from "./components/shared/dialogs/date-picker/date-picker.component";
import { PasswordResetConfirmComponent } from "./components/shared/dialogs/password-reset-confirm/password-reset-confirm.component";
import { SetRetailerDialogComponent } from "./components/shared/dialogs/set-retailer-dialog/set-retailer-dialog.component";
import { TermsAndConditionsComponent } from "./components/shared/dialogs/terms-and-conditions/terms-and-conditions.component";
import { ErrorComponent } from "./components/shared/error/error.component";
import { HelpComponent } from "./components/shared/help/help.component";
import { LoaderComponent } from "./components/shared/loader/loader.component";
import { NotificationComponent } from "./components/shared/notification/notification.component";
import { PrintLayoutComponent } from "./components/shared/print-layout/print-layout.component";
import { ScrollDirective } from "./components/shared/scrollDirective/scroll.directive";
import { TradingPartnerSummaryComponent } from "./components/trading-partners/trading-partner-summary/trading-partner-summary.component";
import { Session } from "./models/session";
import { AwsAuthRepository } from "./repositories/aws-auth-repository";
import { AwsEventRepository } from "./repositories/aws-event.repository";
import { AwsFinanceRepository } from "./repositories/aws-finance.repository";
import { AwsOrderRepository } from "./repositories/aws-order.repository";
import { AwsPartnerMarketPlaceRepository } from "./repositories/aws-partner-market-place-repository";
import { AwsProductRepository } from "./repositories/aws-product-repository";
import { AwsReportRepository } from "./repositories/aws-report-repository";
import { AwsSupportRepository } from "./repositories/aws-support-repository";
import { AwsUserRepository } from "./repositories/aws-user-repository";
import { RepositoryFactory } from "./repositories/repository.factory";
import { DialogService } from "./services/dialog.service";
import { EventService } from "./services/event.service";
import { FinanceService } from "./services/finance.service";
import { NotificationService } from "./services/notification.service";
import { UiDataService } from "./services/order-uidata.service";
import { OrdersService } from "./services/orders.service";
import { ProductService } from "./services/product.service";
import { SupportService } from "./services/support.service";
import { Settings } from "./settings";

@NgModule({
  declarations: [
    AppComponent,
    SearchDocumentComponent,
    HomeComponent,
    NavMenuComponent,
    SideMenuComponent,
    LandingComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    OrdersComponent,
    MyProductsComponent,
    PartnerMarketplaceComponent,
    MyProfileComponent,
    TotalOrdersComponent,
    OrderValuesComponent,
    OrderTableComponent,
    OrderNewComponent,
    NewOrderTableComponent,
    TradingPartnerSummaryComponent,
    InvoiceComponent,
    NotificationComponent,
    AddProductComponent,
    UploadProductComponent,
    PasswordResetConfirmComponent,
    ConnectPartnerComponent,
    PartnerMarketplaceDetailsComponent,
    ConnectPartnerDialogComponent,
    ManageUserComponent,
    VodalendComponent,
    FinancingTermsComponent,
    FinancingSuccessComponent,
    ForgotPasswordComponent,
    PrintLayoutComponent,
    ResetPasswordComponent,
    UsersComponent,
    EditBusinessInfoComponent,
    EditNotificationsComponent,
    LoaderComponent,
    HelpComponent,
    ErrorComponent,
    BusinessInformationComponent,
    LastPerformedActionComponent,
    ScrollDirective,
    TradingPartnersComponent,
    TradingPartnerDetailsComponent,
    RetailerInformationComponent,
    LinkedRetailerComponent,
    TradingProcessComponent,
    LinkedStoreComponent,
    EditTradingPartnerComponent,
    LinkRetailerComponent,
    UploadTradingPartnersComponent,
    ChangeHistoryComponent,
    TermsAndConditionsComponent,
    OrderToInvoiceComponent,
    TopPerformingProductsComponent,
    SupplierViewComponent,
    ActivityLogsComponent,
    AdminReportComponent,
    AdminReportViewComponent,
    AssignSuppliersComponent,
    ManageUsersComponent,
    SetRetailerDialogComponent,
    UserReportComponent,
    ReportsComponent,
    DetailedSupplierReportComponent,
    SupplierReportViewComponent,
    GetFinancedComponent,
    MoreInfoComponent,
    TellUsAboutYourBusinessComponent,
    NextStepsComponent,
    InvoiceProcessingComponent,
    InvoicesSoldComponent,
    InvoiceRejectedComponent,
    InvoicesAvailableComponent,
    InvoicePendingComponent,
    SrfAdditionalInformationComponent,
    RedirectPopupComponent,
    DatePickerComponent,
    SupplierFeaturesComponent,
  ],
  imports: [
    MatTableFilterModule,
    MatTableExporterModule,
    BrowserModule,
    GoogleChartsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    ScrollingModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    NgChartsModule,
    MatDialogModule,
  ],
  providers: [
    AwsAuthRepository,
    AwsUserRepository,
    DocumentsService,
    AwsDocumentsRepository,
    MockDocumentRepository,
    RepositoryFactory,
    UiDataService,
    AwsTradingPartnerRepository,
    OrdersService,
    AwsOrderRepository,
    TradingPartnerService,
    SupportService,
    AwsSupportRepository,
    EventService,
    AwsEventRepository,
    FinanceService,
    AwsFinanceRepository,
    DialogService,
    Session,
    NotificationService,
    ProductService,
    AwsProductRepository,
    AwsPartnerMarketPlaceRepository,
    AwsReportRepository,
    PrintLayoutComponent,
    Settings,
    SetRetailerDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
