import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Session } from "../models/session";
import { Partner } from "../models/types";
import { PartnerMarketPlaceRepository } from "../repositories/partner-market-place-repository";
import { RepositoryFactory } from "../repositories/repository.factory";

@Injectable({
  providedIn: "root",
})
export class PartnerMarketPlaceService {
  tradingPartnerMarketPlaceRepo: PartnerMarketPlaceRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.tradingPartnerMarketPlaceRepo =
      repoFactory.getPartnerMarketRepository();
  }

  connect(id: string, partner: Partner): Observable<void> {
    // if (!partner) {
    //   throw new Error("partner is required");
    // }
    return this.tradingPartnerMarketPlaceRepo.connect(
      this.session.getSupplierGln(),
      id,
      partner,
    );
  }
}
