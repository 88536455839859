import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReportRepository } from "../repositories/report-repository";
import { Session } from "./../models/session";
import {
  DetailedSupplierReport,
  SupplierReport,
  User,
} from "./../models/types";
import { RepositoryFactory } from "./../repositories/repository.factory";
@Injectable({
  providedIn: "root",
})
export class ReportService {
  reportRepo: ReportRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.reportRepo = repoFactory.getReportRepository();
  }

  getDetailedSupplierReports(
    dateFrom: string,
    dateTo: string,
    supplierGlns: string[],
  ): Observable<DetailedSupplierReport[]> {
    return this.reportRepo.generateTradingPartnerDetailedSupplierReports(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
      supplierGlns,
    );
  }

  getSupplierReports(
    dateFrom: string,
    dateTo: string,
    supplierGlns: string[],
  ): Observable<SupplierReport[]> {
    return this.reportRepo.generateTradingPartnerSupplierReports(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
      supplierGlns,
    );
  }

  getUserReports(): Observable<User[]> {
    return this.reportRepo.generateActiveUserReports(
      this.session.getSupplierGln(),
    );
  }
}
