import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { Session } from "../../../../models/session";
import {
  BusinessAddress,
  BusinessDetail,
  PersonalAddress,
  PersonalDetail,
  SrfApplication,
} from "../../../../models/srfApplication";
import { TradingPartner } from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { FinanceService } from "../../../../services/finance.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

@Component({
  selector: "app-tell-us-about-your-business",
  templateUrl: "./tell-us-about-your-business.component.html",
  styleUrls: ["./tell-us-about-your-business.component.sass"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class TellUsAboutYourBusinessComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  tradingPartner: TradingPartner = new TradingPartner();
  loadTradingPartner = false;
  businessInformationFormGroup: UntypedFormGroup;
  personalInfomationFormGroup: UntypedFormGroup;
  consentToShareFormGroup: UntypedFormGroup;
  companyTypes: string[] = [];
  businessTypes: string[] = [];
  selectedType = "id";
  idErrorMessage = "";
  idRegExpression = "";
  selectedCompanyType = "";
  selectedBusinessType = "";
  termsAndConditions = false;
  applicationSubmitted = false;
  applicationInProgress = false;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private location: Location,
    private session: Session,
    private dialogRef: DialogService,
    private navigationService: NavigationService,
    private notification: NotificationService,
    private financeService: FinanceService,
    private tradingPartnerService: TradingPartnerService,
  ) {}

  ngOnInit(): void {
    this.setupBusinessInformationFormGroup();
    this.setupPersonalInfomationFormGroup();
    this.setupConsentToShareFormGroup();
    this.getCompanyTypes();
    this.getBusinessTypes();
    this.setTradingPartner();
    this.setIdRegularExpression();

    this.businessInformationFormGroup.controls.businessType.valueChanges.subscribe();
  }

  private setTradingPartner() {
    this.loadTradingPartner = true;
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartnerService
        .getTradingPartner(this.session.getSupplierGln())
        .subscribe((tradingPartner) => {
          if (tradingPartner) {
            this.tradingPartner = tradingPartner;
            this.loadTradingPartner = false;
            this.setDefaults();
          }
        });
    });
  }

  setupBusinessInformationFormGroup() {
    this.businessInformationFormGroup = this._formBuilder.group({
      companyName: ["", Validators.required],
      glnCode: ["", Validators.required],
      vatNumber: ["", Validators.required],
      registeredName: ["", Validators.required],
      companyRegistrationNumber: ["", Validators.required],
      companyType: ["", Validators.required],
      tradingYears: ["", Validators.required],
      businessType: ["", Validators.required],

      unitNumber: ["", null],
      streetNumber: ["", null],
      buildingName: ["", null],
      streetName: ["", null],
      suburb: ["", null],
      city: ["", null],
    });
  }

  setupPersonalInfomationFormGroup() {
    this.personalInfomationFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      surname: ["", Validators.required],
      designation: ["", Validators.required],
      idType: ["", Validators.required],
      idNumber: ["", Validators.required],
      emailAddress: ["", Validators.required, undefined, Validators.email],
      cellphoneNumber: ["+27", Validators.required],

      addressLine1: ["", null],
      addressLine2: ["", null],
      addressLine3: ["", null],
      suburb: ["", null],
      city: ["", null],
    });
  }

  setDefaults() {
    this.selectedType = "id";
    this.selectedCompanyType = "";
    this.selectedBusinessType = "";
    this.termsAndConditions = false;
    this.applicationSubmitted = false;
    this.businessInformationFormGroup.controls.companyName.setValue(
      this.tradingPartner.name,
    );
    this.businessInformationFormGroup.controls.companyName.disable();
    this.businessInformationFormGroup.controls.glnCode.setValue(
      this.tradingPartner.gln,
    );
    this.businessInformationFormGroup.controls.glnCode.disable();
    this.businessInformationFormGroup.controls.vatNumber.setValue(
      this.tradingPartner.vatNo,
    );
    this.businessInformationFormGroup.controls.vatNumber.disable();
    this.businessInformationFormGroup.controls.registeredName.setValue(
      this.tradingPartner.name,
    );
    this.businessInformationFormGroup.controls.companyRegistrationNumber.setValue(
      "",
    );
    this.businessInformationFormGroup.controls.companyType.setValue("");
    this.businessInformationFormGroup.controls.tradingYears.setValue("");
    this.businessInformationFormGroup.controls.businessType.setValue(
      this.tradingPartner.type,
    );

    this.businessInformationFormGroup.controls.unitNumber.setValue("");
    this.businessInformationFormGroup.controls.streetNumber.setValue("");
    this.businessInformationFormGroup.controls.buildingName.setValue("");

    this.businessInformationFormGroup.controls.streetName.setValue("");
    this.businessInformationFormGroup.controls.suburb.setValue("");
    this.businessInformationFormGroup.controls.city.setValue("");

    this.personalInfomationFormGroup.controls.firstName.setValue(
      this.session.getUser().name,
    );
    this.personalInfomationFormGroup.controls.surname.setValue(
      this.session.getUser().surname,
    );
    this.personalInfomationFormGroup.controls.designation.setValue("");
    this.personalInfomationFormGroup.controls.idType.setValue("id");
    this.personalInfomationFormGroup.controls.idNumber.setValue("");
    this.personalInfomationFormGroup.controls.emailAddress.setValue(
      this.session.getUser().email,
    );
    this.personalInfomationFormGroup.controls.emailAddress.disable();
    this.personalInfomationFormGroup.controls.cellphoneNumber.setValue("+27");

    this.personalInfomationFormGroup.controls.addressLine1.setValue("");
    this.personalInfomationFormGroup.controls.addressLine2.setValue("");
    this.personalInfomationFormGroup.controls.addressLine3.setValue("");
    this.personalInfomationFormGroup.controls.suburb.setValue("");
    this.personalInfomationFormGroup.controls.city.setValue("");
  }

  setupConsentToShareFormGroup() {
    this.consentToShareFormGroup = this._formBuilder.group({
      termsAndConditions: ["", Validators.required],
    });
  }

  getCompanyTypes() {
    this.financeService.getCompanyTypes().subscribe(
      (companyTypes) => {
        if (companyTypes) {
          this.companyTypes = companyTypes;
        }
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  getBusinessTypes() {
    this.financeService.getBusinessTypes().subscribe(
      (businessTypes) => {
        if (businessTypes) {
          this.businessTypes = businessTypes;
        }
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  setSelectedCompanyType(companyType: string) {
    this.selectedCompanyType = companyType;
  }

  setSelectedBusinessType(businessType: string) {
    this.selectedBusinessType = businessType;
  }

  getIdType(type: string) {
    this.selectedType = type;
    this.personalInfomationFormGroup.controls.idType.setValue(
      this.selectedType.toLowerCase(),
    );
    this.personalInfomationFormGroup.controls.idType.setErrors(null);
    this.setIdRegularExpression();
    this.personalInfomationFormGroup.controls.idNumber.setValue("");
  }

  confirmInformation() {
    this.dialogRef
      .showConfirmationDialog(
        "Confirm your information",
        "Are you sure the information captured is correct?",
        "Edit",
        "Continue",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.myStepper.next();
        }
      });
  }

  cancelApplication() {
    this.dialogRef
      .showConfirmationDialog(
        "Cancel your application?",
        "Are you sure you want to cancel this application?",
        "No",
        "Yes",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.navigationService.navigateToGetFinanced();
        }
      });
  }

  getCapturedInformation(): SrfApplication {
    const businessDetails: BusinessDetail = {
      companyName: this.businessInformationFormGroup.controls.companyName.value,
      glnCode: this.businessInformationFormGroup.controls.glnCode.value,
      vatNumber: this.businessInformationFormGroup.controls.vatNumber.value,
      registeredName:
        this.businessInformationFormGroup.controls.registeredName.value,
      registrationNumber:
        this.businessInformationFormGroup.controls.companyRegistrationNumber
          .value,
      companyType: this.businessInformationFormGroup.controls.companyType.value,
      businessType:
        this.businessInformationFormGroup.controls.businessType.value,
      tradingYears:
        this.businessInformationFormGroup.controls.tradingYears.value,
    };

    const businessAddress: BusinessAddress = {
      unitNumber: this.businessInformationFormGroup.controls.unitNumber.value,
      streetNumber:
        this.businessInformationFormGroup.controls.streetNumber.value,
      streetName: this.businessInformationFormGroup.controls.streetName.value,
      suburb: this.businessInformationFormGroup.controls.suburb.value,
      city: this.businessInformationFormGroup.controls.city.value,
      buildingName:
        this.businessInformationFormGroup.controls.buildingName.value,
    };

    const personalDetails: PersonalDetail = {
      firstNames: this.personalInfomationFormGroup.controls.firstName.value,
      surname: this.personalInfomationFormGroup.controls.surname.value,
      designation: this.personalInfomationFormGroup.controls.designation.value,
      idType: this.personalInfomationFormGroup.controls.idType.value,
      idPassportNumber:
        this.personalInfomationFormGroup.controls.idNumber.value,
      email: this.personalInfomationFormGroup.controls.emailAddress.value,
      cell: this.personalInfomationFormGroup.controls.cellphoneNumber.value,
    };

    const personalAddress: PersonalAddress = {
      line1: this.personalInfomationFormGroup.controls.addressLine1.value,
      line2: this.personalInfomationFormGroup.controls.addressLine2.value,
      line3: this.personalInfomationFormGroup.controls.addressLine3.value,
      suburb: this.personalInfomationFormGroup.controls.suburb.value,
      city: this.personalInfomationFormGroup.controls.city.value,
    };

    const srfApplication: SrfApplication = {
      businessAddress: businessAddress,
      personalAddress: personalAddress,
      businessDetail: businessDetails,
      personalDetail: personalDetails,
    };

    return srfApplication;
  }

  submit() {
    this.ApplyForFinance();
  }

  nextSteps() {
    this.navigationService.navigateToNextSteps();
  }

  ApplyForFinance() {
    this.applicationInProgress = true;
    const srfApplication = this.getCapturedInformation();
    this.financeService.processApplication(srfApplication).subscribe(
      (status) => {
        this.applicationInProgress = false;
        if (status) {
          this.applicationSubmitted = true;
          this.applicationInProgress = true;
        }
      },
      (error) => {
        this.notification.showError(error);
        this.applicationInProgress = false;
      },
    );
  }

  showTermsAndConditions() {
    this.dialogRef.termsAndContitions().afterClosed();
  }

  setIdRegularExpression() {
    if (this.selectedType.toLocaleLowerCase() === "id") {
      this.idErrorMessage = "Id number is invald";
      this.idRegExpression =
        "^(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))$";
    } else {
      this.idErrorMessage = "Passport is invald";
      this.idRegExpression = "^(?!^0+$)[a-zA-Z0-9]{3,20}$";
    }
  }

  getBusinessInfoControlErrorsStates(controlName: any) {
    if (!this.businessInformationFormGroup) return false;
    return this.businessInformationFormGroup.controls[controlName.toString()]
      ?.errors == null
      ? false
      : true;
  }

  getPersonalInfoControlErrorsStates(controlName: any) {
    if (!this.personalInfomationFormGroup) return false;
    return this.personalInfomationFormGroup.controls[controlName.toString()]
      ?.errors == null
      ? false
      : true;
  }
}
