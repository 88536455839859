import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-redirect-popup",
  templateUrl: "./redirect-popup.component.html",
  styleUrls: ["./redirect-popup.component.sass"],
})
export class RedirectPopupComponent {
  title: string;
  message: string;
  name: string;
  declineButtonText: string;
  acceptButtonText: string;
  constructor(public dialogRef: MatDialogRef<RedirectPopupComponent>) {}

  confirm(): void {
    this.dialogRef.close(true);
  }
  cancel(): void {
    this.dialogRef.close(false);
  }
}
