import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { isEqual } from "lodash";

import {
  EditType,
  ManageUserModel,
  Roles,
  TradingPartnerSummary,
  User,
} from "../../../../../models/types";
import { AuthService } from "../../../../../services/auth.service";
import { NotificationService } from "../../../../../services/notification.service";
import { TradingPartnerService } from "../../../../../services/trading-partner.service";
import { UserService } from "../../../../../services/user.service";
import { Session } from "./../../../../../models/session";

export interface UserRoles {
  name: string;
}

export interface Country {
  name: string;
  code: string;
}

@Component({
  selector: "app-manage-user",
  templateUrl: "./manage-user.component.html",
  styleUrls: ["./manage-user.component.sass"],
})
export class ManageUserComponent implements OnInit {
  message: string;
  manageUserType: EditType;
  nameLabel = "Na";
  businessTypeLabel = "Business type";
  addUserForm: UntypedFormGroup;
  tradingPartnerGln: string;
  user: User;
  isLoading = false;
  dataEdited = false;
  roles: UserRoles[] = [];
  alreadySelectedSuppliers: TradingPartnerSummary[];
  selectedSuppliers: TradingPartnerSummary[];
  selectedSuppliersGlns: string[];

  countries: Country[] = [{ name: "South Africa", code: "+27" }];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ManageUserComponent>,
    public authService: AuthService,
    public userService: UserService,
    private tradingPartnerService: TradingPartnerService,
    public notificationService: NotificationService,
    private session: Session,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.user = data.user;
    this.tradingPartnerGln = data.tradingPartnerGln;
    if (
      session.isAdmin() &&
      this.tradingPartnerGln == session.getUser().tradingPartnerGln
    ) {
      this.roles.push({ name: Roles.admin });
    } else {
      this.roles.push({ name: Roles.customer });
      this.roles.push({ name: Roles.customerAdmin });
    }
    this.manageUserType = data.type;
    this.message = `${
      this.manageUserType[0].toUpperCase() +
      this.manageUserType.slice(1, this.manageUserType.length)
    } User`;
  }

  addUser() {
    this.isLoading = true;
    this.userService.add(this.getCapturedData()).subscribe(
      () => {
        this.isLoading = false;
        this.notificationService.showSuccess("User added successfully");
        this.close(true);
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.showError(error);
      },
    );
  }

  close(result: boolean) {
    this.dialogRef.close(result);
  }

  constructPhoneNumber() {
    return this.addUserForm.controls.contactNumber.value[0] === "0"
      ? this.addUserForm.controls.country.value +
          this.addUserForm.controls.contactNumber.value.substr(1)
      : this.addUserForm.controls.contactNumber.value;
  }

  disableFields() {
    this.addUserForm.controls.role.disable();
    this.addUserForm.controls.name.disable();
    this.addUserForm.controls.country.disable();
    this.addUserForm.controls.surname.disable();
    this.addUserForm.controls.email.disable();
    this.addUserForm.controls.contactNumber.disable();
    this.addUserForm.controls.enableMFA.disable();
  }

  editUser() {
    this.isLoading = true;
    this.userService.edit(this.getCapturedData()).subscribe(
      () => {
        this.notificationService.showSuccess("User edited successfully");
        this.isLoading = false;
        this.close(true);
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.showError(error);
      },
    );
  }

  formValidity() {
    return this.supplierValidationCheck() && this.addUserForm.valid;
  }

  getCapturedData() {
    let user: ManageUserModel;
    if (this.tradingPartnerGln) {
      user = {
        userId: this.addUserForm.controls.userId.value,
        name: this.addUserForm.controls.name.value,
        surname: this.addUserForm.controls.surname.value,
        mobile: this.constructPhoneNumber(),
        email: this.addUserForm.controls.email.value,
        username: this.addUserForm.controls.email.value,
        roles: [this.addUserForm.controls.role.value],
        isMfa: this.addUserForm.controls.enableMFA.value,
        tradingPartnerGlns: this.selectedSuppliersGlns,
      };
    } else {
      user = {
        userId: this.addUserForm.controls.userId.value,
        name: this.addUserForm.controls.name.value,
        surname: this.addUserForm.controls.surname.value,
        mobile: this.constructPhoneNumber(),
        email: this.addUserForm.controls.email.value,
        username: this.addUserForm.controls.email.value,
        roles: [this.addUserForm.controls.role.value],
        isMfa: this.addUserForm.controls.enableMFA.value,
        tradingPartnerGlns: this.selectedSuppliersGlns,
      };
    }
    return user;
  }

  getSelectedSuppliers(suppliers: TradingPartnerSummary[]) {
    this.selectedSuppliersGlns = [];
    this.selectedSuppliers = suppliers;
    this.selectedSuppliers.forEach((supplier) =>
      this.selectedSuppliersGlns.push(supplier.gln),
    );
  }

  getSuppliers() {
    this.tradingPartnerService.getSuppliers().subscribe(
      (suppliers) => {
        if (!suppliers) return;
        if (this.user) {
          this.selectedSuppliersGlns = [];
          this.alreadySelectedSuppliers = suppliers.filter(
            (supplier) =>
              this.user.tradingPartnerGlns.indexOf(supplier.gln) != -1,
          );
          this.selectedSuppliers = this.alreadySelectedSuppliers;
          this.selectedSuppliers.forEach((supplier) =>
            this.selectedSuppliersGlns.push(supplier.gln),
          );
        }
      },
      (error) => {
        this.notificationService.showError(error);
      },
    );
  }

  isAdmin(): boolean {
    // html check for vodacom admin only
    return this.session.isAdmin();
  }

  ngOnInit(): void {
    this.setupFormGroup();
    this.manageUserType == EditType.view ? this.disableFields() : {};

    if (this.user) {
      this.isLoading = true;
      // Load full user
      this.userService.get(this.user.userId).subscribe(
        (result) => {
          this.user = result;
          this.isLoading = false;
          this.prepopulate();
          this.addUserForm.controls.email.disable();
        },
        (error) => {
          this.notificationService.showError(error);
          this.isLoading = false;
        },
      );
    }

    this.getSuppliers();
  }

  prepopulate() {
    this.addUserForm.controls.userId.setValue(this.user.userId);
    this.addUserForm.controls.name.setValue(this.user.name);
    this.addUserForm.controls.country.setValue(this.countries[0].code);
    this.addUserForm.controls.surname.setValue(this.user.surname);
    this.addUserForm.controls.email.setValue(this.user.email);
    this.addUserForm.controls.contactNumber.setValue(this.user.mobile);
    this.addUserForm.controls.enableMFA.setValue(this.user.isMfa);

    if (this.user.roles.includes(Roles.admin)) {
      const role = this.roles.find((x) => x.name == Roles.admin);
      this.addUserForm.controls.role.setValue(role?.name);
    } else if (this.user.roles.includes(Roles.customerAdmin)) {
      const role = this.roles.find((x) => x.name == Roles.customerAdmin);
      this.addUserForm.controls.role.setValue(role?.name);
    } else {
      const role = this.roles.find((x) => x.name == Roles.customer);
      this.addUserForm.controls.role.setValue(role?.name);
    }

    const tempData = this.getCapturedData();

    this.addUserForm.valueChanges.subscribe(() => {
      this.dataEdited = isEqual(tempData, this.getCapturedData());
    });
  }

  setupFormGroup() {
    this.addUserForm = this.formBuilder.group({
      userId: ["", null],
      name: ["", Validators.required],
      surname: ["", Validators.required],
      contactNumber: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      role: ["", Validators.required],
      country: ["", Validators.required],
      enableMFA: ["", null],
    });
  }

  showAssignSuppliers(): boolean {
    return (
      !this.userIsAdmin() &&
      this.isAdmin() &&
      this.tradingPartnerGln != "AdminGln"
    );
  }

  submit(): void {
    if (this.user) {
      this.editUser();
    } else {
      this.addUser();
    }
  }

  supplierValidationCheck() {
    return this.showAssignSuppliers()
      ? this.selectedSuppliersGlns?.length > 0
        ? true
        : false
      : true;
  }

  userIsAdmin(): boolean {
    return this.user?.roles?.includes(Roles.admin) ?? false;
  }

  userIsCustomerAdmin(): boolean {
    return this.user?.roles?.includes(Roles.customerAdmin) ?? false;
  }
}
