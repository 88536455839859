<div #pageDiv class="flex fx-jc-center fx-ai-center grayBG m-b-70">
  <div class="width-90 flex m-20 fx-jc-center">
    <form [formGroup]="productControlForm">
      <div class="flex fx-row">
        <div class="flex fx-row m-t-10 width-100">
          <mat-form-field class="width-20 round tradeCard p-l-20">
            <mat-select
              (selectionChange)="selectRetailer()"
              placeholder="Retailer"
              formControlName="tradingPartner"
            >
              <mat-option
                *ngFor="let tradingPartner of tradingPartners"
                [value]="tradingPartner.gln"
              >
                {{ tradingPartner.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            class="width-20 fx-ai-center m-l-auto flex round xs-card-t p-r-20 p-l-20"
          >
            <mat-form-field>
              <input
                matInput
                (keyup.enter)="search()"
                (blur)="clearSearch()"
                formControlName="searchProduct"
                pattern="^[0-9]+([,.][0-9]+)?$"
              />
              <mat-label
                ><mat-icon>search</mat-icon> Type to search Barcode
              </mat-label>
              <mat-error
                *ngIf="
                  productControlForm.controls.searchProduct.hasError('pattern')
                "
              >
                Numeric value <strong>only</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <p class="heading flex p-b-10">My Products</p>

        <div
          class="grayBanner flex p-20 m-b-20 fx-row"
          [ngStyle]="{ width: setWidth() + 'px' }"
          [ngClass]="{ stickyProducts: getScrollPosition() }"
        >
          <div *ngIf="!loadTradingPartner" class="flex fx-row">
            <p class="p-b p-r-10">{{ getArrayLength() }}</p>
            <p class="flex p-b">Products</p>
          </div>

          <div
            *ngIf="loadTradingPartner"
            class="animate__loadingFlash animate__animated animate__slow animate__infinite height-20 width-30 fx-ai-center flex fx-row"
          >
            <div class="loadBar height-5 p-5 width-5"></div>
            <div class="loadBar height-5 p-5 m-l-10 width-15"></div>
          </div>

          <div class="flex fx-jc-center m-l-auto fx-row productButtons">
            <button
              matRipple
              type="button"
              class="flex selectedItem m-r-10"
              (click)="uploadFile()"
            >
              Upload
            </button>
            <button
              matRipple
              type="button"
              class="flex selectedItem m-r-10"
              (click)="addProduct()"
            >
              Add
            </button>
            <button
              matRipple
              type="button"
              [disabled]="disableEdit()"
              (click)="editProduct()"
              class="flex selectedItem m-r-10"
            >
              Edit
            </button>
            <button
              matRipple
              type="button"
              [disabled]="disableDelete()"
              (click)="deleteProduct()"
              class="flex selectedItem m-r-10"
            >
              Delete
            </button>
          </div>
        </div>

        <div
          *ngIf="loadProducts"
          class="animate__loadingFlash animate__animated animate__slow animate__infinite height-30 p-b-30 p-t-30 grayBG fx-jc-center fx-ai-center flex fx-row"
        >
          <div class="loadBar height-5 p-5 m-l-60 width-15"></div>
          <div class="width-40"></div>
          <div class="loadBar height-5 p-5 m-l-60 width-20"></div>
          <div class="width-70"></div>
          <div class="loadBar height-5 p-5 m-l-60 width-25"></div>
          <div class="width-40"></div>
          <div class="loadBar height-5 p-5 m-l-60 width-15"></div>
          <div class="width-100"></div>
          <div class="loadBar height-5 p-5 m-r-60 width-5"></div>
        </div>

        <div>
          <table
            *ngIf="!loadProducts"
            matTableFilter
            matTableExporter
            mat-table
            [dataSource]="dataSource"
            class="width-100"
            #exporter="matTableExporter"
            [exampleEntity]="filterEntity"
          >
            <!-- retailer Column -->
            <ng-container matColumnDef="retailer">
              <th mat-header-cell *matHeaderCellDef>Retailer</th>
              <td mat-cell *matCellDef="let element">
                {{ element.retailerName }}
              </td>
            </ng-container>

            <!-- retailer Column -->
            <ng-container matColumnDef="barcode">
              <th mat-header-cell *matHeaderCellDef>
                Barcode
                <input
                  formControlName="barcode"
                  style="display: none"
                  matInput
                  [(ngModel)]="filterEntity.barcode"
                />
              </th>
              <td mat-cell *matCellDef="let element">{{ element.barCode }}</td>
            </ng-container>

            <!-- productCode Column -->
            <ng-container matColumnDef="productCode">
              <th mat-header-cell *matHeaderCellDef>Product Code</th>
              <td mat-cell *matCellDef="let element">
                {{ element.productCode }}
              </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let element">
                {{ element.productDescription }}
              </td>
            </ng-container>

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (change)="manageProduct(row)"
                  [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)"
                  formControlName="selected"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </form>
  </div>
</div>
