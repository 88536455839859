import { Component, OnInit } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { FinanceProduct } from "../../../models/financeProduct";
import { Session } from "../../../models/session";
import { SrfApplicationStatus } from "../../../models/srfApplication";
import { TradingPartner } from "../../../models/types";
import { FinanceService } from "../../../services/finance.service";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";

@Component({
  selector: "app-get-financed",
  templateUrl: "./get-financed.component.html",
  styleUrls: ["./get-financed.component.sass"],
})
export class GetFinancedComponent implements OnInit {
  tradingPartner: Observable<TradingPartner>;
  applicationState = "";
  financeProducts: FinanceProduct[];
  financeProduct: FinanceProduct;
  loadProducts = false;
  loadTradingPartner = false;
  constructor(
    private navigationService: NavigationService,
    private notification: NotificationService,
    private session: Session,
    private tradingPartnerService: TradingPartnerService,
    private financeService: FinanceService,
  ) {}

  ngOnInit(): void {
    this.setTradingPartner();
    this.getProducts();
    this.getInvoiceApplicationStatus();
    this.financeService.getProducts().subscribe(
      (status) => {
        if (status) {
          this.financeProduct = status[0];
        }
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  private setTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartner = this.tradingPartnerService.getTradingPartner(
        this.session.getSupplierGln(),
      );

      this.tradingPartner.subscribe(
        () => {
          this.loadTradingPartner = false;
        },
        () => {
          this.loadTradingPartner = false;
        },
      );
    });
  }

  getInvoiceApplicationStatus() {
    this.financeService.getFinanceApplicationStatus().subscribe(
      (status) => {
        if (status) {
          this.applicationState = status;
        }
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  getProducts() {
    this.loadProducts = true;
    this.financeService.getProducts().subscribe(
      (product) => {
        if (product) {
          this.financeProducts = product;
          this.loadProducts = false;
        }
      },
      (error) => {
        this.notification.showError(error);
        this.loadProducts = false;
      },
    );
  }

  moreInfo() {
    this.navigationService.navigateToMoreInfo();
  }

  viewFAQ() {
    this.navigationService.navigateToNextSteps();
  }
  applicationRejected(): boolean {
    return this.applicationState == SrfApplicationStatus.Rejected
      ? true
      : false;
  }

  applicationInProgress(): boolean {
    return this.applicationState == SrfApplicationStatus.InProgress
      ? true
      : false;
  }

  applicationInCompleted(): boolean {
    return this.applicationState != SrfApplicationStatus.Complete
      ? true
      : false;
  }

  navToFundYourBusiness() {
    window.open(
      "https://www.vodacombusiness.co.za/business/solutions/fund-my-business",
      "_blank",
    );
  }

  navToBusinessCashAdvance() {
    window.open(
      "https://www.vodacombusiness.co.za/business/solutions/financial-services/accept-payments",
      "_blank",
    );
  }

  formatDates() {
    const startDate = moment(new Date());
    const endDate = moment(this.financeProducts[0]?.nextProcessDate);
    const weekData = [];
    while (startDate.isSameOrBefore(endDate)) {
      if (weekData.length > 0) {
        // Update end date
        const lastObj = weekData[weekData.length - 1];
        if (lastObj) {
          lastObj["endDate"] = moment(startDate).format("MM/DD");
          lastObj[
            "label"
          ] = `${lastObj.startDate} - ${lastObj["endDate"]} (week${weekData.length})`;
          startDate.add(1, "days");
        }
      }
      weekData.push({ startDate: moment(startDate).format("MM/DD") });
      startDate.add(6, "days");
    }
    if (startDate.isAfter(endDate)) {
      // Update last object
      const lastObj = weekData[weekData.length - 1];
      if (lastObj) {
        lastObj["endDate"] = moment(endDate).format("MM/DD");
        lastObj[
          "label"
        ] = `${lastObj.startDate} - ${lastObj["endDate"]} (week${weekData.length})`;
      }
    }
    return weekData.length > 1
      ? weekData.length + " Weeks"
      : weekData.length + " Week";
  }
}
