<!-- <app-nav-menu></app-nav-menu> -->
<!-- <div class="p-30"></div> -->
<div class="flex center-item height fx-row noOverFlow">
  <div class="flex loginBg fx-jc-center p-30 width-60 artBG">
    <div class="flex height-40">
      <img width="250" src="./assets/icons/Vodacom_fin_services.svg" />
    </div>
    <div class="flex height-100">
      <img
        class="artmin-width"
        width="500"
        src="./assets/images/technicalError.svg"
      />
    </div>
    <div class="flex">
      <p class="loginCol p-l subheadingTxt">
        © 2020 Vodacom Financial Services
      </p>
    </div>
    <!-- <img 
        class="artmin-width flex fx-ai-end" width="500" src='{{artworkpath+artwork}}'> -->
  </div>
  <div class="width-100 flex fx-row whiteBG">
    <div class="flex center-item fx-jc-center width-30 min-width">
      <div class="flex center-item fx-jc-center fx-ai-center">
        <h1 class="flex p-b-20 logintxt title fx-jc-center fx-ai-center">
          Technical Issue
        </h1>
        <h2 class="flex p-b-20 logintxt subTitle fx-jc-center fx-ai-center">
          Uh oh! We’re experiencing technical difficulties, but we’re working on
          it. Please try again later.
        </h2>

        <button
          mat-flat-button
          class="flex primaryBtn width-50 round"
          (click)="navigateHome()"
        >
          Return to home page
        </button>

        <div class="bottomLinks m-t-20">
          <a mat-flat routerLink="." (click)="cancel()" class="m-0 fw-300"
            >Cancel?
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
