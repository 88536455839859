import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NotificationComponent } from "../components/shared/notification/notification.component";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar) {}

  showError(error: Error, action = "Dismiss") {
    const config = new MatSnackBarConfig();
    config.panelClass = ["notification-error"];
    config.duration = 5000;
    this.snackBar.open(error.message ?? "Something went wrong", action, config);
  }

  showSuccess(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ["notification-success"];
    config.duration = 5000;
    config.data = {
      message: message,
      type: "success",
    };

    this.snackBar.openFromComponent(NotificationComponent, config);
  }

  showWarning(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ["v-background-yellow"];
    this.snackBar.open(message, action, config);
  }
  showInfo(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ["v-background-grey"];
    config.duration = 5000;
    this.snackBar.open(message, action, config);
  }

  showBusy(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ["v-background-grey"];
    return this.snackBar.open(message, action, config);
  }
}
