import { Component } from "@angular/core";
import { Session } from "../../../../models/session";

@Component({
  selector: "app-last-performed-action",
  templateUrl: "./last-performed-action.component.html",
  styleUrls: ["./last-performed-action.component.sass"],
})
export class LastPerformedActionComponent {
  lastPerformedActions = [
    {
      status: "Invoiced",
      retailer: "Pick ‘n Pay",
      orderNumber: "1234567",
      orderDate: Date.now(),
      byUser: "Username",
    },
    {
      status: "New",
      retailer: "Pick ‘n Pay",
      orderNumber: "1234567",
      orderDate: Date.now(),
      byUser: "User",
    },
    {
      status: "Acknowledged",
      retailer: "Foshini",
      orderNumber: "1234567",
      orderDate: Date.now(),
      byUser: "Foshini",
    },
  ];

  graphColors = {
    New: "#00B0CA",
    Acknowledged: "#9C2AA0",
    Confirmed: "#005765",
    Rejected: "#FF0000",
    Invoiced: "#BD0000",
  };

  constructor(public session: Session) {}

  borderStyle(status) {
    return { "border-left": "8px solid " + this.graphColors[status] };
  }
}
