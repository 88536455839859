<div class="flex fx-row m-t-10 m-b-40 bl-b-l">
  <h2 class="fw-500 m-t-10 m-b-10">{{ "Upload" | uppercase }}</h2>
  <img
    (click)="closeDialog()"
    matRipple
    class="icon selectedItem flex m-l-auto"
    src="./assets/icons/close.svg"
    style="justify-self: end"
  />
</div>
<div
  [ngClass]="{ dragStarted: dragStarted }"
  class="dropZone flex fx-jc-center fx-ai-center"
  (drop)="dropHandler($event)"
  (dragover)="dragOverHandler($event)"
>
  <div class="width-50 flex fx-jc-center fx-ai-center">
    <p class="p-b-30 dragText">Drag File Here</p>
    <p class="p-b-30 dragText">Or</p>
    <form class="width-100" [formGroup]="uploadTradingPartnerForm">
      <input
        style="display: none"
        type="file"
        formControlName="file"
        (change)="chosenFile($event.target.files)"
        accept="application/vnd.ms-excel"
        accept=".csv"
        #fileInput
      />
      <button
        mat-dialog-actions
        mat-button
        class="flex width-100 p-t-10 p-b-10 round btn selectedItem primaryBtn"
        (click)="fileInput.click()"
      >
        Upload from file
      </button>
    </form>
  </div>
</div>

<div class="flex fx-row fx-jc-center fx-ai-center">
  <div class="width-100">
    <div
      class="p-t-10 p-b-10 m-r-10 flex fx-jc-center fx-ai-center fx-row bl-t-l bl-b-l"
      *ngFor="let file of selectedFile; let i = index"
    >
      <span class="noMargin m-l-20 flex fx-jc-center fx-ai-center fw-400">{{
        file.filename | titlecase
      }}</span>
      <img
        (click)="removeFile(file)"
        matRipple
        class="noMargin m-r-20 flex icon selectedItem flex m-l-auto"
        src="./assets/icons/close.svg"
      />
    </div>
  </div>

  <div
    *ngIf="selectedFile.length !== 0"
    class="width-20 m-t-30 m-b-30 flex fx-jc-center fx-ai-center"
  >
    <button
      [disabled]="selectedFile.length === 0"
      [ngClass]="{ tertiaryBtn: selectedFile.length !== 0 }"
      mat-dialog-actions
      mat-button
      class="flex width-100 round btn selectedItem"
      (click)="UploadFile()"
    >
      Upload
    </button>
  </div>
</div>
