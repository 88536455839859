import { Component } from "@angular/core";
import { MockDocumentRepository } from "../../../../app/repositories/mockDocumentRepository";
import { DocumentsService } from "../../../services/documents.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.sass"],
})
export class LandingComponent {
  constructor(
    private mockDocumentRepository: MockDocumentRepository,
    private documentsService: DocumentsService,
  ) {}
}
