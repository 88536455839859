<div class="">
  <div class="flex fx-row fx-jc-space-between fx-ai-center width-100 m-b-15">
    <p class="subHeading flex p-b-10">{{ componentAction }} users</p>
    <div
      class="tradeCard width-30 fx-ai-start flex round xs-card-t p-r-20 p-l-20"
    >
      <mat-form-field class="width-100 noMargin">
        <input
          matInput
          (keyup.enter)="searchUsers()"
          [(ngModel)]="searchText"
        />
        <mat-label> <mat-icon>search</mat-icon> Search users </mat-label>
      </mat-form-field>
    </div>
  </div>
  <div
    class="grayBanner flex fx-r ow"
    style="min-height: 50px; max-height: 500px; overflow: auto"
  >
    <div
      *ngIf="loader"
      class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
    >
      <mat-spinner strokeWidth="5" [diameter]="50"></mat-spinner>
    </div>
    <table
      *ngIf="!loader"
      mat-table
      [dataSource]="dataSource"
      class="width-100 userButtons"
    >
      <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Surname Column -->
      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef>Surname</th>
        <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Mobile Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef>Mobile</th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container *ngIf="isAdmin()" matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>
          {{ isAdmin() ? "" : "View User" }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            matRipple
            mat-flat-button
            class="flex m-l-auto edit"
            (click)="ViewUser(element)"
          >
            View
          </button>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container *ngIf="!viewOnly" matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>
          {{ isAdmin() ? "" : "Remove User" }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            matRipple
            mat-flat-button
            [disabled]="getLoggedInUser(element)"
            class="flex m-l-auto remove"
            (click)="removeUser(element.userId)"
          >
            Remove
          </button>
        </td>
      </ng-container>

      <!-- Mobile Column -->
      <ng-container *ngIf="!viewOnly" matColumnDef="editUser">
        <th mat-header-cell *matHeaderCellDef>
          {{ isAdmin() ? "" : "Edit User" }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            matRipple
            mat-flat-button
            class="flex m-l-50 m-r-50 edit"
            (click)="editUser(element)"
          >
            Edit
          </button>
        </td>
      </ng-container>

      <!-- Mobile Column -->
      <ng-container *ngIf="isAdmin() && !viewOnly" matColumnDef="assign">
        <th mat-header-cell *matHeaderCellDef>
          {{ isAdmin() ? "" : "Assign Supplier" }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            matRipple
            mat-flat-button
            class="flex m-l-50 m-r-50 edit"
            (click)="AssignUser(element)"
          >
            Assign
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="flex m-l-auto fx-jc-end fx-row m-t-30">
    <button
      *ngIf="!viewOnly"
      mat-flat-button
      (click)="addUser()"
      class="btn cancelBtn flex fx-ai-center m-l-10"
    >
      Add user
    </button>
  </div>
</div>
