import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminReportViewComponent } from "./components/home/admin-report/admin-report-view/admin-report-view.component";
import { AdminReportComponent } from "./components/home/admin-report/admin-report.component";
import { DetailedSupplierReportComponent } from "./components/home/admin-report/detailed-supplier-report/detailed-supplier-report.component";
import { SupplierReportViewComponent } from "./components/home/admin-report/detailed-supplier-report/supplier-report-view/supplier-report-view.component";
import { DashboardComponent } from "./components/home/dashboard/dashboard.component";
import { GetFinancedComponent } from "./components/home/get-financed/get-financed.component";
import { InvoiceProcessingComponent } from "./components/home/get-financed/invoice-processing/invoice-processing.component";
import { MoreInfoComponent } from "./components/home/get-financed/more-info/more-info.component";
import { NextStepsComponent } from "./components/home/get-financed/next-steps/next-steps.component";
import { SrfAdditionalInformationComponent } from "./components/home/get-financed/srf-additional-information/srf-additional-information.component";
import { TellUsAboutYourBusinessComponent } from "./components/home/get-financed/tell-us-about-your-business/tell-us-about-your-business.component";
import { HomeComponent } from "./components/home/home.component";
import { ManageUsersComponent } from "./components/home/manage-users/manage-users/manage-users.component";
import { MyProductsComponent } from "./components/home/my-products/my-products.component";
import { MyProfileComponent } from "./components/home/my-profile/my-profile.component";
import { OrderNewComponent } from "./components/home/orders/order-new/order-new.component";
import { PartnerMarketplaceDetailsComponent } from "./components/home/partner-marketplace/partner-marketplace-details/partner-marketplace-details.component";
import { PartnerMarketplaceComponent } from "./components/home/partner-marketplace/partner-marketplace.component";
import { ReportsComponent } from "./components/home/reports/reports.component";
import { UserReportComponent } from "./components/home/reports/user-report/user-report.component";
import { SupplierViewComponent } from "./components/home/supplier-view/supplier-view.component";
import { ChangeHistoryComponent } from "./components/home/trading-partners/change-history/change-history.component";
import { TradingPartnerDetailsComponent } from "./components/home/trading-partners/trading-partner-details/trading-partner-details.component";
import { TradingPartnersComponent } from "./components/home/trading-partners/trading-partners.component";
import { FinancingSuccessComponent } from "./components/home/vodalend/financing-success/financing-success.component";
import { FinancingTermsComponent } from "./components/home/vodalend/financing-terms/financing-terms.component";
import { VodalendComponent } from "./components/home/vodalend/vodalend.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { ForgotPasswordComponent } from "./components/portal/forgot-password/forgot-password.component";
import { LandingComponent } from "./components/portal/landing/landing.component";
import { ResetPasswordComponent } from "./components/portal/reset-password/reset-password.component";
import { ErrorComponent } from "./components/shared/error/error.component";

const routes: Routes = [
  { path: "", component: LandingComponent },
  { path: "home", component: HomeComponent },
  {
    path: "",
    component: HomeComponent,
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "orders", component: OrdersComponent },
      { path: "newOrders", component: OrderNewComponent },
      { path: "myProducts", component: MyProductsComponent },
      {
        path: "partnerMarketplaceComponent",
        component: PartnerMarketplaceComponent,
      },
      {
        path: "partnerMarketplaceDetails",
        component: PartnerMarketplaceDetailsComponent,
      },
      { path: "myProfile", component: MyProfileComponent },
      { path: "vodalend", component: VodalendComponent },
      { path: "tradingPartner", component: TradingPartnersComponent },
      {
        path: "tradingPartnerDetails",
        component: TradingPartnerDetailsComponent,
      },
      { path: "financingTerms", component: FinancingTermsComponent },
      { path: "financingSuccess", component: FinancingSuccessComponent },
      { path: "changeHistory", component: ChangeHistoryComponent },
      { path: "supplierView", component: SupplierViewComponent },
      { path: "adminReport", component: AdminReportComponent },
      { path: "userReport", component: UserReportComponent },
      { path: "adminReportView", component: AdminReportViewComponent },
      { path: "manageUsers", component: ManageUsersComponent },
      { path: "reports", component: ReportsComponent },
      {
        path: "detailedSupplierReport",
        component: DetailedSupplierReportComponent,
      },
      {
        path: "detailedSupplierReportView",
        component: SupplierReportViewComponent,
      },

      {
        path: "invoiceProcessing/getFinanced",
        component: GetFinancedComponent,
      },
      { path: "invoiceProcessing/moreInfo", component: MoreInfoComponent },
      {
        path: "invoiceProcessing/tellUsAboutYourBusiness",
        component: TellUsAboutYourBusinessComponent,
      },
      { path: "invoiceProcessing/nextSteps", component: NextStepsComponent },
      {
        path: "invoiceProcessing/invoiceProcessing",
        component: InvoiceProcessingComponent,
      },
      {
        path: "invoiceProcessing/srfAdditionalInfo",
        component: SrfAdditionalInformationComponent,
      },
    ],
  },
  { path: "login", component: LandingComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "resetPassword", component: ResetPasswordComponent },
  { path: "error", component: ErrorComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      useHash: true,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
