<form [formGroup]="featuresFormGroup">
  <div class="flex fx-row">
    <p class="subHeading flex p-b-10">Supplier Features</p>
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="m-l-auto p-r-30"
      (click)="enableEdit()"
      >create</mat-icon
    >
  </div>
  <div
    *ngIf="loader"
    class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
  >
    <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
  </div>
  <div *ngIf="!loader" class="flex">
    <div class="flex p-r-30">
      <div class="flex fx-row">
        <p class="flex">Selective Receivable Finance</p>
        <mat-slide-toggle
          [color]="'primary'"
          class="m-l-auto"
          [disabled]="!enable"
          formControlName="srfFeature"
        ></mat-slide-toggle>
      </div>
    </div>

    <div
      *ngIf="enable"
      style="justify-content: space-between"
      class="flex fx-row m-10"
    >
      <button
        mat-flat-button
        class="btn cancelBtn flex fx-ai-center flex p-r-30"
        (click)="cancel()"
      >
        Cancel
      </button>

      <div class="flex fx-row fx-ai-center">
        <button
          mat-flat-button
          class="btn primaryBtn flex fx-ai-center flex p-r-30 fx-row noBreak"
          (click)="save()"
        >
          Save
        </button>
        <mat-spinner
          *ngIf="editingTP"
          class="m-l-5"
          strokeWidth="3"
          [diameter]="15"
        ></mat-spinner>
      </div>
    </div>
  </div>
</form>
