import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { TradingPartner } from "./../../../../../models/types";
import { NotificationService } from "./../../../../../services/notification.service";
import { TradingPartnerService } from "./../../../../../services/trading-partner.service";

@Component({
  selector: "app-supplier-features",
  templateUrl: "./supplier-features.component.html",
  styleUrls: ["./supplier-features.component.sass"],
})
export class SupplierFeaturesComponent implements OnInit {
  featuresFormGroup: UntypedFormGroup;
  enable = false;
  editingTP = false;
  loader = false;
  @Input() retailer: TradingPartner;
  tradingPartner: TradingPartner;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.InitialiseForm();
    this.setDefaults();
  }

  enableEdit() {
    this.enable = !this.enable;
    this.setDefaults();
  }

  cancel() {
    this.enable = false;
    this.setDefaults();
  }

  InitialiseForm() {
    this.featuresFormGroup = this.formBuilder.group({
      srfFeature: [""],
    });
  }

  setDefaults() {
    this.loader = true;

    const subscription: Subscription = this.tradingPartnerService
      .getTradingPartner(this.retailer.gln)
      .subscribe(
        (tp) => {
          if (tp) {
            this.loader = false;
            this.tradingPartner = tp;
            if (tp.features) {
              this.featuresFormGroup.controls.srfFeature.setValue(
                tp.features?.selectiveReceivableFinance,
              );
            }
            subscription.unsubscribe();
          }
        },
        (error) => {
          this.notification.showError(error);
          this.loader = false;
        },
      );
  }

  save() {
    this.tradingPartner.gln = this.retailer.gln;
    this.tradingPartner.features.selectiveReceivableFinance =
      this.getSelectiveReceivableFinance();

    this.tradingPartnerService
      .editTradingPartner(this.tradingPartner)
      .subscribe(
        (res) => {
          if (res) {
            this.notification.showSuccess("Feature toggle edited successfully");
            this.enable = false;
            this.editingTP = false;
            this.setDefaults();
          }
        },
        (error) => {
          this.notification.showError(error);
          this.editingTP = false;
        },
      );
  }

  getSelectiveReceivableFinance() {
    return this.featuresFormGroup.controls.srfFeature.value;
  }
}
