import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { EventRepository } from "../repositories/event.repository";
import { TradingPartnersRepository } from "../repositories/trading-partners.repository";
import { Session } from "./../models/session";
import {
  AnalyticResult,
  AnalyticType,
  breadCrumbModel,
  BusinessInfoMoldel,
  DetailedSupplierReport,
  OrderAndInvoice,
  SupplierReport,
  TopPerformingProduct,
  TradingPartner,
  TradingPartnerBreakdown,
  TradingPartnerSummary,
  VTEvent,
} from "./../models/types";
import { MockDocumentRepository } from "./../repositories/mockDocumentRepository";
import { RepositoryFactory } from "./../repositories/repository.factory";
@Injectable({
  providedIn: "root",
})
export class TradingPartnerService {
  tradingPartnerRepo: TradingPartnersRepository;
  eventRepo: EventRepository;
  mockTradingPartnerRepo: MockDocumentRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.tradingPartnerRepo = repoFactory.getTradingPartnerRepository();
    this.eventRepo = repoFactory.getEventRepository();
    this.mockTradingPartnerRepo = repoFactory.getMockTradingRepository();
  }

  public orderReports: BehaviorSubject<DetailedSupplierReport> =
    new BehaviorSubject<DetailedSupplierReport>(null);
  public supplierTradingPartnerSelected: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  public ordersInvoicedByRetailer: BehaviorSubject<TradingPartnerBreakdown> =
    new BehaviorSubject<TradingPartnerBreakdown>(null);
  public detailedSupplierReports: BehaviorSubject<SupplierReport[]> =
    new BehaviorSubject<SupplierReport[]>(null);

  public topPerformingProduct: BehaviorSubject<TopPerformingProduct[]> =
    new BehaviorSubject<TopPerformingProduct[]>(null);
  public orderAndInvoice: BehaviorSubject<OrderAndInvoice[]> =
    new BehaviorSubject<OrderAndInvoice[]>(null);
  public currentTradingPartner: BehaviorSubject<TradingPartner> =
    new BehaviorSubject<TradingPartner>(null);
  public selectedTradingPartner: BehaviorSubject<TradingPartner> =
    new BehaviorSubject<TradingPartner>(null);
  public srfApplicationStatus: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public tradingPartnerBreadcrumb: breadCrumbModel[] = [];
  // public billingSummary = new BehaviorSubject<BillingSummary>(null);

  getTradingPartner(gln: string): Observable<TradingPartner> {
    return this.tradingPartnerRepo.get(this.session.getSupplierGln(), gln);
  }

  getSuppliers(): Observable<TradingPartnerSummary[]> {
    return this.tradingPartnerRepo.getSuppliers(this.session.getSupplierGln());
  }

  getRetailers(): Observable<TradingPartnerSummary[]> {
    return this.tradingPartnerRepo.getRetailers(this.session.getSupplierGln());
  }

  getStores(parentGln: string): Observable<TradingPartnerSummary[]> {
    return this.tradingPartnerRepo.getStores(
      this.session.getSupplierGln(),
      parentGln,
    );
  }

  getPrintStores(parentGln: string): Observable<TradingPartner[]> {
    return this.tradingPartnerRepo.getPrintStores(
      this.session.getSupplierGln(),
      parentGln,
    );
  }

  getPrintTradingPartners(orderIds: any[]): Observable<TradingPartner[]> {
    return this.tradingPartnerRepo.getPrint(
      this.session.getSupplierGln(),
      orderIds,
    );
  }

  search(
    gln: string,
    withTradingPartner?: boolean,
    searchText?: string,
    type?: string,
  ): Observable<TradingPartner[]> {
    return this.tradingPartnerRepo.search(
      this.session.getSupplierGln(),
      gln,
      withTradingPartner,
      searchText,
      type,
    );
  }

  setCurrentTradingPartner(tradingPartner: TradingPartner) {
    this.currentTradingPartner.next(tradingPartner);
  }

  editBusinessInfo(businessInfo: BusinessInfoMoldel): Observable<any> {
    return this.tradingPartnerRepo.editBusinessInfo(
      this.session.getSupplierGln(),
      businessInfo,
    );
  }

  getAnalytics(
    dateFrom: string,
    dateTo: string,
    analytics: AnalyticType[],
  ): Observable<AnalyticResult[]> {
    return this.tradingPartnerRepo.getAnalytics(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
      analytics,
    );
  }

  addTradingPartner(tradingPartner: TradingPartner): Observable<any> {
    return this.tradingPartnerRepo.insert(
      this.session.getSupplierGln(),
      tradingPartner,
    );
  }

  batchInsertTradingPartner(
    tradingPartners: TradingPartner[],
  ): Observable<any> {
    return this.tradingPartnerRepo.batchInsert(
      this.session.getSupplierGln(),
      tradingPartners,
    );
  }

  editTradingPartner(tradingPartner: TradingPartner): Observable<any> {
    return this.tradingPartnerRepo.edit(
      this.session.getSupplierGln(),
      tradingPartner,
    );
  }

  async uploadImage(gln: string, data: any): Promise<Observable<string>> {
    const encodedData = await this.toBase64(data);

    return this.tradingPartnerRepo.uploadImage(
      this.session.getSupplierGln(),
      gln,
      encodedData,
    );
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  getDetailedSupplierReports(
    dateFrom: string,
    dateTo: string,
    supplierGlns: string[],
  ): Observable<DetailedSupplierReport[]> {
    return this.tradingPartnerRepo.generateDetailedSupplierReports(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
      supplierGlns,
    );
  }

  generateSupplierReports(
    dateFrom: string,
    dateTo: string,
    supplierGlns: string[],
  ): Observable<SupplierReport[]> {
    return this.tradingPartnerRepo.generateSupplierReports(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
      supplierGlns,
    );
  }

  getActivityLogs(): Observable<VTEvent[]> {
    return this.eventRepo.search(this.session.getSupplierGln());
  }
}
