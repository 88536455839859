<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <form class="flex m-t-10 width-100" [formGroup]="PartnerMarketplaceForm">
      <div class="flex fx-row"></div>
      <div
        class="flex fx-ai-center m-l-auto fx-row round tradeCard width-30 p-t-10 p-r-20 p-l-20"
      >
        <mat-form-field class="width-65">
          <input
            matInput
            (blur)="clearSearch()"
            (keyup.enter)="search($event.target.value)"
            (blur)="clearSearch()"
            formControlName="searchTradingPartner"
          />
          <mat-label
            ><mat-icon>search</mat-icon> Search Trading Partner
          </mat-label>
        </mat-form-field>
        <div class="bl-l p-l-10 fx-ai-center fx-row"></div>
        <mat-form-field class="width-35">
          <mat-select
            formControlName="partnerType"
            placeholder="Trading Partner"
            (selectionChange)="filterTradingPartners()"
          >
            <mat-option
              *ngFor="let tradingPartner of tradingPartnerTypes"
              [selected]=""
              [value]="tradingPartner.value"
            >
              {{ tradingPartner.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <p class="heading flex p-b-10">Trading Partners</p>

        <div class="grayBanner flex p-20 m-b-20 fx-row">
          <div class="flex fx-row">
            <p class="p-b p-r-10">{{ tradingPartners.length }}</p>
            <p class="flex p-b">Trading Partners</p>
          </div>

          <div class="flex fx-jc-center m-l-auto fx-row productButtons">
            <button
              matRipple
              type="button"
              class="flex selectedItem m-r-10"
              (click)="newTradingPartner()"
            >
              New
            </button>
          </div>
        </div>
        <!-- <app-order-table (value)="showValue($event)"></app-order-table>   -->

        <div>
          <div
            *ngIf="loader"
            class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
          >
            <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
          </div>
          <table
            *ngIf="!loader"
            matTableFilter
            matTableExporter
            mat-table
            [dataSource]="dataSource"
            class="width-100"
            #exporter="matTableExporter"
          >
            <!-- productCode Column -->
            <ng-container matColumnDef="gln">
              <th mat-header-cell *matHeaderCellDef>Gln</th>
              <td mat-cell *matCellDef="let element">{{ element.gln }}</td>
            </ng-container>

            <!-- productCode Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">
                {{ element.type }}
              </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef>View</th>
              <td mat-cell *matCellDef="let element" style="color: #00b0ca">
                <p class="p-l">
                  <a
                    matRipple
                    (click)="goToTradingPartner(element.gln)"
                    class="pointer"
                  >
                    View
                  </a>
                </p>
              </td>
            </ng-container>

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation(); manageProduct(row)"
                  [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)"
                  formControlName="selected"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            hidePageSize="true"
            [pageSizeOptions]="[defaultItemsPerPage]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </form>
  </div>
</div>
