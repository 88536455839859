import { Injectable } from "@angular/core";
import Amplify, { Auth } from "aws-amplify";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { Session } from "../models/session";
import { NotificationType, User, UserChallenges } from "../models/types";
import { AuthRepository } from "./auth-repository";
import {
  default as awsconfig_dev,
  default as awsconfig_prd,
} from "./aws-exports";

@Injectable()
export class AwsAuthRepository implements AuthRepository {
  private currentUser: any = {};

  constructor(private session: Session) {
    if (environment.production) {
      Amplify.configure(awsconfig_prd);
    } else {
      Amplify.configure(awsconfig_dev);
    }
  }

  login(username: string, password: string): Observable<User> {
    const response = new Subject<User>();

    Auth.signIn(username, password)
      .then((amplifyUser) => {
        this.currentUser = amplifyUser;

        switch (amplifyUser.challengeName) {
          case undefined:
            this.session.start().subscribe(
              (user) => {
                response.next(user);
              },
              (error) => {
                response.error(error);
              },
            );

            break;
          case UserChallenges.NEW_PASSWORD_REQUIRED:
            response.next({
              email: "",
              userId: "",
              mobile: "",
              isMfa: false,
              newPasswordRequired: true,
              otpRequired: false,
              roles: [],
              username: "",
              accessToken: "",
              name: "",
              surname: "",
              refreshToken: "",
              token: "",
              tradingPartnerGln: "",
              lastActivityDate: "",
              lastActivityType: "",
            });

            break;
          case UserChallenges.SMS_MFA:
          case UserChallenges.TOTP:
            response.next({
              email: "",
              userId: "",
              mobile: "",
              isMfa: true,
              newPasswordRequired: false,
              otpRequired: true,
              roles: [],
              username: "",
              accessToken: "",
              name: "",
              surname: "",
              refreshToken: "",
              token: "",
              tradingPartnerGln: "",
              lastActivityDate: "",
              lastActivityType: "",
            });

            break;
          default:
            break;
        }
      })
      .catch((error) => {
        response.error(error);
      });

    return response;
  }

  completeNewPassword(password: string): Observable<void> {
    const response = new Subject<void>();

    Auth.completeNewPassword(
      this.currentUser,
      password,
      this.currentUser.challengeParam.requiredAttributes,
    )
      .then(() => {
        this.session.start().subscribe(
          () => {
            response.next();
          },
          (error) => {
            response.error(error);
          },
        );
      })
      .catch((error) => {
        response.error(error);
      });

    return response;
  }

  verifyLoginOtp(otp: string): Observable<void> {
    const response = new Subject<void>();

    Auth.confirmSignIn(
      this.currentUser,
      otp, // Confirmation code
      this.currentUser.challengeName, //= "SMS_MFA"//mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .then(() => {
        this.session.start().subscribe(
          () => {
            response.next();
          },
          (error) => {
            response.error(error);
          },
        );
      })
      .catch((error) => {
        response.error(error);
      });

    return response;
  }

  logout(): Observable<void> {
    const response = new Subject<void>();

    Auth.signOut()
      .then(function (res) {
        response.next(res);
      })
      .catch((error) => {
        response.error(error);
      });
    return response;
  }

  resetPassword(): Observable<any> {
    throw new Error("Method not implemented.");
  }

  forgotPassword(username: string): Observable<User> {
    const response = new Subject<User>();

    Auth.forgotPassword(username)
      .then(() => {
        response.next();
      })
      .catch((error) => {
        response.error(error);
      });

    return response;
  }

  forgotPasswordSubmit(
    username: string,
    otp: string,
    password: string,
  ): Observable<void> {
    const response = new Subject<void>();

    Auth.forgotPasswordSubmit(username, otp, password)
      .then(function () {
        response.next();
      })
      .catch((error) => {
        response.error(error);
      });

    return response;
  }

  validatePwResetCode(): Observable<void> {
    throw new Error("Method not implemented.");
  }

  changePassword(oldPassword: string, password: string): Observable<any> {
    const response = new Subject<void>();

    Auth.changePassword(this.currentUser, oldPassword, password)
      .then(() => {
        response.next();
      })
      .catch((error) => {
        response.error(error);
      });
    return response;
  }

  setNotificationPreference(type: NotificationType): Observable<void> {
    const response = new Subject<void>();

    const att = {
      "custom:notificationType": type,
    };

    Auth.currentAuthenticatedUser()
      .then((result) => {
        Auth.updateUserAttributes(result, att)
          .then(() => {
            response.next();
          })
          .catch((error) => {
            response.error(error);
          });
      })
      .catch((error) => {
        response.error(error);
      });

    return response;
  }
}
