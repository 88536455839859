import type { OnInit } from "@angular/core";
import { Component, Input } from "@angular/core";
import type { Observable } from "rxjs";
import type { TradingPartner } from "../../../models/types";
import { TradingPartnerService } from "./../../../services/trading-partner.service";

@Component({
  selector: "app-trading-partner-summary",
  templateUrl: "./trading-partner-summary.component.html",
  styleUrls: ["./trading-partner-summary.component.sass"],
})
export class TradingPartnerSummaryComponent implements OnInit {
  @Input() gln: string;
  loadingTradingPartner = false;
  public tradingPartner: Observable<TradingPartner>;
  tradingPartnerObj: TradingPartner;
  constructor(private tradingPartnerService: TradingPartnerService) {}

  ngOnInit(): void {
    this.loadingTradingPartner = true;

    this.tradingPartner = this.tradingPartnerService.getTradingPartner(
      this.gln,
    );

    this.tradingPartner.subscribe(
      (result) => {
        this.tradingPartnerObj = result;
        this.loadingTradingPartner = false;
      },
      () => {
        this.loadingTradingPartner = false;
      },
    );
  }
}
