import { Component, Input } from "@angular/core";
import { Session } from "../../../models/session";
import { EmailTemplate } from "../../../models/types";
import { NotificationService } from "../../../services/notification.service";
import { SupportService } from "../../../services/support.service";
import { NavigationService } from "./../../../services/navigation.service";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.sass"],
})
export class HelpComponent {
  @Input() contactNumbers = "";
  @Input() contactEmail = "";
  toggleHelp = false;
  userName: string;
  helpMeTextValue = "";
  sendingEmail = false;
  @Input() loggedIn = false;
  @Input() inSRF = false;
  constructor(
    private navigation: NavigationService,
    private session: Session,
    private supportService: SupportService,
    private notificationService: NotificationService,
  ) {}

  moveHelpButton(): boolean {
    return this.navigation.currentRoute() === "myProducts";
  }

  adjustOnMoreInfo() {
    return this.navigation.currentRoute() === "moreInfo";
  }

  switchToggle() {
    this.toggleHelp = !this.toggleHelp;
  }

  getName() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      this.userName = user.name;
    });
  }

  constructEmail(descripton: string): EmailTemplate {
    const user = this.session.getUser();

    const emailTemplate: EmailTemplate = {
      name: user.name,
      surname: user.surname,
      email: user.email,
      description: descripton,
    };

    return emailTemplate;
  }

  sendHelpMessage() {
    //TODO
    const constructEmail = this.constructEmail(this.helpMeTextValue);

    this.supportService.sendSupportEmail(constructEmail).subscribe(
      () => {
        this.sendingEmail = true;
        this.notificationService.showSuccess("Email successfully sent");
        setTimeout(() => {
          this.helpMeTextValue = "";
          this.switchToggle();
          this.sendingEmail = false;
        }, 5000);
      },
      () => {
        this.sendingEmail = false;
        this.notificationService.showError(
          new Error("Error sending support email"),
        );
      },
    );
  }

  isCurrentlyInSRF(): boolean {
    this.navigation
      .currentRoute()
      .toLocaleLowerCase()
      .includes("invoiceprocessing")
      ? (this.inSRF = true)
      : false;
    return this.inSRF;
  }
}
