<div class="navbar p-5 shadow flex fx-row fx-jc-center">
  <div class="flex fx-row fx-ai-center m-l-20">
    <a mat-button class="flex" (click)="navDashBoard()">
      <img
        class="menulogo p-r-50"
        width="150"
        src="./assets/icons/Vodacom_fin_services.svg"
    /></a>
  </div>
  <div></div>
  <div class="flex lisnk fx-row fx-ai-center m-l-auto p-r-30">
    <img
      class="icon flex"
      src="./assets/icons/redAccount.svg"
      style="justify-self: end"
    />
    <span *ngIf="userName !== null" class="account flex m-l-20 m-r-10 fw-400"
      >Hello {{ userName | titlecase }}</span
    >

    <img
      class="icon flex"
      width="200px"
      src="./assets/icons/rightArrowBlack.svg"
      style="justify-self: end"
    />
  </div>
</div>
