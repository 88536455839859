import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Session } from "../models/session";
import { ManageUserModel, User } from "../models/types";
import { newClient } from "../services/api-gateway.service.js";
import { UserRepository } from "./user-repository";

const apigClient = newClient();

@Injectable()
export class AwsUserRepository implements UserRepository {
  constructor(private session: Session) {}

  add(svGln: string, userModel: ManageUserModel): Observable<User> {
    const response = new Subject<User>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = this.getheaders(user.token);

      additionalParams.queryParams = {
        svGln: svGln,
        userGlns: userModel.tradingPartnerGlns,
      };

      apigClient
        .usersPost({}, userModel, additionalParams)
        .then((res) => {
          const result = res.data as User;
          response.next(result);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  delete(svGln: string, userId: string): Observable<void> {
    const response = new Subject<void>();
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = this.getheaders(user.token);

      additionalParams.queryParams = {
        svGln: svGln,
      };

      apigClient
        .usersDelete({}, { userId: userId }, additionalParams)
        .then((res) => {
          response.next(res);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  edit(svGln: string, userModel: ManageUserModel): Observable<User> {
    const response = new Subject<User>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = this.getheaders(user.token);

      additionalParams.queryParams = {
        svGln: svGln,
        userGlns: userModel.tradingPartnerGlns,
      };

      apigClient
        .usersPut({}, userModel, additionalParams)
        .then((res) => {
          const result = res.data as User;
          response.next(result);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  get(svGln: string, userId: string): Observable<User> {
    const response = new Subject<User>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = this.getheaders(user.token);

      additionalParams.queryParams = {
        svGln: svGln,
        userId: userId,
      };

      const params = {
        userId: userId,
      };

      apigClient
        .usersUserIdGet(params, {}, additionalParams)
        .then((res) => {
          const result = res.data as User;
          response.next(result);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  getheaders(token): any {
    return {
      headers: {
        Authorization: token,
      },
    };
  }

  search(svGln: string): Observable<User[]> {
    const response = new Subject<User[]>();
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .usersGet({}, null, additionalParams)
        .then((res) => {
          const result = res.data as User[];
          response.next(result);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  userLoggedIn(svGln: string, userId: string): Observable<void> {
    const response = new Subject<void>();
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = this.getheaders(user.token);

      additionalParams.queryParams = {
        svGln: svGln,
        userId: userId,
      };

      const params = {
        userId: userId,
      };

      apigClient
        .usersUserIdLoggedinPost(params, { userId: userId }, additionalParams)
        .then((res) => {
          response.next(res);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }
}
