import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Chart } from "chart.js";
import { InvoiceAnalyticsResponse } from "../../../../../models/invoice";
import { FinanceService } from "../../../../../services/finance.service";
import { NotificationService } from "../../../../../services/notification.service";

@Component({
  selector: "app-invoice-pending",
  templateUrl: "./invoice-pending.component.html",
  styleUrls: ["./invoice-pending.component.sass"],
})
export class InvoicePendingComponent implements OnInit, OnChanges {
  @Input() fromMinDate: Date;
  @Input() toMaxDate: Date;
  invoicesPending: InvoiceAnalyticsResponse[];
  amounts: InvoiceAnalyticsResponse[] = [];
  retailers: string[] = [];
  total = 0;
  numberOfGraphValues: number;
  totalInvoiceCount = 0;
  loadValues = false;
  chart: Chart<"doughnut">;
  dataAvailable = false;
  graphColorNames: string[] = [
    "New",
    "Acknowledged",
    "Confirmed",
    "Rejected",
    "Invoiced",
  ];
  graphColors = {
    New: "#00B0CA",
    Acknowledged: "#9C2AA0",
    Confirmed: "#FF0000",
    Rejected: "#005765",
    Invoiced: "#BD0000",
  };
  constructor(
    private financeService: FinanceService,
    private notificationService: NotificationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fromMinDate || changes.toMaxDate) {
      this.getinvoicesPending();
    }
  }

  onResize() {
    this.plotGraph(this.invoicesPending);
  }

  ngOnInit(): void {
    this.getinvoicesPending();
    this.numberOfGraphValues = 0;
  }

  getinvoicesPending() {
    // TODO SET FROM FE DATE CONTROL
    const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 3));
    const dateTo = new Date();

    this.dataAvailable = false;
    this.financeService.getInvoicesPending(dateFrom, dateTo).subscribe(
      (result) => {
        if (result) {
          this.invoicesPending = result;

          if (result.length > 0) {
            this.dataAvailable = true;

            setTimeout(() => {
              this.plotGraph(this.invoicesPending);
            }, 500);
          }
        }
      },
      (error) => {
        this.notificationService.showError(error);
        this.dataAvailable = false;
      },
    );
  }

  plotGraph(invoiceAnalyticsResponses: InvoiceAnalyticsResponse[]) {
    const points: number[] = [];
    let value = 0;
    const label: string[] = [];
    this.totalInvoiceCount = 0;

    invoiceAnalyticsResponses.forEach((iAR) => {
      label.push(iAR.retailer);

      this.totalInvoiceCount += parseInt(iAR.count);
      value = parseFloat(iAR.amount);

      points.push(value);
    });

    this.retailers = label;
    this.total = points.reduce((a, b) => a + b, 0);

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart("canvas3", {
      type: "doughnut",
      data: {
        labels: [...label],
        datasets: [
          {
            data: points,
            backgroundColor: [
              this.graphColors["New"],
              this.graphColors["Acknowledged"],
              this.graphColors["Confirmed"],
              this.graphColors["Rejected"],
              this.graphColors["Invoiced"],
            ],
          },
        ],
      },
      options: {
        cutout: "85%",
        aspectRatio: 1,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }

  getColour(position: number): string {
    return this.graphColors[this.graphColorNames[position]];
  }
}
