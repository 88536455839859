<div class="fx-ai-center flex">
  <img
    *ngIf="!toggleHelp"
    (click)="switchToggle()"
    class="icobn flex"
    src="./assets/icons/helpIcon.svg"
    style="justify-self: end"
  />

  <div
    *ngIf="toggleHelp"
    style="border-radius: 10px !important"
    class="whiteBG"
  >
    <div
      style="border-radius: 10px 10px 0px 0px"
      class="flex fx-row helpTopBG p-10 fx-jc-space-between fx-ai-center"
    >
      <p class="p-b whiteTxt noMargin">Get in touch</p>
      <img
        (click)="switchToggle()"
        class="flex whiteTxt"
        width="20"
        src="./assets/icons/close-white.svg"
      />
    </div>

    <div *ngIf="isCurrentlyInSRF()" class="whiteBG">
      <div class="flex whiteBG p-l-10 p-r-10 p-t-20 fx-aib-center">
        <div class="flex whiteBG m-10 fx-aib-center fx-row">
          <img
            class="flex m-r-10"
            width="20"
            src="./assets/icons/callIcon.svg"
          />
          <p
            class="noMargin width-100"
            style="justify-self: center !important; text-align: center"
          >
            {{ contactNumbers }}
          </p>
        </div>

        <div class="flex whiteBG m-10 fx-aib-center fx-row">
          <img class="flex m-r-10" width="20" src="./assets/icons/mail.svg" />
          <a
            href="mailto:{{ contactEmail }}"
            target="_top"
            style="color: red"
            >{{ contactEmail }}</a
          >
        </div>
      </div>
    </div>

    <div *ngIf="!loggedIn && !isCurrentlyInSRF()" class="whiteBG">
      <div class="flex whiteBG p-l-10 p-r-10 p-t-20 fx-aib-center">
        <img class="flex" src="./assets/icons/helpPopupArt.svg" />
        <p class="noMargin" style="justify-self: left !important">
          {{ userName }}
        </p>
        <p class="noMargin" style="justify-self: left !important">
          {{ contactNumbers }}
        </p>
        <a href="mailto:{{ contactEmail }}" target="_top" style="color: red">{{
          contactEmail
        }}</a>
      </div>
    </div>

    <div
      *ngIf="loggedIn && !isCurrentlyInSRF()"
      style="border-radius: 10px !important"
      class="p-10"
    >
      <div *ngIf="!sendingEmail" class="animate__animated animate__fadeIn">
        <div class="flex whiteBG m-10 fx-aib-center fx-row">
          <img
            class="flex m-r-10"
            width="20"
            src="./assets/icons/callIcon.svg"
          />
          <p class="noMargin" style="justify-self: left !important">
            {{ contactNumbers }}
          </p>
        </div>
        <div class="outline whiteBG">
          <mat-form-field class="m-5">
            <textarea
              matInput
              type="text"
              placeholder="How can we help you?"
              [(ngModel)]="helpMeTextValue"
            ></textarea>
            <button
              class="m-l-auto"
              mat-button
              *ngIf="helpMeTextValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="helpMeTextValue = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <button
              class="m-l-auto"
              mat-button
              *ngIf="helpMeTextValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="sendHelpMessage()"
            >
              <mat-icon>send</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="sendingEmail" class="animate__animated animate__fadeIn">
        <div class="flex whiteBG m-10 fx-jc-center fx-row">
          <img class="flex" width="80" src="./assets/icons/greenCheck.svg" />
        </div>
        <div class="helpConfirm whiteBG">
          <p class=" ">Thank you for submitting your query</p>
          <p class=" ">
            Please check your activity log for your service request number
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
