import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TradingPartner, User } from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { PrintingServiceService } from "../../../../services/printing-service.service";
import { ReportService } from "../../../../services/report.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";
import { Settings } from "../../../../settings";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
const ELEMENT_DATA: User[] = [];

@Component({
  selector: "app-user-report",
  templateUrl: "./user-report.component.html",
  styleUrls: ["./user-report.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UserReportComponent implements OnInit {
  name = "";
  surname = "";
  email = "";
  mobile = "";
  username = "";
  roles: string[] = [];
  isMfa = false;
  tradingPartnerGln = "";
  lastActivityDate = "";
  lastActivityType = "";
  searchText = "";
  inactiveDates: number[] = [30, 60, 90];
  selectedRange = 30;
  tradingPartners: TradingPartner[] = [];

  displayedColumns: string[] = [
    "username",
    "supplierName",
    "userType",
    "name",
    "surname",
    "active",
    "noOfDaysInactive",
    "lastDayInactive",
  ];
  userReports: User[] = [];
  userReportsForm: UntypedFormGroup;
  loadingReports = false;
  dateFormat;

  dataSource = new MatTableDataSource<User>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private reportService: ReportService,
    private navigate: NavigationService,
    private dialogService: DialogService,
    private notification: NotificationService,
    private settings: Settings,
    private printingService: PrintingServiceService,
  ) {}

  ngOnInit(): void {
    this.generateReports();
  }

  setupInputControls() {
    this.userReportsForm = this.formBuilder.group({
      searchReports: ["", null],
      inactiveDateRange: ["", null],
    });
  }

  generateReports() {
    this.loadingReports = true;
    this.reportService.getUserReports().subscribe(
      (reports) => {
        if (reports) {
          this.loadingReports = false;
          this.userReports = reports;
          this.dataSource.data = this.userReports;
          // this.paginator = this.dataSource.paginator
          this.dataSource.paginator = this.paginator;
        }
      },
      (error) => {
        this.loadingReports = false;
        this.notification.showError(error);
      },
    );
  }

  downloadReport() {
    this.printingService.generateUserReport(
      this.dataSource.data,
      this.selectedRange,
    );
  }

  getActiveStatus(lastDayInactive: string) {
    const date1 = new Date(lastDayInactive);
    const date2 = new Date();
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let activityStatus = "";
    switch (this.selectedRange) {
      case 30:
        activityStatus = Math.floor(Difference_In_Days) <= 30 ? "Yes" : "No";
        break;
      case 60:
        activityStatus = Math.floor(Difference_In_Days) <= 60 ? "Yes" : "No";
        break;
      case 90:
        activityStatus = Math.floor(Difference_In_Days) <= 90 ? "Yes" : "No";
        break;

      default:
        activityStatus = Math.floor(Difference_In_Days) <= 30 ? "Yes" : "No";
        break;
    }
    return activityStatus;
  }

  getNoOfDaysInactive(lastDayInactive: string) {
    if (lastDayInactive === "") {
      return "n/a";
    }
    const date1 = new Date(lastDayInactive);
    const date2 = new Date();

    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return this.getActiveStatus(lastDayInactive).toLowerCase() == "no"
      ? Math.floor(Difference_In_Days)
      : "-";
  }

  getArrayAsString(array: string[]) {
    let arrayValue = "";
    for (let index = 0; index < array?.length; index++) {
      arrayValue += array[index] + (index + 1 !== array.length ? " ,\n" : "");
    }
    return arrayValue;
  }

  setRolesAsString(array: string[]) {
    let arrayValue = "";

    if (array.some((val) => val.toLowerCase().includes("admin"))) {
      for (let index = 0; index < array?.length; index++) {
        if (array[index].toLowerCase().includes("admin")) {
          if (
            !array[index].toLowerCase().includes("customer") &&
            array[index].toLowerCase().includes("admin")
          ) {
            arrayValue +=
              array[index] + (index + 1 !== array.length ? " ,\n" : "");
          } else if (array[index].toLowerCase().includes("admin")) {
            arrayValue +=
              array[index] + (index + 1 !== array.length ? " ,\n" : "");
          }
        }
      }
    } else {
      arrayValue = array[0];
    }
    return arrayValue;
  }

  clearSearch() {
    this.searchText == "";
    this.searchText == "" ? (this.paginator = this.dataSource.paginator) : {};
  }

  formatDate(dateArg: string): string {
    if (this.getActiveStatus(dateArg).toLowerCase() === "no") return "-";
    const date: Date = new Date(dateArg);
    return (
      (date.getDate() < 9 ? "0" + date.getDate() : date.getDate()) +
      " " +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear()
    );
  }
}
