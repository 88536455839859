import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../../../services/navigation.service";

interface SideMenuItems {
  title: string;
  imageUrl: string;
  isSelected: any;
  route: string;
}

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.sass"],
})
export class FooterComponent implements OnInit {
  businessSolutions: SideMenuItems[] = [];
  dealsDevices: SideMenuItems[] = [];
  aboutUs: SideMenuItems[] = [];
  contactUs: SideMenuItems[] = [];

  socialMedia: SideMenuItems[] = [];
  constructor(private service: NavigationService) {}

  ngOnInit() {
    this.setupMenu();
  }

  private setupMenu() {
    this.businessSolutions.length = 0;
    this.dealsDevices.length = 0;
    this.aboutUs.length = 0;
    this.contactUs.length = 0;

    this.businessSolutions.push(
      this.getMenuItem(
        "Broadband & landlines",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.businessSolutions.push(
      this.getMenuItem(
        "Business internet",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.businessSolutions.push(
      this.getMenuItem(
        "Cloud & hosting",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.businessSolutions.push(
      this.getMenuItem(
        "Mobile & voice",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.businessSolutions.push(
      this.getMenuItem(
        "Tracking & monitoring",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.businessSolutions.push(
      this.getMenuItem(
        "Ready Business",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.businessSolutions.push(
      this.getMenuItem(
        "SME catalogue [PDF - 1.4MB]",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );

    this.dealsDevices.push(
      this.getMenuItem(
        "Cellphones",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.dealsDevices.push(
      this.getMenuItem(
        "Routers & modems",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.dealsDevices.push(
      this.getMenuItem(
        "Price plans",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );

    this.aboutUs.push(
      this.getMenuItem(
        "General terms & conditions",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.aboutUs.push(
      this.getMenuItem(
        "Acceptable Usage Policy",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.aboutUs.push(
      this.getMenuItem(
        "Corporate responsibility",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.aboutUs.push(
      this.getMenuItem(
        "Investors",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.aboutUs.push(
      this.getMenuItem(
        "Media",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.aboutUs.push(
      this.getMenuItem(
        "Careers",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.aboutUs.push(
      this.getMenuItem(
        "Disclaimer",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );

    this.contactUs.push(
      this.getMenuItem(
        "Contact us",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.contactUs.push(
      this.getMenuItem(
        "Let us call you back",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.contactUs.push(
      this.getMenuItem(
        "Find a store",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.contactUs.push(
      this.getMenuItem(
        "Coverage map",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );
    this.contactUs.push(
      this.getMenuItem(
        "Ask our community",
        "./assets/icons/rightArrow.svg",
        this.service.home,
      ),
    );

    this.socialMedia.push(
      this.getMenuItem("", "./assets/icons/facebook.svg", this.service.home),
    );
    this.socialMedia.push(
      this.getMenuItem("", "./assets/icons/twitter.svg", this.service.home),
    );
    this.socialMedia.push(
      this.getMenuItem("", "./assets/icons/youtube.svg", this.service.home),
    );
  }

  public getMenuItem(
    title: string,
    imageUrl: string,
    navigationRoute: string,
  ): SideMenuItems {
    return {
      title,
      imageUrl,
      isSelected: () => {
        return this.service.currentRoute() === navigationRoute;
      },
      route: navigationRoute,
    };
  }

  public navigate(menuItem: SideMenuItems) {
    this.service.navigate(menuItem.route);
  }
}
