import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import _ from "underscore";
import { Session } from "../../../../../models/session";
import {
  Address,
  AddressType,
  BusinessInfoMoldel,
  Contact,
  TradingPartner,
} from "../../../../../models/types";
import { NotificationService } from "../../../../../services/notification.service";
import { TradingPartnerService } from "../../../../../services/trading-partner.service";

@Component({
  selector: "app-edit-business-info",
  templateUrl: "./edit-business-info.component.html",
  styleUrls: ["./edit-business-info.component.sass"],
})
export class EditBusinessInfoComponent implements OnInit {
  title = "";
  editBusinessInfoForm: UntypedFormGroup;
  tradingPartner: TradingPartner;
  canEdit = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<EditBusinessInfoComponent>,
    private session: Session,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.title = data.title;

    if (data.tradingPartner) {
      this.tradingPartner = data.tradingPartner;
    }
  }

  ngOnInit(): void {
    this.setupInputs();
    if (this.tradingPartner) {
      this.populateBusinessInformation();
    }

    const originalInfo = this.getBusinessInfo();
    this.editBusinessInfoForm.valueChanges.subscribe(() => {
      this.canEdit = !_.isEqual(originalInfo, this.getBusinessInfo());
    });
  }

  setupInputs() {
    this.editBusinessInfoForm = new UntypedFormGroup({
      addressLine1: new UntypedFormControl("", Validators.required),
      addressLine2: new UntypedFormControl(""),
      addressLine3: new UntypedFormControl(""),
      addressLine4: new UntypedFormControl(""),
      postalCode: new UntypedFormControl(""),
      email: new UntypedFormControl(""),
      telNumber: new UntypedFormControl(""),
      faxNumber: new UntypedFormControl(""),
      contactName: new UntypedFormControl(""),
      contactSurname: new UntypedFormControl(""),
    });
  }

  getBusinessInfo(): BusinessInfoMoldel {
    const contact: Contact = {
      telNo: this.editBusinessInfoForm.controls.telNumber.value ?? "",
      faxNo: this.editBusinessInfoForm.controls.faxNumber.value ?? "",
      emailAddress: this.editBusinessInfoForm.controls.email.value ?? "",
      contactName: this.editBusinessInfoForm.controls.contactName.value ?? "",
      contactSurname:
        this.editBusinessInfoForm.controls.contactSurname.value ?? "",
    };

    const address: Address = {
      line1: this.editBusinessInfoForm.controls.addressLine1.value ?? "",
      line2: this.editBusinessInfoForm.controls.addressLine2.value ?? "",
      line3: this.editBusinessInfoForm.controls.addressLine3.value ?? "",
      line4: this.editBusinessInfoForm.controls.addressLine4.value ?? "",
      postalCode: this.editBusinessInfoForm.controls.postalCode.value ?? "",
    };

    const capturedData: BusinessInfoMoldel = {
      contact: contact,
      addresses: [address],
    };

    return capturedData;
  }

  getTelNo() {
    const telNo = this.tradingPartner?.contact?.telNo?.trim();
    if (telNo?.charAt(0) == "0") {
      return "+27" + telNo.substring(1);
    }
    return telNo; //telNo;
  }

  getFaxNo() {
    const faxNo = this.tradingPartner?.contact?.faxNo?.trim();
    if (faxNo?.charAt(0) == "0") {
      return "+27" + faxNo.substring(1);
    }
    return faxNo;
  }

  cancel() {
    this.close(false);
  }

  close(result) {
    this.dialogRef.close(result);
  }

  submit() {
    const businessInfo = this.getBusinessInfo();
    this.tradingPartnerService.editBusinessInfo(businessInfo).subscribe(
      () => {
        this.notificationService.showSuccess(
          "Business information upated successfully",
        );
        this.close(true);
      },
      (error) => {
        this.notificationService.showError(error);
      },
    );
  }

  populateBusinessInformation() {
    let postalAddress = this.tradingPartner.addresses.find(
      (x) => x.type == AddressType.postalAddress,
    );

    if (!postalAddress) {
      postalAddress = this.tradingPartner.addresses[0];
    }

    if (postalAddress) {
      this.editBusinessInfoForm.controls.addressLine1.setValue(
        postalAddress.line1?.trim(),
      );
      this.editBusinessInfoForm.controls.addressLine2.setValue(
        postalAddress.line2?.trim(),
      );
      this.editBusinessInfoForm.controls.addressLine3.setValue(
        postalAddress.line3?.trim(),
      );
      this.editBusinessInfoForm.controls.addressLine4.setValue(
        postalAddress.line4?.trim(),
      );
      this.editBusinessInfoForm.controls.postalCode.setValue(
        postalAddress.postalCode?.trim(),
      );
      this.editBusinessInfoForm.controls.contactName.setValue(
        this.tradingPartner?.contact?.contactName?.trim(),
      );
      this.editBusinessInfoForm.controls.contactSurname.setValue(
        this.tradingPartner?.contact?.contactSurname?.trim(),
      );
      this.editBusinessInfoForm.controls.email.setValue(
        this.tradingPartner?.contact?.emailAddress?.trim(),
      );
      this.editBusinessInfoForm.controls.telNumber.setValue(
        this.getTelNo()?.trim(),
      );
      this.editBusinessInfoForm.controls.faxNumber.setValue(
        this.getFaxNo()?.trim(),
      );
    }
  }
}
