<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 scroll">
  <div class="width-90 flex m-20 fx-jc-center">
    <p class="m-b-30 title">{{ (tradingPartner | async)?.name }}</p>
    <div
      *ngIf="getApplicationStatus && srfApplication"
      class="widsth-95 p-10 m-t-5 fx-ai-center tradeCard flex fx-row"
    >
      <p class="P-l noMargin">
        Your Invoice Finance Application has been approved
      </p>
      <div class="m-l-auto">
        <button
          mat-flat-button
          class="textOnly redText btn round noMargin"
          (click)="NavigateToInvoices()"
        >
          View Invoices
          <mat-icon class="flex">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="!getApplicationStatus && srfApplication"
      class="manageButtons widsth-95 p-10 m-t-5 fx-ai-center tradeCard flex fx-row"
    >
      <p class="P-l noMargin">
        Need funding? Unlock business capital with our financing options
      </p>
      <div class="m-l-auto">
        <button
          matRipple
          class="flex m-l-auto selectedItem fx-row fx-ai-center fx-jc-center"
          (click)="getFinanced()"
        >
          {{ "Get Financed" | uppercase }}
          <mat-icon class="flex">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="dashboardLayout">
      <div *ngIf="isAdmin()" class="flex fx-row">
        <p
          (click)="goToSupplierView()"
          class="noMargin selectedItem breadcrumb fw-700 paragraph"
        >
          Supplier View
        </p>
        <img
          class="icon flex p-r-10 p-l-10"
          width="200px"
          src="./assets/icons/rightArrowBlack.svg"
        />
        <p
          class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center fx-ai-center"
        >
          Dashboard
        </p>
      </div>

      <h3 class="orderSummary heading">Order Summary</h3>
      <div class="datePicker">
        <form
          [formGroup]="dashboardForm"
          class="datePicket round tradeCard fx-ai-center fx-jc-center fx-row flex"
        >
          <mat-form-field class="">
            <mat-label>From Date</mat-label>
            <input
              matInput
              [max]="toMaxDate"
              [matDatepicker]="fromDate"
              formControlName="fromDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="m-l-20">
            <mat-label>To Date</mat-label>
            <input
              matInput
              [min]="fromMinDate"
              [max]="todaysDate"
              [matDatepicker]="toDate"
              formControlName="toDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </form>
        <div class="totalOrderValue">
          <div class="label flex fx-row fx-ai-center">
            Total Order Value:
            <span class="fw-900 m-l-10">{{ totalOrderValue }}</span>
          </div>
        </div>
        <div class="toBeInvoiced">
          <div class="label flex m-l-20 fx-row fx-ai-center">
            Total Invoiced Value:
            <span class="fw-900 m-l-10">{{ toBeInvoiced }}</span>
          </div>
        </div>
      </div>
      <app-total-orders
        class="totalOrders"
        [toMaxDate]="toMaxDate"
        [fromMinDate]="fromMinDate"
        (totalOrdersAmount)="setTotalOrdersAmount($event)"
        (ordersToBeInvoiced)="setOrdersToBeInvoiced($event)"
      >
      </app-total-orders>
      <app-order-values
        class="orderValues width-100"
        [orderValueByMonthPerRetailer]="orderValueByMonthPerRetailer"
        [toMaxDate]="toMaxDate"
        [fromMinDate]="fromMinDate"
      >
      </app-order-values>
      <app-order-to-invoice
        class="orderToInvoice width-100"
        [ordersInvoicedByRetailer]="ordersInvoicedByRetailer"
        [toMaxDate]="toMaxDate"
        [fromMinDate]="fromMinDate"
      ></app-order-to-invoice>
      <app-top-performing-products
        class="topPerforming width-100"
        [topPerformingProduct]="topPerformingProduct"
      ></app-top-performing-products>
      <div></div>
    </div>
  </div>
</div>
