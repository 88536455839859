<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <form class="flex m-t-10 width-100" [formGroup]="AdminReportsForm">
      <div class="flex fx-row">
        <div>
          <p class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center">
            Report for:
          </p>
          <p class="title">{{ supplierName }} - {{ supplierGln }}</p>
          <div class="flex fx-row">
            <p
              (click)="goToReportView()"
              class="noMargin selectedItem breadcrumb fw-700 paragraph"
            >
              Reports
            </p>
            <img
              class="icon flex p-r-10 p-l-10"
              width="200px"
              src="./assets/icons/rightArrowBlack.svg"
            />
            <p
              class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center fx-ai-center"
            >
              Report Items
            </p>
          </div>
        </div>
        <div
          class="flex fx-ai-center m-l-auto fx-row round tradeCard width-30 p-t-10 p-r-20 p-l-20"
        >
          <mat-form-field class="width-65">
            <input
              matInput
              (blur)="clearSearch()"
              (keyup.enter)="search($event.target.value)"
              (blur)="clearSearch()"
              formControlName="searchReports"
            />
            <mat-label><mat-icon>search</mat-icon> Search Report </mat-label>
            <!-- <mat-error
                            *ngIf="AdminReportsForm.controls.searchTradingPartner.hasError('required')">
                            Numeric value <strong>only</strong>
                        </mat-error> -->
          </mat-form-field>
          <div class="bl-l p-l-10 fx-ai-center fx-row"></div>
        </div>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <div class="flex fx-row">
          <p class="heading flex p-b-10">Report Items</p>
          <div class="m-l-auto flex fx-ai-center manageButtons fx-row">
            <button
              matRipple
              class="downl oadAllBtn fx-row fx-ai-center flex selectedItem"
              (click)="downloadExcelFile()"
            >
              {{ "Download Excel" | uppercase }}
              <img
                src="assets/icons/downloadIcon.svg"
                width="20"
                class="animate__animated"
              />
            </button>
          </div>
        </div>

        <div class="grayBanner flex p-20 m-b-20 fx-row">
          <div class="flex fx-row">
            <p class="p-b p-r-10">{{ dataSource.data.length }}</p>
            <p class="flex p-b">Order Report Items</p>
          </div>

          <!-- <div class="flex fx-jc-center m-l-auto fx-row productButtons">
                    <button matRipple type="button" class="flex selectedItem m-r-10" (click)="newTradingPartner()">New</button>
                  </div> -->
        </div>
        <!-- <app-order-table (value)="showValue($event)"></app-order-table>   -->

        <div>
          <div
            *ngIf="loader"
            class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
          >
            <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
          </div>
          <div
            #TABLE
            class="scroll"
            style="border: 1px lightgray solid; border-radius: 10px !important"
          >
            <table
              #table
              *ngIf="!loader"
              matTableFilter
              matTableExporter
              mat-table
              [dataSource]="dataSource"
              class="width-100"
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="supplierName">
                <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.SupplierName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="retailer">
                <th mat-header-cell *matHeaderCellDef>Retailer</th>
                <td mat-cell *matCellDef="let element" class="noWrap">
                  {{ element.Retailer }}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderNo">
                <th mat-header-cell *matHeaderCellDef>Order No</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.OrderNumber }}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderStatus">
                <th mat-header-cell *matHeaderCellDef>Order Status</th>
                <td mat-cell *matCellDef="let element" class="noWrap">
                  {{ element.OrderStatus }}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderDate">
                <th mat-header-cell *matHeaderCellDef>Order Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.OrderDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderValue">
                <th mat-header-cell *matHeaderCellDef>Order Value</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.OrderValue | currency : "R" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="invoiceValue">
                <th mat-header-cell *matHeaderCellDef>Invoice Value</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.InvoiceValue | currency : "R" }}
                </td>
              </ng-container>

              <!-- description Column
                  <ng-container matColumnDef="reportData">
                    <th mat-header-cell *matHeaderCellDef> View Report </th>
                    <td mat-cell *matCellDef="let element"> 
                        <mat-icon (click)="ViewReport(element)">visibility</mat-icon>
                    </td>
                  </ng-container> -->

              <!-- Checkbox Column -->
              <!-- <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation(); manageProduct(row)"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)"
                                    formControlName="selected"
                                    >
                      </mat-checkbox>
                    </td>
                  </ng-container> -->

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>

          <mat-paginator
            [pageSizeOptions]="[20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </form>
  </div>
</div>
