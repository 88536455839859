<div #edlem>
  <div *ngIf="!loadOrders" class="grayBanner flex p-20 m-b-20 fx-row">
    <div class="flex fx-row">
      <span class="fw-900 flex fx-row m-r-10">{{ noOrders }}</span>
      <span class="flex"
        >Viewing {{ ordersSummaries.length }} of
        {{ (orderResult | async)?.scannedCount }}
        {{ orderStatus }} order(s)</span
      >
    </div>
    <div class="flex fx-jc-center m-l-auto">
      <div class="flex fx-jc-center manageButtons fx-row">
        <div>
          <button
            [disabled]="!enableButtons"
            matRipple
            class="flex selectedItem noMargin"
            (click)="printOrders('print')"
          >
            {{ "PDF" | uppercase }}
          </button>
        </div>
        <div>
          <button
            [disabled]="!enableButtons"
            matRipple
            class="flex selectedItem"
            (click)="printOrders('exportCVS')"
          >
            {{ "Excel" | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loadOrders" class="p-10 loader flex fx-jc-center fx-ai-center">
    <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
  </div>
  <table
    *ngIf="!loadOrders"
    matTableExporter
    mat-table
    [dataSource]="dataSource"
    class="width-100"
  >
    <!-- Interchange no Column -->
    <ng-container matColumnDef="retailerName">
      <th mat-header-cell *matHeaderCellDef>Retailer</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ determineRetailer(element) }}</p>
      </td>
    </ng-container>

    <!-- Destination Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Destination</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ determineDestination(element) }}</p>
      </td>
    </ng-container>

    <!-- Order no Column -->
    <ng-container matColumnDef="orderNo">
      <th mat-header-cell *matHeaderCellDef>Order no</th>
      <td mat-cell *matCellDef="let element" style="color: #00b0ca">
        <p class="p-l">
          <a matRipple (click)="goToNewOrder(element)" class="pointer">
            {{ element.orderNumber }}
          </a>
        </p>
      </td>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l alignRight">{{ element.total?.toFixed(2) }}</p>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ element.dateOfOrder | date : dateFormat }}</p>
      </td>
    </ng-container>

    <!-- Earliest Delivery Column -->
    <ng-container matColumnDef="earliestDelivery">
      <th mat-header-cell *matHeaderCellDef>Earliest Delivery</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ element.earliestDelivery | date : dateFormat }}</p>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="latestDelivery">
      <th mat-header-cell *matHeaderCellDef>Latest Delivery</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ element.latestDelivery | date : dateFormat }}</p>
      </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selectedOrders(row) : ''"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Symbol Column -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"
    ></tr>
  </table>
  <div
    *ngIf="!loadOrders"
    class="clearButtons noOverFlow p-10 flex fx-ai-center fx-jc-center grayBanner"
  >
    <button
      (click)="loadMoreOrders()"
      [disabled]="loadingMoreOrders"
      class="fx-row fx-ai-center fx-jc-center flex loadMore height-100 width-full pointer"
    >
      <span *ngIf="!loadingMoreOrders">Load more</span>
      <div
        *ngIf="loadingMoreOrders"
        class="p-l-10 loader flex fx-jc-center fx-ai-center"
      >
        <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
      </div>
    </button>
  </div>
</div>
<div *ngIf="showPrintLayout === true">
  <app-print-layout
    (onClose)="onClose()"
    [data]="data"
    [type]="downloadType"
  ></app-print-layout>
</div>
