<div class="tradeCard flex height-100">
  <div class="center-item flex height-100 p-20">
    <p class="subHeading">Orders to Invoice by Supplier</p>
    <div class="bl-t-l height-100">
      <div class="m-15"></div>
      <div class="flex fx-jc-center fx-ai-center height-100">
        <div
          *ngIf="!ordersInvoicedByRetailer"
          style="min-width: 50px !important; max-width: 200px !important"
          class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
        >
          <mat-spinner></mat-spinner>
        </div>
        <div
          class="height-100"
          *ngIf="ordersInvoicedByRetailer"
          style="max-height: 350px"
        >
          <canvas
            baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [colors]="chartColors"
            [chartType]="barChartType"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
