import { Component, ElementRef, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Session } from "../../../models/session";
import { TradingPartnerSummary, User, routes } from "../../../models/types";
import { AuthService } from "../../../services/auth.service";
import { DialogService } from "../../../services/dialog.service";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { UiDataService } from "../../../services/order-uidata.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.sass"],
})
export class LoginComponent implements OnInit {
  @Input("focuMe") isFocused: boolean;
  resetPasswortOTP = "12345";
  loginOTP = "12345";
  loginForm: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  otpForm: UntypedFormGroup;
  hide = true;
  forgotPassword = false;
  incorrectLogin = false;
  showOtp = false;
  technicalDifficulties = false;
  isVerifying: boolean;
  resetPassword = false;
  passwordMatch = false;
  loginFailureMessage: string;
  user: User;
  loader = false;
  suppliers: TradingPartnerSummary[];

  constructor(
    private notification: NotificationService,
    private uidata: UiDataService,
    private formBuilder: UntypedFormBuilder,
    private navigationservice: NavigationService,
    private uiData: UiDataService,
    private dialog: DialogService,
    private hostElement: ElementRef,
    private authService: AuthService,
    private session: Session,
    private navigationService: NavigationService,
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.initializeLogins();
    this.setDefaults();
  }

  initializeLogins() {
    this.loginForm = this.formBuilder.group({
      username: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
    });

    this.otpForm = new UntypedFormGroup({
      OTP: new UntypedFormControl("", [Validators.required]),
    });

    const showOtp = this.navigationservice.getQueryStringValue("showOtp");

    if (showOtp == "true") {
      this.showOtp = true;
    }
  }

  setDefaults() {
    this.loginForm.controls.username.setValue("");
    this.loginForm.controls.password.setValue("");
    this.otpForm.controls.OTP.setValue("");
  }

  login() {
    const loader = this.dialog.loader();
    this.tradingPartnerService.supplierTradingPartnerSelected.next(false);
    this.authService
      .login(
        this.loginForm.controls.username.value.trim(),
        this.loginForm.controls.password.value.trim(),
      )
      .subscribe(
        (result) => {
          loader.close();
          this.incorrectLogin = false;
          this.user = result;

          if (result.otpRequired) {
            this.showOtp = true;
            return;
          }
          if (result.newPasswordRequired) {
            this.navigationservice.navigateToResetPassword(routes.login);
            return;
          }
          if (this.user.roles.indexOf("admin") != -1) {
            this.userService.userLoggedIn(this.user.userId);
            this.navigationservice.navigateToTradingPartner();
          } else {
            //maybe customer admin vs customer check
            // this.dialog.selectSupplierDialog()
            //   .afterClosed()
            //   .subscribe(res => {
            //     if (!res) return;
            //     // session supplierViewGln should be set

            //     this.navigationservice.navigateDashboard();
            //   })
            this.session.fromLogin = true;

            this.userService.userLoggedIn(this.user.userId);
            this.navigationservice.navigateDashboard();
          }
        },
        (error) => {
          loader.close();
          this.incorrectLogin = true;
          this.loginFailureMessage =
            error.message ?? "Your password or username is incorrect.";
        },
      );
  }

  showSelectSupplierDialog() {
    this.dialog
      .selectSupplierDialog()
      .afterClosed()
      .subscribe((res) => {
        if (!res) return;
        // session supplierViewGln should be set
      });
  }

  verifyOTP() {
    this.authService.verifyLoginOtp(this.otpForm.controls.OTP.value).subscribe(
      () => {
        if (this.user?.newPasswordRequired) {
          this.navigationservice.navigateToResetPassword(routes.login);
        }

        this.userService.userLoggedIn(this.user.userId);
        this.navigationservice.navigateDashboard();
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  onforgotPassword() {
    this.navigationservice.navigateToForgotPassword();
  }

  showTermsAndConditions() {
    this.dialog.termsAndContitions().afterClosed();
  }

  moveHelpButton(): number {
    return this.navigationService.currentRoute() === "myProducts" ? 80 : 0;
  }

  getSuppliers() {
    this.loader = true;
    this.tradingPartnerService.getSuppliers().subscribe(
      (suppliers) => {
        if (!suppliers) return;
        this.suppliers = suppliers;
        // this.dataSource.data.forEach((row) => this.selection.select(row));
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.notificationService.showError(error);
      },
    );
  }
}
