import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { routes } from "../../../models/types";
import { AuthService } from "../../../services/auth.service";
import { DialogService } from "../../../services/dialog.service";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.sass"],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private navigationservice: NavigationService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private dialog: DialogService,
  ) {}

  forgotPasswordForm: UntypedFormGroup;

  ngOnInit(): void {
    this.initializeInputs();
    this.setDefaults();
  }

  initializeInputs() {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl("", [Validators.required]), //Validators.email
    });
  }

  setDefaults() {
    this.forgotPasswordForm.controls.email.setValue("");
  }

  submit() {
    this.authService
      .forgotPassword(this.forgotPasswordForm.controls.email.value)
      .subscribe(
        () => {
          this.navigationservice.navigateToResetPassword(
            routes.forgotPassword,
            this.forgotPasswordForm.controls.email.value,
          );
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  cancel() {
    this.navigationservice.navigateToLogin();
  }
}
