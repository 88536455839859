import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import _ from "underscore";
import { Order, Vat } from "../models/order";
import { Session } from "../models/session";
import {
  OrderSummary,
  QueryResult,
  TradingPartner,
  TradingPartnerSummary,
} from "../models/types";
import { OrderRepository } from "../repositories/order.repository";
import { RepositoryFactory } from "../repositories/repository.factory";
import { TradingPartnersRepository } from "../repositories/trading-partners.repository";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  orderRepo: OrderRepository;
  tradingPartnerRepo: TradingPartnersRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.orderRepo = repoFactory.getOrderRepository();
    this.tradingPartnerRepo = repoFactory.getTradingPartnerRepository();
  }

  public currentOrder: Subject<Order> = new Subject();
  public updateTempTotal: Subject<number> = new Subject();

  get(id: string): Observable<Order> {
    this.orderRepo.get(this.session.getSupplierGln(), id).subscribe(
      (order) => {
        if (!order) {
          return;
        }
        this.currentOrder.next(order);
      },
      (error) => this.currentOrder.error(error),
    );
    return this.currentOrder;
  }

  private searchResults: Subject<OrderSummary[]> = new Subject<
    OrderSummary[]
  >();
  private lastSearchedDateTime = new Date(1900, 1, 1);
  private ONE_MINUTE = 60 * 1000; /* ms */
  getActive(): Observable<OrderSummary[]> {
    if (this.occurredBefore(this.lastSearchedDateTime, this.ONE_MINUTE)) {
      return this.searchResults; // Dont search if its been done within the last minute
    }

    this.lastSearchedDateTime = new Date();
    this.orderRepo.getActive(this.session.getSupplierGln()).subscribe(
      (results) => {
        this.searchResults.next(results);
        this.loadMissingTradingPartners(results);
      },
      (error) => {
        this.searchResults.error(error);
      },
    );

    return this.searchResults;
  }

  private printResults: Subject<Order[]> = new Subject<Order[]>();
  getPrint(orderIds: any[]): Observable<Order[]> {
    this.orderRepo.getPrint(this.session.getSupplierGln(), orderIds).subscribe(
      (results) => {
        this.printResults.next(results);
      },
      (error) => {
        this.printResults.error(error);
      },
    );

    return this.printResults;
  }

  private occurredBefore(date: Date, timeInterval: number) {
    return Date.now() - date.getTime() / 1000 < timeInterval;
  }

  search(
    sender: string,
    searchText: string,
    status: string[],
    dateFrom: Date,
    dateTo: Date,
    take: number = null,
    skip: number = null,
  ): Observable<QueryResult> {
    const searchResults: Subject<QueryResult> = new Subject<QueryResult>();
    this.orderRepo
      .search(
        this.session.getSupplierGln(),
        sender,
        searchText,
        status,
        dateFrom,
        dateTo,
        take,
        skip,
      )
      .subscribe(
        (results) => {
          searchResults.next(results);
          this.loadMissingTradingPartners(results.data);
        },
        (error) => {
          searchResults.error(error);
        },
      );

    return searchResults;
  }

  private tradingPartnerCache: TradingPartnerSummary[] = [];

  private getCachedTradingPartner(gln: string): TradingPartnerSummary | null {
    return _.find(this.tradingPartnerCache, (tp: TradingPartnerSummary) => {
      return tp.gln === gln;
    });
  }

  private loadMissingTradingPartners(orders: OrderSummary[]): void {
    _.each(orders, (order: OrderSummary) => {
      const loadDeliverTo = (order.deliverTo?.name ?? "") == "";
      if (loadDeliverTo) {
        this.getTradingPartner(order.deliverTo.gln).subscribe((tps) => {
          order.deliverTo = tps;
        });
      }
    });
  }

  private getTradingPartner(gln: string): Observable<TradingPartnerSummary> {
    const cachedTp = this.getCachedTradingPartner(gln);

    if (cachedTp != undefined && cachedTp != null) {
      return new BehaviorSubject<TradingPartnerSummary>(cachedTp);
    }

    const retSubject = this.tradingPartnerRepo
      .get(this.session.getSupplierGln(), gln)
      .pipe(
        map((tp: TradingPartner): TradingPartnerSummary => {
          const tps = new TradingPartnerSummary();
          tps.gln = tp.gln;
          tps.imageURL = tp.imageURL;
          tps.name = tp.name;
          tps.type = tp.type;
          return tps;
        }),
      );

    retSubject.subscribe(
      (tps) => {
        this.tradingPartnerCache.push(tps);
      },
      () => {
        const tps = new TradingPartnerSummary();
        tps.gln = gln;
        this.tradingPartnerCache.push(tps);
      },
    );

    return retSubject;
  }

  update(order: Order): Observable<Order> {
    if (!order) {
      throw new Error("Order is required");
    }

    return this.orderRepo.update(this.session.getSupplierGln(), order);
  }
  confirm(id: string): Observable<Order> {
    if (!id) {
      throw new Error("Id is required");
    }
    return this.orderRepo.confirm(this.session.getSupplierGln(), id);
  }
  reject(id: string): Observable<Order> {
    if (!id) {
      throw new Error("Id is required");
    }
    return this.orderRepo.reject(this.session.getSupplierGln(), id);
  }
  new(id: string): Observable<Order> {
    if (!id) {
      throw new Error("Id is required");
    }
    return this.orderRepo.revertNew(this.session.getSupplierGln(), id);
  }
  acknowledge(id: string): Observable<Order> {
    if (!id) {
      throw new Error("Id is required");
    }
    return this.orderRepo.acknowledge(this.session.getSupplierGln(), id);
  }

  invoice(
    id: string,
    invoiceNumber: string,
    invoiceAmount: number,
    invoiceDate: string,
  ): Observable<Order> {
    if (!id) {
      throw new Error("Id is required");
    }
    if (!invoiceNumber) {
      throw new Error("Invoice number is required");
    }
    if (!invoiceAmount) {
      throw new Error("Invoice amount is required");
    }
    if (!invoiceDate) {
      throw new Error("Invoice date is required");
    }
    return this.orderRepo.invoice(
      this.session.getSupplierGln(),
      id,
      invoiceNumber,
      invoiceAmount,
      invoiceDate,
    );
  }

  resendInvoice(orderId: string, invoiceId: string): Observable<Order> {
    if (!orderId) {
      throw new Error("Order Id is required");
    }
    if (!invoiceId) {
      throw new Error("Invoice Id is required");
    }
    return this.orderRepo.resendInvoice(
      this.session.getSupplierGln(),
      orderId,
      invoiceId,
    );
  }

  loadMoreOrders(request: QueryResult): Observable<OrderSummary[]> {
    return this.orderRepo.loadMoreOrders(request);
  }

  getVat(varRateCode: string): Observable<Vat[]> {
    return this.orderRepo.getVat(this.session.getSupplierGln(), varRateCode);
  }
}
