<div class="m-b-10 m-t-10 p-60 s-card">
  <div class="flex fx-row">
    <div class="width-30 flex s-card-t">
      <h5 class="fw-500 p-l-10">Select supplier:</h5>
      <form class="p-l-10 p-r-10">
        <mat-form-field>
          <input
            type="text"
            placeholder="Search for document"
            aria-label="Number"
            matInput
            [formControl]="documentSearchFormControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
              (click)="selected_document(option)"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div *ngIf="found" class="p-t-20">
    <div class="s-card-t p-l-30 p-r-30">
      <div class="flex fx-row">
        <div class="width-30 flex fx-jc-center block">
          <mat-form-field>
            <p class="fw-500"></p>
            <mat-label>Filter Search Results</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Ex. Mia"
            />
          </mat-form-field>
        </div>

        <div class="m-l-auto p-l-20 p-b-20 p-r-20 flex fx-col">
          <h3 class="fw-700">Filter document by date</h3>
          <div class="p-l-10 p-r-10 flex fx-row">
            <mat-form-field class="flex p-r-20">
              <mat-label>From:</mat-label>
              <input
                matInput
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="fromDate"
                (dateChange)="checkDate('from', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDate d></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex bl-l p-l-20">
              <mat-label>To:</mat-label>
              <input
                matInput
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="toDate"
                (dateChange)="checkDate('to', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="toDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
            <div mat-flat-button class="flex m-l-40 flex fx-jc-center">
              <a
                mat-stroked-button
                routerLink="."
                [disabled]="!!rightDate"
                class="s-card"
              >
                Filter Results
              </a>
            </div>
          </div>
          <h5 *ngIf="rightDate" class="fw-800 m0" style="color: red">
            'From' has to be less than 'To'
          </h5>
        </div>
      </div>

      <div>
        <table
          class="mat-elevation-z8 width-100"
          mat-table
          [dataSource]="dataSource"
          matSort
        >
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let row">{{ row.id }}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Progress</th>
            <td mat-cell *matCellDef="let row">{{ row.createdBy }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let row">{{ row.type }}</td>
          </ng-container>

          <!-- Link Column -->
          <ng-container matColumnDef="date-created">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="width-30"
            >
              Date Created
            </th>
            <td mat-cell *matCellDef="let row" class="fw-800">
              <p class="inlineflex fw-300 m-l-auto">
                {{
                  row.dateCreated.getDate() +
                    " " +
                    monthName(row.dateCreated.getMonth()) +
                    " " +
                    row.dateCreated.getFullYear()
                }}
              </p>
            </td>
          </ng-container>

          <!-- Link Column -->
          <ng-container matColumnDef="view" class="m-l-auto" style="right: 0px">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="m-l-auto"
            >
              View
            </th>
            <td mat-cell *matCellDef="let row" class="">
              <a
                mat-stroked-button
                routerLink="."
                class="inlineflex fw-300 m-l-auto"
                >view</a
              >
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
