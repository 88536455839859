import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  DetailedOrderReportForCsv,
  DetailedSupplierReport,
} from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { PrintingServiceService } from "../../../../services/printing-service.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";
import { Settings } from "../../../../settings";

const ELEMENT_DATA: DetailedOrderReportForCsv[] = [];
@Component({
  selector: "app-admin-report-view",
  templateUrl: "./admin-report-view.component.html",
  styleUrls: ["./admin-report-view.component.sass"],
})
export class AdminReportViewComponent implements OnInit {
  @ViewChild("TABLE") table: ElementRef;
  displayedColumns: string[] = [
    "orderNumber",
    "orderDate",
    "invoiceDate",
    "sender",
    "receiver",
    "unitBarcode",
    "packBarcode",
    "packSize",
    "productDescription",
    "orderedQuantity",
    "invoiceQuantity",
    "price",
  ];
  orderReports: DetailedOrderReportForCsv[] = [];
  orderReport: DetailedSupplierReport;
  AdminReportsForm: UntypedFormGroup;
  loader = false;
  supplierName = "";
  supplierGln = "";
  dateFormat;
  dataSource = new MatTableDataSource<DetailedOrderReportForCsv>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private navigate: NavigationService,
    private dialogService: DialogService,
    private notification: NotificationService,
    private settings: Settings,
    private printingService: PrintingServiceService,
  ) {}

  ngOnInit(): void {
    this.dateFormat = this.settings.dateFormat;
    this.setupInputControls();
    this.getReports();
  }

  setupInputControls() {
    this.AdminReportsForm = this.formBuilder.group({
      searchReports: ["", null],
    });
  }

  goToReportView() {
    this.navigate.navigateToAdminReports();
  }

  getReports() {
    this.tradingPartnerService.orderReports.subscribe(
      (reports) => {
        if (reports) {
          this.orderReport = reports;
          this.orderReports = reports.reportData;
          this.supplierName = reports.supplierName;
          this.supplierGln = reports.supplierGln;
          this.dataSource.data = this.orderReports;
          this.dataSource.paginator = this.paginator;
        }
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  ViewReport() {
    this.navigate.navigateToAdminReportsView();
  }

  search(filterValue: string) {
    this.dataSource.data = this.orderReports.filter(
      (x) =>
        x.OrderNumber.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.OrderDate.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.Status.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.InvoiceDate.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.Sender.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.Receiver.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.UnitBarcode.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.PackBarcode.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.PackSize.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.ProductDescription.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.OrderedQuantity.toString()
          .toLowerCase()
          .includes(filterValue.trim().toLowerCase()) ||
        x.InvoiceQuantity.toString()
          .toLowerCase()
          .includes(filterValue.trim().toLowerCase()) ||
        x.Price.toString()
          .toLowerCase()
          .includes(filterValue.trim().toLowerCase()),
    );
  }

  clearSearch() {
    this.AdminReportsForm.controls.searchReports.value == "";
    this.AdminReportsForm.controls.searchReports.value == ""
      ? (this.search(""), (this.paginator = this.dataSource.paginator))
      : {};
  }

  downloadExcelFile() {
    this.printingService.generateDetailedSupplierReport([this.orderReport]);
  }
}
