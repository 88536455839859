import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Session } from "../models/session";
import { EmailTemplate } from "../models/types";
import { RepositoryFactory } from "../repositories/repository.factory";
import { SupportRepository } from "../repositories/support.repository";
@Injectable({
  providedIn: "root",
})
export class SupportService {
  supportRepo: SupportRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.supportRepo = repoFactory.getSupportRepository();
  }

  sendSupportEmail(body: EmailTemplate): Observable<any> {
    return this.supportRepo.sendSupportEmail(
      this.session.getSupplierGln(),
      body,
    );
  }
}
