import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Chart, ChartDataset, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { OrderAndInvoice } from "../../../../models/types";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

Chart.register(ChartDataLabels);

const graphColors = {
  New: "#00B0CA",
  Acknowledged: "#9C2AA0",
  Confirmed: "#005765",
  Rejected: "#FF0000",
  Invoiced: "#BD0000",
};

@Component({
  selector: "app-order-to-invoice",
  templateUrl: "./order-to-invoice.component.html",
  styleUrls: ["./order-to-invoice.component.sass"],
})
export class OrderToInvoiceComponent implements OnInit, OnChanges {
  @Input() toMaxDate: Date;
  @Input() fromMinDate: Date;
  @Input() ordersInvoicedByRetailer: OrderAndInvoice[];
  @ViewChild("myCanvas") canvas: ElementRef;
  delayed;
  public barChartOptions: ChartOptions<"bar"> = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 15,
      },
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        offset: -5,
      },
      legend: {
        position: "bottom",
        display: true,
        labels: {
          font: { size: 9 },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          font: {
            size: 10,
          },
        },
        title: {
          font: {
            size: 13,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          display: false,
          font: { size: 8 },
        },
        title: {
          font: {
            size: 13,
          },
        },
      },
    },
  };
  public barChartLabels: string[] = [];
  public barChartType = "bar";

  public barChartData: ChartDataset<"bar">[] = [
    {
      backgroundColor: graphColors["Rejected"],
      barPercentage: 0.7,
      categoryPercentage: 0.2,
      data: [],
      label: "Number of orders",
    },
    {
      backgroundColor: graphColors["New"],
      barPercentage: 0.7,
      categoryPercentage: 0.2,
      data: [],
      label: "Number of orders invoice",
    },
  ];
  loadingData = false;
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
  ) {}

  ngOnChanges(): void {
    this.plotGraph();
  }

  ngOnInit(): void {
    this.plotGraph();
  }

  plotGraph() {
    this.loadingData = true;
    this.barChartData = [
      { ...this.barChartData[0], data: [] },
      { ...this.barChartData[1], data: [] },
    ];
    this.barChartLabels = [];

    if (this.ordersInvoicedByRetailer) {
      this.loadingData = false;
      this.ordersInvoicedByRetailer.forEach((chartData) => {
        this.barChartLabels.push(chartData.name);
        this.barChartData[0].data.push(chartData.orders);
        this.barChartData[1].data.push(chartData.invoicedOrders);
      });
    }
  }
}
