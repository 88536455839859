import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Session } from "../models/session";
import { EmailTemplate } from "../models/types";
import { newClient } from "../services/api-gateway.service.js";
import { SupportRepository } from "./support.repository";

const apigClient = newClient();

@Injectable()
export class AwsSupportRepository implements SupportRepository {
  constructor(private session: Session) {}

  sendSupportEmail(svGln: string, body: EmailTemplate): Observable<any> {
    const retObject = new Subject<any>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();

      if (!user) return;

      const additionalParams = {
        queryParams: {
          svGln: svGln,
          gln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .emailsSupportPost({}, body, additionalParams)
        .then((result) => {
          const tp = result.data;
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }
}
