<div class="fx-box fx-col" id="menu">
  <mat-list class="">
    <mat-list-item
      [ngClass]="{ selected: menuItem.isSelected() }"
      class="bl-b-w"
      matRipple
      *ngFor="let menuItem of menuItems"
      id="navItem"
      (click)="navItemClick(menuItem)"
    >
      <img
        [ngStyle]="{ 'margin-left': offsetMenuItem(menuItem) }"
        [ngClass]="{ animate__tada: menuItem.isSelected() }"
        src="assets/icons/{{ menuItem.title | lowercase }}.svg"
        width="20"
        [matTooltip]="menuItem.title"
        class="animate__animated p-l-20"
      />
      <p class="mh1 m-r-auto">{{ menuItem.title }}</p>
      <img
        [ngClass]="{ showIcon: menuItem.isSelected() }"
        style="opacity: 0"
        width="40"
        [src]="menuItem.imageUrl"
        class="menuArrow m-l-auto"
      />
      <!-- <img *ngIf="menuItem.isSelected()" [src]="menuItem.imageUrl" width="20" class="icon" [matTooltip]="menuItem.title"> -->
    </mat-list-item>
  </mat-list>
  <!-- <button mat-button class="fixed-bottom center-item flex fx-jc-center fx-ai-center" (click)="isExpanded = !isExpanded">
        <img src="./assets/icons/expand.svg" class="icon" *ngIf="!isExpanded">
        <img src="./assets/icons/minimize.svg" class="icon" *ngIf="isExpanded">
        <span *ngIf="isExpanded" class="mh1 textWhite">Minimize</span>
    </button> -->
</div>
