import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Product } from "../models/product";
import { Session } from "../models/session";
import { ProductRepository } from "../repositories/product-repository";
import { RepositoryFactory } from "../repositories/repository.factory";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  editedValues: string[];
  stickyProductsHeading = new BehaviorSubject<boolean>(false);

  productRepo: ProductRepository;
  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.productRepo = repoFactory.getProductRepository();
  }

  ManageProductValues(values): string[] {
    this.editedValues = values;
    return this.editedValues;
  }

  productValues(): string[] {
    return this.editedValues;
  }

  public currentProduct: Subject<Product> = new Subject();
  get(id: string): Observable<Product> {
    this.productRepo.get(this.session.getSupplierGln(), id).subscribe(
      (product) => {
        if (!product) {
          return;
        }
        this.currentProduct.next(product);
      },
      (error) => this.currentProduct.error(error),
    );
    return this.currentProduct;
  }

  search(
    searchText: string,
    productCode: string,
    barCode: string,
    retailerGln: string,
  ): Observable<Product[]> {
    const searchResults: Subject<Product[]> = new Subject<Product[]>();
    this.productRepo
      .search(
        this.session.getSupplierGln(),
        searchText,
        productCode,
        barCode,
        retailerGln,
      )
      .subscribe(
        (products) => {
          searchResults.next(products);
        },
        (error) => {
          searchResults.error(error);
        },
      );
    return searchResults;
  }

  add(product: Product): Observable<Product> {
    if (!product) {
      throw new Error("Product is required");
    }
    return this.productRepo.add(this.session.getSupplierGln(), product);
  }

  addProducts(products: Product[]): Observable<void> {
    if (!products) {
      throw new Error("Products are required");
    }
    return this.productRepo.addProducts(
      this.session.getSupplierGln(),
      products,
    );
  }

  update(product: Product): Observable<Product> {
    if (!product) {
      throw new Error("Product is required");
    }
    return this.productRepo.update(this.session.getSupplierGln(), product);
  }

  delete(id: string): Observable<Product> {
    if (!id) {
      throw new Error("id is required");
    }
    return this.productRepo.delete(this.session.getSupplierGln(), id);
  }
}
