import { Component, OnInit } from "@angular/core";
import { Session } from "../../../models/session";
import { NavigationService } from "../../../services/navigation.service";
import { UiDataService } from "../../../services/order-uidata.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.sass"],
})
export class NavMenuComponent implements OnInit {
  public userName: string = null;
  constructor(
    private service: NavigationService,
    private uiData: UiDataService,
    private session: Session,
  ) {}

  ngOnInit() {
    this.getName();
  }

  getName() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      this.userName = user.name;
    });
  }

  navLogin() {
    this.uiData.clearUserName();
    this.service.navigateToLogin();
  }

  navDashBoard() {
    this.service.navigateDashboard();
  }
}
