<div class="flex fx-jc-center fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <div class="flex m-t-10 width-100">
      <div class="flex fx-row height-10">
        <h1 class="fw-500 m-b-30 title">Tiger Brands</h1>

        <div
          class="width-20 height-10 m-l-auto flex round xs-card-t p-r-20 p-l-20"
        >
          <mat-form-field>
            <input matInput pattern="^[0-9]+([,.][0-9]+)?$" />
            <!-- // formControlName="searchMarketplacePartner"  > -->
            <mat-label
              ><mat-icon>search</mat-icon>Search by retailer, barcode,
              etc...</mat-label
            >
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="tradeCard flex p-l-20 p-r-20 p-t-30 m-t-30">
      <div
        matRipple
        class="flex round selectedItem fx-jc-center width-5 fx-ai-center fx-row"
      >
        <img
          width="20"
          src="./assets/icons/leftArrowBlack .svg"
          alt="back_arrow"
        />
        <span
          (click)="navigateBack()"
          class="noMargin subTitle fx-ai-center flex p-5"
          >Back</span
        >
      </div>
      <div class="m-20 partnerMarketImg">
        <img
          src="{{ tradingPartner.imageURL }}"
          alt="tradingPartner.imageURL"
        />
      </div>
      <div
        class="centerItem width-90 m-l-20 flex fx-jc-center m-t-20 m-b-40 fx-row"
      >
        <div class="flex fx-row width-80">
          <div class="width-50 m-10 marketTradeDetails">
            <p>Available Message Types</p>
            <h3 class="p-b-20 fw-800">{{ availableMessages }}</h3>

            <p>shared message types</p>
            <h3 class="p-b-20 fw-800">{{ availableMessages }}</h3>

            <p>Number of stores</p>
            <h3 class="p-b-20 fw-800">{{ numberOfStores }}</h3>
          </div>
          <div class="width-50">
            <p>Contact details</p>
            <div class="flex fx-ai-center fx-row m-10">
              <img
                width="30"
                height="30"
                src="./assets/icons/local_phone.svg"
                alt="local_phone"
              />
              <span class="subTitle m-l-20 fx-ai-center flex fw-500">{{
                tradingPartner.contact?.telNo
              }}</span>
            </div>
            <div class="flex fx-ai-center fx-row m-10">
              <img
                width="30"
                height="30"
                src="./assets/icons/mail.svg"
                alt="mail"
              />
              <span class="subTitle m-l-20 fx-ai-center flex fw-500">{{
                tradingPartner.contact?.emailAddress
              }}</span>
            </div>
            <div class="flex fx-row m-10">
              <img
                width="30"
                height="30"
                src="./assets/icons/map.svg"
                alt="map"
              />
              <div class="pmAddress m-l-20 subTitle flex">
                <h4>{{ postalAddress?.line1 }}</h4>
                <h4>{{ postalAddress?.line2 }}</h4>
                <h4>{{ postalAddress?.line3 }}</h4>
                <h4>{{ postalAddress?.line4 }}</h4>
                <h4>{{ postalAddress?.postalCode }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="" class="flex width-20 pmMap">
          <img src="./assets/images/branchesMap.svg" alt="branchesMap" />
          <button
            matRipple
            (click)="connectExisting()"
            class="m-l-auto width-50 m-t-50 primaryBtn selectedItem round p-10 fx-ai-center fw-800 flex"
          >
            Connect me
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
