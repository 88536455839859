import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import _ from "underscore";
import { NavigationService } from "../../../../services/navigation.service";
import { TradingPartner } from "./../../../../models/types";
import { TradingPartnerService } from "./../../../../services/trading-partner.service";

@Component({
  selector: "app-trading-partner-details",
  templateUrl: "./trading-partner-details.component.html",
  styleUrls: ["./trading-partner-details.component.sass"],
})
export class TradingPartnerDetailsComponent {
  tradingPartnerGln = "";
  currentRetailer: TradingPartner;
  retailer: TradingPartner;
  breadCrumbs: any[] = [];
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private navigationService: NavigationService,
    private location: Location,
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe(() => {
      this.init(); // not using component init
    });
  }

  init(): void {
    this.getRetailer();
    this.breadCrumbs = this.tradingPartnerService.tradingPartnerBreadcrumb;
  }

  getRetailer() {
    const gln = this.navigationService.getQueryStringValue("gln");

    const subscription = this.tradingPartnerService
      .getTradingPartner(gln)
      .subscribe((x) => {
        const tradingPartner = x as TradingPartner;

        this.tradingPartnerService.selectedTradingPartner.next(tradingPartner);
        _.isEqual(
          this.tradingPartnerService.tradingPartnerBreadcrumb[
            this.tradingPartnerService.tradingPartnerBreadcrumb.length - 1
          ]?.tradingPartner.gln,
          tradingPartner.gln,
        )
          ? ""
          : this.tradingPartnerService.tradingPartnerBreadcrumb.push({
              tradingPartner: tradingPartner,
              index:
                this.tradingPartnerService.tradingPartnerBreadcrumb.length + 1,
            });

        this.retailer = tradingPartner;
        this.currentRetailer = this.retailer;
        this.tradingPartnerGln = this.currentRetailer.gln;

        subscription.unsubscribe();
      });
  }

  goBack() {
    this.navigationService.navigateToTradingPartner();
    this.tradingPartnerService.tradingPartnerBreadcrumb = [];
  }

  goToCrumb(crumb: any) {
    this.tradingPartnerService.tradingPartnerBreadcrumb.splice(
      crumb.index,
      this.tradingPartnerService.tradingPartnerBreadcrumb.length,
    );

    this.tradingPartnerService.selectedTradingPartner.next(
      crumb.tradingPartner,
    );
    this.navigationService.navigateToTradingPartnerDetails(
      (crumb.tradingPartner as TradingPartner).gln,
    );
  }
}
