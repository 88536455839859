import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EditType, TradingPartnerSummary } from "../../../../models/types";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

const ELEMENT_DATA: TradingPartnerSummary[] = [];
@Component({
  selector: "app-assign-suppliers",
  templateUrl: "./assign-suppliers.component.html",
  styleUrls: ["./assign-suppliers.component.sass"],
})
export class AssignSuppliersComponent implements OnInit {
  @Input() manageUserType: EditType;
  @Input() alreadySelectedSuppliers: TradingPartnerSummary[];
  @Output() selectedSuppliers: EventEmitter<TradingPartnerSummary[]> =
    new EventEmitter();
  displayedColumns: string[] = ["name", "select"];
  dataSource = new MatTableDataSource<TradingPartnerSummary>(ELEMENT_DATA);
  selection = new SelectionModel<TradingPartnerSummary>(true, []);
  loader = false;
  enableButtons = false;
  suppliers: TradingPartnerSummary[];
  allSuppliers: TradingPartnerSummary[];
  cancelBtnText = "Cancel";
  searchText: string;
  assignForm: UntypedFormGroup;
  searchToggle = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.setupInputControls();
    this.getSuppliers();
    this.dataSource.paginator = this.paginator;
  }

  preselectSuppliers() {
    if (this.alreadySelectedSuppliers) {
      if (this.manageUserType == EditType.view) {
        this.dataSource.data = this.alreadySelectedSuppliers;
      } else {
        this.alreadySelectedSuppliers.forEach((supplier) => {
          this.dataSource.data.forEach((row) =>
            row.gln == supplier.gln ? this.selection.select(row) : "",
          );
        });
      }
    }
  }

  setupInputControls() {
    this.assignForm = new UntypedFormGroup({
      searchText: new UntypedFormControl("", null),
    });
  }

  enableSearch() {
    this.searchToggle = true;
  }

  search() {
    this.searchText = this.assignForm.controls.searchText.value.trim();
    this.suppliers = this.allSuppliers.filter((supplier) =>
      supplier.name.toLowerCase().includes(this.searchText),
    );
    this.dataSource.data = this.suppliers;
    this.dataSource.paginator = this.paginator;
  }

  getSuppliers() {
    this.loader = true;
    this.tradingPartnerService.getSuppliers().subscribe(
      (suppliers) => {
        if (!suppliers) return;
        this.suppliers = suppliers;
        this.allSuppliers = this.suppliers;
        this.dataSource.data = this.suppliers;
        this.dataSource.paginator = this.paginator;
        this.preselectSuppliers();
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.notificationService.showError(error);
      },
    );
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.selection.selected.length > 0
      ? (this.enableButtons = true)
      : (this.enableButtons = false);
    this.selectedSuppliers.emit(this.selection.selected);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectedTradingPartners(row: TradingPartnerSummary) {
    this.selection.isSelected(row)
      ? this.selection.deselect(row)
      : this.selection.select(row);

    this.selection.selected.length > 0
      ? (this.enableButtons = true)
      : (this.enableButtons = false);

    this.selectedSuppliers.emit(this.selection.selected);
  }

  supplierIsSelected(): boolean {
    return this.selection.selected.length > 0 ? true : false;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TradingPartnerSummary): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }

    return `${this.selection.isSelected(row) ? "deselect" : "select"} ${
      row.name
    }`;
  }

  assignSuppliers() {
    this.selectedSuppliers.emit(this.selection.selected);
  }
}
