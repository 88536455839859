import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { TradingPartner, TradingProcess } from "./../../../../../models/types";
import { NotificationService } from "./../../../../../services/notification.service";
import { TradingPartnerService } from "./../../../../../services/trading-partner.service";
@Component({
  selector: "app-trading-process",
  templateUrl: "./trading-process.component.html",
  styleUrls: ["./trading-process.component.sass"],
})
export class TradingProcessComponent implements OnInit {
  tradingProcessForm: UntypedFormGroup;
  enable = false;
  editingTP = false;
  loader = false;
  tradingProcess: TradingProcess;
  @Input() retailer: TradingPartner;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.InitialiseForm();
    this.setDefaults();
  }

  enableEdit() {
    this.enable = !this.enable;
    this.setDefaults();
  }

  InitialiseForm() {
    this.tradingProcessForm = this.formBuilder.group({
      acknowledgeOrder: [""],
      confirmOrder: [""],
      editOrderLines: [""],
      invoiceOrder: [""],
      rejectOrder: [""],
      sendAcknowledgement: [""],
      sendConfirmation: [""],
      sendInvoice: [""],
      sendRejection: [""],
    });
  }

  setDefaults() {
    this.loader = true;
    let TP; // = this.retailer.tradingProcess;

    const subscription: Subscription = this.tradingPartnerService
      .getTradingPartner(this.retailer.gln)
      .subscribe(
        (tp) => {
          if (tp) {
            TP = tp.tradingProcess;
            this.loader = false;
            if (TP) {
              this.tradingProcessForm.controls.acknowledgeOrder.setValue(
                TP.acknowledgeOrder,
              );
              this.tradingProcessForm.controls.confirmOrder.setValue(
                TP.confirmOrder,
              );
              this.tradingProcessForm.controls.editOrderLines.setValue(
                TP.editOrderLines,
              );
              this.tradingProcessForm.controls.invoiceOrder.setValue(
                TP.invoiceOrder,
              );
              this.tradingProcessForm.controls.rejectOrder.setValue(
                TP.rejectOrder,
              );
              this.tradingProcessForm.controls.sendAcknowledgement.setValue(
                TP.sendAcknowledgement,
              );
              this.tradingProcessForm.controls.sendConfirmation.setValue(
                TP.sendConfirmation,
              );
              this.tradingProcessForm.controls.sendInvoice.setValue(
                TP.sendInvoice,
              );
              this.tradingProcessForm.controls.sendRejection.setValue(
                TP.sendRejection,
              );
            }
            subscription.unsubscribe();
          }
        },
        (error) => {
          this.notification.showError(error);
          this.loader = false;
        },
      );
  }

  getCapturedTradingProcess(): TradingProcess {
    const tradingProcess: TradingProcess = {
      acknowledgeOrder: this.tradingProcessForm.controls.acknowledgeOrder.value,
      editOrderLines: this.tradingProcessForm.controls.editOrderLines.value,
      sendAcknowledgement:
        this.tradingProcessForm.controls.sendAcknowledgement.value,
      confirmOrder: this.tradingProcessForm.controls.confirmOrder.value,
      sendConfirmation: this.tradingProcessForm.controls.sendConfirmation.value,
      rejectOrder: this.tradingProcessForm.controls.rejectOrder.value,
      sendRejection: this.tradingProcessForm.controls.sendRejection.value,
      invoiceOrder: this.tradingProcessForm.controls.invoiceOrder.value,
      sendInvoice: this.tradingProcessForm.controls.sendInvoice.value,
    };
    return tradingProcess;
  }

  save() {
    const tradingPartner = {} as TradingPartner;
    tradingPartner.gln = this.retailer.gln;
    tradingPartner.tradingProcess = this.getCapturedTradingProcess();

    this.tradingPartnerService.editTradingPartner(tradingPartner).subscribe(
      (res) => {
        if (res) {
          this.notification.showSuccess("Trading Process Succefully Edited");
          this.enable = false;
          this.editingTP = false;
          this.tradingPartnerService.tradingPartnerBreadcrumb[
            this.tradingPartnerService.tradingPartnerBreadcrumb.length - 1
          ].tradingPartner = res;
        }
      },
      () => {
        this.editingTP = false;
      },
    );
  }

  cancel() {
    this.enable = false;
    this.setDefaults();
  }
}
