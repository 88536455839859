<app-nav-menu></app-nav-menu>
<mat-sidenav-container autosize class="height m-b-50">
  <mat-sidenav mode="side" id="sideNavBar" opened>
    <app-side-menu></app-side-menu>
  </mat-sidenav>
  <mat-sidenav-content appScroll class="white grayBG m-b-70">
    <div
      class="m-20"
      [ngStyle]="{ bottom: moveHelpButton() + adjustOnMoreInfo() + 'px' }"
      style="position: fixed; right: 20px; z-index: 10"
    >
      <app-help
        *ngIf="!isAdmin()"
        [inSRF]="inSRF"
        [loggedIn]="loggedIn"
        [contactNumbers]="getPhoneNumber()"
        [contactEmail]="getEmail()"
      ></app-help>
    </div>
    <router-outlet class="m-b-50"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
