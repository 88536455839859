<!-- <app-nav-menu></app-nav-menu> -->
<!-- <div class="p-30"></div> -->
<div class="flex center-item height fx-row noOverFlow">
  <div class="flex loginBg p-30 fx-jc-center height-100 width-60 artBG">
    <div class="flex height-50">
      <img width="250" src="./assets/icons/Vodacom_fin_services.svg" />
    </div>
    <div class="flex height-100">
      <img
        class="artmin-width"
        width="500"
        src="./assets/images/undraw_authentication.svg"
      />
    </div>
    <div class="flex">
      <p class="loginCol p-l subheadingTxt">
        © 2020 Vodacom Financial Services
      </p>
    </div>
    <!-- <img 
        class="artmin-width flex fx-ai-end" width="500" src='{{artworkpath+artwork}}'> -->
  </div>
  <div class="width-100 scroll p-30 flex fx-row whiteBG">
    <div class="flex center-item fx-jc-center width-30 min-width">
      <div>
        <h3 class="flex p-b-20 logintxt fx-jc-center fx-ai-center">
          Change password
        </h3>
        <form
          class="center-item flex fx-jc-center fx-ai-center"
          [formGroup]="changePasswordForm"
        >
          <mat-form-field
            *ngIf="showOtp()"
            class="width-90 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <mat-label>OTP</mat-label>
            <input
              matInput
              placeholder="otp"
              formControlName="OTP"
              pattern="^[0-9]+([0-9]+)?$"
            />
            <mat-error
              *ngIf="changePasswordForm.controls.OTP.hasError('required')"
            >
              OTP is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="changePasswordForm.controls.OTP.hasError('pattern')"
            >
              numeric values <strong>only</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="showOldPassword()"
            class="width-90 m-t-20 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <mat-label>Old Password</mat-label>
            <input
              matInput
              placeholder="enter old password"
              formControlName="oldPassword"
              [type]="oldpasswordToggle ? 'password' : 'text'"
            />
            <!-- <mat-error
                        *ngIf="changePasswordForm.controls.newPassword.hasError('required')">
                        New Password is <strong>required</strong>
                    </mat-error> -->
            <mat-icon
              matSuffix
              *ngIf="oldpasswordToggle"
              (click)="oldpasswordToggle = !oldpasswordToggle"
              >visibility</mat-icon
            >
            <mat-icon
              matSuffix
              *ngIf="!oldpasswordToggle"
              (click)="oldpasswordToggle = !oldpasswordToggle"
              >visibility_off</mat-icon
            >
          </mat-form-field>

          <mat-form-field
            class="width-90 m-t-20 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <mat-label>New Password</mat-label>
            <input
              matInput
              placeholder="enter password to login"
              formControlName="newPassword"
              [type]="passwordToggle ? 'password' : 'text'"
            />
            <!-- <mat-error
                        *ngIf="changePasswordForm.controls.newPassword.hasError('required')">
                        New Password is <strong>required</strong>
                    </mat-error> -->
            <mat-icon
              matSuffix
              *ngIf="passwordToggle"
              (click)="passwordToggle = !passwordToggle"
              >visibility</mat-icon
            >
            <mat-icon
              matSuffix
              *ngIf="!passwordToggle"
              (click)="passwordToggle = !passwordToggle"
              >visibility_off</mat-icon
            >
          </mat-form-field>

          <mat-form-field
            class="width-90 m-t-20 .s-card-t round fieldColor p-r-30 p-l-30"
          >
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              placeholder="enter password to login"
              formControlName="confirmPassword"
              [type]="confirmToggle ? 'password' : 'text'"
            />
            <!-- <mat-error
                        *ngIf="changePasswordForm.controls.confirmPassword.hasError('required')">
                        Confirm Password is <strong>required</strong>
                    </mat-error>
                     <mat-error *ngIf="!passwordMatch">
                        Confirm Password <strong>does not match</strong> New password
                    </mat-error> -->
            <mat-icon
              matSuffix
              *ngIf="confirmToggle"
              (click)="confirmToggle = !confirmToggle"
              >visibility</mat-icon
            >
            <mat-icon
              matSuffix
              *ngIf="!confirmToggle"
              (click)="confirmToggle = !confirmToggle"
              >visibility_off</mat-icon
            >
          </mat-form-field>

          <div class="flex fx-ai-center fx-jc-center m-t-60 width-80">
            <button
              mat-flat-button
              class="primaryBtn width-100 round"
              [disabled]="!this.changePasswordForm.valid"
              (click)="submit()"
            >
              Change password
            </button>
          </div>

          <div class="bottomLinks m-t-20">
            <a mat-flat routerLink="." (click)="cancel()" class="m-0 fw-300"
              >Cancel?
            </a>
          </div>

          <div class="passwordConditions width-80">
            <p style="text-align: center">
              Please note your password must contain
            </p>
            <div
              class="flex fx-row p-t-45"
              style="justify-content: space-between"
            >
              <div class="flex fx-jc-start">
                <div
                  class="flex m-b-15 fx-row fx-ai-center fx-jc-censter passwordRules"
                >
                  <div
                    [ngClass]="{ 'bgRed ': oneNumber }"
                    class="flex indicator"
                  ></div>
                  <p class="flex lightGray p-l-10 noMargin">One number</p>
                </div>
                <div
                  class="flex m-b-15 fx-row fx-ai-center fx-jc-center passwordRules"
                >
                  <div
                    [ngClass]="{ 'bgRed ': oneUppercaseCharacter }"
                    class="flex indicator"
                  ></div>
                  <p class="flex lightGray p-l-10 noMargin">
                    One uppercase character
                  </p>
                </div>
                <div
                  class="flex fx-row fx-ai-center fx-jc-center passwordRules"
                >
                  <div
                    [ngClass]="{ 'bgRed ': oneLowercaseCharacter }"
                    class="flex indicator"
                  ></div>
                  <p class="flex lightGray p-l-10 noMargin">
                    One lowercase character
                  </p>
                </div>
              </div>
              <div class="flex fx-jc-start">
                <div
                  class="flex m-b-15 fx-row fx-ai-center fx-jc-censter passwordRules"
                >
                  <div
                    [ngClass]="{ 'bgRed ': oneSpecialCharacter }"
                    class="flex indicator"
                  ></div>
                  <p class="flex lightGray p-l-10 noMargin">
                    One special character
                  </p>
                </div>
                <div
                  class="flex fx-row m-b-15 fx-ai-center fx-jc-center passwordRules"
                >
                  <div
                    [ngClass]="{ 'bgRed ': eightCharactersMinimum }"
                    class="flex indicator"
                  ></div>
                  <p class="flex lightGray p-l-10 noMargin">
                    Eight characters minimum
                  </p>
                </div>
                <div
                  class="flex fx-row fx-ai-center fx-jc-censter passwordRules"
                >
                  <div
                    [ngClass]="{ 'bgRed ': passwordMatch }"
                    class="flex indicator"
                  ></div>
                  <p class="flex lightGray p-l-10 noMargin">
                    Passwords must match!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
