import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Partner, TradingPartner } from "../../../../models/types";
import { NotificationService } from "../../../../services/notification.service";
import { PartnerMarketPlaceService } from "../../../../services/partner-market-place.service";

@Component({
  selector: "app-connect-partner",
  templateUrl: "./connect-partner.component.html",
  styleUrls: ["./connect-partner.component.sass"],
})
export class ConnectPartnerComponent implements OnInit {
  dialogType: string;
  nameLabel = "Na";
  businessTypeLabel = "Business type";
  connectParterForm: UntypedFormGroup;
  retailers = ["Food & Beverage"];
  tradePartner: TradingPartner;
  connectType: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConnectPartnerComponent>,
    private partnerMarketPlaceService: PartnerMarketPlaceService,
    private notificationService: NotificationService,

    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.dialogType = data.dialogType;
    this.tradePartner = data.tradePartner;
    this.connectType = data.connectType;
  }

  ngOnInit(): void {
    this.dialogType === "connectPartner"
      ? (this.nameLabel = "Name and Surname")
      : (this.nameLabel = "Business address");
    this.dialogType === "connectPartner"
      ? (this.businessTypeLabel = "Business type")
      : (this.businessTypeLabel = "Request");

    this.setupFormGroup();

    if (this.connectType == "myPartner") {
      this.prepopulatePartner();
    }
  }

  prepopulatePartner() {
    this.connectParterForm.controls.name.setValue(this.tradePartner.name);
    this.connectParterForm.controls.email.setValue(
      this.tradePartner.contact?.emailAddress,
    );
    this.connectParterForm.controls.contactNumber.setValue(
      this.tradePartner.contact.telNo,
    );

    this.connectParterForm.controls.name.disable();
    this.connectParterForm.controls.email.disable();
    this.connectParterForm.controls.contactNumber.disable();
  }

  setupFormGroup() {
    this.connectParterForm = this.formBuilder.group({
      businessAddress: ["", null],
      businessType: ["", Validators.required],
      contactNumber: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      name: ["", [Validators.required]],
    });
  }

  getCapturedData(): Partner {
    const partner: Partner = {
      nameSurname: this.connectParterForm.controls.name.value,
      businessType: this.connectParterForm.controls.businessType.value,
      contactNumber: this.connectParterForm.controls.contactNumber.value,
      emailAddress: this.connectParterForm.controls.email.value,
      tradingPartnerGln: this.tradePartner.gln,
    };

    return partner;
  }

  isValidForm() {
    if (!this.connectParterForm.valid) {
      return false;
    }

    if (
      this.connectParterForm.controls.contactNumber.value.length < 10 ||
      this.connectParterForm.controls.contactNumber.value.length > 10
    ) {
      return false;
    }

    return true;
  }

  submit(): void {
    const connetPartner = this.getCapturedData();
    this.partnerMarketPlaceService
      .connect(this.tradePartner.gln, connetPartner)
      .subscribe(() => {
        this.notificationService.showSuccess("Connection request sent");
      });

    this.close(true);
  }

  close(action): void {
    this.dialogRef.close(action);
  }
}
