import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.sass"],
})
export class TermsAndConditionsComponent implements OnInit {
  termsTitle: string;
  termsBody: string;
  constructor(public dialogRef: MatDialogRef<TermsAndConditionsComponent>) {}

  ngOnInit(): void {
    this.termsTitle = "Terms of use";
    this.termsBody =
      "This system is for the use of authorised users only in accordance with Vodafone security policies and procedures. Individuals using this device without authorisation or in excess of their authority are subject to sanctioning procedures by Vodafone authorities and/or law enforcement officials. Vodafone will not be responsible for any misuse or personal use of any kind, in its information systems, and reserves the right for monitoring systems usage to control abusive situations or security policy violations.";
  }

  close() {
    this.dialogRef.close();
  }
}
