import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Session } from "./../../../../models/session";
import { NotificationType } from "./../../../../models/types";
import { AuthService } from "./../../../../services/auth.service";
import { NotificationService } from "./../../../../services/notification.service";

@Component({
  selector: "app-edit-notifications",
  templateUrl: "./edit-notifications.component.html",
  styleUrls: ["./edit-notifications.component.sass"],
})
export class EditNotificationsComponent implements OnInit {
  editNotificationsForm: UntypedFormGroup;
  notificationType: NotificationType;
  notifications = ["Email", "Sms"];
  title: string;
  constructor(
    private dialogRef: MatDialogRef<EditNotificationsComponent>,
    private authService: AuthService,
    private notificationService: NotificationService,
    private session: Session,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.title = data.title;
    this.notificationType = data.notificationType;
  }

  ngOnInit(): void {
    this.setupInputs();
    this.prepopulate();
  }

  setupInputs() {
    this.editNotificationsForm = new UntypedFormGroup({
      notifications: new UntypedFormControl("", Validators.required),
    });
  }

  prepopulate() {
    this.editNotificationsForm.controls.notifications.setValue(
      this.notificationType,
    );
  }

  cancel() {
    this.dialogRef.close(false);
  }

  captureData() {
    return this.editNotificationsForm.controls.notifications.value;
  }

  submit() {
    const notType = this.captureData();
    this.authService.setNotificationPreference(notType).subscribe(
      () => {
        this.notificationService.showSuccess(
          "Notification type successfully changed to " + notType,
        );
      },
      (error) => {
        this.notificationService.showError(error);
      },
    );
    this.dialogRef.close(true);
  }
}
