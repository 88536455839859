import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { FinanceProduct } from "../models/financeProduct";
import {
  Invoice,
  InvoiceAnalyticsResponse,
  SearchInvoicesResponse,
} from "../models/invoice";
import { Session } from "../models/session";
import { SrfApplication } from "../models/srfApplication";
import { newClient } from "../services/api-gateway.service.js";
import { FinanceRepository } from "./finance.repository";

const apigClient = newClient();

@Injectable()
export class AwsFinanceRepository implements FinanceRepository {
  constructor(private session: Session) {}

  getProducts(svGln: string): Observable<FinanceProduct[]> {
    const retObject = new Subject<FinanceProduct[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeGet({}, undefined, additionalParams)
        .then((result) => {
          // Success
          const financeProductResponse = result.data as FinanceProduct[];
          retObject.next(financeProductResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  processApplication(
    svGln: string,
    srfApplication: SrfApplication,
  ): Observable<string> {
    const retObject = new Subject<string>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeSrfPost({}, srfApplication, additionalParams)
        .then((result) => {
          // Success
          const processApplicationResponse = result.data;
          retObject.next(processApplicationResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  searchInvoices(
    svGln: string,
    dateFrom: Date,
    dateTo: Date,
    activeRetailers: string[],
    take: number,
    skip: number,
  ): Observable<SearchInvoicesResponse> {
    const retObject = new Subject<SearchInvoicesResponse>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateTo: dateTo,
          dateFrom: dateFrom,
          activeRetailers: activeRetailers,
          take: take,
          skip: skip,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeInvoiceGet({}, undefined, additionalParams)
        .then((result) => {
          // Success
          const searchInvoicesResponse = new SearchInvoicesResponse();
          searchInvoicesResponse.invoiceData = result.data.invoiceData;
          searchInvoicesResponse.invoiceCount = result.data.invoiceCount;

          retObject.next(searchInvoicesResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  financeInvoices(svGln: string, invoices: Invoice[]): Observable<string> {
    const retObject = new Subject<string>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const params = {
        invoices: invoices,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeInvoiceFinancePost({}, params, additionalParams)
        .then((result) => {
          // Success
          const processApplicationResponse = result.data;
          retObject.next(processApplicationResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  getInvoicesPendingAnalytics(
    svGln: string,
    dateFrom: Date,
    dateTo: Date,
  ): Observable<InvoiceAnalyticsResponse[]> {
    const retObject = new Subject<InvoiceAnalyticsResponse[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateTo: dateTo,
          dateFrom: dateFrom,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeInvoiceAnalyticsPendingGet({}, undefined, additionalParams)
        .then((result) => {
          // Success
          const processApplicationResponse = result.data;
          retObject.next(processApplicationResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  getInvoicesRejectedAnalytics(
    svGln: string,
    dateFrom: Date,
    dateTo: Date,
  ): Observable<InvoiceAnalyticsResponse[]> {
    const retObject = new Subject<InvoiceAnalyticsResponse[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateTo: dateTo,
          dateFrom: dateFrom,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeInvoiceAnalyticsRejectedGet({}, undefined, additionalParams)
        .then((result) => {
          // Success
          const processApplicationResponse = result.data;
          retObject.next(processApplicationResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  getInvoicesSoldAnalytics(
    svGln: string,
    dateFrom: Date,
    dateTo: Date,
  ): Observable<InvoiceAnalyticsResponse[]> {
    const retObject = new Subject<InvoiceAnalyticsResponse[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateTo: dateTo,
          dateFrom: dateFrom,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .financeInvoiceAnalyticsSoldGet({}, undefined, additionalParams)
        .then((result) => {
          // Success
          const processApplicationResponse = result.data;
          retObject.next(processApplicationResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }
}
