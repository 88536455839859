<div class="tradeCard p-20">
  <div class="totalOrdersCard">
    <div class="totalOrders">
      <div class="flex p-l-20">
        <p class="fx-ai-center fx-jc-center subHeading">Total Orders</p>

        <div class="flex w fx-row">
          <div class="flex width-95 fx-jc-center selectedItem">
            <div
              matRipple
              *ngFor="let status of statuses; let i = index"
              class="selectedItem fx-row m-b-10 fx-ai-center flex totalOrderStatus"
              (click)="selectedValue(status, i)"
            >
              <div
                class="flex m-r-10"
                style="width: 20px; height: 20px"
                [ngStyle]="{ 'background-color': graphColors[status] }"
              ></div>
              <p
                class="flex noMargin fx-jc-center"
                [ngClass]="{ 'fw-600': selectedStatus === status }"
              >
                {{ status }}
              </p>
              <img
                class="icon flex"
                src="./assets/icons/rightArrowBlack.svg"
                style="justify-self: end"
              />
            </div>
          </div>

          <!-- pie chart -->
          <div class="flex width-95 p-r-20 p-l-20 fx-jc-end fx-ai-center">
            <div
              class="flex fx-jc-center fx-ai-center"
              style="position: relative"
            >
              <canvas class="flex" id="canvas"></canvas>
              <div
                *ngIf="!loadOrder"
                style="position: absolute"
                class="flex fx-jc-center fx-ai-center"
              >
                <h1 class="orderCountNo noMargin fw-500 p-5">
                  {{ orders === null ? 0 : orders.length }}
                </h1>
                <h3 class="orderCountTxt noMargin fw-400">
                  {{ "order" }}{{ orders.length === 1 ? "" : "s" }}
                </h3>
              </div>
              <div *ngIf="loadOrder" style="position: absolute">
                <mat-spinner></mat-spinner>
              </div>
            </div>
          </div>

          <div class="flex fx-jc-center nowrap">
            <div
              class="p-l-10 flex height-90 noMargin fx-jc-center fx-ai-center"
              [ngStyle]="borderStyle()"
            >
              <div class="flex height-90">
                <p class="statusHeader">{{ selectedStatus }} Orders</p>

                <p class="noMargin totalSubHeading">{{ orderNumber }}</p>
                <p class="p-status">{{ selectedStatus }} Order</p>

                <p class="noMargin totalSubHeading">{{ totalOrdersForType }}</p>
                <p class="p-l">Order Value</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="newOrdersPerRetailer">
      <div class="width-100 p-l-30 bl-l-l flex height-100">
        <p
          class="fw-800 fx-ai-center fx-jc-center subHeading"
          *ngIf="availableToDisplay()"
        >
          {{ selectedStatus }} Orders Per Retailer
        </p>
        <p *ngIf="!availableToDisplay()">
          No orders in the <strong>{{ selectedStatus }}</strong> status
        </p>
        <div class="tradingPartnerItems">
          <div
            *ngIf="loadOrder"
            class="animate__loadingFlash animate__animated animate__loadingSlow animate__infinite width-100 flex height-90 flex fx-jc-center"
          >
            <div class="width-100 flex fx-row fx-jc-center">
              <div class="loadBar height-5 p-5 m-5 width-5"></div>
              <div class="loadBar height-5 p-10 m-5 width-30"></div>
            </div>
          </div>
          <div
            *ngFor="let item of tradePartnerList"
            (click)="goToOrders(item)"
            class="selectedItem flex fx-jc-center fx-row fx-ai-center m-t-10 p-t-10 fx-w"
          >
            <h2 class="fw-800 p-t-15 p-r-10">{{ item.count }}</h2>
            <div class="bl-l-l imageLine p-r-10"></div>
            <div>
              <div
                *ngIf="(item.tradingPartner | async) === false"
                class="loader flex fx-jc-center fx-ai-center"
              >
                <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
              </div>
              <img
                class="medium-image"
                src="{{ (item.tradingPartner | async)?.imageURL }}"
                alt="{{ (item.tradingPartner | async)?.name }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
