import { Injectable, OnChanges } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FinanceProduct } from "../models/financeProduct";
import {
  Invoice,
  InvoiceAnalyticsResponse,
  SearchInvoicesResponse,
} from "../models/invoice";
import { Session } from "../models/session";
import { SrfApplication, SrfApplicationStatus } from "../models/srfApplication";
import { FinanceRepository } from "../repositories/finance.repository";
import { RepositoryFactory } from "../repositories/repository.factory";

@Injectable({
  providedIn: "root",
})
export class FinanceService implements OnChanges {
  private financeRepo: FinanceRepository;

  returnedStatus: BehaviorSubject<string> = new BehaviorSubject<string>(
    SrfApplicationStatus.InProgress,
  );
  srfApplicationStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.financeRepo = repoFactory.getFinanceRepository();
  }
  ngOnChanges(): void {
    console.log("FINANCE SERVICE CHANGES HAPPENED");
    this.getFinanceApplicationStatus();
  }

  public getInvoicesAvailableForFinancing(
    dateFrom: Date,
    dateTo: Date,
    activeRetailers: string[],
    take: number,
    skip: number,
  ): Observable<SearchInvoicesResponse> {
    return this.financeRepo.searchInvoices(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
      activeRetailers,
      take,
      skip,
    );
  }

  public getCompanyTypes(): Observable<string[]> {
    const companyTypes: BehaviorSubject<string[]> = new BehaviorSubject<
      string[]
    >(null);
    companyTypes.next([
      "Sole proprietorship",
      "Partnership",
      "Company",
      "Close corporation",
      "Non-profit organisation",
      "Trust",
    ]);
    return companyTypes;
  }

  public getBusinessTypes(): Observable<string[]> {
    const businessTypes: BehaviorSubject<string[]> = new BehaviorSubject<
      string[]
    >(null);
    businessTypes.next([
      "Automotive",
      "Building and Construction",
      "Financial",
      "ICT",
      "Insurance",
      "Logistics",
      "Pharmaceutical",
      "Retail",
    ]);
    return businessTypes;
  }

  public getInvoicesPending(
    dateFrom: Date,
    dateTo: Date,
  ): Observable<InvoiceAnalyticsResponse[]> {
    return this.financeRepo.getInvoicesPendingAnalytics(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
    );
  }

  public getInvoicesRejected(
    dateFrom: Date,
    dateTo: Date,
  ): Observable<InvoiceAnalyticsResponse[]> {
    return this.financeRepo.getInvoicesRejectedAnalytics(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
    );
  }

  public getInvoiceSold(
    dateFrom: Date,
    dateTo: Date,
  ): Observable<InvoiceAnalyticsResponse[]> {
    return this.financeRepo.getInvoicesSoldAnalytics(
      this.session.getSupplierGln(),
      dateFrom,
      dateTo,
    );
  }

  getFinanceApplicationStatus(): Observable<string> {
    this.getProducts().subscribe((status) => {
      if (status) {
        if (status[0]?.status) {
          console.log("getFinanceApplicationStatus getProducts STATUS", status);
          this.returnedStatus.next(status[0].status);
        } else {
          this.returnedStatus.next(null);
        }
      }
    });
    return this.returnedStatus;
  }

  getProducts(): Observable<FinanceProduct[]> {
    return this.financeRepo.getProducts(this.session.getSupplierGln());
  }

  processApplication(srfApplication: SrfApplication) {
    return this.financeRepo.processApplication(
      this.session.getSupplierGln(),
      srfApplication,
    );
  }

  financeInvoices(invoices: Invoice[]) {
    return this.financeRepo.financeInvoices(
      this.session.getSupplierGln(),
      invoices,
    );
  }
}
