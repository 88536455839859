/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const config = {
  authenticationFlowType: "USER_PASSWORD_AUTH",
  aws_project_region: process.env.NG_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: "",
  aws_cognito_region: process.env.NG_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.NG_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.NG_APP_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
};

export default config;
