import { Directive, ElementRef, HostListener } from "@angular/core";
import { NavigationService } from "./../../../services/navigation.service";
import { ProductService } from "./../../../services/product.service";

@Directive({
  selector: "[appScroll]",
})
export class ScrollDirective {
  constructor(
    el: ElementRef,
    private navigationService: NavigationService,
    private products: ProductService,
  ) {}

  @HostListener("scroll", ["$event"]) onScrollEvent($event) {
    this.navigationService.currentRoute() === "myProducts"
      ? this.products.stickyProductsHeading.next($event.target.scrollTop >= 200)
      : this.products.stickyProductsHeading.next(false);
  }
}
