import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DocumentsService } from "./../../services/documents.service";

export interface UserData {
  id: string;
  name: string;
  createdBy: string;
  type: string;
  dateCreated: Date;
}

/** Constants used to fill up our data base. */
const DATECREATED: Date[] = [
  new Date(
    2020,
    Math.round(Math.random() * 12),
    Math.round(Math.random() * 30),
  ),
  new Date(
    2020,
    Math.round(Math.random() * 12),
    Math.round(Math.random() * 30),
  ),
  new Date(
    2020,
    Math.round(Math.random() * 12),
    Math.round(Math.random() * 30),
  ),
  new Date(
    2020,
    Math.round(Math.random() * 12),
    Math.round(Math.random() * 30),
  ),
  new Date(
    2020,
    Math.round(Math.random() * 12),
    Math.round(Math.random() * 30),
  ),
];

const CREATEDBY: string[] = [
  "Leanne Graham",
  "Ervin Howell",
  "Clementine Bauch",
  "Patricia Lebsack",
  "Chelsey Dietrich",
  "Mrs. Dennis Schulist",
  "Kurtis Weissnat",
  "Nicholas Runolfsdottir V",
  "Glenna Reichert",
  "Clementina DuBuque",
];
const TYPE: string[] = ["txt", "exel", "doc", "pdf", "xml"];
const NAMES: string[] = [
  "3G MOBILE(6004930005245)",
  "ABSA(6004930004156)",
  "ACKERMANS 2(6004930006488)",
  "ACKERMANS TRADING GATEWAY(ACKERMANSTG)",
  "ACT(6004930019983)",
  "ADCOCK INGRAM HEALTH(6003252084327)",
  "ADCOCK INGRAM PHARMA(6004930010041)",
  "ADCOCK INGRAM S-C(6001206428890)",
  "ADCOCK INGRAM(6001319000013)",
  "ADVACOS(6004930008711)",
  "AFRI BERRY (SBT)(6009900331003)",
  "AFRICA SALES(6006239000006)",
  "AFRICAN EXTRACTS(6004930005085)",
  "AFRISAM(6004930021481)",
  "ALDABRISNOOKUMS(6001911039473)",
  "ALL JOY FOODS(6004930005405)",
  "ALPHA PHARM(6004930000554)",
  "AMKA PRODUCTS(6001374000010)",
  "AMPATH TRUST 1(6004930005177)",
  "ANALOG DEVICES(ANALOGDEVICES)",
  "ANCHOR YEAST XML(ANCHORYEAST01)",
  "ANCHOR YEAST(6001323000009)",
  "AQUACHLOR(6001385000009)",
  "ARROW ALTECH(6004930002680)",
  "ARROW EUROPE(ARROWEU)",
  "ASCO(SBT)(6006239199687)",
  "ASSUPOL(6004930008857)",
  "AVI - NBL(6001156000009)",
  "AVID BRANDS(6004930008468)",
  "AWESOME CARE TRIND(6004930006761)",
  "BAD DATA(0001024474)",
  "BANKMED(6004930001742)",
  "BARRS PHARMA(6004930010546)",
  "BAUSCH AND LOMB(6003299000533)",
  "BEACON SWEETS(6001120000004)",
];

@Component({
  selector: "app-search-document",
  templateUrl: "./search-document.component.html",
  styleUrls: ["./search-document.component.sass"],
})
export class SearchDocumentComponent implements OnInit {
  found = false;
  displayedColumns: string[] = [
    "id",
    "name",
    "progress",
    "type",
    "date-created",
    "view",
  ];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  documentSearchFormControl = new UntypedFormControl();
  options: string[] = [...NAMES];
  rightDate = false;
  filteredOptions: Observable<string[]>;
  minDate: Date;
  maxDate: Date;
  selectedFromDate: Date;
  selectedToDate: Date;
  searchFormGroup: UntypedFormGroup;
  userNames: Array<string> = [];

  constructor(private documentsService: DocumentsService) {
    // this.documentsService.searchDocuments("")
    //   .subscribe(result => {
    //   }, error => {
    //   })

    // Create 100 users
    const users = Array.from({ length: 100 }, (_, k) =>
      this.createNewUser(k + 1),
    );

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(users);
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 40);
  }

  ngOnInit() {
    this.rightDate = false;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filteredOptions = this.documentSearchFormControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value)),
    );
  }

  monthName(month: number): string {
    const monthNameVar = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNameVar[month];
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }
  events: string[] = [];

  checkDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    type === "from" ? (this.selectedFromDate = event.value) : "";
    type === "to" ? (this.selectedToDate = event.value) : "";
    type === "To" ? (this.minDate = this.selectedFromDate) : "";
    if (this.selectedToDate != null && this.selectedFromDate != null) {
      this.selectedFromDate <= this.selectedToDate
        ? (this.rightDate = false)
        : (this.rightDate = true);
    }
  }

  selected_document(name: string) {
    this.dataSource.filter = name.trim().toLowerCase();
    if (this.dataSource.filter != null) {
      this.found = true;
    } else {
      this.found = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private createNewUser(id: number): UserData {
    const name =
      NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
      " " +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
      ".";

    return {
      id: id.toString(),
      name: name,
      createdBy: CREATEDBY[Math.round(Math.random() * (CREATEDBY.length - 1))],
      type: TYPE[Math.round(Math.random() * (TYPE.length - 1))],
      dateCreated: DATECREATED[Math.round(Math.random() * (TYPE.length - 1))],
    };
  }
}
