import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { TradingPartner } from "./../../../../../models/types";
import { DialogService } from "./../../../../../services/dialog.service";
import { NavigationService } from "./../../../../../services/navigation.service";
import { TradingPartnerService } from "./../../../../../services/trading-partner.service";

@Component({
  selector: "app-retailer-information",
  templateUrl: "./retailer-information.component.html",
  styleUrls: ["./retailer-information.component.sass"],
})
export class RetailerInformationComponent {
  @Output() reloadRetailer: EventEmitter<string> = new EventEmitter();
  @Input() retailer: TradingPartner;
  subscription: Subscription;
  loader = false;
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private dialog: DialogService,
    private navigate: NavigationService,
  ) {}

  getAddress() {
    return this.retailer?.addresses[0];
  }

  getNameOfContactPerson() {
    return this.retailer?.contact?.contactName?.toString();
  }

  getSurnameOfContactPerson() {
    return this.retailer?.contact?.contactSurname?.toString();
  }

  getEmailOfContactPerson() {
    return this.retailer?.contact?.emailAddress?.toString();
  }

  getTelNo() {
    return this.retailer?.contact?.telNo;
  }

  getFaxNo() {
    return this.retailer?.contact?.faxNo;
  }

  getVat() {
    return {
      vatNo: this.retailer?.vatNo,
      vatCountryCode: this.retailer?.vatCountryCode,
      vatRateCode: this.retailer?.vatRateCode,
    };
  }

  getGln() {
    return this.retailer?.gln;
  }

  editBusinessInformation() {
    const update = this.dialog.editTradingPartner(this.retailer);

    update.afterClosed().subscribe((updated) => {
      if (updated) {
        this.reloadRetailer.emit();
      }
    });
  }

  viewHistory() {
    this.navigate.navigateToChangeHistory();
  }
}
