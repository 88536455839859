import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../../../../app/services/dialog.service";
import { NotificationService } from "../../../../../app/services/notification.service";
import { UiDataService } from "../../../../../app/services/order-uidata.service";
import { ProductService } from "../../../../../app/services/product.service";
import { Product } from "../../../../models/product";
import { Contact, TradingPartner } from "../../../../models/types";

export enum action {
  add = "Add",
  edit = "Edit",
}

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.sass"],
})
export class AddProductComponent implements OnInit {
  myControl = new UntypedFormControl();
  retailers: TradingPartner[] = [];
  title = "";
  product = {} as Product;
  productCodeChanged: boolean;
  retailerChanged: boolean;

  manageProductForm: UntypedFormGroup;
  action: action;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private uiData: UiDataService,
    private productService: ProductService,
    public dialogRef: MatDialogRef<AddProductComponent>,
    public dialogService: DialogService,
    public notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.retailers = data.retailers;
    this.product = data.product ?? ({} as Product);
    this.productCodeChanged = false;
    this.retailerChanged = false;

    if (data.product) {
      this.action = action.edit;
    } else {
      this.action = action.add;
    }
  }

  disableKeyFields() {
    return this.action == action.edit;
  }

  ngOnInit(): void {
    this.setupFormGroup();
  }

  setupFormGroup() {
    this.manageProductForm = this.formBuilder.group({
      barCode: [this.product.barCode, Validators.required],
      productCode: [this.product.productCode, Validators.required],
      retailer: [this.product.retailerGln, null],
      productDescription: [
        this.product.productDescription,
        Validators.required,
      ],
      selectedFile: [null],
    });

    if (this.action == action.edit) {
      this.manageProductForm.controls.barCode.disable();
    }

    this.manageProductForm.controls.retailer.valueChanges.subscribe(() => {
      this.retailerChanged = true;
    });

    this.manageProductForm.controls.productCode.valueChanges.subscribe(() => {
      this.productCodeChanged = true;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  addProduct(): void {
    const loader = this.dialogService.loader();
    const retailer = this.manageProductForm.controls.retailer.value;
    const retailerGln = retailer == null || retailer == "all" ? "" : retailer;

    const product: Product = {
      id: null,
      retailerGln: retailerGln,
      productCode: this.manageProductForm.controls.productCode.value,
      barCode: this.manageProductForm.controls.barCode.value,
      productDescription:
        this.manageProductForm.controls.productDescription.value,
      productFilter:
        this.manageProductForm.controls.barCode.value +
        "_" +
        this.manageProductForm.controls.productCode.value,
      imageURL: "https://somePath.jpg",
    };

    if (this.action == action.add) {
      this.productService
        .search(null, null, product.barCode, retailerGln)
        .subscribe(
          (result) => {
            const global = result.find((x) => {
              return (x.retailerGln = "");
            });
            if (result.length == 0 && !global) {
              // doesn't exist
              this.productService.add(product).subscribe(
                (product) => {
                  if (product) {
                    loader.close();
                    this.dialogRef.close(true);
                  }
                },
                (error) => {
                  loader.close();
                  this.notificationService.showError(error);
                },
              );
            } else {
              loader.close();
              this.notificationService.showError(
                new Error("A product with that Barcode already exists"),
              );
            }
          },
          () => {
            loader.close();
            this.notificationService.showError(
              new Error("Something went wrong"),
            );
          },
        );
    } else {
      product.id = this.product.id;
      if (this.productCodeChanged || this.retailerChanged) {
        // update will be inserting "new" barcode_productCode anyway
        this.productService.delete(product.id);
      }
      this.productService.update(product).subscribe(
        (product) => {
          if (product) {
            loader.close();
            this.dialogRef.close(true);
          }
        },
        (error) => {
          loader.close();
          this.notificationService.showError(error);
        },
      );
    }
  }

  addAllItemsOption() {
    if (this.retailers) {
      this.retailers.push({
        gln: "all",
        name: "All",
        addresses: [],
        contact: {} as Contact,
        imageURL: "",
        vatNo: "",
        vatCountryCode: "",
        events: [],
      });
    }
  }
}
