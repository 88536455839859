import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Session } from "../../../../models/session";
import { VTEvent } from "../../../../models/types";
import { EventService } from "../../../../services/event.service";
import { NotificationService } from "../../../../services/notification.service";
import { Settings } from "../../../../settings";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
const ELEMENT_DATA: VTEvent[] = [];
@Component({
  selector: "app-activity-logs",
  templateUrl: "./activity-logs.component.html",
  styleUrls: ["./activity-logs.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ActivityLogsComponent implements OnInit {
  displayedColumns: string[] = [
    "date",
    "description",
    "username",
    "serviceRequestNumber",
  ];
  dataSource = new MatTableDataSource<VTEvent>(ELEMENT_DATA);
  dataSourceThisWeek = new MatTableDataSource<VTEvent>(ELEMENT_DATA);
  dataSourceThisMonth = new MatTableDataSource<VTEvent>(ELEMENT_DATA);
  dataSourceOlder = new MatTableDataSource<VTEvent>(ELEMENT_DATA);
  @Input() tradingPartnerGln: string;
  activityLogDateRanges: { name: string; value: string }[] = [
    {
      name: "All Dates",
      value: "All",
    },
    {
      name: "This Week",
      value: "Week",
    },
    {
      name: "This Month",
      value: "thisMonth",
    },
    {
      name: "Last Month",
      value: "lastMonth",
    },
  ];
  activityLogDescriptionTypes: string[] = [];
  activityLogUsers: string[] = [];
  private activityLogs: VTEvent[] = [];
  private filteredLogsByThisWeek: VTEvent[] = [];
  private filteredLogsByThisMonth: VTEvent[] = [];
  private filteredLogsByOlder: VTEvent[] = [];
  private filteredLogsByDateRange: VTEvent[] = [];
  private filteredActivityLogs: VTEvent[] = [];
  private filteredLogsByStatus: VTEvent[] = [];
  firstWord: string;
  restOfSentance: string;
  loader = false;
  olderThanAMonth = false;
  activityLogForm: UntypedFormGroup;
  dateFormat;
  todaysDate = new Date();
  firstDayLastMonth = new Date(
    this.todaysDate.getFullYear(),
    this.todaysDate.getMonth() - 1,
    1,
  );
  lastDayLastMonth = new Date(
    this.todaysDate.getFullYear(),
    this.todaysDate.getMonth() - 1 + 1,
    0,
  );
  firstDayThisMonth = new Date(
    this.todaysDate.getFullYear(),
    this.todaysDate.getMonth(),
    1,
  );
  lastDayThisMonth = new Date(
    this.todaysDate.getFullYear(),
    this.todaysDate.getMonth() + 1,
    0,
  );
  firstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getUTCDate() - new Date().getUTCDate() + 1,
  );
  lastDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );
  toMaxDate: Date;
  fromMinDate: Date;

  @ViewChild("weekPaginator") weeklyPaginator: MatPaginator;
  @ViewChild("monthPaginator") monthlyPaginator: MatPaginator;
  @ViewChild("olderPaginator") olderPaginator: MatPaginator;

  graphColors = {
    New: "#00B0CA",
    Acknowledged: "#9C2AA0",
    Confirmed: "#005765",
    Rejected: "#FF0000",
    Invoiced: "#BD0000",
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private eventService: EventService,
    public session: Session,
    private settings: Settings,
  ) {}

  ngOnInit(): void {
    this.getActivityLogs();
    this.setupInputControls();
    this.adjustDateFilter();
    this.dateFormat = this.settings.dateFormat;
  }

  adjustDateFilter() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 1000);
    const [startWeek, endWeek] = this.getWeekDates();
    this.filteredLogsByDateRange = [];
    this.filteredLogsByDateRange = this.activityLogs;
    this.olderThanAMonth = +this.firstDayThisMonth > +this.fromMinDate;
    this.filteredLogsByThisWeek = this.filteredLogsByDateRange
      .filter(
        (d) =>
          +new Date(d.eventTime) >= +startWeek &&
          +new Date(d.eventTime) < +endWeek,
      )
      .filter(
        (d) =>
          +new Date(d.eventTime) >= +this.fromMinDate &&
          +new Date(d.eventTime) <=
            +new Date(this.toMaxDate.setHours(23, 59, 59)),
      );

    this.filteredLogsByThisMonth = this.filteredLogsByDateRange
      .filter(
        (d) =>
          +new Date(d.eventTime) >= +this.firstDayThisMonth &&
          +new Date(d.eventTime) <= +this.lastDayThisMonth,
      )
      .filter((result) => this.filteredLogsByThisWeek.indexOf(result) < 0)
      .filter(
        (d) =>
          +new Date(d.eventTime) >= +this.fromMinDate &&
          +new Date(d.eventTime) <= +this.toMaxDate,
      );

    this.filteredLogsByOlder = this.filteredLogsByDateRange
      .filter((d) => +new Date(d.eventTime) < +this.firstDayLastMonth)
      .filter(
        (d) =>
          +new Date(d.eventTime) >= +this.fromMinDate &&
          +new Date(d.eventTime) <= +this.toMaxDate,
      );

    this.dataSourceThisWeek.data = this.filteredLogsByThisWeek.sort(
      (a, b) => +new Date(b.eventTime) - +new Date(a.eventTime),
    );
    this.dataSourceThisMonth.data = this.filteredLogsByThisMonth.sort(
      (a, b) => +new Date(b.eventTime) - +new Date(a.eventTime),
    );
    this.dataSourceOlder.data = this.filteredLogsByOlder.sort(
      (a, b) => +new Date(b.eventTime) - +new Date(a.eventTime),
    );

    this.dataSourceThisWeek.paginator = this.weeklyPaginator;
    this.dataSourceThisMonth.paginator = this.monthlyPaginator;
    this.dataSourceOlder.paginator = this.olderPaginator;
  }

  setupInputControls() {
    this.activityLogForm = this.formBuilder.group({
      searchMarketplacePartner: ["", null],
      userFllter: ["", null],
      statusFllter: [this.activityLogDescriptionTypes[0], null],
      fromDate: new UntypedFormControl([
        new Date(this.firstDayLastMonth),
        null,
      ]),
      toDate: new UntypedFormControl([
        new Date(this.lastDayThisMonth.setHours(23, 59, 59)),
        null,
      ]),
      dateRangeFllter: [this.activityLogDateRanges[0].value, null],
    });
    let newDate: Date = new Date();
    this.activityLogForm.controls.fromDate.setValue(new Date(this.firstDay));
    this.activityLogForm.controls.toDate.setValue(
      new Date(this.lastDay.setHours(23, 59, 59)),
    );
    this.fromMinDate = this.activityLogForm.controls.fromDate.value;
    this.toMaxDate = this.activityLogForm.controls.toDate.value;
    this.activityLogForm.controls.fromDate.valueChanges.subscribe((x) => {
      this.fromMinDate = x;
      this.adjustDateFilter();
    });
    this.activityLogForm.controls.toDate.valueChanges.subscribe((x) => {
      newDate = x;
      this.toMaxDate = new Date(newDate);
      this.adjustDateFilter();
    });
  }

  filterLogsByStatus(type) {
    this.filteredLogsByStatus = this.filteredLogsByDateRange;
    const tempFilteredList = this.filteredLogsByStatus;
    const value: string = type.value;
    if (value) {
      value.includes("All")
        ? (this.filteredLogsByStatus = tempFilteredList)
        : (this.filteredLogsByStatus = this.filteredLogsByStatus.filter((log) =>
            log.description.toLowerCase().includes(value.toLowerCase()),
          ));
      this.searchLogs(this.activityLogForm.controls.userFllter.value);
      this.dataSource.data = this.filteredLogsByStatus;
    }
  }

  getFormatedDate(date: string): string {
    return `
    ${
      new Date(date).getUTCDate().toString().length > 1
        ? new Date(date).getUTCDate().toString()
        : "0" + new Date(date).getUTCDate().toString()
    } 
    ${new Date(date).toLocaleDateString("default", { month: "long" })} 
    ${new Date(date).getFullYear().toString()} 
    at ${new Date(date).toLocaleTimeString()}
    `;
  }

  getRestOfSentance(description: string): string {
    const str = description;
    const ret = str.split(" ");
    let str1 = "";
    for (let i = 0; i < ret.length; i++) {
      str1 += i > 0 ? " " + ret[i] : " ";
    }

    return str1;
  }

  getFirstWord(description: string): string {
    const str = description;
    const ret = str.split(" ");
    return ret[0];
  }

  search() {
    this.searchLogs(this.activityLogForm.controls.userFllter.value);
  }

  clearSearch() {
    this.activityLogForm.controls.userFllter.value == ""
      ? this.searchLogs("")
      : {};
  }

  searchLogs(searchTerm: string) {
    searchTerm.trim() == "" || searchTerm.trim() == null
      ? (this.dataSourceThisWeek.data = this.filteredLogsByThisWeek)
      : (this.dataSourceThisWeek.data = this.filteredLogsByThisWeek.filter(
          (log) =>
            log.user.name
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            log.description
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            log.typeUniqueId
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()),
        ));

    searchTerm.trim() == "" || searchTerm.trim() == null
      ? (this.dataSourceThisMonth.data = this.filteredLogsByThisMonth)
      : (this.dataSourceThisMonth.data = this.filteredLogsByThisMonth.filter(
          (log) =>
            log.user.name
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            log.description
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            log.typeUniqueId
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()),
        ));

    searchTerm.trim() == "" || searchTerm.trim() == null
      ? (this.dataSourceOlder.data = this.filteredLogsByOlder)
      : (this.dataSourceOlder.data = this.filteredLogsByOlder.filter(
          (log) =>
            log.user.name
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            log.description
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            log.typeUniqueId
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()),
        ));

    this.dataSourceThisWeek.paginator = this.weeklyPaginator;
    this.dataSourceThisMonth.paginator = this.monthlyPaginator;
    this.dataSourceOlder.paginator = this.olderPaginator;
  }

  getActivityLogColor(description: string): string {
    if (description.toLowerCase().includes("invoice")) {
      return "#fec800";
    } else if (description.toLowerCase().includes("acknowledge")) {
      return "#9c2aa0";
    }
    return "#e60000";
  }

  getRequestLabelType(description: string): string {
    if (description.toLowerCase().includes("invoice")) {
      return "Invoice number:";
    } else if (description.toLowerCase().includes("order")) {
      return "Order number:";
    } else if (description.toLowerCase().includes("service")) {
      return "Service request number:";
    } else if (description.toLowerCase().includes("connection"))
      return "Retailer Name:";
  }

  getActivityLogs() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.loader = true;
      this.activityLogDescriptionTypes.push("All");
      this.activityLogUsers.push("All");

      this.eventService.search().subscribe(
        (logs) => {
          if (logs) {
            this.loader = false;
            this.activityLogs = logs;
            this.filteredActivityLogs = this.activityLogs;
            logs.forEach((log) => {
              this.activityLogDescriptionTypes.includes(
                this.getFirstWord(log.description),
              )
                ? {}
                : this.activityLogDescriptionTypes.push(
                    this.getFirstWord(log.description).toLowerCase() ==
                      "service"
                      ? this.getFirstWord(log.description) + " request"
                      : this.getFirstWord(log.description),
                  );
              this.activityLogUsers.includes(log.user.name)
                ? {}
                : this.activityLogUsers.push(log.user.name);
            });
            this.adjustDateFilter();
            this.dataSource.data = this.filteredActivityLogs;
          }
        },
        (error) => {
          this.loader = false;
          this.notificationService.showError(error);
        },
      );
    });
  }

  getWeekDates() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const numDay = now.getDate();

    const start = new Date(now);
    start.setDate(numDay - dayOfWeek);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(0, 0, 0, 0);
    return [start, end];
  }

  getMonthDates() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const numDay = now.getDate();

    const start = new Date(now);
    start.setDate(numDay - dayOfWeek);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(0, 0, 0, 0);

    return [start, end];
  }
}
