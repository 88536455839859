<div class="tradeCard height-100">
  <div class="center-item p-20">
    <p class="subHeading">Total Order Value Per Month</p>
    <div class="bl-t-l">
      <div class="m-15"></div>
      <div class="flex fx-jc-center fx-ai-center">
        <div
          *ngIf="!orderValueByMonthPerRetailer"
          style="min-width: 50px !important; max-width: 200px !important"
          class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
        >
          <mat-spinner></mat-spinner>
        </div>
        <canvas
          baseChart
          *ngIf="orderValueByMonthPerRetailer"
          #myCanvas
          id="canvas"
          [type]="chartType"
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
        ></canvas>
      </div>
    </div>
  </div>
</div>
