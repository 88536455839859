<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-30 fx-jc-center">
    <div class="flex fx-ai-center fx-row">
      <img
        *ngIf="retailer?.type !== 'Store'"
        class="medium-image"
        src="{{ retailer?.imageURL }}"
      />
      <p class="p-l-20 title">{{ retailer?.name }}</p>
    </div>

    <div class="m-l-30">
      <button
        mat-flat-button
        class="flex breadCrumb fx-ai-center m-l-10"
        (click)="goBack()"
      >
        Trading Partners
      </button>
      <span *ngFor="let crumb of breadCrumbs">
        <mat-icon class="icon">arrow_forward_ios</mat-icon>
        <button
          mat-flat-button
          class="flex breadCrumb fx-ai-center m-l-10"
          (click)="goToCrumb(crumb)"
        >
          {{ crumb.tradingPartner?.name }}
        </button>
      </span>
    </div>
    <div class="flex fx-jc-center fx-ai-center grayBG m-b-70">
      <div class="width-95 p-l-35 p-r-35 p-t-30 p-b-30 tradeCard">
        <app-retailer-information
          class="p-l-10 p-r-10"
          [retailer]="retailer"
          (reloadRetailer)="getRetailer()"
        ></app-retailer-information>
      </div>
      <div
        *ngIf="currentRetailer?.type !== 'Store'"
        class="width-100 p-30 flex fx-row fx-jc-center"
      >
        <div class="width-100 p-30 tradeCard">
          <app-linked-retailer
            *ngIf="retailer?.type === 'Supplier'"
            [retailer]="retailer"
          ></app-linked-retailer>
          <app-linked-store
            *ngIf="retailer?.type === 'Retailer'"
            [retailer]="retailer"
          ></app-linked-store>
        </div>
        <div
          *ngIf="currentRetailer?.type === 'Retailer'"
          class="width-90 p-30 m-l-20 tradeCard"
        >
          <app-trading-process [retailer]="retailer"></app-trading-process>
        </div>
        <div
          *ngIf="currentRetailer?.type === 'Supplier'"
          class="width-90 p-30 m-l-20 tradeCard"
        >
          <app-supplier-features [retailer]="retailer"></app-supplier-features>
        </div>
      </div>
      <div
        *ngIf="currentRetailer?.type === 'Supplier'"
        class="width-95 p-30 tradeCard"
      >
        <app-users
          [tradingPartnerGln]="tradingPartnerGln"
          class="mpUsers tradeCard"
          [viewOnly]="true"
        ></app-users>
      </div>
    </div>
  </div>
</div>
