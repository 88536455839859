<div class="m-l-auto flex noMargin">
  <mat-icon (click)="cancel()" class="flex m-l-auto">close</mat-icon>
</div>

<div class="flex fx-ai-center m-l-10 m-r-10 m-b-10">
  <div>
    <h2 class="noMargin">You're about to be redirected</h2>
  </div>
  <div mat-dialog-content class="mat-typography m-t-10">
    <h3 class="flex fx-jc-center">
      You will find the invoice file that you created in the "Downloads" folder
      on your device.
    </h3>
    <h3>
      You will now be redirected to the Receivables Finance platform. Log in to
      the platform and upload the invoice file
    </h3>
  </div>
  <div class="flex fx-jc-center width-50">
    <!-- <button class="btn selectedItem cancelBtn" (click)="cancel()">{{declineButtonText}}</button>  -->
    <button
      mat-flat-button
      class="btn round selectedItem primaryBtn right"
      (click)="confirm()"
    >
      Confirm
    </button>
  </div>
</div>
