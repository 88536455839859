<div
  *ngIf="!currentOrder"
  class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
>
  <mat-spinner></mat-spinner>
</div>

<div class="flex fx-jc-center fx-ai-center m-b-70 grayBG" *ngIf="currentOrder">
  <div class="width-90 flex m-30 fx-jc-center">
    <div *ngIf="isAdmin()" class="flex fx-row">
      <p
        (click)="goToSupplierView()"
        class="noMargin selectedItem breadcrumb fw-700 paragraph"
      >
        Supplier View
      </p>
      <img
        class="icon flex p-r-10 p-l-10"
        width="200px"
        src="./assets/icons/rightArrowBlack.svg"
      />
      <p
        class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center fx-ai-center"
      >
        Orders
      </p>
    </div>
    <p class="title">{{ currentOrder.supplier?.name }}</p>
    <div class="tradeCard p-r-20 p-t-30 p-l-20">
      <div class="flex fx-row m-b-35">
        <div class="flex fx-row">
          <p
            (click)="goToOrders()"
            class="selectedItem breadcrumb fw-700 paragraph"
          >
            Orders
          </p>
          <img
            class="icon flex p-r-10 p-l-10"
            width="200px"
            src="./assets/icons/rightArrowBlack.svg"
          />
          <p class="paragraph breadcrumb p-r-10 flex fx-jc-center fx-ai-center">
            {{ currentOrder.orderFrom.name | uppercase }}
          </p>
        </div>

        <div class="width-20 m-l-auto flex fx-row fx-ai-center">
          <div
            class="orderType flex m-l-auto"
            [ngStyle]="calculateStyles(currentOrder.status)"
          ></div>
          <p class="p-l-10 p-r-50 flex subHeading noMargin">
            {{ currentOrder.status | titlecase }}
          </p>
        </div>
      </div>

      <div class="grayBanner flex p-20 m-b-20 fx-row">
        <div class="flex m-l-auto manageButtons fx-row">
          <button
            matRipple
            *ngIf="currentOrder.status === 'Invoiced'"
            class="flex selectedItem fx-jc-center fw-500"
            (click)="resendInvoice()"
          >
            <p class="subHeading noMargin">{{ "re-send" | uppercase }}</p>
          </button>
          <button
            matRipple
            class="flex selectedItem fx-jc-center fw-500"
            (click)="printPage()"
          >
            <p class="subHeading noMargin">{{ "PDF" | uppercase }}</p>
          </button>
          <button
            matRipple
            class="flex selectedItem fx-jc-center fw-500"
            (click)="exportOrder()"
          >
            <p class="subHeading noMargin">{{ "Excel" | uppercase }}</p>
          </button>
        </div>
      </div>
      <div *ngIf="allowInvoicing() && !isAdmin()">
        <app-invoice
          (statusChange)="updateStatusChange($event)"
          [invoiceTotal]="currentOrder.total"
          [currentOrder]="currentOrder"
          (updateInvoiceNumber)="updateInvoiceNumber($event)"
          (updateInvoiceDate)="updateInvoiceDate($event)"
          (updateInvoiceAmount)="updateInvoiceAmount($event)"
          (enableInvoiceButton)="enableInvoiceButton($event)"
        ></app-invoice>
      </div>
      <div class="flex m-t-35">
        <div class="flex fx-row bl-b-l p-b-15">
          <div class="flex width-100">
            <p class="p-b noMargin">Ordered by</p>
          </div>

          <div class="flex width-100">
            <p class="p-b noMargin">Deliver to</p>
          </div>

          <div class="flex width-100">
            <p class="p-b noMargin">Order details</p>
          </div>
        </div>

        <div class="flex fx-row p-t-25 p-b-20">
          <div class="flex width-100">
            <p class="p-b noMargin p-b-10">{{ currentOrder.orderFromGln }}</p>
            <app-trading-partner-summary
              [gln]="currentOrder.orderFromGln"
            ></app-trading-partner-summary>
          </div>

          <div class="flex width-100">
            <p class="p-b noMargin p-b-10">{{ determineOrderToGln() }}</p>
            <app-trading-partner-summary
              [gln]="determineOrderToGln()"
            ></app-trading-partner-summary>
          </div>

          <div class="flex width-100 fx-row">
            <div class="flex fx-col width-100">
              <p
                *ngIf="currentOrder.status === 'Invoiced'"
                class="p-b noMargin p-b-10 fx-row"
              >
                Invoice number:
              </p>
              <p
                *ngIf="currentOrder.status === 'Invoiced'"
                class="p-b noMargin p-b-10 fx-row"
              >
                Invoice date:
              </p>
              <p class="p-b noMargin p-b-10 fx-row">Order number:</p>
              <p class="p-b noMargin p-b-10 fx-row">Order status:</p>
              <p class="p-b noMargin p-b-10 fx-row">Order type:</p>
              <p class="p-b noMargin p-b-10 fx-row">Date of order:</p>
              <p class="p-b noMargin p-b-10 fx-row">Earliest delivery:</p>
              <p class="p-b noMargin p-b-10 fx-row">Latest delivery:</p>
            </div>
            <div class="flex fx-col width-100">
              <p
                *ngIf="currentOrder.status === 'Invoiced'"
                class="paragraph noMargin p-b-10 fx-row"
              >
                {{
                  getInvoiceDetails().invoiceId !== null
                    ? getInvoiceDetails().invoiceId
                    : "-"
                }}
              </p>
              <p
                *ngIf="currentOrder.status === 'Invoiced'"
                class="paragraph noMargin p-b-10 fx-row"
              >
                {{
                  currentOrder.invoiceDate !== null
                    ? (currentOrder.invoiceDate | date : dateFormat)
                    : "-"
                }}
              </p>
              <p class="paragraph noMargin p-b-10 fx-row">
                {{ currentOrder.orderNumber }}
              </p>
              <p class="paragraph noMargin p-b-10 fx-row">
                {{ currentOrder.status !== null ? currentOrder.status : "-" }}
              </p>
              <p class="paragraph noMargin p-b-10 fx-row">
                {{
                  currentOrder.transactionCode
                    ? currentOrder.transactionCode
                    : "-"
                }}
              </p>
              <p class="paragraph noMargin p-b-10 fx-row">
                {{ currentOrder.dateOfOrder | date : dateFormat }}
              </p>
              <p class="paragraph noMargin p-b-10 fx-row">
                {{ currentOrder.earliestDelivery | date : dateFormat }}
              </p>
              <p class="paragraph noMargin p-b-10 fx-row">
                {{ currentOrder.latestDelivery | date : dateFormat }}
              </p>
            </div>
          </div>
        </div>

        <div class="m-b-40">
          <p class="paragraph noMargin p-b-10">Narrative:</p>
          <p>{{ currentOrder.narrative }}</p>
        </div>

        <div #elem>
          <app-new-order-table
            *ngIf="currentOrder !== null"
            [headerChangeTrigger]="headerChangeTrigger"
            [order]="currentOrder"
          ></app-new-order-table>
        </div>

        <div class="flex clearButtons fx-row m-t-30 m-b-30">
          <button
            matRipple
            (click)="goToOrders()"
            class="flex orderBtn-card-t back fx-jc-center fx-ai-center m-l-10"
          >
            <span class="flex fx-row fx-jc-center fx-ai-center p-r-20 p-l-20">
              Back
            </span>
          </button>
          <div *ngIf="!isAdmin()" class="flex m-l-auto fx-jc-center fx-row">
            <button
              matRipple
              *ngIf="allowAcknowledgement()"
              (click)="acknowledgeOrder()"
              class="flex orderBtn-card-t fx-jc-center fx-ai-center m-l-10"
            >
              <span class="flex fx-row fx-jc-center fx-ai-center m-r-10 m-l-10">
                <mat-icon svgIcon="thumbs-up" class="flex m-r-10"></mat-icon>
                Acknowledge
              </span>
            </button>
            <button
              matRipple
              *ngIf="allowRejection()"
              (click)="rejectOrder()"
              class="flex orderBtn-card-t fx-jc-center fx-ai-center m-l-10"
            >
              <span class="flex fx-row fx-jc-center fx-ai-center m-r-10 m-l-10">
                <mat-icon svgIcon="close" class="flex m-r-10"></mat-icon>
                Reject
              </span>
            </button>
            <button
              matRipple
              *ngIf="allowConfirmation()"
              (click)="confirmOrder()"
              class="flex orderBtn-card-t fx-jc-center fx-ai-center m-l-10"
            >
              <span class="flex fx-row fx-jc-center fx-ai-center m-r-10 m-l-10">
                <mat-icon svgIcon="check" class="flex m-r-10"></mat-icon>
                Confirm
              </span>
            </button>
          </div>
          <div *ngIf="!isAdmin()" class="flex m-l-auto fx-jc-center fx-row">
            <button
              matRipple
              *ngIf="isRejected()"
              (click)="resetOrderToNew()"
              class="flex orderBtn-card-t fx-jc-center fx-ai-center m-l-10"
            >
              <span class="flex fx-row fx-jc-center fx-ai-center m-r-10 m-l-10">
                <mat-icon svgIcon="arrow_back" class="flex m-r-10"></mat-icon>
                Reinstate
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showPrintLayout === true">
  <app-print-layout
    (onClose)="onClose()"
    [data]="data"
    [type]="type"
  ></app-print-layout>
</div>
