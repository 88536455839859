import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Session } from "../../../models/session";
import { SrfApplicationStatus } from "../../../models/srfApplication";
import { DialogService } from "../../../services/dialog.service";
import { FinanceService } from "../../../services/finance.service";
import { NotificationService } from "../../../services/notification.service";
import {
  AnalyticType,
  MonthlyBreakdowns,
  OrderAndInvoice,
  TopPerformingProduct,
  TradingPartner,
} from "./../../../models/types";
import { DocumentsService } from "./../../../services/documents.service";
import { NavigationService } from "./../../../services/navigation.service";
import { TradingPartnerService } from "./../../../services/trading-partner.service";
import { Settings } from "./../../../settings";

const dateFormat = new Settings().dateFormat;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

export interface User {
  name: string;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  myControl = new UntypedFormControl();
  options: User[] = [
    { name: "This Month" },
    { name: "Last Month" },
    { name: "Two Month Ago" },
  ];
  filteredOptions: Observable<User[]>;
  selectedTradingPartner: TradingPartner;
  dateFormat: string;
  totalOrderValue: string;
  toBeInvoiced: string;
  currentOrder: TradingPartner;
  todaysDate = new Date();
  dashboardForm: UntypedFormGroup;
  firstDay: Date = new Date();
  lastDay: Date = new Date();
  tradingPartner: Observable<TradingPartner>;
  orders: any;
  tradingPartnerObj: TradingPartner;
  toMaxDate: Date;
  fromMinDate: Date;
  loadTradingPartner = false;
  ordersInvoicedByRetailer: OrderAndInvoice[];
  topPerformingProduct: TopPerformingProduct;
  orderValueByMonthPerRetailer: MonthlyBreakdowns[];
  getApplicationStatus = false;
  srfApplication = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private navigate: NavigationService,
    private tradingPartnerService: TradingPartnerService,
    private session: Session,
    private documentService: DocumentsService,
    private notificationService: NotificationService,
    private settings: Settings,
    private dialog: DialogService,
    private financeService: FinanceService,
  ) {}
  ngOnDestroy(): void {
    this.tradingPartnerService.supplierTradingPartnerSelected.next(false);
  }

  ngOnInit() {
    this.selectSupplier();
    this.getSessionDates();
    this.tradingPartnerService.supplierTradingPartnerSelected.next(true);
    this.loadTradingPartner = true;
    this.hookSearchFilter();
    this.setupControls();
    this.setTradingPartner();
    this.dateFormat = dateFormat;
    this.getAnalytics();
    this.getInvoiceApplicationStatus();
  }

  isAdmin(): boolean {
    return this.session.isAdmin();
  }

  private selectSupplier() {
    if (this.session.fromLogin == true) {
      const user = this.session.getUser();
      if (user.tradingPartnerGlns.length > 1) {
        this.dialog
          .selectSupplierDialog()
          .afterClosed()
          .subscribe((res) => {
            if (!res) return;

            this.navigate.navigateRedirect("dashboard");
          });
      } else if (user.tradingPartnerGlns.length == 1) {
        this.session.setSupplierGln(user.tradingPartnerGlns[0]);
      } else {
        console.log("ERROR error with user", user);
      }
      this.session.fromLogin = false;
      this.navigate.navigateRedirect("dashboard");
    }
  }

  private getAnalytics() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartnerService
        .getAnalytics(this.fromMinDate.toString(), this.toMaxDate.toString(), [
          AnalyticType.TopPerformingProducts,
          AnalyticType.OrdersInvoicedByRetailer,
          AnalyticType.OrderValueByMonthPerRetailer,
        ])
        .subscribe((result) => {
          this.ordersInvoicedByRetailer = result.filter(
            (type) => type.type == AnalyticType.OrdersInvoicedByRetailer,
          )[0].resultData;
          this.topPerformingProduct = result.filter(
            (type) => type.type == AnalyticType.TopPerformingProducts,
          )[0].resultData;
          this.orderValueByMonthPerRetailer = result.filter(
            (type) => type.type == AnalyticType.OrderValueByMonthPerRetailer,
          )[0].resultData;
        });
    });
  }

  private setTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartner = this.tradingPartnerService.getTradingPartner(
        this.session.getSupplierGln(),
      );

      this.tradingPartner.subscribe(
        (result) => {
          this.loadTradingPartner = false;
          this.session.setSRFApplicationToggle(
            result?.features?.selectiveReceivableFinance,
          );

          this.srfApplication = this.session.getSRFApplicationToggle();
          this.financeService.srfApplicationStatus.next(this.srfApplication);
        },
        () => {
          this.loadTradingPartner = false;
        },
      );
    });
  }

  setupControls() {
    this.dashboardForm = this.formBuilder.group({
      fromDate: [new Date(this.firstDay)],
      toDate: [new Date(this.lastDay.setHours(23, 59, 59))],
    });
    this.fromMinDate = this.dashboardForm.controls.fromDate.value;
    this.toMaxDate = this.dashboardForm.controls.toDate.value;
    this.dashboardForm.controls.fromDate.valueChanges.subscribe((x) => {
      if (this.isDateRangeValid(x, this.toMaxDate)) {
        this.fromMinDate = x;
        this.setSessionDates();
        this.getAnalytics();
      }
    });
    this.dashboardForm.controls.toDate.valueChanges.subscribe((x) => {
      if (this.isDateRangeValid(this.fromMinDate, x)) {
        this.toMaxDate = x;
        this.setSessionDates();
        this.getAnalytics();
      }
    });
  }

  hookSearchFilter() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.options.slice())),
    );
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : "";
  }

  applyFinancing() {
    this.navigate.navigateToInvoiceFinancing();
  }

  public setTotalOrdersAmount(totalOrderValue: string) {
    this.totalOrderValue = parseFloat(totalOrderValue).toFixed(2);
  }

  public setOrdersToBeInvoiced(toBeInvoiced: number) {
    this.toBeInvoiced = toBeInvoiced.toFixed(2);
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  goToSupplierView() {
    this.navigate.navigateToSupplierView();
  }

  getSessionDates() {
    const [fromDate, toDate] = this.session.getDates();
    this.firstDay = new Date(fromDate);
    this.lastDay = new Date(toDate);
  }

  setSessionDates() {
    this.session.setDates(this.fromMinDate, this.toMaxDate);
  }

  NavigateToInvoices() {
    this.navigate.navigateToInvoiceProcessing();
  }

  getInvoiceApplicationStatus() {
    this.getApplicationStatus = false;
    this.financeService.getFinanceApplicationStatus().subscribe((status) => {
      if (status) {
        this.getApplicationStatus =
          status == SrfApplicationStatus.Complete ? true : false;
      }
    });
  }

  getFinanced() {
    this.navigate.navigateToGetFinanced();
  }

  isDateRangeValid(minDate: Date, maxDate: Date) {
    if (!minDate || !maxDate) {
      return false;
    }

    if (minDate <= maxDate) {
      return true;
    }

    this.notificationService.showError(
      new Error("From date cannot be greater than to date"),
    );
    this.session.resetDates();
    return false;
  }
}
