import { Injectable } from "@angular/core";
import ExcelJS from "exceljs";
import * as saveAs from "file-saver";
import "jspdf-autotable";
import {
  DetailedSupplierReport,
  OrderSummary,
  SupplierReport,
  User,
} from "./../models/types";

@Injectable({
  providedIn: "root",
})
export class PrintingServiceService {
  private orders: OrderSummary[];
  public currentTab: string;
  public activityRange = 0;

  getOrdersToExport(): OrderSummary[] {
    return this.orders;
  }

  setOrdersToExport(orders: OrderSummary[], currentTab: string) {
    this.orders = orders;
    this.currentTab = currentTab;
  }

  async generateDetailedSupplierReport(
    supplierReport: DetailedSupplierReport[],
  ) {
    const wb = new ExcelJS.Workbook();
    supplierReport.forEach((report) => {
      const worksheetName = report.supplierName + " - " + report.supplierGln;
      wb.addWorksheet(worksheetName);
      wb.getWorksheet(worksheetName).getRow(1).font = { bold: true };
      wb.getWorksheet(worksheetName).getColumn(1).width = 15;
      wb.getWorksheet(worksheetName).getColumn(2).width = 25;
      wb.getWorksheet(worksheetName).getColumn(3).width = 25;
      wb.getWorksheet(worksheetName).getColumn(4).width = 10;
      wb.getWorksheet(worksheetName).getColumn(5).width = 10;
      report.reportData.forEach((line) => {
        wb.getWorksheet(worksheetName).addRow([
          line.OrderNumber,
          line.OrderDate,
          line.InvoiceDate,
          line.Sender,
          "",
          line.Receiver,
          "",
          line.UnitBarcode,
          line.PackBarcode,
          line.PackSize,
          line.ProductDescription,
          line.OrderedQuantity,
          line.InvoiceQuantity,
          line.Price,
        ]);
      });
    });
    const buf = await wb.xlsx.writeBuffer();
    saveAs(
      new Blob([buf]),
      supplierReport.length > 1
        ? `${supplierReport.length}_Reports_${
            this.formatDateDisplay(new Date()) + this.currentTime()
          }.xlsx`
        : `${supplierReport[0].supplierName}_Report_${
            this.formatDateDisplay(new Date()) + this.currentTime()
          }.xlsx`,
    );
  }

  setRolesAsString(array: string[]) {
    let arrayValue = "";

    if (array.some((val) => val.toLowerCase().includes("admin"))) {
      for (let index = 0; index < array?.length; index++) {
        if (array[index].toLowerCase().includes("admin")) {
          if (
            !array[index].toLowerCase().includes("customer") &&
            array[index].toLowerCase().includes("admin")
          ) {
            arrayValue +=
              array[index] + (index + 1 !== array.length ? " ,\n" : "");
          } else if (array[index].toLowerCase().includes("admin")) {
            arrayValue +=
              array[index] + (index + 1 !== array.length ? " ,\n" : "");
          }
        }
      }
    } else {
      arrayValue = array[0];
    }
    return arrayValue;
  }

  formatDateDisplay(dateArg: Date): string {
    if (!dateArg) return "-";
    const date: Date = new Date(dateArg);
    return (
      (date.getDate() < 9 ? "0" + date.getDate() : date.getDate()) +
      " " +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear()
    );
  }

  currentTime(): string {
    return (
      "-" +
      (new Date().getHours() <= 9
        ? "0" + new Date().getHours()
        : new Date().getHours()) +
      (new Date().getMinutes() <= 9
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes()) +
      (new Date().getSeconds() <= 9
        ? "0" + new Date().getSeconds()
        : new Date().getSeconds())
    );
  }

  async generateUserReport(userReport: User[], selectedRange: number) {
    this.activityRange = selectedRange;
    const wb = new ExcelJS.Workbook();
    const worksheetName = "User Report ";
    wb.addWorksheet(worksheetName);
    wb.getWorksheet(worksheetName).getRow(1).font = { bold: true };
    wb.getWorksheet(worksheetName).getColumn(1).width = 30;
    wb.getWorksheet(worksheetName).getColumn(2).width = 25;
    wb.getWorksheet(worksheetName).getColumn(3).width = 25;
    wb.getWorksheet(worksheetName).getColumn(4).width = 15;
    wb.getWorksheet(worksheetName).getColumn(5).width = 15;
    wb.getWorksheet(worksheetName).getColumn(6).width = 10;
    wb.getWorksheet(worksheetName).getColumn(7).width = 20;
    wb.getWorksheet(worksheetName).getColumn(8).width = 18;
    wb.getWorksheet(worksheetName).getColumn(7).alignment = {
      horizontal: "right",
    };
    wb.getWorksheet(worksheetName).getColumn(8).alignment = {
      horizontal: "right",
    };
    userReport.forEach((report) => {
      wb.getWorksheet(worksheetName).addRow([
        report.username,
        this.getArrayAsString((report as any).supplierNames),
        this.setRolesAsString((report as any).roles),
        report.name,
        report.surname,
        this.getActiveStatus(report.lastActivityDate),
        this.determineNoOfDaysInactive(report.lastActivityDate),
        this.formatDate(report.lastActivityDate),
      ]);
    });
    const buf = await wb.xlsx.writeBuffer();
    saveAs(
      new Blob([buf]),
      `${this.activityRange}_Day_Active_Users_Reports-${
        this.formatDateDisplay(new Date()) + this.currentTime()
      }.xlsx`,
    );
  }

  getActiveStatus(lastDayInactive: string) {
    const date1 = new Date(lastDayInactive);
    const date2 = new Date();
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let activityStatus = "";
    switch (this.activityRange) {
      case 30:
        activityStatus = Math.floor(Difference_In_Days) <= 30 ? "Yes" : "No";
        break;
      case 60:
        activityStatus = Math.floor(Difference_In_Days) <= 60 ? "Yes" : "No";
        break;
      case 90:
        activityStatus = Math.floor(Difference_In_Days) <= 90 ? "Yes" : "No";
        break;

      default:
        activityStatus = Math.floor(Difference_In_Days) <= 30 ? "Yes" : "No";
        break;
    }
    return activityStatus;
  }

  determineNoOfDaysInactive(dateString: string) {
    if (dateString === "") {
      return "n/a";
    }
    const date1 = new Date(dateString);
    const date2 = new Date();
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return this.getActiveStatus(dateString).toLowerCase() == "no"
      ? Math.floor(Difference_In_Days)
      : "-";
  }

  getArrayAsString(array: []) {
    let arrayValue = "";
    for (let index = 0; index < array?.length; index++) {
      arrayValue += array[index] + (index + 1 !== array.length ? " ,\r\n" : "");
    }
    return arrayValue;
  }

  formatDate(dateArg: string): string {
    if (this.getActiveStatus(dateArg).toLowerCase() === "no") return "-";
    const date: Date = new Date(dateArg);
    return (
      (date.getDate() < 9 ? "0" + date.getDate() : date.getDate()) +
      " " +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear()
    );
  }

  async generateSupplierReport(supplierReport: SupplierReport[]) {
    const wb = new ExcelJS.Workbook();

    const worksheetName = `Supplier_Report`;
    wb.addWorksheet(worksheetName);
    wb.getWorksheet(worksheetName).getRow(1).font = { bold: true };
    wb.getWorksheet(worksheetName).getColumn(1).width = 15;
    wb.getWorksheet(worksheetName).getColumn(2).width = 15;
    wb.getWorksheet(worksheetName).getColumn(3).width = 15;
    wb.getWorksheet(worksheetName).getColumn(4).width = 15;
    wb.getWorksheet(worksheetName).getColumn(5).width = 15;
    wb.getWorksheet(worksheetName).getColumn(6).width = 15;
    wb.getWorksheet(worksheetName).getColumn(7).width = 15;

    supplierReport.forEach((report) => {
      report.reportData.forEach((line) => {
        wb.getWorksheet(worksheetName).addRow([
          report.supplierName,
          line.Retailer,
          line.OrderNumber,
          line.Status,
          line.OrderDate,
          line.OrderValue,
          line.InvoiceValue,
        ]);
      });
    });
    const buf = await wb.xlsx.writeBuffer();
    saveAs(
      new Blob([buf]),
      supplierReport.length > 1
        ? `Supplier_Reports_${
            this.formatDateDisplay(new Date()) + this.currentTime()
          }.xlsx`
        : `${supplierReport[0].supplierName}_Report_${
            this.formatDateDisplay(new Date()) + this.currentTime()
          }.xlsx`,
    );
  }
}
