<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 m-t-30">
  <div class="width-90 myProfileCard">
    <p *ngIf="!session.isAdmin()" class="title">{{ tradingPartner?.name }}</p>
    <div *ngIf="session.isCustomerAdmin()" class="mpBusinessInfo tradeCard">
      <app-business-information
        [tradingPartner]="tradingPartner"
      ></app-business-information>
    </div>

    <div class="mpNotifications tradeCard">
      <p class="subHeading flex p-b-10">Notifications</p>
      <div class="flex p-t-20 fx-r ow">
        <div class="flex fx-row">
          <div class="flex width-100">
            <p class="flex p-l">
              Where would you like to receive your Notifications?
            </p>
            <div class="flex fx-r ow">
              <p class="flex p-b noMargn">{{ notificationType }}</p>
            </div>
          </div>
        </div>
        <div class="flex m-l-auto fx-jc-center fx-row m-t-30">
          <div class="flex clickme fx-jc-center fx-row m-l-20">
            <button
              mat-flat-button
              class="flex btn cancelBtn fx-ai-center m-l-10"
              (click)="editNotifications()"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mpPassword tradeCard">
      <p class="subHeading flex p-b-10">Password</p>
      <div class="flex p-t-20">
        <div class="flex fx-row">
          <div class="flex width-100">
            <p class="flex p-l">Change or update your password</p>
          </div>
        </div>
        <div class="flex m-l-auto fx-jc-center fx-row m-t-60">
          <div class="flex clickme fx-jc-center fx-row m-l-20">
            <button
              mat-flat-button
              class="btn cancelBtn flex fx-ai-center m-l-10"
              (click)="changePassword()"
            >
              Change
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="session.isAdmin()" class="mpLastPerformed tradeCard ">  
            <app-last-performed-action></app-last-performed-action>
        </div> -->
    <app-users
      *ngIf="session.isAdmin() || session.isCustomerAdmin()"
      [tradingPartnerGln]="determineTradingPartnerGln()"
      class="mpUsers tradeCard"
    ></app-users>
    <app-activity-logs
      *ngIf="!session.isAdmin()"
      class="mpActivityLogs tradeCard"
    ></app-activity-logs>
  </div>
</div>
