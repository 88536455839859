import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import ExcelJS from "exceljs";
import * as saveAs from "file-saver";
import { jsPDF } from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { Observable, Subscription } from "rxjs";
import { NotificationService } from "../../../services/notification.service";
import { Order, OrderLine, OrderStatus, Vat } from "./../../../models/order";
import { Product } from "./../../../models/product";
import { OrderSummary, TradingPartner } from "./../../../models/types";
import { Address } from "./../../../repositories/models/types";
import { OrdersService } from "./../../../services/orders.service";
import { TradingPartnerService } from "./../../../services/trading-partner.service";
import { Settings } from "./../../../settings";

@Component({
  selector: "app-print-layout",
  templateUrl: "./print-layout.component.html",
  styleUrls: ["./print-layout.component.sass"],
})
export class PrintLayoutComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() type: string;
  @Output() onClose: EventEmitter<null> = new EventEmitter<null>();
  @ViewChild("export") cvsExporter;
  @ViewChild("elem") tableToPrint;
  displayedColumns: string[] = [
    "store",
    "unit",
    "productDescription",
    "quantity",
    "costPrice",
    "lineCost",
    "lineNarrative",
    "packSize",
  ];
  printDisplayedColumns: string[] = [
    "interchangeNo",
    "name",
    "orderNo",
    "value",
    "date",
    "earliestDelivery",
    "latestDelivery",
  ];
  statusOfOrder = "New";
  orders: Order[] = [];
  order: Order;
  order$: Observable<Order>[];
  orderInformation$: Observable<any>[] = [];
  orderSummaries: OrderSummary[] = [];
  style = "";
  invoiceLayout = "";
  product: Product;
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  orderFrom$: Observable<TradingPartner>[] = [];
  deliveredToTradingPartner: TradingPartner[] = [];
  deliveredFromTradingPartner: TradingPartner[] = [];
  dateFormat;
  selectedOrders: OrderSummary[] = [];
  currTab;
  loadingOrders = false;
  timeStamp: string;

  busyIndicator: MatSnackBarRef<SimpleSnackBar>;

  graphColors = {
    new: "#00B0CA",
    acknowledged: "#9C2AA0",
    confirmed: "#A8B400",
    rejected: "#FF0000",
    invoiced: "#EB9700",
  };
  @ViewChild("printOut") pageToPrint;
  spans = [];
  span = [];
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
    private orderService: OrdersService,
    private settings: Settings,
  ) {}
  ngOnDestroy(): void {
    this.orders = [];
    if (this.type === "details") {
      this.subscriptions.forEach((subscriptions) =>
        subscriptions.unsubscribe(),
      );
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.orderService.currentOrder.next(null);
    }
  }

  ngOnInit(): void {
    if (!this.data) {
      this.close();
      return;
    }
    let busyMessage = "Preparing Order";
    if (this.data.length && this.data.length > 1) {
      busyMessage += "s";
    }
    this.busyIndicator = this.notificationService.showBusy(busyMessage, "Ok");
    this.dateFormat = this.settings.dateFormat;
    if (this.type === "details") {
      this.getOrderDetails(this.data);
    } else if (this.type === "export") {
      this.type = "exportCVS";
      this.getOrderDetails(this.data);
    } else if (this.type === "excel") {
      this.type = "exportCVS";
      this.getSelectedOrderDetails(this.data);
    } else if (this.type === "exportCVS") {
      this.getSelectedOrderDetails(this.data);
    } else {
      this.getSelectedOrderDetails(this.data);
    }
  }

  determineOrderToGln(order: Order) {
    return !order.deliverToGln ? order.orderFromGln : order.deliverToGln;
  }

  getSelectedOrderDetails(orderSummaries: OrderSummary[]) {
    this.orderService.currentOrder.next(null);
    this.orders = [];
    this.deliveredToTradingPartner = [];
    this.deliveredFromTradingPartner = [];

    const orderIdArr = [];

    orderSummaries.forEach((order) => {
      orderIdArr.push(order.id);
    });

    const subscription: Subscription = this.orderService
      .getPrint(orderIdArr)
      .subscribe((result) => {
        if (result) {
          this.orders = result;

          const deliveredToTradingPartnerIdArr = [];
          const deliveredFromTradingPartnerIdArr = [];

          this.orders.forEach(async (x) => {
            deliveredToTradingPartnerIdArr.push(this.determineOrderToGln(x));
            deliveredFromTradingPartnerIdArr.push(x.orderFromGln);
          });

          const dtSubscription: Subscription = this.tradingPartnerService
            .getPrintTradingPartners(deliveredToTradingPartnerIdArr)
            .subscribe((tpDTResult) => {
              if (tpDTResult) {
                this.deliveredToTradingPartner = tpDTResult;
              }

              if (
                this.deliveredToTradingPartner.length ==
                this.deliveredFromTradingPartner.length
              ) {
                setTimeout(() => {
                  dtSubscription.unsubscribe();
                  dfSubscription.unsubscribe();
                  this.type === "exportCVS"
                    ? this.saveAsExcel(this.orders)
                    : this.downloadPDF();
                }, 1000);
              }
            });

          const dfSubscription: Subscription = this.tradingPartnerService
            .getPrintTradingPartners(deliveredFromTradingPartnerIdArr)
            .subscribe((tpDFResult) => {
              if (tpDFResult) {
                this.deliveredFromTradingPartner = tpDFResult;
              }

              if (
                this.deliveredToTradingPartner.length ==
                this.deliveredFromTradingPartner.length
              ) {
                setTimeout(() => {
                  dtSubscription.unsubscribe();
                  dfSubscription.unsubscribe();
                  this.type === "exportCVS"
                    ? this.saveAsExcel(this.orders)
                    : this.downloadPDF();
                }, 1000);
              }
            });
        }
        subscription.unsubscribe();
      });
  }

  getOrderDetails(printOrder: Order) {
    const observableArray: Observable<Order>[] = [];

    this.deliveredToTradingPartner = [];
    this.deliveredFromTradingPartner = [];

    this.orders.push(printOrder);

    const dtSubscription: Subscription = this.tradingPartnerService
      .getTradingPartner(this.determineOrderToGln(printOrder))
      .subscribe(
        (tpDTResult) => {
          this.deliveredToTradingPartner.push(tpDTResult);

          if (
            this.deliveredToTradingPartner.length ==
            this.deliveredFromTradingPartner.length
          ) {
            setTimeout(() => {
              dtSubscription.unsubscribe();
              dfSubscription.unsubscribe();
              this.type === "exportCVS"
                ? this.saveAsExcel(this.orders)
                : this.downloadPDF();
            }, 1000);
          }
        },
        () => {
          this.deliveredToTradingPartner.push(new TradingPartner());

          if (
            this.deliveredToTradingPartner.length ==
            this.deliveredFromTradingPartner.length
          ) {
            setTimeout(() => {
              dtSubscription.unsubscribe();
              dfSubscription.unsubscribe();
              this.type === "exportCVS"
                ? this.saveAsExcel(this.orders)
                : this.downloadPDF();
            }, 1000);
          }
        },
      );

    const dfSubscription: Subscription = this.tradingPartnerService
      .getTradingPartner(printOrder.orderFromGln)
      .subscribe(
        (tpDFResult) => {
          this.deliveredFromTradingPartner.push(tpDFResult);

          if (
            this.deliveredToTradingPartner.length ==
            this.deliveredFromTradingPartner.length
          ) {
            setTimeout(() => {
              dtSubscription.unsubscribe();
              dfSubscription.unsubscribe();
              this.type === "exportCVS"
                ? this.saveAsExcel(this.orders)
                : this.downloadPDF();
            }, 1000);
          }
        },
        () => {
          this.deliveredFromTradingPartner.push(new TradingPartner());

          if (
            this.deliveredToTradingPartner.length ==
            this.deliveredFromTradingPartner.length
          ) {
            setTimeout(() => {
              dtSubscription.unsubscribe();
              dfSubscription.unsubscribe();
              this.type === "exportCVS"
                ? this.saveAsExcel(this.orders)
                : this.downloadPDF();
            }, 1000);
          }
        },
      );
    observableArray.push(this.orderService.get(printOrder.id));

    this.orderService.currentOrder.next(printOrder);
  }

  chooseUnitBarCode(line: ExtendedOrderLine) {
    if (line.productEan) {
      if (line.productEan.trim() != "") {
        return line.productEan;
      }
    }
    return "-";
  }

  choosePackBarCode(line: ExtendedOrderLine) {
    if (line.packBarcode) {
      if (line.packBarcode.trim() != "") {
        return line.packBarcode;
      }
    }
    return "-";
  }

  downloadPDF() {
    const doc = new jsPDF("p", "pt", [750, 600]);
    doc.setFontSize(10);
    this.orders.forEach((order, idx) => {
      let deliverFromDetals = "";
      let productCode = "";
      let productDescription = "";
      let qty = "";
      let originalQty = "";
      let costPrice = "";
      let lineCost = "";
      let RSP = "";
      let packSize = "";

      const heading: RowInput =
        order.status !== OrderStatus.Invoiced
          ? [
              {
                content: "Pack Barcode\r\nUnit Barcode\r\nProduct Code",
                styles: { fontSize: 7, halign: "center" },
              },
              {
                content: "Product Description",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Qty",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Cost Price excl VAT",
                styles: { fontSize: 7, halign: "center" },
              },
              {
                content: "Line Cost excl VAT",
                styles: { fontSize: 7, halign: "center" },
              },
              {
                content: "RSP",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Pack Size",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
            ]
          : [
              {
                content: "Pack Barcode\r\nUnit Barcode\r\nProduct Code",
                styles: { fontSize: 7, halign: "center" },
              },
              {
                content: "Product Description",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Qty",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Original Qty",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Cost Price excl VAT",
                styles: { fontSize: 7, halign: "center" },
              },
              {
                content: "Line Cost excl VAT",
                styles: { fontSize: 7, halign: "center" },
              },
              {
                content: "RSP",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
              {
                content: "Pack Size",
                styles: { fontSize: 7, halign: "center", valign: "middle" },
              },
            ];

      const orderDeliverToDetails = this.getOrderDeliverToDetails(idx);

      for (let i = 0; i < Object.keys(orderDeliverToDetails).length; i++) {
        deliverFromDetals +=
          (orderDeliverToDetails[i] ? orderDeliverToDetails[i] : "") + "\r\n";
      }

      for (let i = 0; i < Object.keys(order.lines).length; i++) {
        const generatedPDFOrderLines = this.generatePDFOrderLines(order, i);

        productCode +=
          (generatedPDFOrderLines.productCode
            ? generatedPDFOrderLines.productCode
            : "") + "\r\n";
        productDescription +=
          "\r\n" +
          (generatedPDFOrderLines.productDescription
            ? generatedPDFOrderLines.productDescription
            : "") +
          "\r\n" +
          "\r\n";
        qty +=
          "\r\n" +
          (generatedPDFOrderLines.qty ? generatedPDFOrderLines.qty : "") +
          "\r\n" +
          "\r\n";
        originalQty +=
          "\r\n" +
          (generatedPDFOrderLines.originalQty
            ? generatedPDFOrderLines.originalQty
            : "") +
          "\r\n" +
          "\r\n";
        costPrice +=
          "\r\n" +
          (generatedPDFOrderLines.itemCost
            ? generatedPDFOrderLines.itemCost
            : "") +
          "\r\n" +
          "\r\n";
        lineCost +=
          "\r\n" +
          (generatedPDFOrderLines.lineItemCost
            ? generatedPDFOrderLines.lineItemCost
            : "") +
          "\r\n" +
          "\r\n";
        RSP +=
          "\r\n" +
          (generatedPDFOrderLines.lineNarrative
            ? generatedPDFOrderLines.lineNarrative
            : "") +
          "\r\n" +
          "\r\n";
        packSize +=
          "\r\n" +
          (generatedPDFOrderLines.packSize
            ? generatedPDFOrderLines.packSize
            : "") +
          "\r\n" +
          "\r\n";
      }

      const orderTotals: RowInput =
        order.status !== OrderStatus.Invoiced
          ? [
              undefined,
              { content: "Totals", styles: { fontSize: 7 } },
              {
                content: this.getTotalQuantities(order.lines),
                styles: { fontSize: 7 },
              },
              {
                content:
                  "R " + this.roundDecimal(this.getTotalCostPrice(order.lines)),
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content:
                  "R " + this.roundDecimal(this.getTotalLineCost(order.lines)),
                styles: { fontSize: 7, halign: "right" },
              },
              undefined,
            ]
          : [
              undefined,
              { content: "Totals", styles: { fontSize: 7 } },
              {
                content: this.getTotalQuantities(order.lines),
                styles: { fontSize: 7 },
              },
              {
                content: this.getTotalOriginalQuantities(order.lines),
                styles: { fontSize: 7 },
              },
              {
                content:
                  "R " + this.roundDecimal(this.getTotalCostPrice(order.lines)),
                styles: { halign: "right", fontSize: 7 },
              },
              {
                content:
                  "R " + this.roundDecimal(this.getTotalLineCost(order.lines)),
                styles: { halign: "right", fontSize: 7 },
              },
              undefined,
            ];

      const orderLineDetails: RowInput =
        order.status !== OrderStatus.Invoiced
          ? [
              {
                content: productCode,
                styles: { fontSize: 7, cellWidth: 80 },
              },
              {
                content: productDescription,
                styles: { fontSize: 7, cellWidth: 180 },
              },
              {
                content: qty,
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content: costPrice,
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content: lineCost,
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content: RSP,
                styles: { fontSize: 7 },
              },
              {
                content: packSize,
                styles: { fontSize: 7 },
              },
            ]
          : [
              {
                content: productCode,
                styles: { fontSize: 7 },
              },
              {
                content: productDescription,
                styles: { fontSize: 7 },
              },
              {
                content: qty,
                styles: { fontSize: 7 },
              },
              {
                content: originalQty,
                styles: { fontSize: 7 },
              },
              {
                content: costPrice,
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content: lineCost,
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content: RSP,
                styles: { fontSize: 7, halign: "right" },
              },
              {
                content: packSize,
                styles: { fontSize: 7, halign: "right" },
              },
            ];

      this.orders.length != idx && idx > 0 ? doc.addPage() : {};
      doc.text(`${this.orders[0].status} Orders`, 50, 30);

      const orderDeliveredFromDetails = this.getOrderDeliveredFromDetails(idx);
      autoTable(doc, {
        body: [
          [
            {
              content:
                "Order Number: " +
                "\r\n" +
                "Order Status: " +
                "\r\n" +
                "Order Type: " +
                "\r\n" +
                "Ordering Retailer: " +
                "\r\n" +
                "Ordering Store: " +
                "\r\n" +
                "Order Total: " +
                "\r\n" +
                "Total Items: " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                "Gln:" +
                "\r\n" +
                "Name:" +
                "\r\n" +
                "Line1:" +
                "\r\n" +
                "Line2:" +
                "\r\n" +
                "Line3:" +
                "\r\n" +
                "Line4:" +
                "\r\n" +
                "..." +
                "\r\n" +
                "Postal Code:" +
                "\r\n" +
                "Tel No:" +
                "\r\n" +
                "Vat No:" +
                "\r\n",
              styles: {
                fontSize: 7,
                cellWidth: 80,
              },
            },
            {
              content:
                (order?.orderNumber ? order?.orderNumber : "") +
                "\r\n" +
                (order?.status ? order?.status : "") +
                "\r\n" +
                (order?.status ? order?.status + "  Order" : "") +
                "\r\n" +
                (orderDeliveredFromDetails.name
                  ? orderDeliveredFromDetails.name +
                    "(" +
                    orderDeliveredFromDetails.orderFromGln +
                    ")"
                  : "") +
                "\r\n" +
                (deliverFromDetals[1]
                  ? deliverFromDetals[1] + "(" + deliverFromDetals[0] + ")"
                  : "") +
                "\r\n" +
                (this.roundDecimal(this.getTotalLineCost(order.lines))
                  ? "R " + this.roundDecimal(this.getTotalLineCost(order.lines))
                  : "") +
                "\r\n" +
                (this.getTotalQuantities(order.lines)
                  ? this.getTotalQuantities(order.lines)
                  : "") +
                "\r\n" +
                "\r\n" +
                "Retailer Details:" +
                (orderDeliveredFromDetails.orderFromGln
                  ? "\r\n" + orderDeliveredFromDetails.orderFromGln
                  : "") +
                (orderDeliveredFromDetails.name
                  ? "\r\n" + orderDeliveredFromDetails.name
                  : "") +
                (orderDeliveredFromDetails.line1
                  ? "\r\n" + orderDeliveredFromDetails.line1
                  : "") +
                (orderDeliveredFromDetails.line2
                  ? "\r\n" + orderDeliveredFromDetails.line2
                  : "") +
                (orderDeliveredFromDetails.line3
                  ? "\r\n" + orderDeliveredFromDetails.line3
                  : "") +
                (orderDeliveredFromDetails.line4
                  ? "\r\n" + orderDeliveredFromDetails.line4
                  : "") +
                "\r\n" +
                "..." +
                (orderDeliveredFromDetails.postalCode
                  ? "\r\n" + orderDeliveredFromDetails.postalCode
                  : "") +
                (orderDeliveredFromDetails.telNo
                  ? "\r\n" + orderDeliveredFromDetails.telNo
                  : "") +
                (orderDeliveredFromDetails.vatNo
                  ? "\r\n" + orderDeliveredFromDetails.vatNo
                  : ""),
              styles: {
                fontSize: 7,
                cellWidth: 130,
              },
            },
            {
              content:
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                " " +
                "\r\n" +
                "Store Details" +
                "\r\n" +
                (orderDeliverToDetails[0] ? orderDeliverToDetails[0] : "") +
                "\r\n" +
                (orderDeliverToDetails[1] ? orderDeliverToDetails[1] : "") +
                "\r\n" +
                (orderDeliverToDetails[2] ? orderDeliverToDetails[2] : "") +
                "\r\n" +
                (orderDeliverToDetails[3] ? orderDeliverToDetails[3] : "") +
                "\r\n" +
                (orderDeliverToDetails[4] ? orderDeliverToDetails[4] : "") +
                "\r\n" +
                (orderDeliverToDetails[5] ? orderDeliverToDetails[5] : "") +
                "\r\n" +
                "..." +
                "\r\n" +
                (orderDeliverToDetails[6] ? orderDeliverToDetails[6] : "") +
                "\r\n" +
                (orderDeliverToDetails[8] ? orderDeliverToDetails[8] : "") +
                "\r\n" +
                (orderDeliverToDetails[10] ? orderDeliverToDetails[10] : "") +
                "\r\n",

              styles: {
                fontSize: 7,
                halign: "left",
                cellWidth: 130,
              },
            },
            {
              content:
                "Date of Order:" +
                "\r\n" +
                "Earliest Delivery:" +
                "\r\n" +
                "Latest Delivery:" +
                "\r\n",
              styles: {
                fontSize: 7,
                cellWidth: 90,
              },
            },
            {
              content:
                this.formatDateDisplay(order.dateOfOrder) +
                "\r\n" +
                this.formatDateDisplay(order.earliestDelivery) +
                "\r\n" +
                this.formatDateDisplay(order.latestDelivery),
              styles: {
                fontSize: 7,
                cellWidth: 90,
              },
            },
          ],
        ],
      });

      autoTable(doc, {
        head: [[...heading]],
        body: [[...orderLineDetails], [...orderTotals]],
      });

      if (order.narrative) {
        autoTable(doc, {
          body: [
            [{ content: "Narrative:", styles: { fontSize: 10 } }],
            [
              {
                content: order.narrative ? order.narrative : "",
                styles: { fontSize: 7 },
              },
            ],
          ],
        });
      }
    });

    doc.save(
      `${
        this.orders.length < 2
          ? `Order-${this.orders[0].orderNumber}_${
              this.formatDateDisplay(new Date()) + this.currentTime()
            }`
          : `${this.orders.length}_${this.orders[0].status}_Orders_${
              this.formatDateDisplay(new Date()) + this.currentTime()
            }`
      }.pdf`,
    );

    this.close();
  }

  close() {
    this.onClose.emit(null);
    if (this.busyIndicator) {
      this.busyIndicator.dismiss();
    }
  }

  generatePDFOrderLines(order: Order, index) {
    return order.status === OrderStatus.Invoiced
      ? {
          productCode: order.lines[index]
            ? this.choosePackBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              this.chooseUnitBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              (order.lines[index] as ExtendedOrderLine).productCode
            : "",
          productDescription: order.lines[index]
            ? order.lines[index].productDescription
            : "",
          qty: order.lines[index] ? order.lines[index].quantity : "",
          originalQty: order.lines[index]
            ? order.lines[index].originalQuantity
            : "",
          itemCost: order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].itemCost)
            : "",
          lineItemCost: order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].lineItemCost)
            : "",
          lineNarrative: order.lines[index]
            ? order.lines[index].lineNarrative
            : "",
          packSize: order.lines[index] ? order.lines[index].packSize : "",
        }
      : {
          productCode: order.lines[index]
            ? this.choosePackBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              this.chooseUnitBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              (order.lines[index] as ExtendedOrderLine).productCode
            : "",
          productDescription: order.lines[index]
            ? order.lines[index].productDescription
            : "",
          qty: order.lines[index] ? order.lines[index].quantity : "",
          itemCost: order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].itemCost)
            : "",
          lineItemCost: order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].lineItemCost)
            : "",
          lineNarrative: order.lines[index]
            ? order.lines[index].lineNarrative
            : "",
          packSize: order.lines[index] ? order.lines[index].packSize : "",
        };
  }

  roundDecimal(num) {
    return parseFloat(num.toString())?.toFixed(2);
  }

  getTradingPartner(index: number): TradingPartner {
    return this.deliveredToTradingPartner.filter(
      (tradingPartner) =>
        tradingPartner.gln === this.orders[index].deliverToGln,
    )[0];
  }

  getDeliveredFromTradingPartner(index: number): TradingPartner {
    return this.deliveredFromTradingPartner.filter(
      (tradingPartner) =>
        tradingPartner.gln === this.orders[index].orderFromGln,
    )[0];
  }

  getOrderDeliverToDetails(index) {
    const tradingPartnerDeliverTo = this.getTradingPartner(index);

    return tradingPartnerDeliverTo
      ? {
          0: this.determineOrderToGln(this.orders[index])
            ? this.determineOrderToGln(this.orders[index])
            : "",
          1: tradingPartnerDeliverTo.name ? tradingPartnerDeliverTo.name : "",
          2: (tradingPartnerDeliverTo.addresses as Address)[0]?.line1
            ? (tradingPartnerDeliverTo.addresses as Address)[0]?.line1
            : "",
          3: (tradingPartnerDeliverTo.addresses as Address)[0]?.line2
            ? (tradingPartnerDeliverTo.addresses as Address)[0]?.line2
            : "",
          4: (tradingPartnerDeliverTo.addresses as Address)[0]?.line3
            ? (tradingPartnerDeliverTo.addresses as Address)[0]?.line3
            : "",
          5: (tradingPartnerDeliverTo.addresses as Address)[0]?.line4
            ? (tradingPartnerDeliverTo.addresses as Address)[0]?.line4
            : "",
          6: (tradingPartnerDeliverTo.addresses as Address)[0]?.postalCode
            ? (tradingPartnerDeliverTo.addresses as Address)[0]?.postalCode
            : "",
          7: tradingPartnerDeliverTo?.contact?.emailAddress
            ? tradingPartnerDeliverTo?.contact?.emailAddress
            : "",
          8: tradingPartnerDeliverTo?.contact?.telNo
            ? tradingPartnerDeliverTo?.contact?.telNo
            : "",
          9: tradingPartnerDeliverTo?.contact?.faxNo
            ? tradingPartnerDeliverTo?.contact?.faxNo
            : "",
          10: tradingPartnerDeliverTo?.vatNo
            ? tradingPartnerDeliverTo?.vatNo
            : "",
        }
      : {};
  }

  getOrderDeliveredFromDetails(index) {
    const tradingPartnerOrderFrom = this.getDeliveredFromTradingPartner(index);
    return this.deliveredFromTradingPartner[index]
      ? {
          orderFromGln: this.orders[index].orderFromGln
            ? this.orders[index].orderFromGln
            : "",
          name: tradingPartnerOrderFrom.name
            ? tradingPartnerOrderFrom.name
            : "",
          line1: (tradingPartnerOrderFrom.addresses as Address)[0]?.line1
            ? (tradingPartnerOrderFrom.addresses as Address)[0]?.line1
            : "",
          line2: (tradingPartnerOrderFrom.addresses as Address)[0]?.line2
            ? (tradingPartnerOrderFrom.addresses as Address)[0]?.line2
            : "",
          line3: (tradingPartnerOrderFrom.addresses as Address)[0]?.line3
            ? (tradingPartnerOrderFrom.addresses as Address)[0]?.line3
            : "",
          line4: (tradingPartnerOrderFrom.addresses as Address)[0]?.line4
            ? (tradingPartnerOrderFrom.addresses as Address)[0]?.line4
            : "",
          postalCode: (tradingPartnerOrderFrom.addresses as Address)[0]
            ?.postalCode
            ? (tradingPartnerOrderFrom.addresses as Address)[0]?.postalCode
            : "",
          emailAddress: tradingPartnerOrderFrom?.contact?.emailAddress
            ? tradingPartnerOrderFrom?.contact?.emailAddress
            : "",
          telNo: tradingPartnerOrderFrom?.contact?.telNo
            ? tradingPartnerOrderFrom?.contact?.telNo
            : "",
          faxNo: tradingPartnerOrderFrom?.contact?.faxNo
            ? tradingPartnerOrderFrom?.contact?.faxNo
            : "",
          vatNo: tradingPartnerOrderFrom?.vatNo
            ? tradingPartnerOrderFrom?.vatNo
            : "",
        }
      : {};
  }

  generateOrderDetails(order: Order) {
    return order.status !== OrderStatus.Invoiced
      ? {
          0: `Order Number`,
          1: order.orderNumber,
          2: `Order Date:`,
          3: this.formatDateDisplay(order.dateOfOrder),
          4: "Earliest Delivery",
          5: this.formatDateDisplay(order.earliestDelivery),
          6: "Latest Delivery",
          7: this.formatDateDisplay(order.latestDelivery),
          8: "Transaction Code:",
          9: order.status[0],
          10: order.status,
        }
      : {
          0: `Invoice Number`,
          1: this.getInvoiceDetails(order).invoiceId,
          2: `Invoice Date:`,
          3: this.formatDateDisplay(this.getInvoiceDetails(order).invoiceDate),
          4: `Order Number`,
          5: order.orderNumber,
          6: `Order Date:`,
          7: this.formatDateDisplay(order.dateOfOrder),
          8: "Earliest Delivery",
          9: this.formatDateDisplay(order.earliestDelivery),
          10: "Latest Delivery",
          11: this.formatDateDisplay(order.latestDelivery),
          12: "Transaction Code:",
          13: order.status[0],
          14: order.status,
        };
  }

  getInvoiceDetails(order: Order) {
    let splitOrder = [];

    if (order.invoiceId) {
      splitOrder = order.invoiceId.split("_");
    }
    return {
      invoiceId: splitOrder[splitOrder.length - 1],
      invoiceDate: order.invoiceDate,
    };
  }

  generateOrderLines(order: Order, index) {
    return order.status === OrderStatus.Invoiced
      ? [
          order.lines[index]
            ? this.choosePackBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              this.chooseUnitBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              order.lines[index].productCode
            : "",
          order.lines[index] ? order.lines[index].productDescription : "",
          order.lines[index] ? order.lines[index].quantity : "",
          order.lines[index] ? order.lines[index].originalQuantity : "",
          order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].itemCost)
            : "",
          order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].lineItemCost)
            : "",
          order.lines[index] ? order.lines[index].lineNarrative : "",
          order.lines[index] ? order.lines[index].packSize : "",
        ]
      : [
          order.lines[index]
            ? this.choosePackBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              this.chooseUnitBarCode(order.lines[index] as ExtendedOrderLine) +
              "\r\n" +
              order.lines[index].productCode
            : "",
          order.lines[index] ? order.lines[index].productDescription : "",
          order.lines[index] ? order.lines[index].quantity : "",
          order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].itemCost)
            : "",
          order.lines[index]
            ? "R " + this.roundDecimal(order.lines[index].lineItemCost)
            : "",
          order.lines[index] ? order.lines[index].lineNarrative : "",
          order.lines[index] ? order.lines[index].packSize : "",
        ];
  }

  async saveAsExcel(orders: Order[]) {
    const wb = new ExcelJS.Workbook();
    const rowHeight = 70;
    const colWidth = 20;
    let cellAdjustment = 0;
    let orderHeading = [];
    let totalsRow = [];

    orders.forEach(async (order, idx) => {
      this.generateOrderDetails(order);
      const orderDeliverToDetails = this.getOrderDeliverToDetails(idx);
      const tradingPartnerOrderFromDetails =
        this.getOrderDeliveredFromDetails(idx);

      let headerLine18;

      order.status !== OrderStatus.Invoiced
        ? ((orderHeading = [
            "Pack Barcode\r\nUnit Barcode\r\nProduct Code",
            "Product Description",
            "Qty",
            "Cost Price excl VAT",
            "Line Cost excl VAT",
            "RSP",
            "Pack Size",
          ]),
          (totalsRow = [
            undefined,
            undefined,
            "Order Total",
            this.getTotalQuantities(order.lines),
            "R " + this.roundDecimal(this.getTotalCostPrice(order.lines)),
            "R " + this.roundDecimal(this.getTotalLineCost(order.lines)),
            undefined,
          ]),
          (cellAdjustment = 0))
        : ((orderHeading = [
            "Pack Barcode\r\nUnit Barcode\r\nProduct Code",
            "Product Description",
            "Qty",
            "Original Qty",
            "Cost Price excl VAT",
            "Line Cost excl VAT",
            "RSP",
            "Pack Size",
          ]),
          (totalsRow = [
            undefined,
            undefined,
            "Order Total",
            this.getTotalQuantities(order.lines),
            this.getTotalOriginalQuantities(order.lines),
            "R " + this.roundDecimal(this.getTotalCostPrice(order.lines)),
            "R " + this.roundDecimal(this.getTotalLineCost(order.lines)),
            undefined,
          ]),
          (cellAdjustment = 1));

      wb.addWorksheet(order.status + " order_" + order.orderNumber);
      const title = wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .addRow(["Order Number", order?.orderNumber]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Order Status:",
        order?.status,
        "",
        "",
        "",
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Order Type:",
        order?.status + " Order",
        "",
        "Date of Order:",
        this.formatDateDisplay(order.dateOfOrder),
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Ordering Retailer:",
        tradingPartnerOrderFromDetails?.orderFromGln,
        "",
        "Earliest Delivery:",
        this.formatDateDisplay(order.earliestDelivery),
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Ordering Store:",
        orderDeliverToDetails[0],
        "",
        "Latest Delivery:",
        this.formatDateDisplay(order.latestDelivery),
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Order Total:",
        "R " + this.roundDecimal(this.getTotalLineCost(order.lines)),
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Total Items:",
        this.getTotalQuantities(order.lines),
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "",
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "",
        "Retailer Details:",
        "Store Details:",
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "GLN:",
        tradingPartnerOrderFromDetails?.orderFromGln,
        orderDeliverToDetails[0],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Name:",
        tradingPartnerOrderFromDetails?.name,
        orderDeliverToDetails[1],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Line1:",
        tradingPartnerOrderFromDetails?.line1,
        orderDeliverToDetails[2],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Line2:",
        tradingPartnerOrderFromDetails?.line2,
        orderDeliverToDetails[3],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Line3:",
        tradingPartnerOrderFromDetails?.line3,
        orderDeliverToDetails[4],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Line4:",
        tradingPartnerOrderFromDetails?.line4,
        orderDeliverToDetails[5],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "...",
        "...",
        "...",
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Postal Code:",
        tradingPartnerOrderFromDetails?.postalCode,
        orderDeliverToDetails[7],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Tel No:",
        tradingPartnerOrderFromDetails?.telNo,
        orderDeliverToDetails[8],
      ]);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "Vat No:",
        tradingPartnerOrderFromDetails?.vatNo,
        orderDeliverToDetails[9],
      ]);
      if (order?.narrative) {
        headerLine18 = wb
          .getWorksheet(order.status + " order_" + order.orderNumber)
          .addRow(["Narrative", order?.narrative]);
      }
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "",
      ]);

      const cells = wb.getWorksheet(
        order.status + " order_" + order.orderNumber,
      );

      cells.getCell(`B1`).alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell(`E3`).alignment = {
        wrapText: true,
        horizontal: "left",
        vertical: "top",
      };
      cells.getCell("B5").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("E5").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("E4").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("E6").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("B16").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("B17").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("B18").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("C16").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("C17").alignment = {
        wrapText: true,
        horizontal: "left",
      };
      cells.getCell("C18").alignment = {
        wrapText: true,
        horizontal: "left",
      };

      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "",
      ]);

      const tableHeader = wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .addRow(orderHeading);
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow([
        "",
      ]);
      let itemRowNumber = null;

      for (let x = 0; x < order.lines.length; x++) {
        const itemRow = wb
          .getWorksheet(order.status + " order_" + order.orderNumber)
          .addRow(this.generateOrderLines(order, x));
        itemRow.alignment = {
          vertical: "middle",
          wrapText: true,
        };
        itemRowNumber = itemRowNumber == null ? itemRow.number : itemRowNumber;
        if (OrderStatus.Invoiced) {
          itemRow.getCell(3).alignment = {
            horizontal: "left",
            vertical: "middle",
          };
        }
        itemRow.getCell(3 + cellAdjustment).alignment = {
          horizontal: "left",
          vertical: "middle",
        };
        itemRow.getCell(4 + cellAdjustment).alignment = {
          horizontal: "left",
          vertical: "middle",
        };
        itemRow.getCell(5 + cellAdjustment).alignment = {
          horizontal: "left",
          vertical: "middle",
        };
        itemRow.getCell(1).alignment = {
          vertical: "middle",
          wrapText: true,
        };
      }
      wb.getWorksheet(order.status + " order_" + order.orderNumber).addRow("");
      const totals = wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .addRow(totalsRow);

      const deliverTo = wb.getWorksheet(
        order.status + " order_" + order.orderNumber,
      );

      if (OrderStatus.Invoiced) {
        totals.getCell(3).alignment = {
          horizontal: "left",
        };
      }
      totals.getCell(3 + cellAdjustment).alignment = {
        horizontal: "left",
      };
      totals.getCell(4 + cellAdjustment).alignment = {
        horizontal: "left",
      };
      totals.getCell(5 + cellAdjustment).alignment = {
        horizontal: "left",
      };
      tableHeader.height = rowHeight;
      tableHeader.model.cells.map((key, idx) => {
        tableHeader.getCell(idx + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "6495ed" },
        };
      });
      tableHeader.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };

      if (order?.narrative) {
        headerLine18.worksheet.mergeCells(
          `B${headerLine18.number}:G${headerLine18.number}`,
        );
      }

      deliverTo.getCell(`A${itemRowNumber}`).alignment = {
        vertical: "top",
        horizontal: "left",
        wrapText: true,
      };
      deliverTo.getCell(`B${itemRowNumber}`).alignment = {
        vertical: "top",
        horizontal: "left",
        wrapText: true,
      };

      tableHeader.font = { size: 15 };
      title.font = { bold: true, size: 15 };
      for (let i = 1; i < 15; i++) {
        wb
          .getWorksheet(order.status + " order_" + order.orderNumber)
          .getColumn(i).width = i === 2 ? colWidth : colWidth;
      }
      wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .getRow(0).height = 15;
      tableHeader.height = rowHeight;
      wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .getRow(8).font = { bold: true };
      wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .getRow(8).font = { bold: true };
      wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .getRow(8).font = { bold: true };
      wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .getCell(`E3`).font = {
        bold: false,
      };
      wb
        .getWorksheet(order.status + " order_" + order.orderNumber)
        .getCell(`D3`).font = {
        bold: false,
      };
      totals.font = { bold: true, size: 12 };

      const buf = await wb.xlsx.writeBuffer();
      idx === orders.length - 1
        ? (saveAs(
            new Blob([buf]),
            `${this.orders.length}_${order.status}-${
              orders.length > 1
                ? "Orders" +
                  "_" +
                  (this.formatDateDisplay(new Date()) + this.currentTime())
                : `Order-${this.orders[0].orderNumber}` +
                  "_" +
                  (this.formatDateDisplay(new Date()) + this.currentTime())
            }.xlsx`,
          ),
          this.close())
        : {};
    });
  }

  formatDateDisplay(dateArg: Date): string {
    if (!dateArg) return "-";
    const date: Date = new Date(dateArg);
    return (
      (date.getDate() < 9 ? "0" + date.getDate() : date.getDate()) +
      " " +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear()
    );
  }

  getTotalQuantities(orderLines: OrderLine[]) {
    return (
      Math.round(
        orderLines.reduce((sum, current) => sum + current.quantity, 0) * 100,
      ) / 100
    );
  }

  getTotalOriginalQuantities(orderLines: OrderLine[]) {
    return (
      Math.round(
        orderLines.reduce((sum, current) => sum + current.originalQuantity, 0) *
          100,
      ) / 100
    );
  }
  getTotalCostPrice(orderLines: OrderLine[]) {
    return (
      Math.round(
        orderLines.reduce((sum, current) => sum + current.itemCost, 0) * 100,
      ) / 100
    );
  }

  getTotalLineCost(orderLines: OrderLine[]) {
    return (
      Math.round(
        orderLines.reduce((sum, current) => sum + current.lineItemCost, 0) *
          100,
      ) / 100
    );
  }

  currentTime(): string {
    return (
      "-" +
      (new Date().getHours() <= 9
        ? "0" + new Date().getHours()
        : new Date().getHours()) +
      (new Date().getMinutes() <= 9
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes()) +
      (new Date().getSeconds() <= 9
        ? "0" + new Date().getSeconds()
        : new Date().getSeconds())
    );
  }
}

class ExtendedOrderLine implements OrderLine {
  productEan: string;
  productDescription: string;
  quantity: number;
  vat: Vat;
  originalQuantity: number;
  tempQuantity: number;
  itemCost: number;
  lineItemCost: number;
  packBarcode: string;
  unitBarcode: string;
  orderUnitBarcode: string;
  productCode: string;
  packSize?: any;
  lineNarrative?: any;
}
