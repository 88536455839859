<div #pageDiv class="flex fx-jc-center fx-ai-center grayBG m-b-70">
  <div class="width-90 flex m-20 fx-jc-center">
    <form [formGroup]="tradingPartnerControlForm">
      <div class="flex fx-row">
        <div class="flex fx-row m-t-10 width-100">
          <div
            class="width-30 tradeCard m-r-50 fx-ai-start flex round xs-card-t p-r-20 p-l-20"
          >
            <mat-form-field class="">
              <input
                matInput
                (keyup.enter)="search()"
                (blur)="clearSearch()"
                formControlName="searchTradingPartners"
              />
              <mat-label><mat-icon>search</mat-icon> Type to search </mat-label>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div #tradeCard class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
        <p class="heading flex p-b-10">Trading Partners</p>

        <div class="grayBanner flex p-20 m-b-20 fx-row">
          <div *ngIf="!loadTradingPartners" class="flex fx-row">
            <p class="p-b p-r-10">{{ getArrayLength() }}</p>
            <p class="flex p-b">Trading partners</p>
          </div>
        </div>

        <div>
          <div
            *ngIf="loadTradingPartners"
            class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
          >
            <mat-spinner strokeWidth="5" [diameter]="50"></mat-spinner>
          </div>
          <table
            *ngIf="!loadTradingPartners"
            matTableFilter
            matTableExporter
            mat-table
            [dataSource]="dataSource"
            class="width-100"
            #exporter="matTableExporter"
            [exampleEntity]="filterEntity"
          >
            <!-- GLN Column -->
            <ng-container matColumnDef="GLN">
              <th mat-header-cell *matHeaderCellDef>GLN</th>
              <td mat-cell *matCellDef="let element">
                {{ element.gln }}
              </td>
            </ng-container>

            <!-- name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>

            <!-- type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">
                {{ element.type }}
              </td>
            </ng-container>

            <!-- view Column -->
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef>View</th>
              <td mat-cell *matCellDef="let element" style="color: #00b0ca">
                <p class="p-l">
                  <a matRipple (click)="goToSupplier(element)" class="pointer">
                    View
                  </a>
                </p>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            hidePageSize="true"
            [pageSizeOptions]="[defaultItemsPerPage]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </form>
  </div>
</div>
