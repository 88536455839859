<p class="subHeading info">
  Invoices available to sell
  <span
    class="blue-i"
    matTooltip="You can submit sell offers for these invoices."
    matTooltipClass="tooltipWhite"
    >i</span
  >
</p>
<div class="grayBG p-5">
  <p>{{ getInvoiceCount() }} Invoices available</p>
</div>
<div>
  <table matTableExporter mat-table [dataSource]="dataSource" class="width-100">
    <!-- Interchange no Column -->
    <ng-container matColumnDef="retailer">
      <th mat-header-cell *matHeaderCellDef>Retailer</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ element.orderFrom.name }}</p>
      </td>
    </ng-container>

    <!-- Destination Column -->
    <ng-container matColumnDef="invoiceNumber">
      <th mat-header-cell *matHeaderCellDef>Invoice number</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ element.invoiceNumber }}</p>
      </td>
    </ng-container>

    <!-- Order no Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">{{ element.total | currency : "R" }}</p>
      </td>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="invoiceDate">
      <th mat-header-cell *matHeaderCellDef>Invoice date</th>
      <td mat-cell *matCellDef="let element">
        <p class="p-l">
          {{ formateDate(element.invoiceDate) | date : dateFormat }}
        </p>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="maturityDate">
      <th mat-header-cell *matHeaderCellDef>
        Maturity date
        <span
          class="blue-i"
          matTooltip="The date on which the debtor must pay the invoice."
          matTooltipClass="tooltipWhite"
          >i</span
        >
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="flex fx-row fx-ai-center">
          <div class="flex">
            <p class="maturityDateLabel">Select a date</p>
            <h4 class="noMargin">
              {{ formateDate(element.maturityDate) | date : dateFormat }}
            </h4>
          </div>
          <img
            (click)="getDate(element)"
            class="icon flex p-r-10 p-l-10"
            width="200px"
            src="./assets/icons/calendarIcon.svg"
          />
        </div>
      </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Select
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        class="m-l-auto m-r-auto"
        style="text-align: center"
      >
        <mat-checkbox
          *ngIf="checkMaturityDate(row) !== ''"
          (click)="$event.stopPropagation()"
          (change)="$event ? selectedInvoice(row) : ''"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Symbol Column -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="invoiceCount"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
  <div class="flex" *ngIf="invoicesAvailable.length !== 0">
    <div class="m-t-10 m-l-auto">
      <button
        [disabled]="getSelectionLength()"
        mat-flat-button
        class="primaryBtn btn round m-l-auto"
        (click)="downloadInvoices()"
      >
        Download Invoices
      </button>
    </div>
  </div>
  <div
    *ngIf="invoicesAvailable.length === 0"
    class="flex fx-jc-center fx-ai-center"
  >
    <p class="m-20 p-20">No Invoices to Display</p>
  </div>
</div>
