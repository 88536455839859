<div class="fx-box flex fx-col footer p-t-30 p-b-60" id="footer">
  <mat-list
    id="footer"
    class="footer bl-b-w center-item flex width-90 fx-row p-t-30 p-b-60"
  >
    <div class="p-t-20 m-t-20"></div>
    <div class="flex width-100 p-l-20">
      <h3 class="textWhite p-b-20 p-t-50">Business solutions</h3>
      <div class="bl-r-w flex fx-jc-center fx-ai-center height-100 p-r-30">
        <mat-list-item
          [ngClass]="{ selected: menuItem.isSelected() }"
          *ngFor="let menuItem of businessSolutions"
          id="navItem"
          (click)="navigate(menuItem)"
          class="p0 flex fx-jc-center fx-ai-center flex bl-b-w"
        >
          <p class="flex fw-100 textWhite fx-ai-center fontSize">
            {{ menuItem.title }}
          </p>
          <!-- <img [src]="menuItem.imageUrl" width="20" class="icon m-l-auto" [matTooltip]="menuItem.title"> -->
        </mat-list-item>
      </div>
    </div>
    <div class="flex width-100 p-l-20">
      <h3 class="textWhite p-b-20 p-t-50">Deals & devices</h3>
      <div class="bl-r-w height-100 p-r-30">
        <mat-list-item
          [ngClass]="{ selected: menuItem.isSelected() }"
          *ngFor="let menuItem of dealsDevices"
          id="navItem"
          (click)="navigate(menuItem)"
          class="p0 bl-b-w"
        >
          <p class="flex p-t-5 fw-100 textWhite fx-ai-center fontSize">
            {{ menuItem.title }}
          </p>
          <!-- <img [src]="menuItem.imageUrl" width="20" class="icon m-l-auto" [matTooltip]="menuItem.title"> -->
        </mat-list-item>
      </div>
    </div>
    <div class="flex width-100 p-l-20">
      <h3 class="textWhite p-b-20 p-t-50">About us</h3>
      <div class="bl-r-w height-100 p-r-30">
        <mat-list-item
          [ngClass]="{ selected: menuItem.isSelected() }"
          *ngFor="let menuItem of aboutUs"
          id="navItem"
          (click)="navigate(menuItem)"
          class="p0 bl-b-w"
        >
          <p class="flex p-t-5 fw-100 textWhite fx-ai-center fontSize">
            {{ menuItem.title }}
          </p>
          <!-- <img [src]="menuItem.imageUrl" width="20" class="icon m-l-auto" [matTooltip]="menuItem.title"> -->
        </mat-list-item>
      </div>
    </div>
    <div class="flex width-100 p-l-20">
      <h3 class="textWhite p-b-20 p-t-50">Contact us</h3>
      <div class="p-r-30">
        <mat-list-item
          [ngClass]="{ selected: menuItem.isSelected() }"
          *ngFor="let menuItem of contactUs"
          id="navItem"
          (click)="navigate(menuItem)"
          class="p0 bl-b-w"
        >
          <p class="flex fw-100 textWhite fx-ai-center fontSize">
            {{ menuItem.title }}
          </p>
          <!-- <img [src]="menuItem.imageUrl" width="20" class="icon m-l-auto" [matTooltip]="menuItem.title"> -->
        </mat-list-item>
        <div class="p-t-30">
          <button mat-button *ngFor="let menuItem of socialMedia">
            <img
              [src]="menuItem.imageUrl"
              width="10"
              class="icon"
              [matTooltip]="menuItem.title"
            />
          </button>
        </div>
      </div>
    </div>
  </mat-list>
  <div class="flex fx-row center-item width-90 p-t-30">
    <button mat-button class="flex">
      <p class="textWhite">© 2019 Vodacom</p>
    </button>
    <div class="m-l-auto textWhite">
      <button mat-button>
        <p class="textWhite">Terms & conditions | Privacy policy</p>
      </button>
    </div>
  </div>
</div>
