import { Component } from "@angular/core";
import { NavigationService } from "./../../../../services/navigation.service";

@Component({
  selector: "app-financing-success",
  templateUrl: "./financing-success.component.html",
  styleUrls: ["./financing-success.component.sass"],
})
export class FinancingSuccessComponent {
  constructor(private navigate: NavigationService) {}

  submit() {
    this.navigate.navigateToMyProfile();
  }
}
