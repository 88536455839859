import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../../../../../app/services/navigation.service";
import { Session } from "../../../../models/session";
import { SrfApplicationStatus } from "../../../../models/srfApplication";
import { AuthService } from "../../../../services/auth.service";
import { DialogService } from "../../../../services/dialog.service";
import { FinanceService } from "../../../../services/finance.service";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

interface SideMenuItems {
  title: string;
  imageUrl: string;
  isSelected: any;
  route: string;
}

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.sass"],
})
export class SideMenuComponent implements OnInit {
  isExpanded = false;
  supplierSet = false;
  invoicesAvailable = false;
  srfFeatureVisibility = false;
  menuItems: SideMenuItems[] = [];

  constructor(
    private service: NavigationService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private session: Session,
    private tradingPartnerService: TradingPartnerService,
    private dialog: DialogService,
    private finance: FinanceService,
  ) {}

  ngOnInit() {
    this.tradingPartnerService.supplierTradingPartnerSelected.subscribe(
      (selected) => {
        this.supplierSet = selected;
        this.setupMenu();
      },
    );
    this.checkInvoiceFinanceState();
    this.finance.srfApplicationStatus.subscribe(() => {
      this.setupMenu();
    });
  }

  checkInvoiceFinanceState() {
    this.finance.returnedStatus.subscribe((status) => {
      if (status) {
        this.invoicesAvailable =
          status == SrfApplicationStatus.Complete ? true : false;
        this.setupMenu();
      } else {
        this.invoicesAvailable = false;
        this.setupMenu();
      }
    });
  }

  private setupMenu() {
    this.setMenuAll(); // styling size bug

    this.session.promiseWraper().subscribe(async () => {
      this.srfFeatureVisibility = this.session.getSRFApplicationToggle();
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.menuItems.length = 0;

      // VODACOM ADMIN
      if (user.roles.indexOf("admin") != -1) {
        this.menuItems.push(
          this.getMenuItem(
            "Trading Partners",
            "./assets/icons/menuArrow.svg",
            this.service.tradingPartner,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Supplier View",
            "./assets/icons/menuArrow.svg",
            this.service.supplierView,
          ),
        );
        this.supplierSet
          ? this.menuItems.push(
              this.getMenuItem(
                "Dashboard",
                "./assets/icons/menuArrow.svg",
                this.service.dashboard,
              ),
            )
          : {};
        this.supplierSet
          ? this.menuItems.push(
              this.getMenuItem(
                "Orders",
                "./assets/icons/menuArrow.svg",
                this.service.orders,
              ),
            )
          : {};
        this.menuItems.push(
          this.getMenuItem(
            "Reports And Insights",
            "./assets/icons/menuArrow.svg",
            this.service.reports,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "My Profile",
            "./assets/icons/menuArrow.svg",
            this.service.myProfile,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Manage Users",
            "./assets/icons/menuArrow.svg",
            this.service.manageUsers,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Logout",
            "./assets/icons/menuArrow.svg",
            this.service.login,
          ),
        );
      }
      // MULTI SUPPLIER USER
      else if (user.tradingPartnerGlns.length > 1) {
        this.menuItems.push(
          this.getMenuItem(
            "Dashboard",
            "./assets/icons/menuArrow.svg",
            this.service.dashboard,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Orders",
            "./assets/icons/menuArrow.svg",
            this.service.orders,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "My Products",
            "./assets/icons/menuArrow.svg",
            this.service.myProducts,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Partner Network",
            "./assets/icons/menuArrow.svg",
            this.service.partnerMarketplaceComponent,
          ),
        );
        this.session.getSRFApplicationToggle() == true
          ? this.menuItems.push(
              this.getMenuItem(
                "Get Financed",
                "./assets/icons/menuArrow.svg",
                this.service.getFinanced,
              ),
            )
          : {};
        this.session.getSRFApplicationToggle() == true
          ? this.invoicesAvailable
            ? this.menuItems.push(
                this.getMenuItem(
                  "Receivables Finance",
                  "./assets/icons/menuArrow.svg",
                  this.service.invoiceProcessing,
                ),
              )
            : {}
          : {};
        this.menuItems.push(
          this.getMenuItem(
            "My Profile",
            "./assets/icons/menuArrow.svg",
            this.service.myProfile,
          ),
        );
        this.menuItems.push(
          this.getMenuItem("Switch Supplier", "/assets/icons/finance.svg", ""),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Logout",
            "./assets/icons/menuArrow.svg",
            this.service.login,
          ),
        );
      }
      // SINGLE SUPPLIER USER
      else {
        this.menuItems.push(
          this.getMenuItem(
            "Dashboard",
            "./assets/icons/menuArrow.svg",
            this.service.dashboard,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Orders",
            "./assets/icons/menuArrow.svg",
            this.service.orders,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "My Products",
            "./assets/icons/menuArrow.svg",
            this.service.myProducts,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Partner Network",
            "./assets/icons/menuArrow.svg",
            this.service.partnerMarketplaceComponent,
          ),
        );
        this.session.getSRFApplicationToggle() == true
          ? this.menuItems.push(
              this.getMenuItem(
                "Get Financed",
                "./assets/icons/menuArrow.svg",
                this.service.getFinanced,
              ),
            )
          : {};
        this.session.getSRFApplicationToggle() == true
          ? this.invoicesAvailable
            ? this.menuItems.push(
                this.getMenuItem(
                  "Receivables Finance",
                  "./assets/icons/menuArrow.svg",
                  this.service.invoiceProcessing,
                ),
              )
            : {}
          : {};
        this.menuItems.push(
          this.getMenuItem(
            "My Profile",
            "./assets/icons/menuArrow.svg",
            this.service.myProfile,
          ),
        );
        this.menuItems.push(
          this.getMenuItem(
            "Logout",
            "./assets/icons/menuArrow.svg",
            this.service.login,
          ),
        );
      }
    });
  }

  private setMenuAll() {
    this.menuItems.length = 0;
    this.menuItems.push(
      this.getMenuItem(
        "Supplier View",
        "./assets/icons/menuArrow.svg",
        this.service.supplierView,
      ),
    );
    this.menuItems.push(
      this.getMenuItem(
        "Dashboard",
        "./assets/icons/menuArrow.svg",
        this.service.dashboard,
      ),
    );
    this.menuItems.push(
      this.getMenuItem(
        "Orders",
        "./assets/icons/menuArrow.svg",
        this.service.orders,
      ),
    );
    this.menuItems.push(
      this.getMenuItem(
        "My Products",
        "./assets/icons/menuArrow.svg",
        this.service.myProducts,
      ),
    );
    this.menuItems.push(
      this.getMenuItem(
        "Partner Network",
        "./assets/icons/menuArrow.svg",
        this.service.partnerMarketplaceComponent,
      ),
    );
    this.menuItems.push(
      this.getMenuItem("Switch Supplier", "/assets/icons/finance.svg", ""),
    );
    this.menuItems.push(
      this.getMenuItem(
        "Reports And Insights",
        "./assets/icons/menuArrow.svg",
        this.service.adminReport,
      ),
    );
    this.menuItems.push(
      this.getMenuItem(
        "My Profile",
        "./assets/icons/menuArrow.svg",
        this.service.myProfile,
      ),
    );
    this.menuItems.push(
      this.getMenuItem(
        "Manage Users",
        "./assets/icons/menuArrow.svg",
        this.service.manageUsers,
      ),
    );
    this.session.getSRFApplicationToggle() == true
      ? this.menuItems.push(
          this.getMenuItem(
            "Get Financed",
            "./assets/icons/menuArrow.svg",
            this.service.getFinanced,
          ),
        )
      : {};
    this.session.getSRFApplicationToggle() == true
      ? this.invoicesAvailable
        ? this.menuItems.push(
            this.getMenuItem(
              "Receivables Finance",
              "./assets/icons/menuArrow.svg",
              this.service.invoiceProcessing,
            ),
          )
        : {}
      : {};
    this.menuItems.push(
      this.getMenuItem(
        "Logout",
        "./assets/icons/menuArrow.svg",
        this.service.login,
      ),
    );
  }

  public getMenuItem(
    title: string,
    imageUrl: string,
    navigationRoute: string,
  ): SideMenuItems {
    return {
      title,
      imageUrl,
      isSelected: () => {
        return this.service.currentRoute() === navigationRoute;
      },
      route: navigationRoute,
    };
  }

  navigateToVodaLend() {
    window.location.href = "Vodalend";
  }

  public navigate(menuItem: SideMenuItems) {
    this.service.navigate(menuItem.route);
  }

  public route(menuItem: SideMenuItems) {
    if (menuItem.title == "Logout") {
      this.logout();
    }

    this.service.navigate(menuItem.route);
    this.service.currentRoute();
  }

  logout() {
    this.session.resetDates();
    this.session.setSupplierGln(""); // clears admin state
    this.authService.logout().subscribe(undefined, (error) => {
      this.notificationService.showError(error);
    });
  }

  navToVodaLend() {
    window.open("https://vodalend.vodacombusiness.co.za/", "_blank");
  }

  offsetMenuItem(menuItem: SideMenuItems): string {
    return ((menuItem.title.toLocaleLowerCase().includes("dash") ||
      menuItem.title.toLocaleLowerCase().includes("order")) &&
      this.session.isAdmin()) ||
      menuItem.title.toLocaleLowerCase().includes("receivables finance")
      ? "20px"
      : "0px";
  }

  navItemClick(menuItem: SideMenuItems) {
    if (menuItem.title === "Finance") {
      this.navToVodaLend();
    } else if (menuItem.title === "Switch Supplier") {
      this.showSelectSupplierDialog();
    } else {
      this.route(menuItem);
    }
  }

  // should not be visible if user has 1 gln only
  showSelectSupplierDialog() {
    this.dialog
      .selectSupplierDialog()
      .afterClosed()
      .subscribe((res) => {
        if (!res) return;

        console.log("INTENDED checkInvoiceFinanceState FIRE");
        this.checkInvoiceFinanceState();
        this.service.navigateRedirect("dashboard");
      });
  }
}
