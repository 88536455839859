import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import _ from "underscore";
import { Order } from "../../../models/order";
import { Product } from "../../../models/product";
import { Session } from "../../../models/session";
import { Contact, TradingPartner } from "../../../models/types";
import { DialogService } from "../../../services/dialog.service";
import { NotificationService } from "../../../services/notification.service";
import { ProductService } from "../../../services/product.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";

export interface User {
  retailer: string;
}

export interface DisplayProducts {
  tradingPartnerGln: string;
  retailerGln: string;
  retailerName: string;
  productCode: string;
  productFilter: string;
  barCode: string;
  productDescription: string;
  imageURL: string;
}

const ELEMENT_DATA: Product[] = [];

@Component({
  selector: "app-my-products",
  templateUrl: "./my-products.component.html",
  styleUrls: ["./my-products.component.sass"],
})
export class MyProductsComponent implements OnInit {
  myControl = new UntypedFormControl();
  options: User[] = [
    { retailer: "All Retailers" },
    { retailer: "Makro" },
    { retailer: "Woolworths" },
    { retailer: "Ackermans" },
    { retailer: "Pick n Pay" },
  ];
  @ViewChild("tradeCard") divWidth;
  filteredOptions: Observable<User[]>;
  parentVal: string;
  displayedColumns: string[] = [
    "retailer",
    "barcode",
    "productCode",
    "description",
    "select",
  ];
  dataSource = new MatTableDataSource<Product>(ELEMENT_DATA);
  selection = new SelectionModel<Product>(true, []);
  orderBarcodes: Order[] = [];
  clicked = false;
  loadProducts = false;
  loadTradingPartner = false;
  stickyHeading = false;
  title = "";
  searchText: string;
  selectedRetailerGln = "";
  filterEntity = {} as Product;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  productControlForm: UntypedFormGroup;
  barcodeEditorForm: UntypedFormGroup;
  retailerEditorForm: UntypedFormGroup;
  tradingPartners: TradingPartner[];
  Products: Product[] = [];
  constructor(
    private dialog: DialogService,
    private productService: ProductService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private tradingPartnerService: TradingPartnerService,
    private dialogService: DialogService,
    private session: Session,
  ) {}

  ngOnInit() {
    this.loadProducts = true;
    this.loadTradingPartner = true;
    this.stickyHeading = false;
    this.dataSource.paginator = this.paginator;
    this.setupInputControls();
    this.getRetailers();
  }

  setupInputControls() {
    this.productControlForm = this.formBuilder.group({
      searchProduct: ["", null],
      tradingPartner: ["", null],
      barcode: ["", null],
      retailer: ["", null],
      selected: ["", null],
    });
  }

  search() {
    this.searchBarcode(this.productControlForm.controls.searchProduct.value);
    this.paginator = this.dataSource.paginator;
  }

  clearSearch() {
    this.productControlForm.controls.searchProduct.value == ""
      ? (this.searchBarcode(null), (this.paginator = this.dataSource.paginator))
      : {};
  }

  getProducts(
    searchText: string,
    productCode: string,
    barCode: string,
    retailerGln: string,
  ) {
    const loader = this.dialogService.loader();

    this.productService
      .search(searchText, productCode, barCode, retailerGln)
      .subscribe(
        (products) => {
          this.Products = products;
          this.refreshProducts(products);

          if (products) {
            this.loadProducts = false;
          }
          loader.close();
        },
        (error) => {
          loader.close();
          this.loadProducts = false;
          this.notificationService.showError(error);
        },
      );
  }

  getRetailers() {
    this.tradingPartnerService
      .search(this.session.getSupplierGln(), true, null, "Retailer")
      .subscribe(
        (tradingPartners) => {
          if (!tradingPartners) return;

          this.tradingPartners = tradingPartners;
          this.addAllItemsOption();

          if (tradingPartners) {
            this.getProducts(null, null, null, null);
            this.loadTradingPartner = false;
          }
        },
        (error) => {
          this.notificationService.showError(error);
          this.loadTradingPartner = false;
          this.loadProducts = false;
        },
      );
  }

  addAllItemsOption() {
    if (this.tradingPartners) {
      this.tradingPartners.push({
        gln: "all",
        name: "All",
        addresses: [],
        contact: {} as Contact,
        imageURL: "",
        vatNo: "",
        vatCountryCode: "",
        events: [],
      });
    }
  }

  hookInputCotrolsOnchangeEvents() {
    this.productControlForm.controls.searchProduct.valueChanges.subscribe(
      (barCode) => {
        this.searchBarcode(barCode);
        this.paginator = this.dataSource.paginator;
      },
    );
  }

  searchBarcode(searchText: string) {
    const loader = this.dialogService.loader();
    this.searchText = searchText;
    const retailer = this.productControlForm.controls.tradingPartner.value;
    this.selectedRetailerGln =
      retailer == null || retailer == "all" ? "" : retailer;
    this.productService
      .search(this.searchText, null, null, this.selectedRetailerGln)
      .subscribe(
        (product) => {
          this.refreshProducts(product);
          loader.close();
        },
        (error) => {
          loader.close();
          this.notificationService.showError(error);
        },
      );
  }

  selectRetailer() {
    const retailerGln = this.productControlForm.controls.tradingPartner.value;
    this.selectedRetailerGln =
      retailerGln == "all" || retailerGln == "" ? "" : retailerGln;
    this.searchBarcode(this.searchText);
  }

  refreshProducts(products: Product[]) {
    if (products) {
      this.dataSource.data = products;
    }
  }

  getArrayLength(): number {
    return this.dataSource?.paginator?.length;
  }

  displayFn(user: User): string {
    return user && user.retailer ? user.retailer : "";
  }

  private _filter(retailer: string): User[] {
    const filterValue = retailer.toLowerCase();
    return this.options.filter(
      (option) => option.retailer.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  addProduct() {
    this.dialog
      .showManageProductDialog(null, this.tradingPartners)
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.getProducts(
              this.searchText,
              null,
              null,
              this.selectedRetailerGln,
            );
            this.notificationService.showSuccess("Product added successfully");
            this.resetSelectedProducts();
          }
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  editProduct() {
    this.dialog
      .showManageProductDialog(this.selection.selected[0], this.tradingPartners)
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            console.log("Reloading products NOW");
            this.getProducts(
              this.searchText,
              null,
              null,
              this.selectedRetailerGln,
            );
            this.notificationService.showSuccess("Product edited successfully");
            this.resetSelectedProducts();
          }
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  resetSelectedProducts() {
    this.selection.deselect(...this.selection.selected);
    this.productControlForm.controls.selected.setValue(false);
  }

  uploadFile() {
    this.dialog
      .showUploadProductsDialog()
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.parseProductFile(
              result,
              this.addProducts,
              this.validateHeaders,
              this.validateLine,
            );
          }
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  addProducts(products, lento) {
    if (products) {
      const loader = lento.dialog.loader();
      lento.productService.addProducts(products).subscribe(
        () => {
          lento.notificationService.showSuccess("Products added successfully");
          setTimeout(() => {
            loader.close();
            lento.getProducts(
              lento.searchText,
              lento.tradingPartnerGln,
              null,
              lento.selectedRetailerGln,
            );
          }, 2000);
          lento.resetSelectedProducts();
        },
        (error) => {
          loader.close();
          lento.notificationService.showError(error);
        },
      );
    } else {
      lento.notificationService.showError(
        new Error("No products found in the product batch file"),
      );
    }
  }

  parseProductFile(files: File[], addProducts, validateHeaders, validateLine) {
    const products: Product[] = [];
    const fileReader = new FileReader();

    _.each(files, (file: File) => {
      fileReader.onload = () => {
        if (fileReader.result) {
          const filelines = fileReader.result.toString().split("\n");
          let rowNumber = 0;

          _.each(filelines, (line) => {
            if (rowNumber > 0) {
              const lineSplit = line.split(";");
              if (lineSplit.length == 4) {
                // Not end of file yet
                validateLine(lineSplit, this);

                const product = {
                  barCode: lineSplit[1].trim(),
                  productCode: lineSplit[2].trim(),
                  productDescription: lineSplit[3].trim(),
                  productFilter:
                    lineSplit[1].trim() + "_" + lineSplit[2].trim(),
                  imageURL: "", //TODO: TBD later
                  retailerGln: lineSplit[0].trim(),
                } as Product;

                // Lead 0s to 14 digits
                while (product.barCode.length < 14) {
                  product.barCode = "0" + product.barCode;
                }

                products.push(product);
              }
            } else {
              validateHeaders(line, this);
            }
            rowNumber++;
          });
          addProducts(products, this);
        }
      };
      fileReader.readAsText(file);
    });
  }

  validateHeaders(headers: string, lento) {
    const line = headers.split(";");
    let error = "File format is invalid, ";
    if (line[0].toLowerCase().indexOf("gln") == -1) {
      error += "Retailer GLN column missing";
      lento.notificationService.showError(error);
      return;
    }

    if (line[1].toLowerCase().indexOf("barcode") == -1) {
      error += "Barcode column missing";
      lento.notificationService.showError(error);
      return;
    }

    if (line[2].toLowerCase().indexOf("product") == -1) {
      error += "Product Code column missing";
      lento.notificationService.showError(error);
      return;
    }

    if (line[3].toLowerCase().indexOf("description") == -1) {
      error += "Description column missing";
      lento.notificationService.showError(error);
      return;
    }
  }

  validateLine(line: string[], lento) {
    const barCode = line[1];
    const productCode = line[2];
    const productDescription = line[3];

    if (!barCode.trim()) {
      const error = new Error("Barcode is required");
      lento.notificationService.showError(error);
      throw error;
    }

    if (!productCode.trim()) {
      const error = new Error("Product Code is required");
      lento.notificationService.showError(error);
      throw error;
    }

    if (!productDescription.trim()) {
      const error = new Error("Product Description is required");
      lento.notificationService.showError(error);
      throw error;
    }
  }

  deleteProduct() {
    this.dialogService
      .showConfirmationDialog(
        "Confirm Product Delete",
        "Are you sure you want to delete the selected product/s?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          let errors;
          _.each(this.selection.selected, (product: Product) => {
            this.productService.delete(product.id).subscribe(
              () => {
                setTimeout(
                  //This gets executed before the edit for some reason, hence the hack
                  () =>
                    this.getProducts(
                      this.searchText,
                      null,
                      null,
                      this.selectedRetailerGln,
                    ),
                  10,
                );

                this.resetSelectedProducts();
              },
              (error) => {
                errors += ". " + error;
              },
            );
          });

          if (errors) {
            this.notificationService.showError(
              new Error(`There were problems deleting product/s. ${errors}`),
            );
            errors = "";
          } else {
            this.notificationService.showSuccess("Deleted Successfully");
          }
        }
      });
  }

  disableDelete() {
    return this.selection.isEmpty();
  }

  disableEdit() {
    return this.selection.isEmpty() || this.selection.selected.length > 1;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  manageProduct(product: Product) {
    this.selection.isSelected(product)
      ? this.selection.deselect(product)
      : this.selection.select(product);
  }

  arrayLength(): number {
    return this.dataSource.data.length;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Product): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }

    return `${this.selection.isSelected(row) ? "deselect" : "select"}`;
  }

  refresh() {
    //used to animate refresh button
    this.clicked = true;
    setTimeout(() => {
      this.clicked = false;
    }, 1000);
  }

  setWidth(): number {
    if (!this.divWidth) return;
    return this.divWidth.nativeElement.clientWidth - 60;
  }

  getScrollPosition(): boolean {
    this.stickyHeading = false;
    this.productService.stickyProductsHeading.subscribe((x) => {
      this.stickyHeading = x;
    });
    return this.stickyHeading;
  }
}
