import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import {
  Address,
  AddressType,
  TradingPartner,
  TradingProcess,
} from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

@Component({
  selector: "app-partner-marketplace-details",
  templateUrl: "./partner-marketplace-details.component.html",
  styleUrls: ["./partner-marketplace-details.component.sass"],
})
export class PartnerMarketplaceDetailsComponent implements OnInit {
  tradingPartner: TradingPartner;
  public numberOfStores: string;
  public availableMessages: string;
  postalAddress: Address;

  constructor(
    private navigate: NavigationService,
    private formBuilder: UntypedFormBuilder,
    private dialog: DialogService,
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.tradingPartnerService.currentTradingPartner.subscribe(
      (x) => {
        if (!x) return;
        this.tradingPartner = x;
        this.availableMessages = this.getAvailableMessages(x.tradingProcess);
        this.postalAddress = this.getPostalAddress(x.addresses);
        this.numberOfStores = x.numberOfStores;
      },
      (error) => {
        this.notificationService.showError(error);
      },
    );
  }

  navigateBack() {
    this.navigate.navigateToPartnerMarketplace();
  }

  connectExisting() {
    const link = this.dialog.connectPartnerDialog(
      "existingPartner",
      this.tradingPartner,
      "myPartner",
    );

    link.afterClosed().subscribe();
  }

  getAvailableMessages(tradingProcess: TradingProcess): string {
    if (!this.tradingPartner) return;

    let process = "";
    if (tradingProcess.acknowledgeOrder) {
      process = "Acknowledge, ";
    }
    if (tradingProcess.confirmOrder) {
      process += "Confirm, ";
    }
    if (tradingProcess.rejectOrder) {
      process += "Reject, ";
    }
    if (tradingProcess.invoiceOrder) {
      process += "Invoice ";
    }
    return process;
  }

  getPostalAddress(address: Address[]) {
    return address.find((x) => x.type == AddressType.postalAddress);
  }
}
