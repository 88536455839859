import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { Observable, Subject } from "rxjs";
import { NavigationService } from "../services/navigation.service";
import { NotificationService } from "../services/notification.service";
import { Roles, User } from "./types";

@Injectable()
export class Session {
  private user: User;
  public fromLogin = false;
  public srfApplicationToggle = false;
  private supplierViewGln = "";

  public getSupplierGln(): string {
    if (!this.supplierViewGln || this.supplierViewGln == "") {
      this.supplierViewGln = localStorage.getItem("selectedSupplierGln");
    }

    // if still blank then coming from login
    if ((!this.supplierViewGln || this.supplierViewGln == "") && this.user) {
      this.supplierViewGln = this.user.tradingPartnerGlns[0]; // need a valid gln for search tps of select supplier
    }

    return this.supplierViewGln;
  }

  public setSupplierGln(gln: string) {
    this.supplierViewGln = gln;
    localStorage.setItem("selectedSupplierGln", gln);
  }

  private fromDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 6,
    new Date().getUTCDate() - new Date().getUTCDate() + 1,
  );

  private toDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );

  private async setUserFromAuth(bypassCache = false) {
    const options = {
      bypassCache: bypassCache,
    };

    const user = await Auth.currentAuthenticatedUser(options);

    if (user) {
      this.user = {
        username: user.username,
        email: user.attributes.email,
        mobile: user.attributes.phone_number,
        isMfa: user.attributes.isMfa,
        token: user.signInUserSession.idToken.jwtToken,
        refreshToken: user.signInUserSession.refreshToken.token,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        tradingPartnerGln: user.attributes["custom:tradingPartnerGln"],
        tradingPartnerGlns: user.attributes["custom:tradingPartnerGlns"]
          ? user.attributes["custom:tradingPartnerGlns"].split(",")
          : [],
        notificationType: user.attributes["custom:notificationType"],
        userId: user.attributes.sub,
        newPasswordRequired: false,
        otpRequired: false,
        name: user.attributes.name,
        surname: user.attributes.family_name,
        roles:
          user.signInUserSession.accessToken.payload["cognito:groups"] ?? [],
        lastActivityDate: "",
        lastActivityType: "",
      };
    }

    return this.user;
  }

  constructor(
    private navigationService: NavigationService,
    private notificationService: NotificationService,
  ) {}

  public start(): Observable<User> {
    const user = new Subject<User>();

    this.setUserFromAuth()
      .then(() => {
        this.getSRFApplicationToggle();
        user.next(this.user);
      })
      .catch((e) => {
        user.error(e);
      });

    return user;
  }

  public promiseWraper(): Observable<unknown> {
    // Todo: I think this can go. It just pushes an empty string synchronously to subscribers.
    return new Observable((observer) => {
      observer.next("");
      observer.complete();
    });
  }

  public getUser() {
    if (!this.user) {
      this.start();
    }

    // Todo: I'm leaving this unmodified for now, but I doubt this function does what the original author intended, because rxjs observers are not synchronous.
    this.setUserFromAuth();

    return this.user;
  }

  public async getUserAsync(bypassCache = false) {
    try {
      await this.setUserFromAuth();

      // Todo: These are probably called in the incorrect order, or we should at least wait for refreshing before continuing
      this.refreshToken();

      return this.user;
    } catch (error) {
      this.navigationService.navigateToLogin();
    }
  }

  refreshToken() {
    Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]).then(
      ([user, currentSession]) =>
        new Promise((resolve, reject) => {
          user.refreshSession(
            currentSession.getRefreshToken(),
            (err, session) => {
              if (err) {
                reject(err);
              } else {
                resolve(session);
              }
            },
          );
        }),
    );
  }

  public isAdmin() {
    return this.user?.roles?.includes(Roles.admin) ?? false;
  }

  public isCustomerAdmin() {
    return this.user?.roles?.includes(Roles.customerAdmin) ?? false;
  }

  public resetDates() {
    this.fromDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getUTCDate() - new Date().getUTCDate() + 1,
    );
    this.toDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    );
  }

  public setDates(fromDate: Date, toDate: Date) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  public getDates(): Date[] {
    return [this.fromDate, this.toDate];
  }

  public setSRFApplicationToggle(status: boolean) {
    this.srfApplicationToggle = status;
  }

  public getSRFApplicationToggle(): boolean {
    return this.srfApplicationToggle;
  }
}
