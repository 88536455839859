import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ChartDataset, ChartOptions } from "chart.js";
import { TopPerformingProduct } from "../../../../models/types";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { OrdersService } from "../../../../services/orders.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

const graphColors = {
  New: "#00B0CA",
  Acknowledged: "#9C2AA0",
  Confirmed: "#005765",
  Rejected: "#FF0000",
  Invoiced: "#BD0000",
};

@Component({
  selector: "app-top-performing-products",
  templateUrl: "./top-performing-products.component.html",
  styleUrls: ["./top-performing-products.component.sass"],
})
export class TopPerformingProductsComponent implements OnInit, OnChanges {
  @Input() toMaxDate: Date;
  @Input() fromMinDate: Date;
  @Input() topPerformingProduct: TopPerformingProduct;
  public barChartOptions: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom",
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  public barChartLabels: string[] = [];
  public barChartType = "bar";

  public barChartData: ChartDataset<"bar">[] = [];
  loadingData = false;
  constructor(
    private orderService: OrdersService,
    private tradingPartner: TradingPartnerService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
  ) {}

  ngOnChanges(): void {
    this.plotGraph();
  }

  ngOnInit(): void {
    this.plotGraph();
  }

  plotGraph() {
    this.barChartData = [
      {
        barPercentage: 0.2,
        data: [],
        label: "Number of orders",
        backgroundColor: graphColors["Rejected"],
        borderColor: graphColors["invoiced"],
      },
    ];
    this.loadingData = true;
    if (this.topPerformingProduct) {
      this.barChartLabels = [];
      this.barChartData[0].data = [];
      this.loadingData = false;

      this.topPerformingProduct.data.forEach((product) => {
        if (product) {
          this.barChartLabels.push(product["productEan"]);
          this.barChartData[0].data.push(product["total"]);
        }
      });
    }
  }
}
