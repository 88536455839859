<form [formGroup]="linkedTradingPartnerForm">
  <div class="flex fx-row">
    <p class="subHeading flex p-b-10">{{ linkedTypesLabel(retailer?.type) }}</p>
    <div
      class="flex fx-ai-center m-l-auto fx-row round tradeCard width-30 p-t-10 p-r-20 p-l-20"
    >
      <mat-form-field class=" ">
        <input
          matInput
          (blur)="clearSearch()"
          (keyup.enter)="search($event.target.value)"
          formControlName="searchTradingPartner"
        />
        <mat-label
          ><mat-icon>search</mat-icon> Search
          {{ linkedTypesLabel(retailer?.type) }}
        </mat-label>
        <!-- <mat-error
                *ngIf="PartnerMarketplaceForm.controls.searchTradingPartner.hasError('required')">
                Numeric value <strong>only</strong>
            </mat-error> -->
      </mat-form-field>
    </div>
  </div>
  <div class="scrollTable m-t-15">
    <div
      *ngIf="loader"
      class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
    >
      <mat-spinner strokeWidth="6" [diameter]="50"></mat-spinner>
    </div>
    <table
      *ngIf="!loader"
      matTableFilter
      matTableExporter
      mat-table
      [dataSource]="dataSource"
      class="width-100"
      #exporter="matTableExporter"
    >
      <!-- productCode Column -->
      <ng-container matColumnDef="gln">
        <th mat-header-cell *matHeaderCellDef>GLN</th>
        <td mat-cell *matCellDef="let element">{{ element.gln }}</td>
      </ng-container>

      <!-- productCode Column -->
      <!-- <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef> Image </th>
          <td mat-cell *matCellDef="let element"> 
            <img class="medium-image linkedRetailersImage" src="{{element?.imageURL}}" alt="{{element?.name}}">
        </td>
        </ng-container> -->

      <!-- description Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let element" style="color: #00b0ca">
          <p class="p-l">
            <a matRipple (click)="goToStore(element.gln)" class="pointer">
              View
            </a>
          </p>
        </td>
        <!-- <td mat-cell *matCellDef="let element"> 
              <mat-icon  [matTooltip]="'View '+linkedTypesLabel(retailer?.type)" (click)="goToStore(element.gln)">visibility</mat-icon>
          </td> -->
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation(); manageProduct(row)"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
            formControlName="selected"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</form>
<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
></mat-paginator>

<div class="flex fx-jc-center fx-row m-t-30 height-100">
  <div class="flex m-l-auto clickme fx-jc-center fx-row m-l-20 height-100">
    <button
      (click)="addStores()"
      mat-flat-button
      class="flex btn cancelBtn fx-ai-center m-l-10"
    >
      Add Store
    </button>
    <button
      (click)="uploadFile()"
      mat-flat-button
      class="flex btn cancelBtn fx-ai-center m-l-10"
    >
      Upload Batch
    </button>
    <button
      (click)="downloadFile()"
      mat-flat-button
      class="flex btn cancelBtn fx-ai-center m-l-10"
    >
      Download
    </button>
  </div>
</div>
