import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, Observable } from "rxjs";
import _ from "underscore";
import { OrderStatus } from "../../../models/order";
import { Session } from "../../../models/session";
import { OrderSummary, QueryResult } from "../../../models/types";
import { DialogService } from "../../../services/dialog.service";
import { NavigationService } from "../../../services/navigation.service";
import { UiDataService } from "../../../services/order-uidata.service";
import { OrdersService } from "../../../services/orders.service";
import { Settings } from "../../../settings";

// const mockUserdata = require('');
const ELEMENT_DATA: OrderSummary[] = [];

@Component({
  selector: "app-order-table",
  templateUrl: "./order-table.component.html",
  styleUrls: ["./order-table.component.sass"],
})
export class OrderTableComponent
  implements OnInit, AfterContentInit, OnChanges
{
  @Input() orderResult: Observable<QueryResult>;
  orders: BehaviorSubject<OrderSummary[]> = new BehaviorSubject<OrderSummary[]>(
    null,
  );
  @Output() loadMore: EventEmitter<{ status: string; skip: number }> =
    new EventEmitter();
  @Output() onPrintClose: EventEmitter<null> = new EventEmitter<null>();
  @ViewChild("export") cvsExporter;
  @ViewChild("printButton") printButton;
  @ViewChild("elem") tableToPrint;
  @Output() value = new EventEmitter<number>();
  @Input() orderStatusType: OrderStatus;
  @Input() allOrders: OrderSummary[];
  @Input() allOrdersObservable: OrderSummary[];

  @Input() searchText: string;
  @Input() exportCVS: boolean;
  @Input() printOrdersTrigger: boolean;
  @Input() exportOrdersTrigger: boolean;
  @Input() currTab: string;
  @Input() sender: string;
  @Input() downloadType: string;
  @Input() toMaxDate: Date;
  @Input() fromMinDate: Date;
  loadOrders = false;
  statusExample: string;
  trigger = new BehaviorSubject<number>(null);
  enableButtons = false;
  downloadAllToggle = false;

  ordersSummaries: OrderSummary[] = [];

  data: OrderSummary[];
  showPrintLayout = false;

  displayedColumns: string[] = [
    "retailerName",
    "name",
    "orderNo",
    "value",
    "date",
    "earliestDelivery",
    "latestDelivery",
    "select",
  ];
  dataSource = new MatTableDataSource<OrderSummary>(ELEMENT_DATA);
  selection = new SelectionModel<OrderSummary>(true, []);
  ordersForStatus: OrderSummary[] = [];
  dateFormat;
  loadingMoreOrders = false;
  allActiveOrders;

  private skip = 0;
  private take = 10;

  constructor(
    private services: NavigationService,
    private orderService: OrdersService,
    private session: Session,
    private uiData: UiDataService,
    private dialog: DialogService,
    private settings: Settings,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.downloadType) {
      if (changes.downloadType.currentValue != null) {
        this.printAllOrders(changes.downloadType.currentValue);
      }
    }
  }
  ngAfterContentInit(): void {
    this.dataSource.data.forEach((row) => this.selection.deselect(row));
  }

  ngOnInit() {
    this.dateFormat = this.settings.dateFormat;
    this.skip = 0;
    this.dataSource.data = [];
    this.loadOrders = true;
    this.orderResult.subscribe(
      (queryResult) => {
        this.orders.next(queryResult?.data);
      },
      (error) => {
        this.orders.error(error);
      },
    );
    this.subscribeToOrders();
  }

  private getByStatus(status: string, orders: OrderSummary[]): OrderSummary[] {
    return _.filter(orders, (order) => {
      return order.status == status;
    });
  }

  printOrders(type) {
    this.data = this.selection.selected;

    if (type === "print") {
      this.downloadType = "overview";
    } else if (type === "exportCVS") {
      this.downloadType = "exportCVS";
    }
    this.showPrintLayout = true;
  }

  onClose() {
    this.showPrintLayout = false;
    this.onPrintClose.emit(null);
    this.toggleDownloadAll();
  }

  printAllOrders(type) {
    this.data = this.allOrders;
    if (type === "print") {
      this.downloadType = "overview";
    } else if (type === "exportCVS") {
      this.downloadType = "exportCVS";
    }
    this.showPrintLayout = true;
  }

  subscribeToOrders() {
    this.orders.subscribe(
      (result) => {
        this.loadOrders = false;
        if (!result || result == null) {
          this.ordersSummaries.length = 0;
          this.dataSource.data = this.ordersSummaries;
          this.loadOrders = false;
          this.loadingMoreOrders = true;
          return;
        }
        this.loadOrders = false;
        this.loadingMoreOrders = false;
        if (result) {
          const data = result;
          this.addSummaryData(data);
          this.dataSource.data = this.ordersSummaries;
          this.loadingMoreOrders = false;
          this.loadOrders = false;
        }
      },
      () => {
        this.loadOrders = false;
        this.loadingMoreOrders = false;
      },
    );
  }

  addSummaryData(data: OrderSummary[]) {
    _.each(data, (d) => {
      if (!this.isExistingData(d)) {
        this.ordersSummaries.push(d);
      }
    });
  }

  isExistingData(d: OrderSummary) {
    return _.some(this.ordersSummaries, (s) => {
      return s.id == d.id;
    });
  }

  printData() {
    this.dataSource = new MatTableDataSource<OrderSummary>(ELEMENT_DATA);
  }

  goToNewOrder(order: OrderSummary) {
    this.services.navigateNewOrders(
      order.id,
      this.sender,
      this.orderStatusType,
    );
  }

  loadMoreOrders() {
    this.loadingMoreOrders = true;
    this.skip += this.take;
    this.loadMore.emit({ status: this.orderStatusType, skip: this.skip });
  }

  selectedOrders(row: OrderSummary) {
    this.selection.isSelected(row)
      ? this.selection.deselect(row)
      : this.selection.select(row);
    this.trigger.next(this.selection.selected.length);
    this.value.emit(this.selection.selected.length);
    this.selection.selected.length > 0
      ? (this.enableButtons = true)
      : (this.enableButtons = false);
  }

  determineRetailer(order: OrderSummary) {
    if (order.orderFrom) {
      if (order.orderFrom.name) {
        return order.orderFrom.name;
      } else {
        return order.orderFrom.gln;
      }
    }
  }

  determineDestination(order: OrderSummary) {
    if (order.deliverTo) {
      if (order.deliverTo.name) {
        return order.deliverTo.name;
      } else {
        return order.deliverTo.gln;
      }
    } else {
      return order.orderFrom.name;
    }
  }

  ENQUIREABOUTTHISCODE(changes: SimpleChanges) {
    changes.currTab
      ? (this.selection.clear(),
        this.value.emit(this.selection.selected.length))
      : "";
  }

  formatDate(date): string {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: OrderSummary): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }

    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.orderNumber + 1
    }`;
  }

  arrayLength(): number {
    const value = this.dataSource.data.length;
    return value;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.value.emit(this.selection.selected.length);
    this.selection.selected.length > 0
      ? (this.enableButtons = true)
      : (this.enableButtons = false);
  }

  toggleDownloadAll() {
    this.downloadAllToggle = !this.downloadAllToggle;
  }
}
