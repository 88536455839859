<p class="subHeading">{{ title }}</p>
<form class="example-form" [formGroup]="editNotificationsForm">
  <mat-form-field class="round tradeCard p-l-20">
    <select
      matNativeControl
      placeholder="Please select"
      formControlName="notifications"
    >
      <option *ngFor="let notification of notifications" [value]="notification">
        {{ notification }}
      </option>
    </select>
  </mat-form-field>
</form>

<div style="justify-content: space-between" class="flex fx-row m-30 p-t-50">
  <button mat-flat-button class="btn cancelBtn fl flexex" (click)="cancel()">
    Cancel
  </button>
  <button
    mat-flat-button
    [disabled]="!editNotificationsForm.valid"
    class="btn primaryBtn fl flexex"
    (click)="submit()"
  >
    Save
  </button>
</div>
