import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Session } from "../models/session";
import { newClient } from "../services/api-gateway.service.js";
import { PartnerMarketPlaceRepository } from "./partner-market-place-repository";

const apigClient = newClient();

@Injectable()
export class AwsPartnerMarketPlaceRepository
  implements PartnerMarketPlaceRepository
{
  constructor(private session: Session) {}

  connect(svGln: string, id: string): Observable<void> {
    const retObject = new Subject<void>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      const params = {
        tradingPartnerGln: id,
      };

      apigClient
        .tradingpartnersConnectPost(params, params, additionalParams) //partner
        .then(() => {
          retObject.next(null);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });
    return retObject;
  }
}
