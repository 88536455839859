import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  DetailedSupplierReport,
  SupplierReport,
  TradingPartner,
} from "./../../../../models/types";
import { DialogService } from "./../../../../services/dialog.service";
import { NavigationService } from "./../../../../services/navigation.service";
import { NotificationService } from "./../../../../services/notification.service";
import { PrintingServiceService } from "./../../../../services/printing-service.service";
import { TradingPartnerService } from "./../../../../services/trading-partner.service";
import { Settings } from "./../../../../settings";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
const ELEMENT_DATA: SupplierReport[] = [];

@Component({
  selector: "app-detailed-supplier-report",
  templateUrl: "./detailed-supplier-report.component.html",
  styleUrls: ["./detailed-supplier-report.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DetailedSupplierReportComponent implements OnInit {
  displayedColumns: string[] = ["supplierGln", "supplierName", "download"];
  supplierReports: SupplierReport[] = [];
  suppliersList: TradingPartner[] = [];
  filteredSuppliersList: TradingPartner[] = [];
  selectedOptions: string[] = [];
  inactiveDates: number[] = [30, 60, 90];
  DetailedSupplierReportsForm: UntypedFormGroup;
  loader = false;
  noOfSelected = 0;
  buttonText = "";
  dateFormat;

  todaysDate = new Date();
  firstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 6,
    new Date().getUTCDate() - new Date().getUTCDate() + 1,
  );
  lastDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );
  toMaxDate: Date;
  fromMinDate: Date;
  dataSource = new MatTableDataSource<SupplierReport>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private navigate: NavigationService,
    private dialogService: DialogService,
    private notification: NotificationService,
    private settings: Settings,
    private printingService: PrintingServiceService,
  ) {}

  ngOnInit(): void {
    this.setupInputControls();
    this.getSuppliers();
    this.hookInputControls();
    this.dateFormat = this.settings.dateFormat;
  }

  hookInputControls() {
    this.buttonText =
      this.noOfSelected < 1
        ? "Generate Report"
        : `Generate ${this.noOfSelected} Reports`;

    this.DetailedSupplierReportsForm.controls.selectedSuppliers.valueChanges.subscribe(
      (glns) => {
        const filteredGlns = this.filteredSuppliersList.map((y) => y.gln);
        // We include all selected glns, as well as keep any previously selected glns that are not in the filtered list
        this.selectedOptions = [
          ...glns,
          ...this.selectedOptions.filter((x) => !filteredGlns.includes(x)),
        ];
        this.noOfSelected = this.selectedOptions.length;
        this.buttonText =
          this.noOfSelected < 1
            ? "Generate Report"
            : `Generate ${this.noOfSelected} Reports`;
        this.DetailedSupplierReportsForm.controls.selectedSuppliers.setValue(
          this.selectedOptions,
          {
            emitEvent: false,
          },
        );
      },
    );
  }

  resetFilteredSuppliersList() {
    this.filteredSuppliersList = this.suppliersList;
    this.DetailedSupplierReportsForm.controls.filteredSuppliersInput.setValue(
      "",
      {
        emitEvent: false,
      },
    );
  }

  getSelectedGls(): string[] {
    let selectedGls: string[] = [];
    selectedGls =
      this.DetailedSupplierReportsForm.controls.selectedSuppliers.value;
    return selectedGls;
  }

  generateReports() {
    this.getReports(this.fromMinDate, this.toMaxDate, this.getSelectedGls());
  }

  getSuppliers() {
    this.tradingPartnerService.getSuppliers().subscribe(
      (result) => {
        if (!result) return;
        this.suppliersList = result.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
        );
        this.filteredSuppliersList = this.suppliersList;
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  filterSuppliers(filterValue: string) {
    this.filteredSuppliersList = this.suppliersList.filter((supplier) =>
      supplier.name.toLowerCase().includes(filterValue.toLowerCase().trim()),
    );
  }

  getSelectedReportsLength(): number {
    return this.DetailedSupplierReportsForm.controls.selectedSuppliers.value.length();
  }

  setupInputControls() {
    this.DetailedSupplierReportsForm = this.formBuilder.group({
      searchReports: ["", null],
      selectedSuppliers: ["", null],
      fromDate: new UntypedFormControl([new Date(this.firstDay), null]),
      toDate: new UntypedFormControl([
        new Date(this.lastDay.setHours(23, 59, 59)),
        null,
      ]),
      filteredSuppliersInput: ["", null],
    });

    this.DetailedSupplierReportsForm.controls.fromDate.setValue(
      new Date(this.firstDay),
    );
    this.DetailedSupplierReportsForm.controls.toDate.setValue(
      new Date(this.lastDay.setHours(23, 59, 59)),
    );
    this.fromMinDate = this.DetailedSupplierReportsForm.controls.fromDate.value;
    this.toMaxDate = this.DetailedSupplierReportsForm.controls.toDate.value;
    this.DetailedSupplierReportsForm.controls.fromDate.valueChanges.subscribe(
      (x) => {
        this.fromMinDate = x;
      },
    );
    this.DetailedSupplierReportsForm.controls.toDate.valueChanges.subscribe(
      (x) => {
        this.toMaxDate = x;
      },
    );
  }

  getReports(fromDate: Date, toDate: Date, glns: string[]) {
    if (this.getSelectedGls().length < 1) {
      this.notification.showError(
        new Error("Please Select at least 1 Supplier first"),
      );
    } else if (fromDate > toDate) {
      this.notification.showError(
        new Error("From Date cannot be greater than To Date"),
      );
    } else {
      this.loader = true;
      this.tradingPartnerService
        .generateSupplierReports(fromDate.toString(), toDate.toString(), glns)
        .subscribe(
          (reports) => {
            if (reports) {
              this.loader = false;
              this.supplierReports = reports;
              this.dataSource.data = this.supplierReports;
              this.dataSource.paginator = this.paginator;

              this.tradingPartnerService.detailedSupplierReports.next(
                this.supplierReports,
              );
              this.navigate.navigateToDetailesSupplierReportsView();
            }
          },
          (error) => {
            this.loader = false;
            this.notification.showError(error);
          },
        );
    }
  }

  ViewReport(supplierReport: DetailedSupplierReport) {
    this.tradingPartnerService.orderReports.next(supplierReport);
    this.navigate.navigateToAdminReportsView();
  }

  search(filterValue: string) {
    this.dataSource.data = this.supplierReports.filter(
      (x) =>
        x.supplierName
          .toLowerCase()
          .includes(filterValue.trim().toLowerCase()) ||
        x.supplierGln.toLowerCase().includes(filterValue.trim().toLowerCase()),
    );
  }

  clearSearch() {
    this.DetailedSupplierReportsForm.controls.searchReports.value == "";
    this.DetailedSupplierReportsForm.controls.searchReports.value == ""
      ? (this.search(""), (this.paginator = this.dataSource.paginator))
      : {};
  }

  allSupplierGlns(): string[] {
    const glns: string[] = [];
    this.suppliersList.forEach((supplier) => {
      glns.push(supplier.gln);
    });
    return glns;
  }

  generateAllReports() {
    this.loader = true;
    this.tradingPartnerService
      .generateSupplierReports(
        this.fromMinDate.toString(),
        this.toMaxDate.toString(),
        this.allSupplierGlns(),
      )
      .subscribe(
        (reports) => {
          if (reports) {
            this.loader = false;
            this.supplierReports = reports;
            this.dataSource.data = this.supplierReports;
            // this.paginator = this.dataSource.paginator
            this.dataSource.paginator = this.paginator;
          }
        },
        (error) => {
          this.loader = false;
          this.notification.showError(error);
        },
      );
  }

  downloadReport(orderReport: SupplierReport) {
    this.printingService.generateSupplierReport([orderReport]);
  }

  downloadAllReports() {
    this.printingService.generateSupplierReport(this.supplierReports);
  }
}
