<div class="flex fx-jc-center m-b-50 fx-ai-center grayBG">
  <div class="width-90 flex m-30 fx-jc-center">
    <form [formGroup]="ordersForm">
      <div class="flex fx-row m-b-30">
        <p class="title">{{ (tradingPartner | async)?.name }}</p>
      </div>
      <div
        *ngIf="getApplicationStatus && srfApplication"
        class="widsth-95 p-10 fx-ai-center tradeCard flex fx-row"
      >
        <p class="P-l noMargin">
          Your Invoice Finance Application has been approved
        </p>
        <div class="m-l-auto">
          <button
            mat-flat-button
            type="button"
            class="textOnly redText btn round noMargin"
            (click)="NavigateToInvoices()"
          >
            View Invoices >
          </button>
        </div>
      </div>
      <div
        *ngIf="!getApplicationStatus && srfApplication"
        class="dashboardTitle tradeCard p-10 m-b-15 flex manageButtons fx-row"
      >
        <p class="P-l noMargin">
          Need funding? Unlock business capital with our financing options.
        </p>
        <div class="m-l-auto">
          <button
            matRipple
            type="button"
            class="flex m-l-auto selectedItem fx-row fx-ai-center fx-jc-center"
            (click)="getFinanced()"
          >
            {{ "Get Financed" | uppercase }}
            <mat-icon class="flex">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="isAdmin()" class="flex fx-row">
        <p
          (click)="goToSupplierView()"
          class="noMargin selectedItem breadcrumb fw-700 paragraph"
        >
          Supplier View
        </p>
        <img
          class="icon flex p-r-10 p-l-10"
          width="200px"
          src="./assets/icons/rightArrowBlack.svg"
        />
        <p
          class="paragraph noMargin breadcrumb p-r-10 flex fx-jc-center fx-ai-center"
        >
          Orders
        </p>
      </div>
      <div class="m-b-40"></div>
      <div class="fx-row flex fx-jc-start">
        <div class="flex fx-jc-center fx-ai-center m-b-20 m-r-20">
          <mat-slide-toggle formControlName="allOrders" color="warn"
            >Show all orders?</mat-slide-toggle
          >
        </div>

        <div class="datePicker width-30">
          <div
            [ngClass]="{
              'tradeCard animate__animated animate__fadeIn': !showAllOrders
            }"
            class="width-50 datePickOrder round noMargin fx-ai-center fx-jc-center fx-row flex"
          >
            <mat-form-field class="">
              <mat-label>From Date</mat-label>
              <input
                [disabled]="showAllOrders"
                matInput
                [max]="toMaxDate"
                [matDatepicker]="fromDate"
                formControlName="fromDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="">
              <mat-label>To Date</mat-label>
              <input
                [disabled]="showAllOrders"
                matInput
                [min]="fromMinDate"
                [max]="todaysDate"
                [matDatepicker]="toDate"
                formControlName="toDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="toDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="width-50 flex">
          <div class="width-50 flex fx-row fx-jc-center fx-ai-center">
            <mat-form-field
              class="flex orderSearchField tradeCard fx-jc-center fx-ai-center width-100 round p-r-30 p-l-30"
            >
              <input
                (keyup.enter)="search()"
                type="input"
                (focus)="enableSearch()"
                formControlName="searchText"
                matInput
                placeholder=""
              />
              <mat-label> <mat-icon>search</mat-icon> Search </mat-label>
            </mat-form-field>
          </div>
          <div class="m-b-20"></div>
        </div>
      </div>
    </form>

    <div #elem class="flex fx-col tradeCard">
      <div class="flex fx-jc -center fx-row">
        <p class="p-30 heading flex fx-jc-center">Orders</p>
        <div class="m-l-auto flex fx-jc-center fx-ai-center m-r-20">
          <div
            *ngIf="orderStatusType === 'New'"
            class="flex fx-jc-center fx-ai-center downloadAllBtn"
          >
            <!-- toggleDownloadAll -->
            <div
              *ngIf="!downloadAllToggle"
              class="flex fx-ai-center manageButtons fx-row"
            >
              <button
                *ngIf="orderStatusType === 'New'"
                matRipple
                class="fx-row fx-ai-center flex selectedItem"
                (click)="toggleDownloadAll()"
                [disabled]="disableDownloadAll"
              >
                {{ "Download All" | uppercase }}
                <img
                  [ngClass]="{ animate__rotate: clicked }"
                  src="assets/icons/downloadIcon.svg"
                  width="20"
                  class="animate__animated"
                />
              </button>
            </div>
            <div
              *ngIf="downloadAllToggle"
              class="flex fx-jc-center manageButtons"
            >
              <button
                *ngIf="orderStatusType === 'New'"
                matRipple
                class="fx-row fx-ai-center flex selectedItem"
                (click)="printAllOrders('print')"
              >
                {{ "Pdf" | uppercase }}
                <img
                  [ngClass]="{ animate__rotate: clicked }"
                  src="assets/icons/downloadIcon.svg"
                  width="20"
                  class="animate__animated p-l-20 m-l-auto"
                />
              </button>
              <button
                *ngIf="orderStatusType === 'New'"
                matRipple
                class="fx-row fx-ai-center flex selectedItem"
                (click)="printAllOrders('exportCVS')"
              >
                {{ "Excel" | uppercase }}
                <img
                  [ngClass]="{ animate__rotate: clicked }"
                  src="assets/icons/downloadIcon.svg"
                  width="20"
                  class="animate__animated p-l-20 m-l-auto"
                />
              </button>

              <button
                *ngIf="orderStatusType === 'New'"
                matRipple
                class="fx-row fx-ai-center flex selectedItem"
                (click)="toggleDownloadAll()"
              >
                <img
                  [ngClass]="{ animate__rotate: clicked }"
                  src="assets/icons/cancelIcon.svg"
                  width="20"
                  class="animate__animated"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <mat-tab-group
        [selectedIndex]="getSelectedIndex()"
        class="width-100 flex height-20"
        dynamicHeight="true"
        (selectedTabChange)="onTabChanged($event)"
        #tabs
      >
        <mat-tab label="New" class="width-100">
          <app-order-table
            #newOrdersComponent
            (onPrintClose)="onPrintClose()"
            [sender]="senderGln"
            (loadMore)="loadMoreOrders($event)"
            [orderStatusType]="'New'"
            [orderResult]="newOrders"
            [exportCVS]="exportCVS"
            [printOrdersTrigger]="printOrdersTrigger"
            [exportOrdersTrigger]="exportOrdersTrigger"
            [toMaxDate]="toMaxDate"
            [fromMinDate]="fromMinDate"
            (value)="enablePrintButtons($event)"
          >
          </app-order-table>
        </mat-tab>
        <mat-tab label="Acknowledged" class="width-100">
          <app-order-table
            [orderResult]="acknowledgedOrders"
            [sender]="senderGln"
            [toMaxDate]="toMaxDate"
            [fromMinDate]="fromMinDate"
            (loadMore)="loadMoreOrders($event)"
            [orderStatusType]="'Acknowledged'"
            [searchText]="searchText"
            [exportCVS]="exportCVS"
            [printOrdersTrigger]="printOrdersTrigger"
            [exportOrdersTrigger]="exportOrdersTrigger"
            (value)="enablePrintButtons($event)"
          ></app-order-table>
        </mat-tab>
        <mat-tab label="Confirmed" class="width-100">
          <app-order-table
            [orderResult]="confirmedOrders"
            [sender]="senderGln"
            [toMaxDate]="toMaxDate"
            [fromMinDate]="fromMinDate"
            (loadMore)="loadMoreOrders($event)"
            [orderStatusType]="'Confirmed'"
            [searchText]="searchText"
            [exportCVS]="exportCVS"
            [printOrdersTrigger]="printOrdersTrigger"
            [exportOrdersTrigger]="exportOrdersTrigger"
            (value)="enablePrintButtons($event)"
          >
          </app-order-table>
        </mat-tab>
        <mat-tab label="Rejected" class="width-100">
          <app-order-table
            [orderResult]="rejectedOrders"
            [sender]="senderGln"
            [toMaxDate]="toMaxDate"
            [fromMinDate]="fromMinDate"
            (loadMore)="loadMoreOrders($event)"
            [orderStatusType]="'Rejected'"
            [searchText]="searchText"
            [exportCVS]="exportCVS"
            [printOrdersTrigger]="printOrdersTrigger"
            [exportOrdersTrigger]="exportOrdersTrigger"
            (value)="enablePrintButtons($event)"
          >
          </app-order-table>
        </mat-tab>
        <mat-tab label="Invoiced" class="width-100">
          <app-order-table
            [orderResult]="invoicedOrders"
            [sender]="senderGln"
            [toMaxDate]="toMaxDate"
            [fromMinDate]="fromMinDate"
            (loadMore)="loadMoreOrders($event)"
            [orderStatusType]="'Invoiced'"
            [searchText]="searchText"
            [exportCVS]="exportCVS"
            [printOrdersTrigger]="printOrdersTrigger"
            [exportOrdersTrigger]="exportOrdersTrigger"
            (value)="enablePrintButtons($event)"
          >
          </app-order-table>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div *ngIf="showPrintLayoutOrder">
  <app-print-layout
    (onClose)="onClose()"
    [data]="printDataOrders"
    [type]="downloadType"
  ></app-print-layout>
</div>
