import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import _ from "underscore";
import {
  Order,
  OrderLine,
  OrderStatus,
  Vat,
} from "../../../../../models/order";
import { DialogService } from "../../../../../services/dialog.service";
import { Settings } from "../../../../../settings";
import { NotificationService } from "./../../../../../services/notification.service";
import { OrdersService } from "./../../../../../services/orders.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class InvoiceComponent implements OnInit {
  @Output() statusChange: EventEmitter<string> = new EventEmitter();
  @Output() updateInvoiceNumber: EventEmitter<string> = new EventEmitter();
  @Output() updateInvoiceDate: EventEmitter<string> = new EventEmitter();
  @Output() updateInvoiceAmount: EventEmitter<string> = new EventEmitter();
  @Output() enableInvoiceButton: EventEmitter<boolean> = new EventEmitter();
  @Input() invoiceTotal: number;
  @Input() currentOrder: Order;
  invoiceTotal2: number;
  todayDate: Date = new Date();
  orderTotal = "";
  capturedVal = "";

  invoiceEditorForm: UntypedFormGroup;
  matchInvoiceNumber: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private orderService: OrdersService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private settings: Settings,
  ) {}

  ngOnInit(): void {
    this.invoiceEditorForm = this.formBuilder.group({
      supplierInvoiceNo: ["", [Validators.required]],
      totalInvoiceAmount: [null, [Validators.required]],
      invoiceDate: [this.todayDate, Validators.required],
    });
    this.hookInvoiceEvents();
  }

  submitInvoice() {
    this.orderService.update(this.currentOrder).subscribe(
      () => {
        _.each(this.currentOrder.lines as ExtendedOrderLine[], (orderLine) => {
          orderLine.tempQuantity = orderLine.quantity;
        });

        this.dialogService
          .showConfirmationDialog(
            "Confirm Order Invoice",
            "Are you sure you want to invoice the order?",
          )
          .afterClosed()
          .subscribe((result) => {
            const loader = this.dialogService.loader();
            if (result) {
              const id = this.currentOrder.id;
              const invoiceNumber =
                this.invoiceEditorForm.controls.supplierInvoiceNo.value;
              const invoiceDate =
                this.invoiceEditorForm.controls.invoiceDate.value;
              const invoiceTotal =
                this.invoiceEditorForm.controls.totalInvoiceAmount.value;

              this.orderService
                .invoice(id, invoiceNumber, invoiceTotal, invoiceDate)
                .subscribe(
                  (x) => {
                    if (x != null) {
                      this.statusChange.emit(OrderStatus.Invoiced);
                    }
                    this.orderService
                      .get(this.currentOrder.id)
                      .subscribe((result) => {
                        this.currentOrder = result;
                        loader.close();
                      });
                  },
                  () => {
                    this.statusChange.emit(OrderStatus.Confirmed);
                  },
                );
            }
          });
      },
      () => {
        _.each(this.currentOrder.lines as ExtendedOrderLine[], (orderLine) => {
          orderLine.quantity = orderLine.tempQuantity;
        });
        this.notificationService.showError(
          new Error("Something went wrong updating order"),
        );
      },
    );
  }

  hookInvoiceEvents() {
    this.invoiceEditorForm.controls.supplierInvoiceNo.valueChanges.subscribe(
      (x) => {
        this.updateInvoiceNumber.emit(x);
      },
    );

    this.orderService.updateTempTotal.subscribe((x) => {
      this.orderTotal = parseFloat(x.toString())?.toFixed(2);
      this.matchInvoiceNumber = this.capturedVal == this.orderTotal;
      this.updateInvoiceAmount.emit(x.toString());
    });

    this.invoiceEditorForm.controls.totalInvoiceAmount.valueChanges.subscribe(
      (x) => {
        this.capturedVal = parseFloat(x).toFixed(2);
        this.orderTotal = parseFloat(
          this.getTotalLineCost().toString(),
        )?.toFixed(2);
        this.matchInvoiceNumber = this.capturedVal == this.orderTotal;
        this.updateInvoiceAmount.emit(x);
      },
    );

    this.invoiceEditorForm.controls.invoiceDate.valueChanges.subscribe(() => {
      const date =
        this.invoiceEditorForm.controls.invoiceDate.value._d.toISOString();
      this.updateInvoiceDate.emit(date);
    });

    this.invoiceEditorForm.valueChanges.subscribe(() => {
      this.invoiceEditorForm.valid
        ? this.enableInvoiceButton.emit(true)
        : this.enableInvoiceButton.emit(false);
    });
  }

  getTotalLineCost(): number {
    const total = this.currentOrder.lines
      .map((t) => {
        const itemCost = parseFloat(t.itemCost as any);
        const qty = parseInt(t.quantity as any);
        const lineCost = itemCost * qty;
        const vatRate = parseInt(t.vat?.rate as any);

        if (!vatRate || vatRate == 0) {
          return lineCost;
        }

        return lineCost + (lineCost * vatRate) / 100;
      })
      .reduce((acc, value) => acc + value, 0);

    return total;
  }
}

class ExtendedOrderLine implements OrderLine {
  productEan: string;
  productDescription: string;
  quantity: number;
  vat: Vat;
  originalQuantity: number;
  tempQuantity: number;
  itemCost: number;
  lineItemCost: number;
  packBarcode: string;
  unitBarcode: string;
  orderUnitBarcode: string;
  productCode: string;
  packSize?: any;
  lineNarrative?: any;
}
