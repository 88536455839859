import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Settings } from "../../../../settings";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  todaysDate = new Date();
  tomorrowsDate = this.todaysDate.setDate(this.todaysDate.getDate() + 1);
  firstDay: Date = new Date();
  lastDay: Date = new Date();
  toMaxDate: Date;
  fromMinDate: Date;
  @ViewChild("open", { static: false }) open: ElementRef | null;
  @ViewChild("fromDate1") fromDate1: ElementRef;
  @ViewChild("fromDate2") fromDate2: ElementRef;
  title: string;
  message: string;
  name: string;
  declineButtonText: string;
  acceptButtonText: string;
  setDate: Date = new Date();
  dateFormat;
  dateChanged = false;
  invoiceGraphsFormGroup: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<DatePickerComponent>,
    private settings: Settings,
    private formBuilder: UntypedFormBuilder,
  ) {}
  ngAfterViewInit(): void {
    (this.open as any)._elementRef.nativeElement.click();
  }

  ngOnInit(): void {
    this.dateFormat = this.settings.dateFormat;
    this.setupInputControls();
    this.fromMinDate = new Date(this.tomorrowsDate);

    // TODO FIX BAD
    this.invoiceGraphsFormGroup.controls.date.valueChanges.subscribe((x) => {
      const selectedDate = new Date(x);
      this.setDate = new Date(
        selectedDate.setTime(
          selectedDate.getTime() + new Date().getHours() * 60 * 60 * 1000,
        ),
      ); // new Date() on API would yesterday 22:00
      this.confirm();
      this.dateChanged = true;
    });
  }

  setupInputControls() {
    this.invoiceGraphsFormGroup = new UntypedFormGroup({
      date: new UntypedFormControl(),
    });
  }

  confirm(): void {
    console.log(
      "check dates",
      this.setDate,
      this.todaysDate,
      new Date(this.tomorrowsDate),
    );
    this.dialogRef.close(this.setDate);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  onClosed() {
    this.dateChanged == true ? this.confirm() : this.cancel();
  }
}
