<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-30 fx-jc-center">
    <div class="flex fx-row m-30">
      <button
        matRipple
        mat-flat-button
        type="button"
        class="flex cancelBtn selectedItem m-r-10 fx-row"
        (click)="navigateBack()"
      >
        <mat-icon class="p-r-10">keyboard_backspace</mat-icon>Back
      </button>
    </div>
    <div #tradeCard class="tradeCard p-30 m-30">
      <div class="flex fx-ro w">
        <p class="heading flex">Change History</p>

        <div class="grayBanner flex p-20 m-b-20 fx-row">
          <div class="flex fx-row">
            <p class="p-b p-r-10">{{ getLength() }}</p>
            <p class="flex">Changes Made</p>
          </div>
        </div>
      </div>

      <table
        matTableFilter
        mat-table
        [dataSource]="dataSource"
        class="width-100"
      >
        <!-- productCode Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dateTime | date : dateFormat }}
          </td>
        </ng-container>

        <!-- productCode Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
        </ng-container>

        <!-- description Column -->
        <ng-container matColumnDef="property">
          <th mat-header-cell *matHeaderCellDef>description</th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>

        <!-- description Column -->
        <ng-container matColumnDef="oldValue">
          <th mat-header-cell *matHeaderCellDef>Old Value</th>
          <td mat-cell *matCellDef="let element">{{ element.oldValue }}</td>
        </ng-container>

        <!-- description Column -->
        <ng-container matColumnDef="newValue">
          <th mat-header-cell *matHeaderCellDef>New Value</th>
          <td mat-cell *matCellDef="let element">{{ element.newValue }}</td>
        </ng-container>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation(); manageProduct(row)"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              formControlName="selected"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
