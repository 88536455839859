<h2>Order invoice</h2>
<div
  class="flex fx-row fx-jc-center fx-ai-center"
  [ngClass]="{ 'm-b-30': matchInvoiceNumber }"
>
  <form class="invoice flex fx-row" [formGroup]="invoiceEditorForm">
    <mat-form-field
      class="width-100 p-t-5 round m-r-20 xs-card-t p-r-20 p-l-20"
    >
      <mat-label>Supplier invoice number</mat-label>
      <input
        matInput
        formControlName="supplierInvoiceNo"
        placeholder="Supplier invoice number"
      />
      <mat-error
        *ngIf="
          invoiceEditorForm.controls.supplierInvoiceNo.hasError('required')
        "
      >
        Supplier invoice number is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="width-100 p-t-5 round m-r-20 xs-card-t p-r-20 p-l-20"
    >
      <mat-label>Total invoice amount</mat-label>
      <input
        matInput
        formControlName="totalInvoiceAmount"
        placeholder="Total invoice amount"
        pattern="^\d+(?:[,.]\d+)?$"
      />
      <mat-error
        *ngIf="
          invoiceEditorForm.controls.totalInvoiceAmount.hasError('required')
        "
      >
        Invoice total is <strong>required</strong>
      </mat-error>
      <mat-error
        *ngIf="
          invoiceEditorForm.controls.totalInvoiceAmount.hasError('pattern')
        "
      >
        Numeric value <strong>only</strong>
      </mat-error>
      <mat-error
        *ngIf="
          invoiceEditorForm.controls.totalInvoiceAmount.hasError(
            'lessThanLineCost'
          )
        "
      >
        Invoice amount must match <strong>Line Cost incl VAT</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="m-r-20 round xs-card-t p-r-20 p-l-20">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="invoiceDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>

  <button
    class="invoicebtn btn width-20 m-r-20 round p-r-20 p-l-20"
    type="submit"
    mat-raised-button
    [disabled]="
      !this.invoiceEditorForm.valid ||
      !matchInvoiceNumber ||
      invoiceEditorForm.controls.totalInvoiceAmount.value === 0
    "
    (click)="submitInvoice()"
  >
    Submit invoice
  </button>
</div>
<mat-error
  *ngIf="
    invoiceEditorForm.controls.totalInvoiceAmount.value > 0 &&
    !matchInvoiceNumber
  "
>
  Invoice amount must match <strong>Line Cost incl VAT</strong>
</mat-error>
<mat-error *ngIf="invoiceEditorForm.controls.totalInvoiceAmount.value === 0">
  Cannot invoice an order of <strong>R0</strong>
</mat-error>
