<div class="scroll">
  <div class="flex fx-ai-center fx-row m-10">
    <p class="heading">{{ title }}</p>
  </div>
  <mat-divider></mat-divider>
  <form class="example-form flex" [formGroup]="editTradingPartnerForm">
    <div>
      <!-- Type -->
      <div *ngIf="action === 'Add'" id="type-container">
        <mat-form-field class="width-90">
          <mat-select
            class="tradeCard p-l-20 p-r-20 p-t-10 p-b-10 round"
            formControlName="tradingPartnerType"
          >
            <mat-option *ngFor="let type of tradingPartnerTypes" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Image -->
      <div id="img-container" *ngIf="!tpIsStore">
        <div
          *ngIf="tradingPartner?.imageURL !== null"
          class="p-l-20 p-r-20 ss-card-t"
        >
          <img
            class="medium-image"
            src="{{ tradingPartner?.imageURL }}"
            alt="{{ tradingPartner?.name }}"
          />
        </div>
        <div class="p-l-20 p-r-20">
          <input
            class="p-l-20 p-r-20"
            style="display: none"
            type="file"
            formControlName="file"
            (change)="chosenFile($event.target.files)"
            accept="images"
            accept=".png, .jpg"
            #fileInput
          />
          <div mat-dialog-actions>
            <button
              mat-flat-button
              type="button"
              class="width-100 flex round selectedItem tertiaryBtn"
              (click)="fileInput.click()"
            >
              Select Logo
            </button>
          </div>
        </div>
        <div class="p-l-20 p-r-20 m-t-20">
          {{ selectedFile1 ? selectedFile1.name : "" }}
        </div>
      </div>
    </div>
    <div class="flex fx-row">
      <!-- General info -->
      <mat-card id="details-card">
        <mat-card-header>
          <mat-card-title>Details</mat-card-title>
        </mat-card-header>
        <div mat-card-image></div>
        <mat-card-content>
          <div class="fx-row flex">
            <div>
              <mat-form-field
                *ngIf="action !== 'Edit'"
                class="example-full-width noMrgin .sss-card-t p-r-20 p-l-20"
              >
                <mat-label>gln</mat-label>
                <div class="">
                  <input
                    matInput
                    formControlName="gln"
                    pattern="^[0-9]+([,.][0-9]+)?$"
                    [minlength]="13"
                    [maxlength]="13"
                  />
                </div>
                <mat-error
                  *ngIf="
                    editTradingPartnerForm.controls.gln.hasError('required')
                  "
                >
                  gln is <strong>required</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    editTradingPartnerForm.controls.gln.hasError('pattern')
                  "
                >
                  Numeric values <strong>only</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    editTradingPartnerForm.controls.gln.hasError('minlength')
                  "
                >
                  gln has to be <strong>13</strong> numbers
                </mat-error>
              </mat-form-field>

              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>name</mat-label>
                <div class=""><input matInput formControlName="name" /></div>
              </mat-form-field>

              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>contact name</mat-label>
                <div class="">
                  <input matInput formControlName="contactName" />
                </div>
              </mat-form-field>

              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>contact surname</mat-label>
                <div class="">
                  <input matInput formControlName="contactSurname" />
                </div>
              </mat-form-field>

              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>email address</mat-label>
                <div class=""><input matInput formControlName="email" /></div>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>tel no</mat-label>
                <div class="">
                  <input
                    matInput
                    formControlName="cellNumber"
                    pattern="^[\d ()+-]+$"
                    [minlength]="10"
                    [maxlength]="15"
                  />
                </div>
                <mat-error
                  *ngIf="
                    editTradingPartnerForm.controls.cellNumber.hasError(
                      'required'
                    )
                  "
                >
                  tel no is <strong>required</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    editTradingPartnerForm.controls.cellNumber.hasError(
                      'pattern'
                    )
                  "
                >
                  Numeric values <strong>only</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    editTradingPartnerForm.controls.cellNumber.hasError(
                      'minlength'
                    )
                  "
                >
                  tel no has to be at least <strong>10</strong> numbers
                </mat-error>
              </mat-form-field>

              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>fax no</mat-label>
                <div class="">
                  <input matInput formControlName="faxNumber" />
                </div>
              </mat-form-field>

              <mat-form-field
                class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
              >
                <mat-label>vat no</mat-label>
                <div class="">
                  <input matInput formControlName="vatNumber" />
                </div>
              </mat-form-field>

              <mat-form-field class="p-r-20 p-l-20">
                <mat-label>vat country code</mat-label>
                <mat-select
                  formControlName="vatCountryCode"
                  placeholder="Select Country Code"
                >
                  <mat-option
                    *ngFor="let countryCode of vatCountryCodes"
                    [value]="countryCode"
                  >
                    {{ countryCode }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Address -->
      <mat-card id="address-card" class="noMrgin">
        <mat-card-header class="noMrgin">
          <mat-card-title>Address</mat-card-title>
        </mat-card-header>
        <div mat-card-image></div>
        <mat-card-content>
          <mat-form-field class="p-t-5 p-r-20 p-l-20">
            <mat-label>line 1</mat-label>
            <div class="">
              <input matInput formControlName="addressLine1" />
            </div>
          </mat-form-field>
          <mat-form-field
            class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
          >
            <mat-label>line 2</mat-label>
            <input matInput formControlName="addressLine2" />
          </mat-form-field>
          <mat-form-field
            class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
          >
            <mat-label>line 3</mat-label>
            <input matInput formControlName="addressLine3" />
          </mat-form-field>
          <mat-form-field
            class="example-full-width noMrgin .ss-card-t p-r-20 p-l-20"
          >
            <mat-label>line 4</mat-label>
            <input matInput formControlName="addressLine4" />
          </mat-form-field>
          <mat-form-field
            class="example-full-width noMrgin p-b-0 .ss-card-t p-r-20 p-l-20"
          >
            <mat-label>postal code</mat-label>
            <input matInput formControlName="postalCode" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
    <div></div>
  </form>

  <div style="justify-content: space-between" class="flex fx-row m-t-10 m-b-10">
    <button
      mat-flat-button
      class="width-45 round cancelBtn fl flexex"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      [disabled]="!canEdit"
      class="width-45 round primaryBtn fl flexex"
      (click)="submit()"
    >
      <span class="flex fx-row fx-jc-center fx-ai-center"
        >Submit
        <mat-spinner
          *ngIf="loader"
          class="m-l-5"
          strokeWidth="3"
          [diameter]="15"
        ></mat-spinner
      ></span>
    </button>
  </div>
</div>
