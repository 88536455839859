import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable()
export class Settings {
  public readonly dateFormat: string = "dd MMM yyyy";

  public readonly defaultItemsPerPage: number = 20;

  public sciCustomerLink: string = environment.production
    ? "https://scicustomer.primerevenue.com/invoiceUploads/uploadWizard"
    : "https://uat.scicustomer.primerevenue.com/invoiceUploads/uploadWizard";

  public addendumLink: string = environment.production
    ? "https://scicustomer.primerevenue.com/customizedreports"
    : "https://uat.scicustomer.primerevenue.com/customizedreports";
}
