<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <form class="flex m-t-10 width-100" [formGroup]="PartnerMarketplaceForm">
      <div class="flex fx-row">
        <p class="p-b-30 title">{{ (tradingPartner | async)?.name }}</p>
      </div>
      <div class="flex fx-row">
        <mat-form-field class="width-20 round tradeCard p-l-20">
          <mat-select
            formControlName="partnerType"
            (selectionChange)="filterTradingPartners($event)"
          >
            <mat-option
              *ngFor="let marketplacePartner of marketplacePartnerTypes"
              [value]="marketplacePartner.id"
            >
              {{ marketplacePartner.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="width-20 m-l-auto flex round xs-card-t p-r-20 p-l-20">
          <mat-form-field>
            <input
              matInput
              (keyup.enter)="search()"
              (blur)="clearSearch()"
              formControlName="searchMarketplacePartner"
            />
            <mat-label
              ><mat-icon>search</mat-icon>Search by name or gln</mat-label
            >
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
      <p class="heading flex p-b-10">Partner Network</p>

      <div class="grayBanner flex p-20 m-b-50 fx-row">
        <div class="flex fx-row">
          <span class="fw-900 flex fx-row m-r-10">{{ partners.length }}</span>
          <span class="flex">Partners</span>
        </div>
      </div>

      <div class="p-10 p-b-30 partnerMarketCards">
        <div
          class="animate__animated animate__fadeIn tradeCardNoShadow partnerCard"
          *ngFor="let partner of partners"
        >
          <div class="heighgt-80 p -10">
            <div class="flex m-t-20">
              <div class="imgHolder flex fx-ai-center fx-jc-center">
                <div
                  *ngIf="!partner.imageURL"
                  class="noMargin flex fx-ai-center fx-jc-center"
                >
                  <img
                    class="noMargin notAvailableImage"
                    src="../assets/images/imageNotAvailable.jpg"
                  />
                </div>
                <img
                  *ngIf="partner.imageURL"
                  src="{{ partner.imageURL }}"
                  alt="{{ partner.imagePath }}"
                />
              </div>
              <p class="p-l-20 p-t-10">{{ partner.name }}</p>
            </div>
          </div>
          <div
            *ngIf="!tradingPartnerWith"
            [disable]="
              !tradingPartnerWith && partner.connectionStatus !== 'Pending'
            "
            matRipple
            class="bl-t-l selectedItem flex fx-jc-center fx-ai-center"
            (click)="connectPartner(partner)"
          >
            <h3 class="flex fx-jc-center fx-ai-center redButton">connect me</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
