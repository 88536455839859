<div class="flex fx-jc-center fx-ai-center grayBG m-b-70">
  <div class="width-90 flex m-20 fx-jc-center">
    <div class="flex fx-row">
      <h1 class="fw-500 m-b-30 title">Tiger Brands</h1>
    </div>

    <div class="tradeCard p-l-20 p-r-20 p-t-30 m-t-30">
      <h2 class="subTitle flex">Congratulations!</h2>

      <div class="flex p-10 fx-r ow">
        <div
          class="flex p-l-30 height-10 fx-row selectedRow fx-jc-center"
          style="overflow: visible"
        >
          <h3 class="flex fw-400 width-100 fx-jc-center">
            Your Business Cash Advance is on its way.
          </h3>

          <img
            src="assets/images/vectors/finance.svg"
            width="250"
            class="animate__animated p-20"
            style="overflow: visible"
          />
        </div>
      </div>
      <p class="p-l-10">
        Your application is a success. Please check your email with the
        confirmation and contract details.
      </p>
    </div>

    <div class="flex fx-jc-center fx-row m-t-30">
      <button
        mat-flat-button
        (click)="submit()"
        class="flex width-20 fx-ai-center round btn primaryBtn"
      >
        My profile
      </button>
    </div>
  </div>
</div>
