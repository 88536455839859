import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { BehaviorSubject, Observable } from "rxjs";
import _ from "underscore";
import { Order, OrderStatus } from "../../models/order";
import { Session } from "../../models/session";
import { SrfApplicationStatus } from "../../models/srfApplication";
import { OrderSummary, QueryResult, TradingPartner } from "../../models/types";
import { DialogService } from "../../services/dialog.service";
import { FinanceService } from "../../services/finance.service";
import { NavigationService } from "../../services/navigation.service";
import { NotificationService } from "../../services/notification.service";
import { UiDataService } from "../../services/order-uidata.service";
import { OrdersService } from "../../services/orders.service";
import { TradingPartnerService } from "../../services/trading-partner.service";
import { Settings } from "../../settings";
import { OrderNewComponent } from "../home/orders/order-new/order-new.component";
import { OrderTableComponent } from "./order-table/order-table.component";

const dateFormat = new Settings().dateFormat;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class OrdersComponent
  implements OnInit, AfterContentInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild("elem") tableToPrint;
  @ViewChild(OrderTableComponent) newOrdersComponent: OrderTableComponent;
  @ViewChild("tabs", { static: false }) tabGroup: MatTabGroup;
  type: string;
  @Input() selectedTabIndex: string;
  public senderGln: string;
  public status: string;
  public searchStatus: string[] = [];
  public showAllOrders = false;
  public printDataOrders: OrderSummary[];
  favoriteSeason: string;
  statuses: { id: number; status: string }[] = [
    { id: 0, status: "New" },
    { id: 1, status: "Acknowledged" },
    { id: 2, status: "Confirmed" },
    { id: 3, status: "Rejected" },
    { id: 4, status: "Invoiced" },
    { id: 5, status: "All" },
  ];

  orderNewComponent: OrderNewComponent;
  orderTableComponent: OrderTableComponent;
  currTab = "New";
  userName = "";
  searchText = "";
  parentVal = 0;
  noOrders = 0;
  allOrders: Order[] = [];
  ordersForm: UntypedFormGroup;
  searchTextControl: UntypedFormControl;
  statusFormControl: UntypedFormControl;
  newCheckbox: UntypedFormControl;
  acknowledgedCheckbox: UntypedFormControl;
  confirmedCheckbox: UntypedFormControl;
  rejectedCheckbox: UntypedFormControl;
  invoicedCheckbox: UntypedFormControl;
  allCheckbox: UntypedFormControl;
  exportCVS = false;
  loadOrders = false;
  loadTradingPartner = false;
  clicked = false;
  enableButtons = false;
  printOrdersTrigger = false;
  exportOrdersTrigger = false;
  dateFormat;
  loadMoreOrdersRequest: QueryResult;
  cancelBtnText = "Cancel";
  searchToggle = false;
  toggleSearchButton = false;
  srfApplication = false;

  showPrintLayoutOrder = false;

  tradingPartner: Observable<TradingPartner>;
  currentIndex = 0;

  activeOrders: OrderSummary[];
  newOrders: BehaviorSubject<QueryResult> = new BehaviorSubject<QueryResult>(
    null,
  );
  acknowledgedOrders: BehaviorSubject<QueryResult> =
    new BehaviorSubject<QueryResult>(null);
  confirmedOrders: BehaviorSubject<QueryResult> =
    new BehaviorSubject<QueryResult>(null);
  invoicedOrders: BehaviorSubject<QueryResult> =
    new BehaviorSubject<QueryResult>(null);
  rejectedOrders: BehaviorSubject<QueryResult> =
    new BehaviorSubject<QueryResult>(null);
  allInvoicedOrders: BehaviorSubject<QueryResult> =
    new BehaviorSubject<QueryResult>(null);
  allRejectedOrders: BehaviorSubject<QueryResult> =
    new BehaviorSubject<QueryResult>(null);

  private invoicedOrdersLoaded = false;
  private rejectedOrdersLoaded = false;
  todaysDate = new Date();
  firstDay: Date = new Date();
  lastDay: Date = new Date();
  toMaxDate: Date;
  fromMinDate: Date;
  downloadAllToggle = false;
  downloadType: string;
  orderStatusType = "New";
  getApplicationStatus = false;
  disableDownloadAll = true;

  constructor(
    private uiData: UiDataService,
    private orderService: OrdersService,
    private tradingPartnerService: TradingPartnerService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private session: Session,
    private formBuilder: UntypedFormBuilder,
    private settings: Settings,
    private dialogService: DialogService,
    private financeService: FinanceService,
  ) {}
  ngOnDestroy(): void {
    this.tradingPartnerService.supplierTradingPartnerSelected.next(false);
  }

  ngOnChanges(): void {
    this.dateFormat = dateFormat;
  }

  ngAfterContentInit() {
    this.setCurrentIndexFromStatus();

    if (this.currentIndex == 4 && !this.invoicedOrdersLoaded) {
      this.loadInvoicedOrders(10);
    }
  }

  ngAfterViewInit() {
    this.setCurrentIndexFromStatus();
  }

  ngOnInit() {
    this.getSessionDates();
    this.tradingPartnerService.supplierTradingPartnerSelected.next(true);
    this.dateFormat = this.settings.dateFormat;
    this.loadTradingPartner = true;
    this.getQueryStringParams();
    this.setupInputControls();
    this.hookFormChanges();
    this.userName = this.uiData.getUserName();
    this.loadAllOrders(10);
    this.getInvoiceApplicationStatus();
    this.srfApplication = this.session.getSRFApplicationToggle();
    this.srfApplication = this.session.getSRFApplicationToggle();
  }

  loadMoreOrders(event: { status: string; skip: number }) {
    this.orderService
      .search(
        this.senderGln,
        this.searchText,
        [event.status],
        this.fromMinDate,
        this.toMaxDate,
        10,
        event.skip,
      )
      .subscribe(
        (result) => {
          if (event.status == "New") {
            this.newOrders.next(result);
          } else if (event.status == "Acknowledged") {
            this.acknowledgedOrders.next(result);
          } else if (event.status == "Confirmed") {
            this.confirmedOrders.next(result);
          } else if (event.status == "Invoiced") {
            this.invoicedOrders.next(result);
          } else if (event.status == "Rejected") {
            this.rejectedOrders.next(result);
          }
        },
        () => {
          this.notificationService.showError(
            new Error("Error searching orders"),
          );
        },
      );
  }

  search() {
    this.searchText = this.ordersForm.controls.searchText.value.trim();
    this.newOrders.next(null);
    this.acknowledgedOrders.next(null);
    this.confirmedOrders.next(null);
    this.invoicedOrders.next(null);
    this.rejectedOrders.next(null);

    // TODO call
    this.loadAllOrders(10);
  }

  searchOrder() {
    this.search();
    this.cancelBtnText = "Close";
  }

  adjustDateFilter() {
    this.loadAllOrders(10);
  }

  private getByStatus(status: string, orders: OrderSummary[]): OrderSummary[] {
    return _.filter(orders, (order) => {
      return order.status == status;
    });
  }

  private applyFilter(
    searchText: string,
    orders: OrderSummary[],
  ): OrderSummary[] {
    let filteredOrders = orders;
    if (this.senderGln && this.senderGln != null && this.senderGln != "") {
      filteredOrders = _.filter(filteredOrders, (order) => {
        return order.orderFrom.gln == this.senderGln;
      });
    }

    if (this.searchText && this.searchText != null && this.searchText != "") {
      const searchTextLower = searchText.toLowerCase();
      filteredOrders = _.filter(filteredOrders, (order) => {
        return (
          order.orderFrom.name?.toLowerCase().includes(searchTextLower) ||
          order.orderFrom.gln?.toLowerCase().startsWith(searchTextLower) ||
          order.deliverTo.name?.toLowerCase().includes(searchTextLower) ||
          order.deliverTo.gln?.toLowerCase().startsWith(searchTextLower) ||
          order.orderNumber.toLowerCase().startsWith(searchTextLower)
        );
      });
    }

    return filteredOrders;
  }

  setCurrentIndexFromStatus() {
    switch (this.status) {
      case "New":
        this.currentIndex = 0;
        break;
      case "Acknowledged":
        this.currentIndex = 1;
        break;
      case "Confirmed":
        this.currentIndex = 2;
        break;
      case "Rejected":
        this.currentIndex = 3;
        break;
      case "Invoiced":
        this.currentIndex = 4;
        break;
      default:
        break;
    }
  }

  getSelectedIndex() {
    return this.currentIndex;
  }

  exportOrdersToCVS() {
    this.exportCVS = true;
    setTimeout(() => {
      this.exportCVS = false;
    }, 3000);
  }

  private getQueryStringParams() {
    this.senderGln = this.navigationService.getQueryStringValue("sender");
    this.status = this.navigationService.getQueryStringValue("status");

    this.setTradingPartner();
  }

  private setTradingPartner() {
    this.loadTradingPartner = true;

    this.session
      .getUserAsync()
      .then(() => {
        this.tradingPartner = this.tradingPartnerService.getTradingPartner(
          this.session.getSupplierGln(),
        );
        this.tradingPartner.subscribe(
          () => {
            this.loadTradingPartner = false;
          },
          () => {
            this.loadTradingPartner = false;
          },
        );
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  }

  setupInputControls() {
    this.ordersForm = new UntypedFormGroup({
      searchText: new UntypedFormControl("", null),
      statusFormControl: new UntypedFormControl("", null),
      newCheckbox: new UntypedFormControl(false, null),
      acknowledgedCheckbox: new UntypedFormControl(false, null),
      confirmedCheckbox: new UntypedFormControl(false, null),
      rejectedCheckbox: new UntypedFormControl(false, null),
      invoicedCheckbox: new UntypedFormControl(false, null),
      allCheckbox: new UntypedFormControl(false, null),
      fromDate: new UntypedFormControl([new Date(this.firstDay), null]),
      toDate: new UntypedFormControl([
        new Date(this.lastDay.setHours(23, 59, 59)),
        null,
      ]),
      allOrders: new UntypedFormControl(false, null),
    });
    this.ordersForm.controls.fromDate.setValue(new Date(this.firstDay));
    this.ordersForm.controls.toDate.setValue(
      new Date(this.lastDay.setHours(23, 59, 59)),
    );
    this.fromMinDate = this.ordersForm.controls.fromDate.value;
    this.toMaxDate = this.ordersForm.controls.toDate.value;
    this.ordersForm.controls.fromDate.valueChanges.subscribe((x) => {
      if (this.isDateRangeValid(x, this.toMaxDate)) {
        this.fromMinDate = x;
        this.setSessionDates();
        this.search();
      }
    });

    this.ordersForm.controls.toDate.valueChanges.subscribe((x) => {
      if (this.isDateRangeValid(this.fromMinDate, x)) {
        this.toMaxDate = x;
        this.setSessionDates();
        this.search();
      }
    });
  }

  hookFormChanges() {
    this.ordersForm.controls.allOrders.valueChanges.subscribe(
      (showAllOrders) => {
        this.showAllOrders = showAllOrders;
        this.loadAllOrders(100);
      },
    );

    this.ordersForm.controls.searchText.valueChanges.subscribe((searchText) => {
      this.searchText = searchText;
      this.toggleSearchButton = this.searchText.length > 0 ? true : false;
    });

    this.ordersForm.controls.newCheckbox.valueChanges.subscribe((changes) => {
      changes == true
        ? this.ordersForm.controls.allCheckbox.setValue(false)
        : {};
      changes == true
        ? this.searchStatus.push("New")
        : (this.searchStatus = this.searchStatus.filter(
            (statuses) => statuses != "new",
          ));
    });

    this.ordersForm.controls.acknowledgedCheckbox.valueChanges.subscribe(
      (changes) => {
        changes == true
          ? this.ordersForm.controls.allCheckbox.setValue(false)
          : {};
        changes == true
          ? this.searchStatus.push("Acknowledged")
          : (this.searchStatus = this.searchStatus.filter(
              (statuses) => statuses != "acknowledged",
            ));
      },
    );

    this.ordersForm.controls.confirmedCheckbox.valueChanges.subscribe(
      (changes) => {
        changes == true
          ? this.ordersForm.controls.allCheckbox.setValue(false)
          : {};
        changes == true
          ? this.searchStatus.push("Confirmed")
          : (this.searchStatus = this.searchStatus.filter(
              (statuses) => statuses != "confirmed",
            ));
      },
    );

    this.ordersForm.controls.rejectedCheckbox.valueChanges.subscribe(
      (changes) => {
        changes == true
          ? this.ordersForm.controls.allCheckbox.setValue(false)
          : {};
        changes == true
          ? this.searchStatus.push("Rejected")
          : (this.searchStatus = this.searchStatus.filter(
              (statuses) => statuses != "rejected",
            ));
      },
    );

    this.ordersForm.controls.invoicedCheckbox.valueChanges.subscribe(
      (changes) => {
        changes == true
          ? this.ordersForm.controls.allCheckbox.setValue(false)
          : {};
        changes == true
          ? this.searchStatus.push("Invoiced")
          : (this.searchStatus = this.searchStatus.filter(
              (statuses) => statuses != "invoiced",
            ));
      },
    );

    this.ordersForm.controls.allCheckbox.valueChanges.subscribe((changes) => {
      if (changes == true) {
        this.searchStatus = [];
        this.ordersForm.controls.newCheckbox.setValue(false);
        this.ordersForm.controls.acknowledgedCheckbox.setValue(false);
        this.ordersForm.controls.confirmedCheckbox.setValue(false);
        this.ordersForm.controls.rejectedCheckbox.setValue(false);
        this.ordersForm.controls.invoicedCheckbox.setValue(false);
      }
    });
  }

  onPrintClose() {
    this.toggleDownloadAll();
  }

  enablePrintButtons(event) {
    this.parentVal = event;
    setTimeout(() => {
      this.parentVal > 0
        ? (this.enableButtons = true)
        : (this.enableButtons = false);
    }, 500);
  }

  onTabChanged(event: MatTabChangeEvent) {
    const currentTabName = event.tab.textLabel;
    this.orderStatusType = event.tab.textLabel;
    this.currentIndex = event.index;
    this.currTab = currentTabName;
  }

  private loadAllOrders(take: number) {
    this.loadNewOrders(take);
    this.loadAcknowledgedOrders(take);
    this.loadConfirmedOrders(take);
    this.loadRejectedOrders(take);
    this.loadInvoicedOrders(take);
  }

  private loadNewOrders(take: number) {
    this.orderService
      .search(
        this.senderGln,
        this.searchText,
        ["New"],
        this.fromMinDate,
        this.toMaxDate,
        take,
        0,
      )
      .subscribe(
        (result) => {
          if (result == null) {
            return;
          }
          this.disableDownloadAll = result.count === 0;
          this.newOrders.next(result);
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  private loadAcknowledgedOrders(take: number) {
    this.orderService
      .search(
        this.senderGln,
        this.searchText.trim(),
        ["Acknowledged"],
        this.fromMinDate,
        this.toMaxDate,
        take,
        0,
      )
      .subscribe(
        (result) => {
          if (result == null) {
            return;
          }
          this.acknowledgedOrders.next(result);
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  private loadConfirmedOrders(take: number) {
    this.orderService
      .search(
        this.senderGln,
        this.searchText.trim(),
        ["Confirmed"],
        this.fromMinDate,
        this.toMaxDate,
        take,
        0,
      )
      .subscribe(
        (result) => {
          if (result == null) {
            return;
          }
          this.confirmedOrders.next(result);
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  private loadRejectedOrders(take: number) {
    this.orderService
      .search(
        this.senderGln,
        this.searchText.trim(),
        ["Rejected"],
        this.fromMinDate,
        this.toMaxDate,
        take,
        0,
      )
      .subscribe(
        (result) => {
          if (result == null) {
            return;
          }
          this.rejectedOrders.next(result);
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  private loadInvoicedOrders(take: number) {
    this.orderService
      .search(
        this.senderGln,
        this.searchText.trim(),
        ["Invoiced"],
        this.fromMinDate,
        this.toMaxDate,
        take,
        0,
      )
      .subscribe(
        (result) => {
          if (result == null) {
            return;
          }
          this.invoicedOrders.next(result);
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  statusToString(documentStatus: OrderStatus) {
    switch (documentStatus) {
      case OrderStatus.New:
        return "New";
      case OrderStatus.Acknowledged:
        return "Acknowledged";
      case OrderStatus.Confirmed:
        return "Confirmed";
      case OrderStatus.Invoiced:
        return "Invoiced";
      case OrderStatus.Rejected:
        return "Rejected";
    }
  }

  stringToStatus(documentStatus: string) {
    switch (documentStatus.toLowerCase()) {
      case "new":
        return OrderStatus.New;
      case "acknowledged":
        return OrderStatus.Acknowledged;
      case "confirmed":
        return OrderStatus.Confirmed;
      case "invoiced":
        return OrderStatus.Invoiced;
      case "rejected":
        return OrderStatus.Rejected;
    }
  }

  refresh() {
    //used to animate refresh button
    this.clicked = true;
    setTimeout(() => {
      this.clicked = false;
    }, 1000);
  }

  closeAndClear() {
    this.searchToggle = false;
    this.ordersForm.controls.searchText.setValue("");
    this.search();
  }

  enableSearch() {
    this.searchToggle = true;
  }

  onClose() {
    this.showPrintLayoutOrder = false;
    this.toggleDownloadAll();
  }

  searchStatuses(status: string) {
    this.ordersForm.controls.statusFormControl.value == true
      ? this.searchStatus.push(status)
      : (this.searchStatus = this.searchStatus.filter(
          (statuses) => statuses != status,
        ));
  }

  toggleDownloadAll() {
    this.downloadAllToggle = !this.downloadAllToggle;
  }

  printAllOrders(type) {
    if (type === "print") {
      this.downloadType = "overview";
    } else if (type === "exportCVS") {
      this.downloadType = "exportCVS";
    }
    this.orderService
      .search(
        this.senderGln,
        this.searchText,
        ["New"],
        this.fromMinDate,
        this.toMaxDate,
        null,
        null,
      )
      .subscribe(
        (result) => {
          this.showPrintLayoutOrder = true;
          this.printDataOrders = result.data;
          this.toggleDownloadAll();
        },
        () => {
          this.notificationService.showError(
            new Error("Error retrieving orders for print"),
          );
        },
      );
  }

  goToSupplierView() {
    this.navigationService.navigateToSupplierView();
  }

  isAdmin(): boolean {
    return this.session.isAdmin();
  }

  getSessionDates() {
    const [fromDate, toDate] = this.session.getDates();
    this.firstDay = new Date(fromDate);
    this.lastDay = new Date(toDate);
  }

  setSessionDates() {
    this.session.setDates(this.fromMinDate, this.toMaxDate);
  }

  getInvoiceApplicationStatus() {
    this.financeService.getFinanceApplicationStatus().subscribe((status) => {
      if (status) {
        this.getApplicationStatus =
          status == SrfApplicationStatus.Complete ? true : false;
      }
    });
  }

  NavigateToInvoices() {
    this.navigationService.navigateToInvoiceProcessing();
  }

  getFinanced() {
    this.navigationService.navigateToGetFinanced();
  }

  isDateRangeValid(minDate: Date, maxDate: Date) {
    if (!minDate || !maxDate) {
      return false;
    }

    if (minDate <= maxDate) {
      return true;
    }

    this.notificationService.showError(
      new Error("From date cannot be greater than to date"),
    );

    this.session.resetDates();

    return false;
  }
}
