<div class="">
  <div class="m-b-20 flex fx-row fx-ai-center width-100">
    <p class="subHeading flex p-b-10">Last performed actions</p>
    <form
      class="m-l-auto flex fx-row fx-ai-center"
      [formGroup]="activityLogForm"
    >
      <div class="datePicker width-45">
        <div
          class="width-50 datePickOrder round noMargin tradeCard fx-ai-center fx-jc-center fx-row flex"
        >
          <mat-form-field class="">
            <mat-label>From Date</mat-label>
            <input
              matInput
              [max]="toMaxDate"
              [matDatepicker]="fromDate"
              formControlName="fromDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="">
            <mat-label>To Date</mat-label>
            <input
              matInput
              [min]="fromMinDate"
              [max]="todaysDate"
              [matDatepicker]="toDate"
              formControlName="toDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="width-45 fx-a i-center flex round xs-card-t p-r-20 p-l-20">
        <mat-form-field class=" ">
          <input
            matInput
            (keyup.enter)="search()"
            (blur)="search()"
            formControlName="userFllter"
          />
          <mat-label
            ><mat-icon>search</mat-icon> Search order status, connection etc
          </mat-label>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div
    *ngIf="loader"
    class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
  >
    <mat-spinner strokeWidth="5" [diameter]="50"></mat-spinner>
  </div>
  <div class="flex fx-r ow m-t-30">
    <p class="blb-l">This Week</p>
    <table
      *ngIf="!loader"
      mat-table
      [dataSource]="dataSourceThisWeek"
      class="width-100 userButtons activityLogTables"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          <div class="fx-row flex jx-jc-center">
            <div
              class="flex"
              style="width: 5px; height: 20px"
              [ngStyle]="{
                'background-color': getActivityLogColor(element.description)
              }"
            ></div>
            <div class="flex m-l-10">
              {{ getFormatedDate(element.eventTime) }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element" class="noWrap">
          <p>
            <span class="fw-800">{{ getFirstWord(element.description) }}</span>
            {{ getRestOfSentance(element.description) }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td class="noWrap" mat-cell *matCellDef="let element">
          <p class="">
            By:<span class="fw-300 p-l-10">{{ element.user.name }}</span>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceRequestNumber">
        <th mat-header-cell *matHeaderCellDef>Service Request Number</th>
        <td mat-cell *matCellDef="let element">
          <p>
            <span class="fw-800 p-r-10">{{
              getRequestLabelType(element.description)
            }}</span
            >{{ element.typeUniqueId }}
          </p>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="flex fx-r ow m-t-30">
    <p class="blb-l">This Month</p>
    <table
      *ngIf="!loader"
      mat-table
      [dataSource]="dataSourceThisMonth"
      class="width-100 userButtons activityLogTables"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          <div class="fx-row flex jx-jc-center">
            <div
              class="flex"
              style="width: 5px; height: 20px"
              [ngStyle]="{
                'background-color': getActivityLogColor(element.description)
              }"
            ></div>
            <div class="flex m-l-10">
              {{ getFormatedDate(element.eventTime) }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element" class="noWrap">
          <p>
            <span class="fw-800">{{ getFirstWord(element.description) }}</span>
            {{ getRestOfSentance(element.description) }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td class="noWrap" mat-cell *matCellDef="let element">
          <p class="">
            By:<span class="fw-300 p-l-10">{{ element.user.name }}</span>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceRequestNumber">
        <th mat-header-cell *matHeaderCellDef>Service Request Number</th>
        <td mat-cell *matCellDef="let element">
          <p>
            <span class="fw-800 p-r-10">{{
              getRequestLabelType(element.description)
            }}</span
            >{{ element.typeUniqueId }}
          </p>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="flex fx-r ow m-t-30">
    <p class="blb-l">Older</p>
    <table
      *ngIf="!loader"
      mat-table
      [dataSource]="dataSourceOlder"
      class="width-100 userButtons activityLogTables"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          <div class="fx-row flex jx-jc-center">
            <div
              class="flex"
              style="width: 5px; height: 20px"
              [ngStyle]="{
                'background-color': getActivityLogColor(element.description)
              }"
            ></div>
            <div class="flex m-l-10">
              {{ getFormatedDate(element.eventTime) }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element" class="noWrap">
          <p>
            <span class="fw-800">{{ getFirstWord(element.description) }}</span>
            {{ getRestOfSentance(element.description) }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td class="noWrap" mat-cell *matCellDef="let element">
          <p class="">
            By:<span class="fw-300 p-l-10">{{ element.user.name }}</span>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceRequestNumber">
        <th mat-header-cell *matHeaderCellDef>Service Request Number</th>
        <td mat-cell *matCellDef="let element">
          <p>
            <span class="fw-800 p-r-10">{{
              getRequestLabelType(element.description)
            }}</span
            >{{ element.typeUniqueId }}
          </p>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
