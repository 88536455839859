<div class=" ">
  <form [formGroup]="assignForm">
    <p class="subHeading flex p-b-10">
      {{ manageUserType === "view" ? "Assigned suppliers" : "Assign" }}
    </p>

    <div *ngIf="manageUserType !== 'view'" class="width-50 flex">
      <div class="width-100 flex fx-row fx-jc-center fx-ai-center">
        <!-- [ngClass]="{'tradeCard': !searchToggle}" -->
        <mat-form-field
          class="flex orderSearchField tradeCard fx-jc-center fx-ai-center width-100 round p-r-30 p-l-30 m-l-50"
        >
          <input
            (keyup.enter)="search()"
            (blur)="search()"
            formControlName="searchText"
            matInput
            placeholder=""
          />
          <mat-label>
            <mat-icon>search</mat-icon> Search for Supplier
          </mat-label>
        </mat-form-field>
        <!-- <mat-icon *ngIf="searchToggle" matSuffix (click)="disableSearch()" close="fx-jc-center fx-ai-center p-t-20 ">close</mat-icon> -->
      </div>
      <div class="m-b-20"></div>
    </div>
  </form>

  <div *ngIf="loader" class="p-10 loader flex fx-jc-center fx-ai-center">
    <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
  </div>
  <table
    *ngIf="!loader"
    mat-table
    [dataSource]="dataSource"
    class="width-100 userButtons manageUsers"
  >
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Supplier</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container style="width: 0px !important" matColumnDef="select">
      <th
        style="width: 0px !important; text-align: end"
        mat-header-cell
        *matHeaderCellDef
      >
        {{ manageUserType !== "view" ? "Select All" : "" }}
        <mat-checkbox
          *ngIf="manageUserType !== 'view'"
          style="width: 0px !important"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td
        style="width: 0px !important; text-align: end"
        mat-cell
        *matCellDef="let row"
      >
        <mat-checkbox
          *ngIf="manageUserType !== 'view'"
          style="width: 0px !important"
          (click)="$event.stopPropagation()"
          (change)="$event ? selectedTradingPartners(row) : ''"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </table>
  <!-- <div class="flex m-l-auto fx-jc-end fx-row m-t-30">
            <button mat-flat-button (click)="assignSuppliers()" [disabled]="!supplierIsSelected()" class="p-l-20 p-r-20 btn round primaryBtn flex fx-ai-center m-l-10">Submit</button>
    </div> -->
</div>
