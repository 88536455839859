import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Observable } from "rxjs";
import { Session } from "../../../../models/session";
import { TradingPartner } from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { DocumentsService } from "../../../../services/documents.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";
import { Settings } from "../../../../settings";

@Component({
  selector: "app-more-info",
  templateUrl: "./more-info.component.html",
  styleUrls: ["./more-info.component.sass"],
})
export class MoreInfoComponent implements OnInit {
  tradingPartner: Observable<TradingPartner>;
  loadTradingPartner = false;
  moreInfoDescription = "";
  constructor(
    private formBuilder: UntypedFormBuilder,
    private navigate: NavigationService,
    private tradingPartnerService: TradingPartnerService,
    private session: Session,
    private documentService: DocumentsService,
    private notificationService: NotificationService,
    private settings: Settings,
    private location: Location,
    private dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.setTradingPartner();
  }

  private setTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartner = this.tradingPartnerService.getTradingPartner(
        this.session.getSupplierGln(),
      );

      this.tradingPartner.subscribe(
        () => {
          this.loadTradingPartner = false;
        },
        () => {
          this.loadTradingPartner = false;
        },
      );
    });
  }

  navigateToGetFinanced() {
    this.navigate.navigateToGetFinanced();
  }

  goBack() {
    this.location.back();
  }

  apply() {
    this.navigate.navigateToTellUsAboutYourBusiness();
  }
}
