<form
  class="example-form editBusinessLayout"
  [formGroup]="editBusinessInfoForm"
>
  <div class="busiinessAddress">
    <p class="subHeading m-t-30 m-b-50 p-l-30">Address</p>
    <div class="fieldColor mg0 p-t-10 p-b-10 roundAddress">
      <mat-form-field class="example-full-width .s-card-t p-t-20 p-r-30 p-l-30">
        <mat-label>Line 1</mat-label>
        <input matInput formControlName="addressLine1" />
      </mat-form-field>
      <mat-form-field class="example-full-width .s-card-t p-r-30 p-l-30">
        <mat-label>Line 2</mat-label>
        <input matInput formControlName="addressLine2" />
      </mat-form-field>
      <mat-form-field class="example-full-width .s-card-t p-r-30 p-l-30">
        <mat-label>Line 3</mat-label>
        <input matInput formControlName="addressLine3" />
      </mat-form-field>
      <mat-form-field class="example-full-width .s-card-t p-r-30 p-l-30">
        <mat-label>Line 4</mat-label>
        <input matInput formControlName="addressLine4" />
      </mat-form-field>
      <mat-form-field class="example-full-width p-b-20 .s-card-t p-r-30 p-l-30">
        <mat-label>Postal Code</mat-label>
        <input matInput formControlName="postalCode" />
      </mat-form-field>

      <!-- spacing field -->
      <div class="example-full-width p-b-20 .s-card-t p-r-30 p-l-30">
        <mat-label></mat-label>
      </div>
    </div>
  </div>

  <div class="busiinessEmail">
    <p class="subHeading p-l-30">Email</p>
    <mat-form-field
      class="example-full-width .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <input matInput formControlName="email" />
    </mat-form-field>
  </div>

  <div class="busiinessNoC">
    <p class="subHeading p-l-30">Contact Name</p>
    <mat-form-field
      class="example-full-width .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <input matInput formControlName="contactName" />
    </mat-form-field>
  </div>

  <div class="busiinessCellSoC">
    <p class="subHeading p-l-30">Contact Surname</p>
    <mat-form-field
      class="example-full-width .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <input matInput formControlName="contactSurname" />
    </mat-form-field>
  </div>

  <div class="busiinessTelNumber">
    <p class="subHeading p-l-30">Tel Number</p>
    <mat-form-field
      class="example-full-width .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <input matInput formControlName="telNumber" />
    </mat-form-field>
  </div>

  <div class="busiinessFaxNumber">
    <p class="subHeading p-l-30">Fax Number</p>
    <mat-form-field
      class="example-full-width .s-card-t round fieldColor p-r-30 p-l-30"
    >
      <input matInput formControlName="faxNumber" />
    </mat-form-field>
  </div>
</form>

<div style="justify-content: space-between" class="flex fx-row m-30">
  <button mat-flat-button class="btn cancelBtn fl flexex" (click)="cancel()">
    Cancel
  </button>
  <button
    mat-flat-button
    [disabled]="!canEdit"
    class="btn primaryBtn fl flexex"
    (click)="submit()"
  >
    Submit
  </button>
</div>
