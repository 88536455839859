import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Chart } from "chart.js";
import { Observable } from "rxjs";
import { Session } from "../../../../models/session";
import { TradingPartner } from "../../../../models/types";
import { DialogService } from "../../../../services/dialog.service";
import { DocumentsService } from "../../../../services/documents.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";
import { Settings } from "../../../../settings";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
@Component({
  selector: "app-invoice-processing",
  templateUrl: "./invoice-processing.component.html",
  styleUrls: ["./invoice-processing.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class InvoiceProcessingComponent implements OnInit {
  chart: Chart;
  invoiceGraphsFormGroup: UntypedFormGroup;
  tradingPartner: Observable<TradingPartner>;
  loadTradingPartner = false;
  isDataVisible = false;

  todaysDate = new Date();
  firstDay: Date = new Date();
  lastDay: Date = new Date();
  toMaxDate: Date;
  fromMinDate: Date;
  showAllOrders = true;
  dateFormat;
  constructor(
    private session: Session,
    private formBuilder: UntypedFormBuilder,
    private navigate: NavigationService,
    private tradingPartnerService: TradingPartnerService,
    private documentService: DocumentsService,
    private notificationService: NotificationService,
    private settings: Settings,
    private location: Location,
    private dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.dateFormat = this.settings.dateFormat;
    this.getSessionDates();
    this.setTradingPartner();
    this.setupInputControls();
  }

  setupInputControls() {
    this.invoiceGraphsFormGroup = new UntypedFormGroup({
      fromDate: new UntypedFormControl(new Date(this.firstDay)),
      toDate: new UntypedFormControl(
        new Date(this.lastDay.setHours(23, 59, 59)),
      ),
    });
    this.invoiceGraphsFormGroup.controls.fromDate.setValue(
      new Date(this.firstDay),
    );
    this.invoiceGraphsFormGroup.controls.toDate.setValue(
      new Date(this.lastDay.setHours(23, 59, 59)),
    );
    this.fromMinDate = this.invoiceGraphsFormGroup.controls.fromDate.value;
    this.toMaxDate = this.invoiceGraphsFormGroup.controls.toDate.value;
    this.invoiceGraphsFormGroup.controls.fromDate.valueChanges.subscribe(
      (x) => {
        this.fromMinDate = x;
      },
    );
    this.invoiceGraphsFormGroup.controls.toDate.valueChanges.subscribe((x) => {
      this.toMaxDate = x;
    });
  }

  getSessionDates() {
    const [fromDate, toDate] = this.session.getDates();
    this.firstDay = new Date(fromDate);
    this.lastDay = new Date(toDate);
  }

  private setTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartner = this.tradingPartnerService.getTradingPartner(
        this.session.getSupplierGln(),
      );

      this.tradingPartner.subscribe(
        () => {
          this.loadTradingPartner = false;
        },
        () => {
          this.loadTradingPartner = false;
        },
      );
    });
  }

  viewInvoices() {
    const redirectLink = document.createElement("a");
    redirectLink.target = "_blank";
    redirectLink.href = this.settings.addendumLink;
    document.body.appendChild(redirectLink);
    redirectLink.click();
    document.body.removeChild(redirectLink);
  }

  isVisible(visible: boolean) {
    this.isDataVisible = visible;
  }

  navigateToGetFinanced() {
    this.navigate.navigateToGetFinanced();
  }
}
