<form class="partnerMarketplaceArea" [formGroup]="connectParterForm">
  <div class="flex round xs-card-t p-r-20 p-l-20">
    <mat-form-field>
      <input matInput formControlName="name" required />
      <mat-label>{{ nameLabel }}</mat-label>
    </mat-form-field>
  </div>

  <mat-form-field
    class="manageProductsLine1 tradeCard p-t-5 round p-l-20 p-r-20 xs-card-t noMargin"
  >
    <input
      type="text"
      formControlName="businessType"
      placeholder="{{ businessTypeLabel }}"
      aria-label="Number"
      matInput
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let retailer of retailers" [value]="retailer">
        {{ retailer }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="p-t-10 .s-card-t round fieldColor p-r-30 p-l-30">
    <mat-label>Email address</mat-label>
    <input matInput formControlName="email" />
    <mat-error *ngIf="connectParterForm.controls.email.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="connectParterForm.controls.email.hasError('email')">
      Please enter a <strong>valid</strong> email address
    </mat-error>
  </mat-form-field>

  <mat-form-field class="p-t-10 .s-card-t round fieldColor p-r-30 p-l-30">
    <mat-label>Contact number</mat-label>
    <input matInput formControlName="contactNumber" max="10" type="number" />
    <mat-error
      *ngIf="connectParterForm.controls.contactNumber.hasError('required')"
    >
      Contact number is <strong>required</strong>
    </mat-error>
    <mat-error
      *ngIf="connectParterForm.controls.contactNumber.hasError('pattern')"
    >
      Numeric value <strong>only</strong>
    </mat-error>
  </mat-form-field>
  <div mat-dialog-actions class="p-b-50 fx-jc-center">
    <button
      mat-button
      class="btn width-90 p-b-50 round cancel"
      (click)="close(false)"
    >
      Cancel
    </button>
  </div>

  <div mat-dialog-actions class="p-b-50 fx-jc-center">
    <button
      mat-button
      class="btn width-90 p-b-50 round primaryBtn"
      [disabled]="!isValidForm()"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</form>
