import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SupplierReport } from "./../../../../../models/types";
import { DialogService } from "./../../../../../services/dialog.service";
import { NavigationService } from "./../../../../../services/navigation.service";
import { NotificationService } from "./../../../../../services/notification.service";
import { PrintingServiceService } from "./../../../../../services/printing-service.service";
import { TradingPartnerService } from "./../../../../../services/trading-partner.service";
import { Settings } from "./../../../../../settings";

const ELEMENT_DATA: DetailedReportCsv[] = [];
@Component({
  selector: "app-supplier-report-view",
  templateUrl: "./supplier-report-view.component.html",
  styleUrls: ["./supplier-report-view.component.sass"],
})
export class SupplierReportViewComponent implements OnInit {
  @ViewChild("TABLE") table: ElementRef;
  displayedColumns: string[] = [
    "supplierName",
    "retailer",
    "orderNo",
    "orderStatus",
    "orderDate",
    "orderValue",
    "invoiceValue",
  ];
  reportModel: DetailedReportCsv[] = [];
  orderReports: SupplierReport[] = [];
  orderReport: SupplierReport[] = [];
  AdminReportsForm: UntypedFormGroup;
  loader = false;
  supplierName = "";
  supplierGln = "";
  dateFormat;
  dataSource = new MatTableDataSource<DetailedReportCsv>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private navigate: NavigationService,
    private dialogService: DialogService,
    private notification: NotificationService,
    private settings: Settings,
    private printingService: PrintingServiceService,
  ) {}

  ngOnInit(): void {
    this.dateFormat = this.settings.dateFormat;
    this.setupInputControls();
    this.getReports();
  }

  setupInputControls() {
    this.AdminReportsForm = this.formBuilder.group({
      searchReports: ["", null],
    });
  }

  goToReportView() {
    this.navigate.navigateToDetailedSupplierReports();
  }

  getReports() {
    this.reportModel = [];
    this.tradingPartnerService.detailedSupplierReports.subscribe(
      (reports) => {
        if (reports) {
          this.orderReport = reports;
          this.orderReports = reports;
          this.supplierName = "Detailed Supplier Report";
          this.dataSource.data = [];

          this.orderReports.forEach((report) => {
            report.reportData.forEach((line) => {
              const reportModels: DetailedReportCsv = new DetailedReportCsv();
              reportModels.SupplierName = report.supplierName;
              reportModels.Retailer = line.Retailer;
              reportModels.OrderValue = line.OrderValue;
              reportModels.OrderStatus = line.Status;
              reportModels.OrderNumber = line.OrderNumber;
              reportModels.OrderDate = this.formatDate(line.OrderDate);
              reportModels.InvoiceValue = line.InvoiceValue;
              this.reportModel.push(reportModels);
            });
          });

          this.dataSource.data = this.reportModel;

          // this.paginator = this.dataSource.paginator
          this.dataSource.paginator = this.paginator;
        }
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  formatDate(dateArg: string): string {
    const date: Date = new Date(dateArg);
    return (
      (date.getDate() < 9 ? "0" + date.getDate() : date.getDate()) +
      " " +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear()
    );
  }

  ViewReport() {
    this.navigate.navigateToAdminReportsView();
  }

  search(filterValue: string) {
    this.dataSource.data = this.reportModel.filter(
      (x) =>
        x.SupplierName.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.Retailer.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.OrderValue.toString().includes(filterValue.trim().toLowerCase()) ||
        x.OrderStatus.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.OrderNumber.toLowerCase().includes(
          filterValue.trim().toLowerCase(),
        ) ||
        x.OrderDate.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.InvoiceValue.toString().includes(filterValue.trim().toLowerCase()),
    );
  }

  clearSearch() {
    this.AdminReportsForm.controls.searchReports.value == "";
    this.AdminReportsForm.controls.searchReports.value == ""
      ? (this.search(""), (this.paginator = this.dataSource.paginator))
      : {};
  }

  downloadExcelFile() {
    this.printingService.generateSupplierReport(this.orderReport);
  }
}

export class DetailedReportCsv {
  SupplierName = "";
  Retailer = "";
  OrderNumber = "";
  OrderStatus = "";
  OrderDate = "";
  OrderValue = 0;
  InvoiceValue = 0;
}
