<div *ngIf="loader" class="width-100 height-100 flex fx-jc-center fx-ai-center">
  <mat-spinner strokeWidth="5" [diameter]="50"></mat-spinner>
</div>
<div *ngIf="!loader" class="flex fx-jc-center fx-ai-center">
  <h2 mat-dialog-title>{{ actionText }} Supplier</h2>

  <form
    class="flex width-80 flex fx-jc-center fx-ai-center partnerMarket placeArea"
    [formGroup]="setSupplierForm"
  >
    <div
      class="width-70 flex fx-jc-center fx-ai-center bl-t-l round xs-card-t p-t-10 p-r-20 p-l-20"
    >
      <mat-form-field class="width-80 flex fx-jc-center fx-ai-center">
        <mat-select
          class=" "
          placeholder="Please select a supplier"
          formControlName="supplier"
        >
          <mat-option *ngFor="let supplier of suppliers" [value]="supplier">
            {{ supplier.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="setSupplierForm.controls.supplier.hasError('required')"
        >
          Supplier is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div mat-dialog-actions style="justify-content: space-between">
    <button class="btn round selectedItem primaryBtn" (click)="cancel()">
      Cancel
    </button>
    <!-- <button mat-button class="btn selectedItem primaryBtn right" (click)="confirm()">Confirm</button> -->
  </div>
</div>
