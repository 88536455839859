<p class="flex p-l-1">Gln - {{ tradingPartner?.gln }}</p>
<p class="subHeading flex p-b-10">Business information</p>
<div class="flex">
  <div class="flex fx-row">
    <div class="flex width-100">
      <p class="flex p-l">Business Address</p>
      <div class="flex">
        <p class="flex p-b noMargn">{{ getAddress()?.line1 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.line2 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.line3 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.line4 }}</p>
        <p class="flex p-b noMargn">{{ getAddress()?.postalCode }}</p>
      </div>
    </div>
    <div class="flex width-100">
      <p class="flex p-l">Contact Name</p>
      <p class="flex p-b noMargn">
        {{
          tradingPartner?.contact?.contactName
            ? tradingPartner?.contact?.contactName
            : "-"
        }}
      </p>
      <p class="flex p-l">Contact Surname</p>
      <p class="flex p-b noMargn">
        {{
          tradingPartner?.contact?.contactSurname
            ? tradingPartner?.contact?.contactSurname
            : "-"
        }}
      </p>
      <p class="flex p-l">Email Address</p>
      <p class="flex p-b noMargn">
        {{
          tradingPartner?.contact?.emailAddress
            ? tradingPartner?.contact?.emailAddress
            : "-"
        }}
      </p>
    </div>
    <div class="flex width-100">
      <p class="flex p-l">Tel No:</p>
      <p class="flex p-b noMargn">{{ getTelNo() }}</p>
      <p class="flex p-l">Fax No:</p>
      <p class="flex p-b noMargn">{{ getFaxNo() }}</p>
    </div>
  </div>
  <div class="flex m-l-auto fx-jc-center fx-row m-t-30">
    <button
      mat-flat-button
      class="btn cancelBtn flex fx-ai-center flex"
      (click)="editBusinessInformation()"
    >
      Edit
    </button>
  </div>
</div>
