import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import _ from "underscore";
import { Invoice } from "../../../../../models/invoice";
import { Session } from "../../../../../models/session";
import { DialogService } from "../../../../../services/dialog.service";
import { FinanceService } from "../../../../../services/finance.service";
import { NavigationService } from "../../../../../services/navigation.service";
import { NotificationService } from "../../../../../services/notification.service";
import { OrdersService } from "../../../../../services/orders.service";
import { Settings } from "../../../../../settings";

const ELEMENT_DATA: Invoice[] = [];

export const MY_FORMATS = {
  parse: {
    dateInput: "yyyy MMM DD",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-invoices-available",
  templateUrl: "./invoices-available.component.html",
  styleUrls: ["./invoices-available.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class InvoicesAvailableComponent implements OnInit, AfterViewInit {
  @Input() fromMinDate: Date;
  @Input() toMaxDate: Date;
  selection = new SelectionModel<Invoice>(true, []);
  invoicesAvailable: Invoice[] = [];
  invoiceCount = 0;
  maturityDates: string[] = [];
  tempInvoicesAvailable: Invoice = new Invoice();
  dateFormat;
  displayedColumns: string[] = [
    "retailer",
    "invoiceNumber",
    "value",
    "invoiceDate",
    "maturityDate",
    "select",
  ];
  loadTradingPartner = false;
  data: any;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  todaysDate: Date = new Date();
  selectedDate: Date = new Date();
  enableButtons = false;
  invoiceProcessingForm: UntypedFormGroup;
  dataSource = new MatTableDataSource<Invoice>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private skip = 0;
  private take = 10;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private services: NavigationService,
    private orderService: OrdersService,
    private session: Session,
    private dialog: DialogService,
    private settings: Settings,
    private financeService: FinanceService,
    private notification: NotificationService,
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.data = [];
    this.take = this.paginator.pageSize;
    this.skip = this.paginator.pageIndex * this.take;
    this.paginator.length = 50;
    this.getInvoicesToProcess();
    this.paginator.page.subscribe((result) => {
      if (result) {
        this.take = this.paginator.pageSize;
        this.skip = this.paginator.pageIndex * this.take;
        this.getInvoicesToProcess();
        // this.setDates();
      }
    });
  }

  ngOnInit(): void {
    this.take = this.paginator.pageSize;
    this.skip = this.paginator.pageIndex * this.take;

    this.dateFormat = this.settings.dateFormat;
    this.setupInputControls();
    // this.getInvoicesToProcess();
    this.hookFormInput();
    this.dataSource.data = [];
  }

  hookFormInput() {
    this.invoiceProcessingForm.controls.maturityDate.valueChanges.subscribe(
      (date) => {
        this.selectedDate = date._d;
      },
    );
  }

  getInvoicesToProcess() {
    // TODO SET FROM FE DATE CONTROL
    const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 1));
    const dateTo = new Date();
    const activeRetailers = [""]; // not used

    this.financeService
      .getInvoicesAvailableForFinancing(
        dateFrom,
        dateTo,
        activeRetailers,
        this.take,
        this.skip,
      )
      .subscribe(
        (invoices) => {
          if (invoices) {
            this.invoicesAvailable = invoices.invoiceData;
            this.invoiceCount = invoices.invoiceCount;
            const mappedInvoices = _.map(invoices.invoiceData, (invoice) => {
              return this.mapInvoice(invoice);
            });
            this.dataSource.data = mappedInvoices;
            this.maturityDates = this.dataSource.data.map(
              (invoice) => invoice.maturityDate,
            );
          }
        },
        (error) => {
          this.notification.showError(error);
        },
      );
  }

  mapInvoice(invoice: Invoice): any {
    const selectedInvoice = _.find(this.selection.selected, (inv) => {
      this.invoiceProcessingForm.controls.maturityDate.setValue(
        inv.maturityDate ?? "",
      );
      return inv.invoiceId == invoice.invoiceId;
    });
    return selectedInvoice ?? invoice;
  }

  loadMoreInvoices() {
    this.skip += this.take;
    // TODO SET FROM FE DATE CONTROL
    const dateFrom = this.fromMinDate;
    const dateTo = this.toMaxDate;
    this.financeService
      .getInvoicesAvailableForFinancing(
        dateFrom,
        dateTo,
        [],
        this.take,
        this.skip,
      )
      .subscribe(
        (invoices) => {
          if (invoices.invoiceData) {
            this.invoiceCount = invoices.invoiceCount;
            this.invoicesAvailable = this.invoicesAvailable.concat(
              invoices.invoiceData,
            );
            this.dataSource.data = this.invoicesAvailable;
            this.dataSource.paginator = this.paginator;
          }
        },
        () => {
          this.notification.showError(
            new Error("Error searching for more invoices"),
          );
        },
      );
  }

  setupInputControls() {
    this.invoiceProcessingForm = this.formBuilder.group({
      maturityDate: new UntypedFormControl(new Date(this.todaysDate)),
    });
    this.minDate = this.invoiceProcessingForm.controls.maturityDate.value;
    this.maxDate = new Date(new Date().setMonth(new Date().getMonth() + 24));
    this.invoiceProcessingForm.controls.maturityDate.valueChanges.subscribe(
      (x) => {
        this.minDate = x;
      },
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Invoice): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }

    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.orderFrom
    }`;
  }

  getInvoiceCount(): number {
    return this.invoiceCount;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => {
          row.maturityDate != "" ? this.selection.select(row) : null;
        });
    this.selection.selected.length > 0
      ? (this.enableButtons = true)
      : (this.enableButtons = false);
  }

  selectedInvoice(invoice: Invoice) {
    if (this.selection.selected.indexOf(invoice) == -1) {
      this.selection.select(invoice);
    } else {
      this.selection.deselect(invoice);
    }
  }

  setMaturityDate(invoice: Invoice) {
    invoice.maturityDate =
      this.invoiceProcessingForm.controls.maturityDate.value;
    this.tempInvoicesAvailable = invoice;
  }

  formateDate(date: string) {
    return date != "" && date != undefined && date != null
      ? new Date(date)
      : "";
  }

  getSelectionLength(): boolean {
    return this.selection.selected.length == 0 ? true : false;
  }

  deSelectedInvoice(invoice: Invoice) {
    this.selection.deselect(invoice);
  }

  downloadInvoices() {
    const invoices = this.selection.selected;
    this.dialog
      .confirmRedirectPopup()
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.financeService.financeInvoices(invoices).subscribe(
            (preSignedUrl) => {
              // TODO DOWNLOAD CSV FILE FROM S3 BUCKET AND REDIRECT
              const link = document.createElement("a");
              link.href = preSignedUrl;
              link.download = preSignedUrl;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              const redirectLink = document.createElement("a");
              redirectLink.target = "_blank";
              redirectLink.href = this.settings.sciCustomerLink;
              document.body.appendChild(redirectLink);
              redirectLink.click();
              document.body.removeChild(redirectLink);
            },
            (error) => {
              this.notification.showError(error);
            },
          );
        }
      });
  }

  checkMaturityDate(invoice: Invoice) {
    return invoice.maturityDate == "" || invoice.maturityDate == undefined
      ? false
      : true;
  }

  setDates() {
    this.dataSource.data.forEach((invoice) => {
      if (
        invoice.invoiceId ==
        this.selection?.selected?.find(
          (selectedInvoice) => selectedInvoice?.invoiceId === invoice.invoiceId,
        )?.invoiceId
      )
        this.invoiceProcessingForm.controls.maturityDate.setValue(
          invoice.maturityDate,
        );
    });
  }

  test(invoice: Invoice) {
    invoice.maturityDate =
      this.invoiceProcessingForm.controls.maturityDate.value;
    this.tempInvoicesAvailable = invoice;
  }

  getDate(invoice: Invoice) {
    this.dialog
      .datePickerDialog()
      .afterClosed()
      .subscribe((date) => {
        if (date) {
          invoice.maturityDate = date;
        }
      });
  }
}
