<div class="flex fx-jc-center fx-ai-center">
  <h1 class="fw-800">{{ termsTitle }}</h1>
  <p class="m-t-10 m-b-15" style="text-align: center">{{ termsBody }}</p>
  <button
    mat-flat-button
    class="btn m-t-20 width-50 primaryBtn round"
    (click)="close()"
  >
    Close
  </button>
</div>
