import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { Order, Vat } from "../models/order";
import { Session } from "../models/session";
import { OrderSummary, QueryResult } from "../models/types";
import { newClient } from "../services/api-gateway.service.js";
import { OrderRepository } from "./order.repository";

const apigClient = newClient();

@Injectable()
export class AwsOrderRepository implements OrderRepository {
  constructor(private session: Session) {}

  get(svGln: string, id: string): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdGet(params, undefined, additionalParams)
        .then((result) => {
          // Success
          const orderResponse = result.data as Order;
          retObject.next(orderResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  getActiveObservable: BehaviorSubject<OrderSummary[]> = new BehaviorSubject<
    OrderSummary[]
  >(null);

  getActive(svGln: string): Observable<OrderSummary[]> {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersActiveGet({}, undefined, additionalParams)
        .then((result) => {
          // Success
          const orderResponse = result.data as OrderSummary[];
          this.getActiveObservable.next(orderResponse);
        })
        .catch(({ data }) => {
          // Error
          this.getActiveObservable.error(data);
        });
    });

    return this.getActiveObservable;
  }

  getPrint(svGln: string, orderIds: any[]): Observable<Order[]> {
    const retObject = new Subject<Order[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const body = {
        orderIds: orderIds,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersPrintPost({}, body, additionalParams)
        .then((result) => {
          // Success
          const orderResponse = result.data as Order[];
          retObject.next(orderResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  startKey: any = null;
  search(
    svGln: string,
    sender: string,
    searchText: string,
    status: string[],
    dateFrom: Date,
    dateTo: Date,
    take?: number,
    skip?: number,
  ): Observable<QueryResult> {
    const retObject = new Subject<QueryResult>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const additionalParams = {
        queryParams: {
          svGln: svGln,
          sender: sender,
          searchText: searchText,
          dateFrom: dateFrom?.toISOString(),
          dateTo: dateTo?.toISOString(),
          status: status,
          take: take,
          skip: skip,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersGet({}, null, additionalParams)
        .then((getOrdersResult) => {
          // Success
          const orderSummary = getOrdersResult.data as QueryResult;
          retObject.next(orderSummary);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });

    return retObject;
  }

  update(svGln: string, order: Order): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const body = {
        lines: order.lines,
      };
      const params = {
        id: order.id,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdPut(params, body, additionalParams)
        .then((putOrderResult) => {
          const order = putOrderResult.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  confirm(svGln: string, id: string): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdConfirmPost(params, undefined, additionalParams)
        .then((result) => {
          const order = result.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  reject(svGln: string, id: string): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdRejectPost(params, null, additionalParams)
        .then((result) => {
          const order = result.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  revertNew(svGln: string, id: string): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdNewPost(params, null, additionalParams)
        .then((result) => {
          const order = result.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  acknowledge(svGln: string, id: string): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdAcknowledgePost(params, null, additionalParams)
        .then((result) => {
          const order = result.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  invoice(
    svGln: string,
    id: string,
    invoiceNumber: string,
    invoiceAmount: number,
    invoiceDate: string,
  ): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const body = {
        invoiceNumber: invoiceNumber,
        invoiceAmount: invoiceAmount,
        invoiceDate: invoiceDate,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdInvoicePost(params, body, additionalParams)
        .then((result) => {
          const order = result.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  resendInvoice(
    svGln: string,
    orderId: string,
    invoiceId: string,
  ): Observable<Order> {
    const retObject = new Subject<Order>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: orderId,
      };
      const body = {
        invoiceId: invoiceId,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .ordersIdResendPost(params, body, additionalParams)
        .then((result) => {
          const order = result.data as Order;
          retObject.next(order);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  loadMoreOrders(): Observable<OrderSummary[]> {
    const retObject = new Subject<OrderSummary[]>();
    return retObject;
  }

  getVat(svGln: string, vatRateCode: string): Observable<Vat[]> {
    const vat = new Subject<Vat[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const additionalParams = {
        queryParams: {
          svGln: svGln,
          countryCode: vatRateCode,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .vatGet({}, null, additionalParams)
        .then((result) => {
          const rates = result.data as Vat[];
          vat.next(rates);
        })
        .catch(({ data }) => {
          vat.error(data);
        });
    });

    return vat;
  }
}
