<div class="flex fx-jc-center m-b-70 fx-ai-center grayBG">
  <div class="width-90 flex m-20 fx-jc-center">
    <div class="flex m-t-10 width-100">
      <div class="flex fx-row">
        <p class="m-b-30 title">Reports And Insights</p>
      </div>

      <div class="flex fx-row">
        <div
          class="reportCard flex fx-jc-center fx-ai-center clickme"
          (click)="navToUserReports()"
        >
          <p class="fw-600">Active User Reports</p>
          <img
            class="icon flex p-r-10 p-l-10"
            src="./assets/icons/userReportIcon.svg"
          />
        </div>

        <div
          class="reportCard flex fx-jc-center fx-ai-center clickme"
          (click)="navToAdminReports()"
        >
          <p class="fw-600">Detailed Supplier Report</p>
          <img
            class="icon flex p-r-10 p-l-10"
            src="./assets/icons/adminReportIcon.svg"
          />
        </div>

        <div
          class="reportCard flex fx-jc-center fx-ai-center clickme"
          (click)="navToDetailedSupplierReports()"
        >
          <p class="fw-600">Admin Reports</p>
          <img
            class="icon flex p-r-10 p-l-10"
            src="./assets/icons/supplierReportIcon.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
