<div class="flex fx-jc-center fx-ai-center grayBG m-b-70">
  <div class="width-90 flex m-20 fx-jc-center">
    <form class="flex m-t-10 width-100">
      <div class="flex">
        <h1 class="fw-500 m-b-30 title">Tiger Brands</h1>
        <h2 class="subTitle flex fw-400">
          {{ "LULALEND business cash advance contract" | uppercase }}
        </h2>

        <div class="grayBanner flex p-10 m-b-20 fx-r ow">
          <div class="flex fx-row">
            <div class="flex width-100">
              <span class="flex fw-400 nowrap">Merchant name</span>
              <span class="flex fw-600">Marco’s Pizza</span>
            </div>
            <div class="flex width-100">
              <span class="flex fw-400 nowrap">Advance start</span>
              <span class="flex fw-600">03/03/2020</span>
            </div>
            <div class="flex width-100">
              <span class="flex fw-400 nowrap">Merchant address</span>
              <span class="flex fw-600"
                >123 Merchant street, Midrand, 8001</span
              >
            </div>
            <div class="flex width-100">
              <span class="flex fw-400 nowrap">VAT Number</span>
              <span class="flex fw-600">123456789</span>
            </div>
            <div class="flex width-100">
              <span class="flex fw-400 nowrap">Advance Amount</span>
              <span class="flex fw-600">{{ loanAmount | currency : "R" }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="tradeCard m-t-30">
        <div class="flex">
          <div class="flex m-30">
            <span class="flex p-b-20 bl-b-l">{{ legalNoticeDescription }}</span>
            <div
              class="p-20 legalNoticeHeight"
              (scroll)="scrolledToBottom($event)"
            >
              <div
                *ngFor="let point of legalNoticePoints; let i = index"
                class="flex width-100 p-t-20"
              >
                <span class="flex fw-600 p-b-10"
                  >{{ i + 1 }}. {{ point[0] | titlecase }}</span
                >
                <p class="flex">{{ point[1] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="p-10">{{ paymentPeriod }} month</p>
      <div class="">
        <div class="flex fx-jc-center with-100 fx-row m-t-30">
          <button
            [disabled]="!enableSubmit"
            (click)="submit()"
            mat-flat-button
            class="flex width-20 fx-ai-center round btn primaryBtn"
          >
            Accept
          </button>
        </div>
        <button
          matRipple
          (click)="navToInvoiceFinancing()"
          class="m-t-30 backBtn noRrap flex fx-row"
        >
          <mat-icon class="flex">keyboard_arrow_left</mat-icon>
          <span class="flex p-l-10 fw-500">back</span>
        </button>
      </div>
    </form>
  </div>
</div>
