import { Component, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.sass"],
})
export class NotificationComponent {
  public message = "";
  public type = "";
  public title = "";
  public link = "";
  public linkText = "";

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) data: any,
    public snackRef: MatSnackBarRef<NotificationComponent>,
  ) {
    this.message = data.message;
    this.type = data.type;

    if (this.type === "success") {
      this.title = "Success";
    } else if (this.type === "error") {
      this.title = "Error";
    } else if (this.type === "warning") {
      this.title = "Warning";
    } else if (this.type === "info") {
      this.title = "Info";
    }
  }

  public close() {
    this.snackRef.dismiss();
  }
}
