import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "../../../../../app/services/notification.service";

@Component({
  selector: "app-upload-trading-partners",
  templateUrl: "./upload-trading-partners.component.html",
  styleUrls: ["./upload-trading-partners.component.sass"],
})
export class UploadTradingPartnersComponent implements OnInit {
  selectedFile: Record<string, unknown>[] = [];
  dragStarted = false;
  uploadTradingPartnerForm: UntypedFormGroup;
  selectedFile1: File[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private notification: NotificationService,
    public dialogRef: MatDialogRef<UploadTradingPartnersComponent>,
  ) {}

  ngOnInit(): void {
    this.dragStarted = false;

    this.uploadTradingPartnerForm = this.formBuilder.group({
      file: [null],
    });
  }

  UploadFile() {
    this.dialogRef.close();
    this.notification.showSuccess("Trading Partner list uploaded successfully");
  }

  chosenFile(files: File[]) {
    for (const file of files) {
      const found = this.selectedFile1.find((x) => x.name == file.name);

      if (!found) {
        this.selectedFile1.push(file);
      }
    }

    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close(this.selectedFile1);
  }

  dragOverHandler(ev) {
    this.dragStarted = true;
    ev.preventDefault();
    ev.stopPropagation();
  }

  removeFile(i) {
    const index = this.selectedFile.indexOf(i);
    if (index > -1) {
      this.selectedFile1.splice(index, 1);
    }
  }

  dropHandler(ev) {
    this.dragStarted = false;
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      for (const file of ev.dataTransfer.files) {
        file as File;

        const found = this.selectedFile1.find((x) => x.name == file.name);

        if (!found) {
          this.selectedFile1.push(file);
        }
      }

      this.closeDialog();
    }
  }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    const reader = new FileReader();
    reader.readAsText(files[0]);
  }
}
