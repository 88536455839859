import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import _ from "underscore";
import { Session } from "./../../../../../../models/session";
import {
  Address,
  AddressType,
  Contact,
  TradingPartner,
} from "./../../../../../../models/types";
import { NotificationService } from "./../../../../../../services/notification.service";
import { TradingPartnerService } from "./../../../../../../services/trading-partner.service";

@Component({
  selector: "app-edit-trading-partner",
  templateUrl: "./edit-trading-partner.component.html",
  styleUrls: ["./edit-trading-partner.component.sass"],
})
export class EditTradingPartnerComponent implements OnInit {
  title = "";
  action;
  tradingPartnerTypes: string[] = [
    "Retailer",
    "Supplier",
    // "Store"
  ];
  vatCountryCode: string[] = ["FRGN", "FTZ", "NAM", "SWZ", "ZAF"]; // GET FROM VAT TABLE
  editTradingPartnerForm: UntypedFormGroup;
  tradingPartner: TradingPartner;
  parentGln: string;
  loader = false;
  canEdit = false;
  vatCountryCodes: string[] = [];
  selectedFile1: File;
  tpIsStore = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<EditTradingPartnerComponent>,
    private session: Session,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.title = data.title;
    this.action = data.action;

    if (data.parentGln) {
      this.parentGln = data.parentGln;
    }
    if (data.tradingPartner) {
      this.tradingPartner = data.tradingPartner;
    }
  }

  ngOnInit(): void {
    this.selectedFile1 = null;
    this.setupInputs();
    this.manageTradingPartnerInformation();
  }

  manageTradingPartnerInformation() {
    if (this.action === "Add") {
      this.vatCountryCodes = this.vatCountryCode;
      this.editTradingPartnerForm.controls.vatCountryCode.setValue("ZAF");
      this.editTradingPartnerForm.valueChanges.subscribe(() => {
        this.canEdit = this.editTradingPartnerForm.valid;
      });
    } else if (this.action === "Edit") {
      this.vatCountryCodes = this.vatCountryCode;
      if (this.tradingPartner) {
        if (this.tradingPartner.type == "Store") {
          this.tpIsStore = true;
        }
        this.populateBusinessInformation();
        const originalInfo = this.getBusinessInfo();
        this.editTradingPartnerForm.valueChanges.subscribe((changes) => {
          this.canEdit = !_.isEqual(originalInfo, this.getBusinessInfo());
          this.canEdit = changes.file != null ? true : false;
        });
      }
    } else if (this.action === "AddStore") {
      this.tpIsStore = true;

      this.vatCountryCodes = this.vatCountryCode;
      this.editTradingPartnerForm.controls.vatCountryCode.setValue("ZAF");
      this.editTradingPartnerForm.controls.tradingPartnerType.setValue("Store");
      this.editTradingPartnerForm.valueChanges.subscribe(() => {
        this.canEdit = this.editTradingPartnerForm.valid;
      });
    }
  }

  setupInputs() {
    this.editTradingPartnerForm = new UntypedFormGroup({
      addressLine1: new UntypedFormControl("", Validators.required),
      addressLine2: new UntypedFormControl(""),
      addressLine3: new UntypedFormControl(""),
      addressLine4: new UntypedFormControl(""),
      postalCode: new UntypedFormControl(""),
      email: new UntypedFormControl(""),
      cellNumber: new UntypedFormControl("", Validators.required),
      faxNumber: new UntypedFormControl(""),
      name: new UntypedFormControl("", Validators.required),
      contactName: new UntypedFormControl("", Validators.required),
      contactSurname: new UntypedFormControl("", Validators.required),
      gln: new UntypedFormControl("", Validators.required),
      vatNumber: new UntypedFormControl("", Validators.required),
      vatRateCode: new UntypedFormControl(""),
      vatCountryCode: new UntypedFormControl("ZAR"),
      tradingPartnerType: new UntypedFormControl("", Validators.required),
      file: new UntypedFormControl(""),
    });
  }

  getBusinessInfo(): TradingPartner {
    const contact: Contact = {
      telNo: this.editTradingPartnerForm.controls.cellNumber.value ?? "",
      emailAddress: this.editTradingPartnerForm.controls.email.value ?? "",
      faxNo: this.editTradingPartnerForm.controls.faxNumber.value ?? "",
      contactName: this.editTradingPartnerForm.controls.contactName.value ?? "",
      contactSurname:
        this.editTradingPartnerForm.controls.contactSurname.value ?? "",
    };

    const address: Address = {
      line1: this.editTradingPartnerForm.controls.addressLine1.value ?? "",
      line2: this.editTradingPartnerForm.controls.addressLine2.value ?? "",
      line3: this.editTradingPartnerForm.controls.addressLine3.value ?? "",
      line4: this.editTradingPartnerForm.controls.addressLine4.value ?? "",
      postalCode: this.editTradingPartnerForm.controls.postalCode.value ?? "",
    };

    let capturedData: TradingPartner;
    if (this.action === "Add") {
      capturedData = {
        gln: this.editTradingPartnerForm.controls.gln.value,
        contact: contact,
        addresses: [address],
        name: this.editTradingPartnerForm.controls.name.value ?? "",
        vatNo: this.editTradingPartnerForm.controls.vatNumber.value ?? "",
        vatRateCode:
          this.editTradingPartnerForm.controls.vatCountryCode.value ?? "",
        vatCountryCode:
          this.editTradingPartnerForm.controls.vatCountryCode.value ?? "",
        type:
          this.editTradingPartnerForm.controls.tradingPartnerType.value ?? "",
      };
    } else if (this.action === "Edit") {
      capturedData = {
        gln: this.tradingPartner.gln,
        contact: contact,
        addresses: [address],
        name: this.editTradingPartnerForm.controls.name.value ?? "",
        vatNo: this.editTradingPartnerForm.controls.vatNumber.value ?? "",
        vatRateCode:
          this.editTradingPartnerForm.controls.vatCountryCode.value ?? "",
        vatCountryCode:
          this.editTradingPartnerForm.controls.vatCountryCode.value ?? "",
      };
    } else if (this.action === "AddStore") {
      capturedData = {
        gln: this.editTradingPartnerForm.controls.gln.value,
        parentGln: this.parentGln,
        contact: contact,
        addresses: [address],
        name: this.editTradingPartnerForm.controls.name.value ?? "",
        type: "Store",
        vatNo: this.editTradingPartnerForm.controls.vatNumber.value ?? "",
        vatRateCode:
          this.editTradingPartnerForm.controls.vatCountryCode.value ?? "",
        vatCountryCode:
          this.editTradingPartnerForm.controls.vatCountryCode.value ?? "",
      };
    }

    return capturedData;
  }

  getTelNo() {
    const telNo = this.tradingPartner?.contact?.telNo?.trim();
    if (telNo?.charAt(0) == "0") {
      return "+27" + telNo.substring(1);
    }
    return telNo; //telNo;
  }

  getFaxNo() {
    const faxNo = this.tradingPartner?.contact?.faxNo?.trim();
    if (faxNo?.charAt(0) == "0") {
      return "+27" + faxNo.substring(1);
    }
    return faxNo; //telNo;
  }

  cancel() {
    this.close(false);
  }

  close(result) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.loader = true;

    const tradingPartner = this.getBusinessInfo();

    if (this.action === "Edit") {
      this.checkForImageAndSumbit(tradingPartner);
    } else {
      this.tradingPartnerService
        .getTradingPartner(tradingPartner.gln)
        .subscribe(
          (tpExists) => {
            if (tpExists?.gln != "") {
              this.loader = false;
              this.notificationService.showError(
                new Error("The provided gln is already in use"),
              );
            } else {
              this.checkForImageAndSumbit(tradingPartner);
            }
          },
          (error) => {
            if (
              error?.data?.message == "ItemNotFoundException" ||
              error?.data?.message == "Unknown error"
            ) {
              this.checkForImageAndSumbit(tradingPartner);
            }
          },
        );
    }
  }

  checkForImageAndSumbit(tradingPartner: TradingPartner) {
    if (this.selectedFile1) {
      this.tradingPartnerService
        .uploadImage(tradingPartner.gln, this.selectedFile1)
        .then((x) => {
          x.subscribe((imageUrl) => {
            tradingPartner.imageURL = imageUrl;

            this.submitTP(tradingPartner);
          });
        });
    } else {
      this.submitTP(tradingPartner);
    }
  }

  submitTP(tradingPartner: TradingPartner) {
    if (this.action === "Add") {
      this.tradingPartnerService.addTradingPartner(tradingPartner).subscribe(
        (response) => {
          if (response) {
            this.notificationService.showSuccess(
              "Trading partner successfully added",
            );
            this.close({ response: true, tp: tradingPartner });
            this.loader = false;
          }
        },
        (error) => {
          this.loader = false;
          this.notificationService.showError(error);
        },
      );
    } else if (this.action === "Edit") {
      this.tradingPartnerService.editTradingPartner(tradingPartner).subscribe(
        (response) => {
          if (response) {
            this.close(true);
            this.loader = false;
            this.notificationService.showSuccess("Trading partner edited");
          }
        },
        (error) => {
          this.loader = false;
          this.notificationService.showError(error);
        },
      );
    } else if (this.action === "AddStore") {
      this.close({ response: true, tp: tradingPartner });
      this.tradingPartnerService.addTradingPartner(tradingPartner).subscribe(
        (res) => {
          if (res) {
            this.loader = false;
            this.notificationService.showSuccess("Store succefully added");
          }
        },
        (error) => {
          this.loader = false;
          this.notificationService.showError(error);
        },
      );
    }
  }

  populateBusinessInformation() {
    let postalAddress = this.tradingPartner.addresses.find(
      (x) => x.type == AddressType.postalAddress,
    );

    if (!postalAddress) {
      postalAddress = this.tradingPartner.addresses[0];
    }

    if (postalAddress) {
      this.editTradingPartnerForm.controls.addressLine1.setValue(
        postalAddress?.line1?.trim(),
      );
      this.editTradingPartnerForm.controls.addressLine2.setValue(
        postalAddress?.line2?.trim(),
      );
      this.editTradingPartnerForm.controls.addressLine3.setValue(
        postalAddress?.line3?.trim(),
      );
      this.editTradingPartnerForm.controls.addressLine4.setValue(
        postalAddress?.line4?.trim(),
      );
      this.editTradingPartnerForm.controls.postalCode.setValue(
        postalAddress?.postalCode?.trim(),
      );
    }

    this.editTradingPartnerForm.controls.email.setValue(
      this.tradingPartner?.contact?.emailAddress?.trim(),
    );
    this.editTradingPartnerForm.controls.cellNumber.setValue(
      this.getTelNo()?.trim(),
    );
    this.editTradingPartnerForm.controls.faxNumber.setValue(
      this.getFaxNo()?.trim(),
    );
    this.editTradingPartnerForm.controls.contactName.setValue(
      this.tradingPartner?.contact?.contactName?.trim(),
    );
    this.editTradingPartnerForm.controls.contactSurname.setValue(
      this.tradingPartner?.contact?.contactSurname?.trim(),
    );
    this.editTradingPartnerForm.controls.name.setValue(
      this.tradingPartner?.name?.trim(),
    );

    this.editTradingPartnerForm.controls.vatNumber.setValue(
      this.tradingPartner?.vatNo?.trim(),
    );
    this.editTradingPartnerForm.controls.vatRateCode.setValue(
      this.tradingPartner?.vatRateCode?.trim(),
    );
    this.editTradingPartnerForm.controls.vatCountryCode.setValue(
      this.tradingPartner?.vatCountryCode?.trim(),
    );
  }

  chosenFile(files: File[]) {
    this.selectedFile1 = null;

    _.each(files, (file: File) => {
      if (!this.selectedFile1) {
        this.selectedFile1 = file;
        this.notificationService.showSuccess("File Selected: " + file.name);
      }
    });
  }
}
