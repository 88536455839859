import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { RedirectPopupComponent } from "../components/home/get-financed/invoice-processing/invoices-available/redirect-popup/redirect-popup.component";
import { AddProductComponent } from "../components/home/my-products/add-product/add-product.component";
import { UploadProductComponent } from "../components/home/my-products/upload-product/upload-product.component";
import { EditBusinessInfoComponent } from "../components/home/my-profile/business-information/edit-business-info/edit-business-info.component";
import { EditNotificationsComponent } from "../components/home/my-profile/edit-notifications/edit-notifications.component";
import { ManageUserComponent } from "../components/home/my-profile/users/manage-user/manage-user.component";
import { ConnectPartnerComponent } from "../components/home/partner-marketplace/connect-partner/connect-partner.component";
import { UploadTradingPartnersComponent } from "../components/home/trading-partners/upload-trading-partners/upload-trading-partners.component";
import { ConfirmComponent } from "../components/shared/dialogs/confirm/confirm.component";
import { DatePickerComponent } from "../components/shared/dialogs/date-picker/date-picker.component";
import { PasswordResetConfirmComponent } from "../components/shared/dialogs/password-reset-confirm/password-reset-confirm.component";
import { SetRetailerDialogComponent } from "../components/shared/dialogs/set-retailer-dialog/set-retailer-dialog.component";
import { TermsAndConditionsComponent } from "../components/shared/dialogs/terms-and-conditions/terms-and-conditions.component";
import { LoaderComponent } from "../components/shared/loader/loader.component";
import { PrintLayoutComponent } from "../components/shared/print-layout/print-layout.component";
import { Order } from "../models/order";
import { Product } from "../models/product";
import {
  EditType,
  NotificationType,
  OrderSummary,
  TradingPartner,
  User,
} from "../models/types";
import { LinkRetailerComponent } from "./../components/home/trading-partners/trading-partner-details/linked-retailer/link-retailer/link-retailer.component";
import { EditTradingPartnerComponent } from "./../components/home/trading-partners/trading-partner-details/retailer-information/edit-trading-partner/edit-trading-partner.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  dialogConfig: MatDialogConfig;

  constructor(public confirmDialog: MatDialog) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
  }

  public showConfirmationDialog(
    title: string,
    message: string,
    declineButtonText?: string,
    acceptButtonText?: string,
  ) {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      title: title,
      message: message,
      declineButtonText: declineButtonText,
      acceptButtonText: acceptButtonText,
    };
    return this.confirmDialog.open(ConfirmComponent, this.dialogConfig);
  }

  public showManageProductDialog(
    product: Product,
    retailers: TradingPartner[],
  ) {
    this.dialogConfig.width = "60%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      product: product,
      retailers: retailers,
    };
    return this.confirmDialog.open(AddProductComponent, this.dialogConfig);
  }

  public showUploadProductsDialog() {
    this.dialogConfig.width = "60%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {};
    return this.confirmDialog.open(UploadProductComponent, this.dialogConfig);
  }

  public showUploadTradingPartnersDialog() {
    this.dialogConfig.width = "60%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {};
    return this.confirmDialog.open(
      UploadTradingPartnersComponent,
      this.dialogConfig,
    );
  }

  public resetPasswordDialog(message: string, buttonTxt: string) {
    this.dialogConfig.width = "60%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      message: message,
      buttonTxt: buttonTxt,
    };
    return this.confirmDialog.open(
      PasswordResetConfirmComponent,
      this.dialogConfig,
    );
  }

  public connectPartnerDialog(
    dialogType: string,
    tradePartner: TradingPartner,
    connectType: string,
  ) {
    this.dialogConfig.width = "60%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      dialogType: dialogType,
      tradePartner: tradePartner,
      connectType: connectType,
    };
    return this.confirmDialog.open(ConnectPartnerComponent, this.dialogConfig);
  }

  public manageUserDialog(
    tradingPartnerGln?: string,
    user?: User,
    type?: EditType,
  ) {
    this.dialogConfig.width = "60%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      user: user,
      tradingPartnerGln: tradingPartnerGln,
      type: type,
    };
    return this.confirmDialog.open(ManageUserComponent, this.dialogConfig);
  }

  public printSelectedOrdersDialog(orderSummaries: OrderSummary[]) {
    this.dialogConfig.width = "250px";
    this.dialogConfig.height = "250px";
    this.dialogConfig.data = {
      orderSummaries: orderSummaries,
      type: "overview",
    };
    return this.confirmDialog.open(PrintLayoutComponent, this.dialogConfig);
  }

  public exportSelectedOrdersDialog(orderSummaries: OrderSummary[]) {
    this.dialogConfig.width = "250px";
    this.dialogConfig.height = "250px";
    this.dialogConfig.data = {
      orderSummaries: orderSummaries,
      type: "exportCVS",
    };
    return this.confirmDialog.open(PrintLayoutComponent, this.dialogConfig);
  }

  public printOrderDetailsDialog(order: Order) {
    this.dialogConfig.width = "250px";
    this.dialogConfig.height = "250px";
    this.dialogConfig.data = {
      order: order,
      type: "details",
    };
    return this.confirmDialog.open(PrintLayoutComponent, this.dialogConfig);
  }

  public exportOrder(order: Order) {
    this.dialogConfig.width = "250px";
    this.dialogConfig.height = "250px";
    this.dialogConfig.data = {
      order: order,
      type: "export",
    };
    return this.confirmDialog.open(PrintLayoutComponent, this.dialogConfig);
  }

  public editBusinessInfo(tradingPartner: TradingPartner) {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      type: "Edit Business Information",
      tradingPartner: tradingPartner,
    };
    return this.confirmDialog.open(
      EditBusinessInfoComponent,
      this.dialogConfig,
    );
  }

  public editNotifications(notifactionType: NotificationType) {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      title: "Where would you like to receive your notifications?",
      notificationType: notifactionType,
    };
    return this.confirmDialog.open(
      EditNotificationsComponent,
      this.dialogConfig,
    );
  }

  public editTradingPartner(tradingPartner: TradingPartner) {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      title: `Edit ${tradingPartner.type}`,
      action: `Edit`,
      tradingPartner: tradingPartner,
    };
    return this.confirmDialog.open(
      EditTradingPartnerComponent,
      this.dialogConfig,
    );
  }

  public addTradingPartner(parentGln: string, actionType: string) {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    let title = "Add ";
    if (actionType == "AddStore") {
      title += "Store";
    } else {
      title += "Trading Partner";
    }
    this.dialogConfig.data = {
      title: title,
      action: actionType,
      parentGln: parentGln,
    };
    return this.confirmDialog.open(
      EditTradingPartnerComponent,
      this.dialogConfig,
    );
  }

  public linkRetailer(retailer: TradingPartner) {
    this.dialogConfig.width = "80%";
    this.dialogConfig.height = "auto";
    this.dialogConfig.data = {
      title: `Link Retailer`,
      retailer: retailer,
    };
    return this.confirmDialog.open(LinkRetailerComponent, this.dialogConfig);
  }

  public loader() {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    return this.confirmDialog.open(LoaderComponent, this.dialogConfig);
  }

  public termsAndContitions() {
    this.dialogConfig.width = "40%";
    this.dialogConfig.height = "auto";
    return this.confirmDialog.open(
      TermsAndConditionsComponent,
      this.dialogConfig,
    );
  }

  public selectSupplierDialog() {
    this.dialogConfig.width = "35%";
    this.dialogConfig.height = "auto";
    return this.confirmDialog.open(
      SetRetailerDialogComponent,
      this.dialogConfig,
    );
  }

  public confirmRedirectPopup() {
    this.dialogConfig.width = "35%";
    this.dialogConfig.height = "auto";
    return this.confirmDialog.open(RedirectPopupComponent, this.dialogConfig);
  }

  public datePickerDialog() {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    return this.confirmDialog.open(DatePickerComponent, this.dialogConfig);
  }
}
