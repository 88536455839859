import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Order } from "../models/order";
import { DocumentsService } from "./documents.service";

@Injectable({
  providedIn: "root",
})
export class UiDataService {
  private tabName = "";
  private username = "";
  private selectedOrder: Order;
  public orders: Observable<Order[]>;
  constructor(private documentsService: DocumentsService) {}

  setOrderType(value: string) {
    this.tabName = value;
  }

  getOrderType(): string {
    return this.tabName;
  }

  activeTab(tab) {
    localStorage.setItem("tab", tab);
  }

  getActiveTab(): string {
    return localStorage.getItem("tab");
  }

  clearTabSelection() {
    localStorage.removeItem("tab");
  }

  setUserName(value: string) {
    this.username = value;
    this.storeUserName(this.username);
  }

  getUserName(): string {
    if (localStorage.getItem("loggedInUser")) {
      this.username = localStorage.getItem("loggedInUser");
    }
    return this.username;
  }

  clearUserName() {
    this.username = "";
    localStorage.removeItem("loggedInUser");
  }

  currentOrder(curOrder: Order) {
    this.selectedOrder = curOrder;
  }

  saveOrderID(id: string) {
    localStorage.setItem("orderId", id);
  }

  getOrderID(): string {
    return localStorage.getItem("orderId");
  }

  clearID() {
    localStorage.removeItem("orderId");
  }

  storeUserName(username: string) {
    localStorage.setItem("loggedInUser", username);
  }
}
