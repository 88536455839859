<div class="tradeCard p-20">
  <div class="center-item">
    <p class="subHeading">Top Performing Products</p>
    <div class="bl-t-l">
      <div class="m-15"></div>

      <div class="flex fx-jc-center fx-ai-center">
        <div
          *ngIf="!topPerformingProduct"
          style="min-width: 50px !important; max-width: 200px !important"
          class="loader width-100 height-100 flex fx-jc-center fx-ai-center"
        >
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="topPerformingProduct" class="width-100">
          <canvas
            baseChart
            #myCanvas
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [colors]="chartColors"
            [chartType]="barChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
