import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { DialogService } from "../../../services/dialog.service";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { InvoiceFinancingService } from "./../../../services/vodalend.service";

@Component({
  selector: "app-vodalend",
  templateUrl: "./vodalend.component.html",
  styleUrls: ["./vodalend.component.sass"],
})
export class VodalendComponent implements OnInit, OnChanges {
  financeEditGroup: UntypedFormGroup;
  sliderValue = 40000;
  periodValue = 2;
  fixedFee = 4000;

  loanInputTrigger = false;
  rateInputTrigger = false;

  repaymentAmount = 745;
  paymentPeriod = 2;
  totalPaymentPeriod = 6;
  daysInMonth = 30;
  rateValue = 8;
  autoTicks = true;
  disabled = false;
  invert = false;
  sliderMin = 3000;
  sliderMax = 150000;
  rateMin = 3;
  rateMax = 30;
  periodMin = 1;
  periodMax = 6;
  showTicks = true;
  sliderStep = 1000;
  rateStep = 1;
  periodStep = 1;
  thumbLabel = true;
  thumbLabel2 = true;
  thumbLabel3 = true;
  vertical = false;
  tickInterval = 1000;
  rateTickInterval = 1;
  graphColors = ["#00B0CA", "#9C2AA0", "#005765", "#FF0000", "#BD0000"];

  selectedColor: string = this.graphColors[4];
  constructor(
    private dialog: DialogService,
    private notificationService: NotificationService,
    private navigate: NavigationService,
    private formBuilder: UntypedFormBuilder,
    private invoiceFinancingService: InvoiceFinancingService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    //sliderValue
  }

  ngOnInit(): void {
    this.setupInputs();
    this.setDefaultValues();
    this.financeEditGroup.controls.sliderValue.valueChanges.subscribe(
      (slideAmount) => {
        this.sliderValue = slideAmount;
        this.repaymentAmount =
          this.getTotalRepayment() / (this.daysInMonth * this.periodValue);
        this.financeEditGroup.controls.loanAmount.setValue(this.sliderValue);
      },
    );

    this.financeEditGroup.controls.loanAmount.valueChanges.subscribe(
      (loanAmount) => {
        this.sliderValue = loanAmount;
        this.repaymentAmount =
          this.getTotalRepayment() / (this.daysInMonth * this.periodValue);
        this.loanInputTrigger = true;
      },
    );

    this.financeEditGroup.controls.rateValue.valueChanges.subscribe((rate) => {
      this.rateValue = rate;
      this.rateInputTrigger = true;
      this.financeEditGroup.controls.rate.setValue(this.rateValue);
    });

    this.financeEditGroup.controls.rateValue.valueChanges.subscribe((rate) => {
      this.rateValue = rate;
      this.rateInputTrigger = true;
    });

    this.financeEditGroup.controls.loanPeriod.valueChanges.subscribe(
      (loanPeriod) => {
        this.periodValue = loanPeriod;
        this.repaymentAmount =
          this.getTotalRepayment() / (this.daysInMonth * this.periodValue);
      },
    );
  }

  setChangedLoan(amount) {
    this.loanInputTrigger = false;
    this.financeEditGroup.controls.sliderValue.setValue(parseInt(amount));
    this.financeEditGroup.controls.loanAmount.setValue(parseInt(amount));
  }

  setRate(rate) {
    this.rateInputTrigger = false;
    this.financeEditGroup.controls.rate.setValue(parseInt(rate));
    this.financeEditGroup.controls.rateValue.setValue(parseInt(rate));
  }

  setupInputs() {
    this.financeEditGroup = this.formBuilder.group({
      loanAmount: new UntypedFormControl(),
      sliderValue: new UntypedFormControl(),
      rate: new UntypedFormControl(),
      rateValue: new UntypedFormControl(),
      loanPeriod: new UntypedFormControl(),
    });
  }

  setDefaultValues() {
    this.financeEditGroup.controls.loanAmount.setValue(this.sliderValue);
    this.financeEditGroup.controls.sliderValue.setValue(this.sliderValue);
    this.financeEditGroup.controls.rate.setValue(this.rateValue);
    this.financeEditGroup.controls.rateValue.setValue(this.rateValue);
    this.financeEditGroup.controls.loanPeriod.setValue(this.periodValue);
    this.repaymentAmount =
      this.getTotalRepayment() / (this.daysInMonth * this.periodValue);
    this.periodMax = this.totalPaymentPeriod;
  }

  getSliderTickInterval(): number | "auto" {
    if (this.showTicks) {
      return this.autoTicks ? "auto" : this.tickInterval;
    }
    return 0;
  }

  getRateTickInterval(): number | "auto" {
    if (this.showTicks) {
      return this.autoTicks ? "auto" : this.rateTickInterval;
    }
    return 0;
  }

  getPaymentTickInterval(): number | "auto" {
    if (this.showTicks) {
      return this.autoTicks ? "auto" : this.rateTickInterval;
    }
    return 0;
  }

  getTotalRepayment(): number {
    return (
      parseInt(this.financeEditGroup.controls.sliderValue.value) + this.fixedFee
    );
  }

  getPaymentPeriod(): any[] {
    const list = [];
    for (let i = this.periodMin; i <= this.totalPaymentPeriod; i++) {
      list.push(i);
    }
    return list;
  }

  getCapturedData() {
    return {
      dailyAmount: this.repaymentAmount,
      periodValue: this.periodValue,
      rateValue: this.rateValue,
      loanAmount: this.sliderValue,
      totalLoanAmount: this.getTotalRepayment(),
    };
  }

  submit() {
    this.invoiceFinancingService.loanDetails.next(this.getCapturedData());
    this.navigate.navigateToFinancingTermsComponent();
  }
}
