import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Session } from "../../../models/session";
import { routes } from "../../../models/types";
import { AuthService } from "../../../services/auth.service";
import { DialogService } from "../../../services/dialog.service";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.sass"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswortOTP = "12345";
  isConfirmPassword = false;
  isResettingPassword = false;
  isChangingPassword = false;
  isVerifying: boolean;
  resetPassword = false;
  passwordMatch = false;
  confirmToggle = true;
  passwordToggle = true;
  oldpasswordToggle = true;
  changePasswordForm: UntypedFormGroup;
  oneNumber = false;
  oneUppercaseCharacter = false;
  oneLowercaseCharacter = false;
  oneSpecialCharacter = false;
  eightCharactersMinimum = false;
  passwordValid = false;
  passWordMatch = false;
  changePassworAForm: UntypedFormGroup;

  constructor(
    private notification: NotificationService,
    private navigationservice: NavigationService,
    private authService: AuthService,
    private userService: UserService,
    private dialog: DialogService,
    private session: Session,
  ) {}

  ngOnInit(): void {
    this.resetPassword = false;
    this.oneNumber = !false;
    this.initializeInputs();
    this.hookPasswordMatchValidator();
    this.setDefaults();
  }

  setDefaults() {
    this.changePasswordForm.controls.OTP.setValue("");
    this.changePasswordForm.controls.newPassword.setValue("");
    this.changePasswordForm.controls.confirmPassword.setValue("");
    this.changePasswordForm.controls.oldPassword.setValue("");
  }

  initializeInputs() {
    this.changePasswordForm = new UntypedFormGroup({
      OTP: new UntypedFormControl("", [Validators.required]),
      newPassword: new UntypedFormControl("", [Validators.required]),
      confirmPassword: new UntypedFormControl("", [Validators.required]),
      oldPassword: new UntypedFormControl("", [Validators.required]),
    });

    const routedFrom = this.navigationservice.getQueryStringValue("routedFrom");
    if (routedFrom == routes.login) {
      this.isConfirmPassword = true;
      this.changePasswordForm.controls.OTP.clearValidators();
      this.changePasswordForm.controls.oldPassword.clearValidators();
      this.changePasswordForm.controls.OTP.updateValueAndValidity();
      this.changePasswordForm.controls.oldPassword.updateValueAndValidity();
    }

    if (routedFrom == routes.forgotPassword) {
      this.isResettingPassword = true;
      this.changePasswordForm.controls.oldPassword.clearValidators();
      this.changePasswordForm.controls.oldPassword.updateValueAndValidity();
    }

    if (routedFrom == routes.myProfile) {
      this.isChangingPassword = true;
      this.changePasswordForm.controls.OTP.clearValidators();
      this.changePasswordForm.controls.OTP.updateValueAndValidity();
    }
  }

  hookPasswordMatchValidator() {
    this.changePasswordForm.controls.newPassword.valueChanges.subscribe((x) => {
      this.passwordMatch =
        this.changePasswordForm.controls.confirmPassword.value === x
          ? true
          : false;
      this.validatePassword(x);
      if (this.passwordMatch) {
        this.changePasswordForm.controls.confirmPassword.setErrors(null);
      } else {
        this.changePasswordForm.controls.confirmPassword.setErrors({
          passwordMissmatch: true,
        });
      }
    });

    this.changePasswordForm.controls.confirmPassword.valueChanges.subscribe(
      (x) => {
        this.passwordMatch =
          this.changePasswordForm.controls.newPassword.value === x
            ? true
            : false;
        if (this.passwordMatch) {
          this.changePasswordForm.controls.confirmPassword.setErrors(null);
        } else {
          this.changePasswordForm.controls.confirmPassword.setErrors({
            passwordMissmatch: true,
          });
        }
      },
    );
  }

  validatePassword(value) {
    this.oneUppercaseCharacter = /[A-Z]/.test(value);
    this.oneLowercaseCharacter = /[a-z]/.test(value);
    this.oneSpecialCharacter = /[^A-Za-z0-9_]/.test(value);
    this.eightCharactersMinimum = value.length > 7;
    this.oneNumber = /\d/.test(value);

    if (
      this.oneUppercaseCharacter &&
      this.oneLowercaseCharacter &&
      this.oneSpecialCharacter &&
      this.eightCharactersMinimum &&
      this.oneNumber
    ) {
      this.passwordValid = true;
    } else {
      this.passwordValid = false;
    }
  }

  submit() {
    if (this.passwordValid) {
      if (this.isConfirmPassword) {
        this.completeNewPassword();
      }
      if (this.isResettingPassword) {
        this.confirmForgotPassword();
      }
      if (this.isChangingPassword) {
        this.changePassword();
      }
    } else {
      this.notification.showError(new Error("Password is not valid"));
    }
  }

  changePassword() {
    const oldPassword = this.changePasswordForm.controls.oldPassword.value;
    const password = this.changePasswordForm.controls.newPassword.value;

    this.dialog
      .showConfirmationDialog(
        "Reset Password",
        "Are you sure you want to reset your password?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.authService.changePassword(oldPassword, password).subscribe(
            (x) => {
              this.notification.showSuccess("Password changed successfully");
              this.navigationservice.navigateToMyProfile();
            },
            (error) => {
              this.notification.showError(error);

              if (error == "Session expired") {
                this.navigationservice.navigateToLogin();
              }
            },
          );
        }
      });
  }

  completeNewPassword() {
    this.authService
      .completeNewPassword(
        this.changePasswordForm.controls.confirmPassword.value,
      )
      .subscribe(
        () => {
          this.userService.userLoggedIn(this.session.getUser().userId);
          this.navigationservice.navigateHome();
        },
        (error) => {
          if (error == "not authenticated") {
            const showOtp = true;
            this.navigationservice.navigateToLogin(showOtp);
            return;
          }
          this.notification.showError(error);
        },
      );
  }

  confirmForgotPassword() {
    const email = this.navigationservice.getQueryStringValue("email");
    const otp = this.changePasswordForm.controls.OTP.value;
    const password = this.changePasswordForm.controls.newPassword.value;

    this.authService.forgotPasswordSubmit(email, otp, password).subscribe(
      () => {
        this.navigationservice.navigateToLogin();
      },
      (error) => {
        this.notification.showError(error);
      },
    );
  }

  showOtp() {
    const routedFrom = this.navigationservice.getQueryStringValue("routedFrom");
    if (routedFrom == routes.login || routedFrom == routes.myProfile) {
      return false;
    }
    return true;
  }

  showOldPassword() {
    const routedFrom = this.navigationservice.getQueryStringValue("routedFrom");
    if (routedFrom == routes.myProfile) {
      return true;
    }
    return false;
  }

  cancel() {
    const routedFrom = this.navigationservice.getQueryStringValue("routedFrom");
    switch (routedFrom) {
      case routes.myProfile:
        this.navigationservice.navigateToMyProfile();
        break;
      case routes.login:
      case routes.forgotPassword:
        this.navigationservice.navigateToLogin();
        break;
      default:
        this.navigationservice.navigateToLogin();
    }
  }
}
