import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { Order, OrderStatus } from "../../../../models/order";
import { Session } from "../../../../models/session";
import { DialogService } from "../../../../services/dialog.service";
import { NavigationService } from "../../../../services/navigation.service";
import { NotificationService } from "../../../../services/notification.service";
import { OrdersService } from "../../../../services/orders.service";
import { TradingPartnerService } from "../../../../services/trading-partner.service";
import { Settings } from "./../../../../settings";

@Component({
  selector: "app-order-new",
  templateUrl: "./order-new.component.html",
  styleUrls: ["./order-new.component.sass"],
})
export class OrderNewComponent implements OnInit, OnDestroy {
  @ViewChild("elem") tableToPrint;
  orderType: any;
  orderNumber: any;

  ///// OrderFrom
  addrLine1: string;
  addrLine2: string;
  addrLine3: string;
  addrLPostalCode: string;

  data: any;
  type: string;
  showPrintLayout = false;

  dateOfOrder: string;
  vatNo: string;
  tellNo: string;
  dataName = "new";
  dateFormat;

  order: Observable<Order>;
  orderStatus = "new";
  currentOrder: Order;
  status = "New";
  invoiceNumber: string;
  invoiceDate: string = new Date().toISOString();
  invoiceAmount: any;
  formValid = false;
  senderGln: string;
  querystringStatus: string;
  orderId: string;
  headerChangeTrigger = false;

  constructor(
    private orderService: OrdersService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private navigation: NavigationService,
    private settings: Settings,
    private session: Session,
    private tradingPartnerService: TradingPartnerService,
  ) {
    this.setupIcons(iconRegistry, sanitizer);
  }

  ngOnDestroy(): void {
    this.orderService.currentOrder.next(null);
    this.tradingPartnerService.supplierTradingPartnerSelected.next(false);
  }

  ngOnInit() {
    this.tradingPartnerService.supplierTradingPartnerSelected.next(true);
    this.getQueryStringParams();

    this.orderService.get(this.orderId);
    this.dateFormat = this.settings.dateFormat;
    this.order = this.orderService.currentOrder;
    this.order.subscribe(
      (x) => {
        this.currentOrder = x;
      },
      (error) => {
        this.notificationService.showError(error);
      },
    );
  }

  private getQueryStringParams() {
    this.senderGln = this.navigation.getQueryStringValue("sender");
    this.querystringStatus = this.navigation.getQueryStringValue("status");
    this.orderId = this.navigation.getQueryStringValue("orderId");
  }

  confirmOrder() {
    this.dialogService
      .showConfirmationDialog(
        "Confirm Order Confirmation",
        "Are you sure you want to confirm the order?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.orderService.confirm(this.currentOrder.id).subscribe(
            () => {
              this.currentOrder.status = OrderStatus.Confirmed;
              this.notificationService.showSuccess(
                "You have confirmed this order.",
              );
              this.headerChangeTrigger = !this.headerChangeTrigger;
            },
            (error) => {
              this.currentOrder.status = OrderStatus.Acknowledged;
              this.notificationService.showError(error);
            },
          );
        }
      });
  }

  resetOrderToNew() {
    this.dialogService
      .showConfirmationDialog(
        "Reset Order To New",
        "Are you sure you want to reset this order's status to new?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.orderService.new(this.currentOrder.id).subscribe(
            () => {
              this.currentOrder.status = OrderStatus.New;
              this.notificationService.showSuccess(
                "You have reset this order to 'New'.",
              );
            },
            (error) => {
              this.notificationService.showError(error);
            },
          );
        }
      });
  }

  acknowledgeOrder() {
    this.dialogService
      .showConfirmationDialog(
        "Confirm Order Acknowledgement",
        "Are you sure you want to acknowledge the order?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.orderService.acknowledge(this.currentOrder.id).subscribe(
            () => {
              this.currentOrder.status = OrderStatus.Acknowledged;
              this.notificationService.showSuccess(
                "You have acknowledged this order.",
              );
              this.headerChangeTrigger = !this.headerChangeTrigger;
            },
            (error) => {
              this.notificationService.showError(error);
            },
          );
        }
      });
  }

  rejectOrder() {
    this.dialogService
      .showConfirmationDialog(
        "Confirm Order Rejection",
        "Are you sure you want to reject the order?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.orderService.reject(this.currentOrder.id).subscribe(
            () => {
              this.currentOrder.status = OrderStatus.Rejected;
              this.notificationService.showSuccess(
                "You have rejected this order.",
              );
            },
            (error) => {
              this.notificationService.showError(error);
            },
          );
        }
      });
  }

  invoiceOrder() {
    this.dialogService
      .showConfirmationDialog(
        "Confirm Order Invoice",
        "Are you sure you want to invoice the order?",
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const resetStatus = this.currentOrder.status;
          this.validateOrderForInvoice();
          this.orderService
            .invoice(
              this.currentOrder.id,
              this.invoiceNumber,
              this.invoiceAmount,
              this.invoiceDate,
            )
            .subscribe(
              () => {
                this.currentOrder.status = OrderStatus.Invoiced;
                this.notificationService.showSuccess(
                  "You have invoiced this order.",
                );
                this.headerChangeTrigger = !this.headerChangeTrigger;
              },
              (error) => {
                this.currentOrder.status = resetStatus;
                this.notificationService.showError(error);
              },
            );
        }
      });
  }

  validateOrderForInvoice() {
    let error = undefined;
    if (!this.currentOrder.id) {
      error = "Id is required";
    }
    if (!this.invoiceNumber) {
      error = "Invoice number is required";
    }
    if (!this.invoiceAmount) {
      error = "Invoice amount is required";
    }
    if (!this.invoiceDate) {
      error = "Invoice date is required";
    }

    if (error) {
      this.notificationService.showError(error);
      throw new Error(error);
    }
  }

  updateStatusChange(status: OrderStatus) {
    this.currentOrder.status = status;
  }

  allowAcknowledgement() {
    if (this.currentOrder.status == OrderStatus.New) {
      return true;
    }
    return false;
  }

  isRejected() {
    if (this.currentOrder.status == OrderStatus.Rejected) {
      return true;
    }
    return false;
  }

  allowRejection() {
    if (
      (this.currentOrder.status == OrderStatus.New ||
        this.currentOrder.status == OrderStatus.Acknowledged ||
        this.currentOrder.status == OrderStatus.Confirmed) &&
      this.currentOrder.tradingProcess.rejectOrder
    ) {
      return true;
    }
    return false;
  }

  allowConfirmation() {
    if (
      this.currentOrder.status == OrderStatus.Acknowledged &&
      this.currentOrder.tradingProcess.confirmOrder
    ) {
      return true;
    }
    return false;
  }

  allowInvoicing() {
    if (this.currentOrder.tradingProcess.invoiceOrder) {
      if (this.currentOrder.status == OrderStatus.Confirmed) {
        return true;
      }
      if (
        this.currentOrder.status == OrderStatus.Acknowledged &&
        !this.currentOrder.tradingProcess.confirmOrder
      ) {
        return true;
      }
    }
    return false;
  }

  isValidInvoiceAmount(): boolean {
    const total = this.currentOrder.lines
      .map((t) => {
        const itemCost = parseFloat(t.itemCost as any);
        const qty = parseInt(t.quantity as any);
        const lineCost = itemCost * qty;
        const vatRate = parseInt(t.vat?.rate as any);

        if (!vatRate || vatRate == 0) {
          return lineCost;
        }

        return lineCost + (lineCost * vatRate) / 100;
      })
      .reduce((acc, value) => acc + value, 0);

    return total == this.invoiceAmount;
  }

  getTotalLineCost() {
    return this.currentOrder.lines
      .map((t) => parseFloat(t.itemCost as any) * parseInt(t.quantity as any))
      .reduce((acc, value) => acc + value, 0);
  }

  updateInvoiceNumber(invoiceNumber) {
    this.invoiceNumber = invoiceNumber;
  }

  updateInvoiceDate(invoiceDate) {
    this.invoiceDate = invoiceDate;
  }

  updateInvoiceAmount(invoiceAmount) {
    this.invoiceAmount = invoiceAmount;
  }

  enableInvoiceButton(enableInvoice) {
    this.formValid = enableInvoice;
  }

  formatDate(date: Date): string {
    if (date != null) {
      return (
        date.getFullYear() +
        "/" +
        (date.getMonth() - 1 < 10 ? "0" + (date.getMonth() - 1) : "too Low") +
        "/" +
        date.getDate()
      );
    }
  }

  setupIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      "check",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/check.svg"),
    );
    iconRegistry.addSvgIcon(
      "note",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/event_note.svg"),
    );
    iconRegistry.addSvgIcon(
      "close",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/close.svg"),
    );
    iconRegistry.addSvgIcon(
      "thumbs-up",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/thumb_up.svg"),
    );
    iconRegistry.addSvgIcon(
      "back",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/back.svg"),
    );
  }

  goToOrders() {
    this.navigation.navigateOrders(this.senderGln, this.querystringStatus);
  }

  printPage() {
    this.data = this.currentOrder;
    this.type = "details";
    this.showPrintLayout = true;
  }

  resendInvoice() {
    this.orderService
      .resendInvoice(this.currentOrder.id, this.currentOrder.invoiceId)
      .subscribe(
        () => {
          this.notificationService.showSuccess("Invoice has been re-sent");
        },
        (error) => {
          this.notificationService.showError(error);
        },
      );
  }

  calculateStyles(status: string) {
    let color: string;

    if (status.toLowerCase() === "new") {
      color = "#00B0CA";
    } else if (status.toLowerCase() === "acknowledged") {
      color = "#9C2AA0";
    } else if (status.toLowerCase() === "confirmed") {
      color = "#A8B400";
    } else if (status.toLowerCase() === "rejected") {
      color = "#FF0000";
    } else if (status.toLowerCase() === "invoiced") {
      color = "#EB9700";
    }

    return {
      "background-color": color,
    };
  }

  determineOrderToGln() {
    return !this.currentOrder.deliverToGln
      ? this.currentOrder.orderFromGln
      : this.currentOrder.deliverToGln;
  }

  exportOrder() {
    this.data = this.currentOrder;
    this.type = "export";
    this.showPrintLayout = true;

    // this.dialogService.exportOrder(this.currentOrder);
  }

  getInvoiceDetails() {
    // if(!this.currentOrder.invoiceNumber) return
    let splitOrder = [];

    if (this.currentOrder.invoiceId) {
      splitOrder = this.currentOrder.invoiceId.split("_");
    }
    return {
      invoiceId: splitOrder[splitOrder.length - 1],
    };
  }

  onClose() {
    this.showPrintLayout = false;
  }

  goToSupplierView() {
    this.navigation.navigateToSupplierView();
  }

  isAdmin(): boolean {
    return this.session.isAdmin();
  }
}
