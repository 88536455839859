import { TradingPartner } from "./types";

export class Invoice {
  invoiceId: string;
  invoiceNumber = "";
  invoiceDate: Date;
  supplier: TradingPartner;
  orderFrom: TradingPartner;
  deliverTo: TradingPartner;
  total = -1;
  lines: InvoiceLine[] = [];
  maturityDate = "";
}

export class InvoiceSummary {
  invoiceNumber = "";
  invoiceDate: Date;
  total = -1;
  invoiceId = "";
  orderFrom: TradingPartner;
}

export interface InvoiceLine {
  productEan: string;
  productDescription: string;
  quantity: number;
  originalQuantity: number;
  itemCost: number;
  lineItemCost: number;
}

export class InvoiceAnalyticsResponse {
  retailer = "";
  amount = "";
  count = "";
}

export class SearchInvoicesResponse {
  invoiceData: Invoice[] = [];
  invoiceCount = 0;
}
