<div>
  <div id="close" (click)="close()">&times;</div>
  <div id="title" class="flexRow">
    <!-- <img *ngIf="type === 'success'" class="small-icon" src="./assets/color-icons/completed.svg">
    <img *ngIf="type === 'warning'" class="small-icon" src="./assets/color-icons/warning.svg">
    <img *ngIf="type === 'error'" class="small-icon" src="./assets/color-icons/error-circle.svg">
    <img *ngIf="type === 'info'" class="small-icon" src="./assets/icons/info-circle.svg"> -->
    <!-- <p class="mt0">{{title}}</p> -->
  </div>
  <div id="message">
    {{ message }}
  </div>
  <div id="link">
    <a href="{{ link }}">{{ linkText }}</a>
  </div>
</div>
