import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.sass"],
})
export class ConfirmComponent {
  title: string;
  message: string;
  name: string;
  declineButtonText: string;
  acceptButtonText: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.name = data.name;
    data.declineButtonText
      ? (this.declineButtonText = data.declineButtonText)
      : (this.declineButtonText = "Cancel");
    data.acceptButtonText
      ? (this.acceptButtonText = data.acceptButtonText)
      : (this.acceptButtonText = "Confirm");
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
  cancel(): void {
    this.dialogRef.close(false);
  }
}
