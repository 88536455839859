import { Component, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { User } from "../../../models/types";
import { DialogService } from "../../../services/dialog.service";
import { NavigationService } from "../../../services/navigation.service";
import { NotificationService } from "../../../services/notification.service";
import { PrintingServiceService } from "../../../services/printing-service.service";
import { ReportService } from "../../../services/report.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";
import { Settings } from "../../../settings";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD MMM yyyy",
  },
  display: {
    dateInput: "DD MMM yyyy",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
const ELEMENT_DATA: User[] = [];

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.sass"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReportsComponent {
  name = "";
  surname = "";
  email = "";
  mobile = "";
  username = "";
  roles: string[] = [];
  isMfa = false;
  tradingPartnerGln = "";
  lastActivityDate = "";
  lastActivityType = "";

  displayedColumns: string[] = [
    "username",
    "supplierName",
    "userType",
    "name",
    "surname",
    "active",
    "noOfDaysInactive",
    "lastDayInactive",
  ];
  userReports: User[] = [];
  userReportsForm: UntypedFormGroup;
  loadingReports = false;
  dateFormat;

  dataSource = new MatTableDataSource<User>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private reportsService: ReportService,
    private navigate: NavigationService,
    private dialogService: DialogService,
    private notification: NotificationService,
    private settings: Settings,
    private printingService: PrintingServiceService,
  ) {}

  setupInputControls() {
    this.userReportsForm = this.formBuilder.group({
      searchReports: ["", null],
      inactiveDateRange: ["", null],
    });
  }

  generateReports() {
    this.loadingReports = true;
    this.reportsService.getUserReports().subscribe(
      (reports) => {
        if (reports) {
          this.loadingReports = false;
          this.userReports = reports;
          this.dataSource.data = this.userReports;
          // this.paginator = this.dataSource.paginator
          this.dataSource.paginator = this.paginator;
        }
      },
      (error) => {
        this.loadingReports = false;
        this.notification.showError(error);
      },
    );
  }

  search(filterValue: string) {
    this.dataSource.data = this.userReports.filter(
      (x) =>
        x.username.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.surname.toLowerCase().includes(filterValue.trim().toLowerCase()),
    );
  }

  navToAdminReports() {
    this.navigate.navigateToAdminReports();
  }

  navToUserReports() {
    this.navigate.navigateToUserReports();
  }

  navToDetailedSupplierReports() {
    this.navigate.navigateToDetailedSupplierReports();
  }
}
