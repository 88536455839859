import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Chart } from "chart.js";
import { Observable } from "rxjs";
import _ from "underscore";
import { OrderStatus } from "../../../../models/order";
import { OrderSummary, TradingPartner } from "../../../../models/types";
import { NavigationService } from "../../../../services/navigation.service";
import { Session } from "./../../../../models/session";
import { OrdersService } from "./../../../../services/orders.service";
import { TradingPartnerService } from "./../../../../services/trading-partner.service";

export interface tradePartner {
  gln: string;
  count: number;
  imageUrl: string;
  tradingPartner: Observable<TradingPartner>;
}

export interface OrdersPerStatus {
  status: string;
  number: number;
}

@Component({
  selector: "app-total-orders",
  templateUrl: "./total-orders.component.html",
  styleUrls: ["./total-orders.component.sass"],
})
export class TotalOrdersComponent implements OnChanges {
  @Output() totalOrdersAmount: EventEmitter<number> = new EventEmitter();
  @Output() ordersToBeInvoiced: EventEmitter<number> = new EventEmitter();
  @Input() toMaxDate: Date;
  @Input() fromMinDate: Date;

  chart: Chart<"doughnut">;
  currentColor = "New";
  filteredOrders: OrderSummary[];
  graphColors = {
    New: "#00B0CA",
    Acknowledged: "#9C2AA0",
    Confirmed: "#005765",
    Rejected: "#FF0000",
    Invoiced: "#BD0000",
  };
  loadOrder = false;
  orderNumber;
  orders: OrderSummary[] = [];
  ordersPerStatus: OrdersPerStatus[] = [];
  ordersPerStatusGroup: number[] = [];
  orderTypePercentage = 100;
  percent = "%";
  selectedColor: string = this.graphColors["Confirmed"];
  selectedStatus = "New";
  statuses: string[] = [
    "New",
    "Acknowledged",
    "Confirmed",
    "Rejected",
    "Invoiced",
  ];
  totalOrdersForType: string;
  tradePartnerList: tradePartner[] = [];

  constructor(
    private orderService: OrdersService,
    private session: Session,
    private tradingPartner: TradingPartnerService,
    private navigationService: NavigationService,
  ) {}

  ngOnChanges() {
    this.loadOrders(null, null, null, this.fromMinDate, this.toMaxDate);
  }

  private loadOrders(
    sender: string,
    searchtext: string,
    status: string,
    dateFrom: Date,
    dateTo: Date,
  ) {
    this.loadOrder = true;
    dateTo = new Date(new Date(dateTo).setHours(23, 59, 59));

    this.orderService
      .search(sender, searchtext, [status], dateFrom, dateTo)
      .subscribe(
        (orders) => {
          if (!orders) {
            return;
          }
          this.orders = orders.data;
          this.processOrdersPerRetailerChart();
          this.processTotalOrdersChart();
          this.loadOrder = false;
        },
        () => {
          this.loadOrder = false;
        },
      );
  }

  processTotalOrdersChart() {
    if (this.orders && this.orders.length >= 0) {
      this.setOrdersPerStatus();
      this.totalOrdersAmount.emit(this.calculateTotalOrders());
      this.ordersToBeInvoiced.emit(this.calculateOrdersToBeInvoiced());
      this.selectedValue(null, 0);
      this.plotChart();
    }
  }

  goToOrders(item) {
    this.navigationService.navigateOrders(item.gln, this.selectedStatus);
  }

  processOrdersPerRetailerChart() {
    this.tradePartnerList.length = 0;

    const filteredOrders = this.selectedStatus
      ? _.filter(this.orders, (x) => x.status == this.selectedStatus)
      : this.orders;

    this.filteredOrders = filteredOrders;

    const groupedOrders = _.groupBy(filteredOrders, (order) => {
      return order.orderFrom.gln;
    });

    const tpList = [];

    _.each(groupedOrders, (tpOrders) => {
      tpList.push({
        count: tpOrders.length,
        gln: tpOrders[0].orderFrom.gln,
        imageUrl: "",
        tradingPartner: this.tradingPartner.getTradingPartner(
          tpOrders[0].orderFrom.gln,
        ),
      });
    });

    this.tradePartnerList = _.sortBy(tpList, (tp) => {
      return tp.count;
    }).reverse();
  }

  availableToDisplay() {
    if (this.filteredOrders) {
      return this.filteredOrders.length > 0;
    }

    return false;
  }

  plotChart() {
    const points = [
      this.getOrderStatusCount(OrderStatus.New),
      this.getOrderStatusCount(OrderStatus.Acknowledged),
      this.getOrderStatusCount(OrderStatus.Confirmed),
      this.getOrderStatusCount(OrderStatus.Rejected),
      this.getOrderStatusCount(OrderStatus.Invoiced),
    ];

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart("canvas", {
      type: "doughnut",

      data: {
        labels: ["New", "Acknowledged", "Confirmed", "Rejected", "Invoiced"],
        datasets: [
          {
            data: points,
            backgroundColor: Object.values(this.graphColors),
          },
        ],
      },
      options: {
        cutout: "85%",
        aspectRatio: 1,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }

  calculateTotalOrders(): number {
    const totalOrders = this.orders.filter(
      (x) =>
        x.status == OrderStatus.Confirmed ||
        x.status == OrderStatus.Acknowledged ||
        x.status == OrderStatus.New,
    );
    if (totalOrders && totalOrders.length > 0) {
      return totalOrders.reduce((sum, current) => sum + current.total, 0);
    }
    return 0;
  }

  calculateTotalOrdersForType(status) {
    const orders = this.orders.filter((x) => x.status == status);
    if (orders && orders.length > 0) {
      const total = orders.reduce((sum, current) => sum + current.total, 0);
      this.totalOrdersForType = total.toFixed(2);
      return total;
    }
    this.totalOrdersForType = "0";
    return 0;
  }

  calculateOrdersToBeInvoiced(): number {
    const confimedOrders = this.orders.filter(
      (x) => x.status == OrderStatus.Invoiced,
    );
    if (confimedOrders && confimedOrders.length > 0) {
      return confimedOrders.reduce((sum, current) => sum + current.total, 0);
    }
    return 0;
  }

  calculateOrderTypePercentage(status: string) {
    const ordersForStatus = this.orders.filter((x) => x.status == status);
    if (ordersForStatus && ordersForStatus.length > 0) {
      const totalCount = this.orders.reduce(
        (sum, current) => sum + current.total,
        0,
      );
      const selectedStatusCount = ordersForStatus.reduce(
        (sum, current) => sum + current.total,
        0,
      );
      this.orderTypePercentage = parseFloat(
        ((selectedStatusCount / totalCount) * 100).toFixed(2),
      );
      return;
    }
    this.orderTypePercentage = 0;
  }

  setOrdersPerStatus() {
    this.ordersPerStatus.length = 0;
    for (const sts in OrderStatus) {
      const status = this.orders.filter((x) => x.status == sts);
      this.ordersPerStatus.push({
        status: sts,
        number: status.length,
      });
    }
  }

  getOrderStatusCount(status: OrderStatus): number {
    const orderStatus = this.ordersPerStatus.find(
      (x) => x.status == status.toString(),
    );
    if (orderStatus) {
      return orderStatus.number;
    }
    return 0;
  }

  selectedValue(value, i): string {
    const status = this.statuses[i];
    const numberOfOrders = this.orders.filter((x) => x.status == status);
    this.selectedStatus = this.statuses[i];
    this.selectedColor = this.graphColors[this.selectedStatus];
    this.orderNumber = numberOfOrders.length;
    this.calculateTotalOrdersForType(status);
    this.calculateOrderTypePercentage(status);
    this.processOrdersPerRetailerChart();
    return this.currentColor;
  }

  borderStyle() {
    return { "border-left": "4px solid " + this.selectedColor };
  }
}
