<div class="flex fx-jc-center fx-ai-center grayBG m-b-70 scroll">
  <div class="width-90 flex m-20 fx-jc-center">
    <p class="m-b-30 title">{{ (tradingPartner | async)?.name }}</p>

    <div
      class="fx-ai-center fx-row m-t-20 m-b-20 flex"
      (click)="navigateToGetFinanced()"
    >
      <!-- <button mat-flat-button class="flex breadCrumb fx-ai-center m-l-10" (click)="goBack()">
             </button> -->
      <div>
        <mat-icon class="icon">arrow_back_ios</mat-icon>
      </div>
      <p class="flex tpInfoP breadCrumb fx-ai-center m-l-10">
        Receivables Finance
      </p>
    </div>

    <div>
      <p class="paragraph">
        If you trade in goods and services, you can unlock valuable cash trapped
        in your unpaid invoices
      </p>
      <p class="paragraph">
        With Receivables Finance, you can sell approved invoices to a funder at
        a discount. This allows you to receive cash before the invoice due date
        (maturity date).
      </p>
      <!-- <p class="paragraph">Choose which invoices you would like to submit for early payment and get fresh capital for your company.
        </p> -->
    </div>

    <div class="p-30 m-t-30 tradeCard">
      <p class="tpInfoP">Here’s how Receivables Finance can help you:</p>
      <div class="flex fx-row fx-ai-center">
        <ul class="listP">
          <li>
            Easy-to-implement solution supported by our implementation partner
            Addendum
          </li>
          <li>Sell your invoices whenever you need cash</li>
          <li>Competitive rates</li>
          <li>Paperless transactions</li>
        </ul>
        <div class="m-l-auto">
          <img
            class="m-l-auto"
            src="assets/images/vectors/heresHowInvoiceImage.svg"
            width="200"
            class="animate__animated"
          />
        </div>
      </div>
    </div>

    <div class="p-30 m-t-30 m-b-50 tradeCard">
      <p class="tpInfoP">Information we need from you:</p>
      <div class="flex fx-row fx-ai-center">
        <ul class="listP">
          <li>Information about your business</li>
          <li>Information about you</li>
          <li>Information about your debtors</li>
        </ul>
        <div class="m-l-auto">
          <img
            class="m-l-auto"
            src="assets/images/vectors/InfoWeNeedImage.svg"
            width="200"
            class="animate__animated"
          />
        </div>
      </div>
    </div>

    <div class="m-b-40"></div>
    <div class="width-100 fx-jc-center flex fx-ai-center">
      <div
        style="bottom: 0px; position: fixed"
        class="width-70 p-t-10 p-b-10 p-l-20 p-r-20 m-t-30 m-b-30 fx-ai-center fx-jc-center fx-ai-center tradeCard flex fx-row"
      >
        <div class="fx-jc-center fx-ai-center flex fx-row width-100">
          <p class="P-l noMargin">
            Would you like to receive cash for your unpaid invoices?
          </p>
          <div class="m-l-auto">
            <button
              mat-flat-button
              class="primaryBtn btn round m-l-auto"
              (click)="apply()"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
