import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Session } from "../models/session";
import { newClient } from "../services/api-gateway.service.js";
import {
  AnalyticResult,
  AnalyticType,
  BusinessInfoMoldel,
  DetailedSupplierReport,
  SupplierReport,
  TradingPartner,
} from "./../models/types";
import { TradingPartnersRepository } from "./trading-partners.repository";

const apigClient = newClient();

@Injectable()
export class AwsTradingPartnerRepository implements TradingPartnersRepository {
  constructor(private session: Session) {}

  get(svGln: string, gln: string): Observable<TradingPartner> {
    const retObject = new Subject<TradingPartner>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        gln: gln,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGlnGet(params, {}, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner;
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  getSuppliers(svGln: string): Observable<TradingPartner[]> {
    const retObject = new Subject<TradingPartner[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          type: "Supplier",
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGet({}, null, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner[];
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  getRetailers(svGln: string): Observable<TradingPartner[]> {
    const retObject = new Subject<TradingPartner[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          type: "Retailer",
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGet({}, null, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner[];
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  getStores(svGln: string, parentGln: string): Observable<TradingPartner[]> {
    const retObject = new Subject<TradingPartner[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          parentGln: parentGln,
          type: "Store",
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGet({}, null, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner[];
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  getPrintStores(
    svGln: string,
    parentGln: string,
  ): Observable<TradingPartner[]> {
    const retObject = new Subject<TradingPartner[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          parentGln: parentGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersPrintstoresGet({}, null, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner[];
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  getPrint(svGln: string, orderIds: any[]): Observable<TradingPartner[]> {
    const retObject = new Subject<TradingPartner[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const body = {
        orderIds: orderIds,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersPrintPost({}, body, additionalParams)
        .then((result) => {
          // Success
          const orderResponse = result.data as TradingPartner[];
          retObject.next(orderResponse);
        })
        .catch(({ data }) => {
          // Error
          retObject.error(data);
        });
    });
    return retObject;
  }

  search(
    svGln: string,
    gln: string,
    withTradingPartner?: boolean,
    searchText?: string,
    type?: string,
  ): Observable<TradingPartner[]> {
    const retObject = new Subject<TradingPartner[]>();
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          gln: gln,
          searchText: searchText,
          tradingWith: withTradingPartner,
          type: type,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGet({}, null, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner[];
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  insert(svGln: string, tradingPartner: TradingPartner): Observable<any> {
    const retObject = new Subject<void>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersPost({}, tradingPartner, additionalParams)
        .then((result) => {
          const tp = result.data;
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  batchInsert(svGln: string, tradingPartners: TradingPartner[]) {
    const retObject = new Subject<TradingPartner[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();

      if (!user) {
        return;
      }

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersBatchPost({}, tradingPartners, additionalParams)
        .then((result) => {
          const tp = result.data as TradingPartner[];
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  edit(svGln: string, tradingPartner: TradingPartner): Observable<any> {
    const retObject = new Subject<void>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const params = {
        gln: tradingPartner.gln,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGlnPut(params, tradingPartner, additionalParams)
        .then((result) => {
          const tp = result.data;
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  editBusinessInfo(
    svGln: string,
    businessInfo: BusinessInfoMoldel,
  ): Observable<any> {
    const retObject = new Subject<void>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      const params = {
        gln: svGln,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGlnPut(params, businessInfo, additionalParams)
        .then((result) => {
          const tp = result.data;
          retObject.next(tp);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  uploadImage(svGln: string, gln: string, data: any): Observable<string> {
    const retObject = new Subject<string>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        gln: gln,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGlnUploadimagePost(params, data, additionalParams)
        .then((result) => {
          const image = result.data;
          retObject.next(image);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  getAnalytics(
    svGln: string,
    dateFrom: string,
    dateTo: string,
    analytics: AnalyticType[],
  ): Observable<AnalyticResult[]> {
    const retObject = new Subject<AnalyticResult[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        gln: svGln,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateFrom: dateFrom,
          dateTo: dateTo,
          analytics: analytics,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .tradingpartnersGlnAnalyticsGet(params, {}, additionalParams)
        .then((result) => {
          const analyticResult = result.data;
          retObject.next(analyticResult);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  generateDetailedSupplierReports(
    svGln: string,
    dateFrom: string,
    dateTo: string,
    suppliers: string[],
  ): Observable<DetailedSupplierReport[]> {
    const retObject = new Subject<DetailedSupplierReport[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {};
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateFrom: dateFrom,
          dateTo: dateTo,
          suppliers: suppliers,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .reportTradingPartnersDetailedSupplierOrderGet(
          params,
          {},
          additionalParams,
        )
        .then((result) => {
          const analyticResult = result.data;
          retObject.next(analyticResult);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }

  generateSupplierReports(
    svGln: string,
    dateFrom: string,
    dateTo: string,
    suppliers: string[],
  ): Observable<SupplierReport[]> {
    const retObject = new Subject<SupplierReport[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {};
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          dateFrom: dateFrom,
          dateTo: dateTo,
          suppliers: suppliers,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .reportTradingPartnersSupplierOrderGet(params, {}, additionalParams)
        .then((result) => {
          const analyticResult = result.data;
          retObject.next(analyticResult);
        })
        .catch(({ data }) => {
          retObject.error(data);
        });
    });

    return retObject;
  }
}
