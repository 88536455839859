import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Product } from "../models/product";
import { Session } from "../models/session";
import { newClient } from "../services/api-gateway.service.js";
import { ProductRepository } from "./product-repository";

const apigClient = newClient();

@Injectable()
export class AwsProductRepository implements ProductRepository {
  constructor(private session: Session) {}

  get(svGln: string, id: string): Observable<Product> {
    const product = new Subject<Product>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .productsIdGet(params, undefined, additionalParams)
        .then((result) => {
          const productResponse = result.data as Product;
          product.next(productResponse);
        })
        .catch(({ data }) => {
          product.error(data);
        });
    });

    return product;
  }

  search(
    svGln: string,
    searchText: string,
    productCode: string,
    barCode: string,
    retailerGln: string,
  ): Observable<Product[]> {
    const productsResponse = new Subject<Product[]>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
          searchText: searchText,
          productCode: productCode,
          barCode: barCode,
          retailerGln: retailerGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .productsGet({}, null, additionalParams)
        .then((getProductResult) => {
          const productResult = getProductResult.data as Product[];
          productsResponse.next(productResult);
        })
        .catch(({ data }) => {
          productsResponse.error(data);
        });
    });

    return productsResponse;
  }

  add(svGln: string, product: Product): Observable<Product> {
    const response = new Subject<Product>();
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .productsPost({}, product, additionalParams)
        .then((postProductResult) => {
          const productResult = postProductResult.data as Product;
          response.next(productResult);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  addProducts(svGln: string, products: Product[]): Observable<void> {
    const response = new Subject<void>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .productsBatchPost({}, products, additionalParams)
        .then(() => {
          response.next(null);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  update(svGln: string, product: Product): Observable<Product> {
    const response = new Subject<Product>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: product.id,
      };

      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .productsIdPut(params, product, additionalParams)
        .then((putProductResult) => {
          const productResult = putProductResult.data as Product;
          response.next(productResult);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }

  delete(svGln: string, id: string): Observable<Product> {
    const response = new Subject<Product>();

    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      const params = {
        id: id,
      };
      const additionalParams = {
        queryParams: {
          svGln: svGln,
        },
        headers: {
          Authorization: user.token,
        },
      };

      apigClient
        .productsIdDelete(params, undefined, additionalParams)
        .then((deleteProductResult) => {
          const productResult = deleteProductResult.data as Product;
          response.next(productResult);
        })
        .catch(({ data }) => {
          response.error(data);
        });
    });

    return response;
  }
}
