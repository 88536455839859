export class SrfApplication {
  public businessDetail: BusinessDetail = new BusinessDetail();
  public businessAddress: BusinessAddress = new BusinessAddress();
  public personalDetail: PersonalDetail = new PersonalDetail();
  public personalAddress: PersonalAddress = new PersonalAddress();
}

export class BusinessDetail {
  public companyName = "";
  public glnCode = "";
  public vatNumber = "";
  public registeredName = "";
  public registrationNumber = "";
  public companyType = "";
  public businessType = "";
  public tradingYears = -1;
}

export class BusinessAddress {
  public unitNumber = -1;
  public streetNumber = -1;
  public buildingName = "";
  public streetName = "";
  public suburb = "";
  public city = "";
}

export class PersonalDetail {
  public firstNames = "";
  public surname = "";
  public designation = "";
  public idType = "";
  public idPassportNumber = "";
  public email = "";
  public cell = -1;
}

export class PersonalAddress {
  public line1 = "";
  public line2 = "";
  public line3 = "";
  public suburb = "";
  public city = "";
}

export enum SrfApplicationStatus {
  New = "New",
  InProgress = "InProgress",
  Complete = "Complete",
  Rejected = "Rejected",
}
