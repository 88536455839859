<div class="flex fx-row">
  <p class="subHeading flex p-b-10">{{ linkedTypesLabel(retailer?.type) }}</p>
  <form
    class="flex fx-ai-center m-l-auto fx-row round tradeCard width-20 p-t-10 p-r-20 p-l-20"
    [formGroup]="searchForm"
  >
    <mat-form-field class=" ">
      <input
        matInput
        (keyup.enter)="search($event.target.value)"
        formControlName="searchTradingPartner"
      />
      <mat-label
        ><mat-icon>search</mat-icon> Search
        {{ linkedTypesLabel(retailer?.type) }}
      </mat-label>
    </mat-form-field>
  </form>
</div>
<form [formGroup]="linkedTradingPartnerForm">
  <div class="scrollLinkTable m-t-10">
    <table
      matTableFilter
      matTableExporter
      mat-table
      [dataSource]="dataSource"
      class="width-100"
      #exporter="matTableExporter"
    >
      <!-- productCode Column -->
      <ng-container matColumnDef="gln">
        <th mat-header-cell *matHeaderCellDef>GLN</th>
        <td mat-cell *matCellDef="let element">{{ element.gln }}</td>
      </ng-container>

      <!-- productCode Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let element">
          <img
            class="medium-image"
            src="{{ element?.imageURL }}"
            alt="{{ element?.name }}"
          />
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>Link</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle
            [color]="'primary'"
            (change)="linkRetailer(element)"
            [checked]="isLinked(element.gln)"
            class="m-l-auto"
          ></mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation(); manageProduct(row)"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
            formControlName="selected"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>

  <div style="justify-content: space-between" class="flex fx-row m-l-10 m-r-10">
    <button
      mat-flat-button
      class="width-45 round cancelBtn fl flexex"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="width-45 round primaryBtn fl flexex"
      (click)="submit()"
    >
      <span class="flex fx-row fx-jc-center fx-ai-center"
        >Submit
        <mat-spinner
          *ngIf="loader"
          class="m-l-5"
          strokeWidth="3"
          [diameter]="15"
        ></mat-spinner
      ></span>
    </button>
  </div>
</form>
