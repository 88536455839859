import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  Params,
  Router,
} from "../../../node_modules/@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  public readonly login = "login";
  public readonly home = "home";

  public dashboard = "dashboard";
  public orders = "orders";
  public newOrders = "newOrders";
  public myProducts = "myProducts";
  public partnerMarketplaceComponent = "partnerMarketplaceComponent";
  public partnerMarketplaceDetails = "partnerMarketplaceDetails";
  public myProfile = "myProfile";
  public vodalend = "vodalend";
  public financingTerms = "financingTerms";
  public financingSuccess = "financingSuccess";
  public forgotPassword = "forgotPassword";
  public resetPassword = "resetPassword";
  public tradingPartner = "tradingPartner";
  public tradingPartnerDetails = "tradingPartnerDetails";
  public error = "error";
  public changeHistory = "changeHistory";
  public supplierView = "supplierView";
  public adminReport = "adminReport";
  public userReport = "userReport";
  public reports = "reports";
  public adminReportView = "adminReportView";
  public manageUsers = "manageUsers";
  public detailedSupplierReport = "detailedSupplierReport";
  public detailedSupplierReportView = "detailedSupplierReportView";
  public getFinanced = "invoiceProcessing/getFinanced";
  public moreInfo = "invoiceProcessing/moreInfo";
  public tellUsAboutYourBusiness = "invoiceProcessing/tellUsAboutYourBusiness";
  public nextSteps = "invoiceProcessing/nextSteps";
  public invoiceProcessing = "invoiceProcessing/invoiceProcessing";
  public srfAdditionalInfo = "invoiceProcessing/srfAdditionalInfo";

  //newOrders
  private currentQueryParams: Params;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
  }

  public navigate(path, queryParams?: any) {
    this.router.navigate([path], queryParams);
  }

  public navigateRedirect(path) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([path]));
  }

  public currentRoute() {
    return this.router.url.replace("/", "");
  }

  public navigateToLogin = function (showOtp = false) {
    const queryParams = {
      showOtp: showOtp,
    };
    this.navigate(this.login, { queryParams: queryParams });
  };

  public navigateHome = function () {
    this.navigate(this.home);
  };

  public navigateDashboard = function () {
    this.navigate(this.dashboard);
  };

  public currentOrderFilter = {
    sender: null,
    status: null,
  };
  public navigateOrders = function (sender?: string, status?: string) {
    const queryParams: any = {};
    this.currentOrderFilter.sender = sender;
    this.currentOrderFilter.status = status;
    if (sender && sender != null) {
      queryParams.sender = sender;
    }
    if (status && status != null) {
      queryParams.status = status;
    }

    this.navigate(this.orders, { queryParams: queryParams });
  };

  public navigateNewOrders = function (
    orderId: string,
    sender: string,
    status: string,
  ) {
    const queryParams: any = {};
    queryParams.orderId = orderId;

    if (sender && sender != null) {
      queryParams.sender = sender;
    }
    if (status && status != null) {
      queryParams.status = status;
    }

    this.navigate(this.newOrders, { queryParams: queryParams });
  };

  public navigateToPartnerMarketplaceDetails = function () {
    this.navigate(this.partnerMarketplaceDetails);
  };

  public navigateToPartnerMarketplace = function () {
    this.navigate(this.partnerMarketplaceComponent);
  };

  public navigateToMyProducts = function () {
    this.navigate(this.myProducts);
  };

  public navigateToMyProfile = function () {
    this.navigate(this.myProfile);
  };

  public navigateToInvoiceFinancing = function () {
    this.navigate(this.vodalend);
  };

  public navigateToFinancingTermsComponent = function () {
    this.navigate(this.financingTerms);
  };

  public navigateToFinancingSuccess = function () {
    this.navigate(this.financingSuccess);
  };

  public navigateToForgotPassword = function () {
    this.navigate(this.forgotPassword);
  };

  public navigateToTradingPartner = function () {
    this.navigate(this.tradingPartner);
  };

  public navigateToTradingPartnerDetails = function (gln: string) {
    const queryParams: any = {};
    queryParams.gln = gln;

    this.navigate(this.tradingPartnerDetails, { queryParams: queryParams });
  };

  public navigateToError = function () {
    this.navigate(this.error);
  };

  public navigateToChangeHistory = function () {
    this.navigate(this.changeHistory);
  };

  public navigateToSupplierView = function () {
    this.navigate(this.supplierView);
  };

  public navigateToAdminReports = function () {
    this.navigate(this.adminReport);
  };

  public navigateToUserReports = function () {
    this.navigate(this.userReport);
  };

  public navigateToAdminReportsView = function () {
    this.navigate(this.adminReportView);
  };

  public navigateToManageUsers = function () {
    this.navigate(this.manageUsers);
  };

  public navigateToReports = function () {
    this.navigate(this.reports);
  };

  public navigateToDetailedSupplierReports = function () {
    this.navigate(this.detailedSupplierReport);
  };

  public navigateToDetailesSupplierReportsView = function () {
    this.navigate(this.detailedSupplierReportView);
  };

  public navigateToResetPassword(routedFrom: string, email?: string) {
    const queryParams: any = {};
    queryParams.routedFrom = routedFrom;
    queryParams.email = email;
    this.navigate(this.resetPassword, { queryParams: queryParams });
  }

  public getQueryStringValue(queryStringName: string): string {
    return this.currentQueryParams[queryStringName];
  }

  public navigateToGetFinanced = function () {
    this.navigate(this.getFinanced);
  };

  public navigateToMoreInfo = function () {
    this.navigate(this.moreInfo);
  };

  public navigateToTellUsAboutYourBusiness = function () {
    this.navigate(this.tellUsAboutYourBusiness);
  };

  public navigateToNextSteps = function () {
    this.navigate(this.nextSteps);
  };

  public navigateToInvoiceProcessing = function () {
    this.navigate(this.invoiceProcessing);
  };

  public navigateToSRFAddidionalInfo = function () {
    this.navigate(this.srfAdditionalInfo);
  };
}
