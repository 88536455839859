import { Component, OnInit } from "@angular/core";
import { Session } from "../../../models/session";
import {
  NotificationType,
  routes,
  TradingPartner,
  User,
} from "../../../models/types";
import { AuthService } from "../../../services/auth.service";
import { NavigationService } from "../../../services/navigation.service";
import { TradingPartnerService } from "../../../services/trading-partner.service";
import { UserService } from "../../../services/user.service";
import { DialogService } from "./../../../services/dialog.service";
import { NotificationService } from "./../../../services/notification.service";
import { Settings } from "./../../../settings";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.sass"],
})
export class MyProfileComponent implements OnInit {
  graphColors = {
    New: "#00B0CA",
    Acknowledged: "#9C2AA0",
    Confirmed: "#005765",
    Rejected: "#FF0000",
    Invoiced: "#BD0000",
  };

  dateFormat;
  tradingPartner: TradingPartner;
  users: User[] = [];
  notificationType: NotificationType;

  constructor(
    private dialog: DialogService,
    private notificationService: NotificationService,
    private userService: UserService,
    private navigationService: NavigationService,
    private tradingPartnerService: TradingPartnerService,
    private dialogService: DialogService,
    private authService: AuthService,
    private setting: Settings,
    public session: Session,
  ) {}

  ngOnInit() {
    this.dateFormat = this.setting.dateFormat;
    this.loadTradingPartner();
    this.getNotificationType();
  }

  loadTradingPartner() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartnerService
        .getTradingPartner(this.session.getSupplierGln())
        .subscribe((x) => {
          if (!x) return;
          this.tradingPartner = x;
        });
    });
  }

  changePassword() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;
      this.navigationService.navigateToResetPassword(routes.myProfile);
    });
  }

  editNotifications() {
    this.dialog
      .editNotifications(this.notificationType)
      .afterClosed()
      .subscribe((x) => {
        if (x) {
          this.getNotificationType();
        }
      });
  }

  getNotificationType() {
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync(true);
      if (!user) return;
      this.notificationType = user.notificationType;
    });
  }

  determineTradingPartnerGln() {
    if (this.session.isAdmin()) {
      return "AdminGln";
    }
    return this.session.getSupplierGln();
  }
}
