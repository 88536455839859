import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Session } from "../../../../models/session";
import { TradingPartner } from "../../../../models/types";
import { TradingPartnerService } from "../../../../services/trading-partner.service";

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.sass"],
})
export class ManageUsersComponent {
  tradingPartner: Observable<TradingPartner>;
  tradingPartnerGln = "";
  loadTradingPartner = false;
  currentRetailer: TradingPartner;
  retailer: TradingPartner = new TradingPartner();
  constructor(
    private tradingPartnerService: TradingPartnerService,
    private session: Session,
  ) {}

  getTradingPartner() {
    this.loadTradingPartner = true;
    this.session.promiseWraper().subscribe(async () => {
      const user = await this.session.getUserAsync();
      if (!user) return;

      this.tradingPartnerGln = this.session.getSupplierGln();

      this.tradingPartner = this.tradingPartnerService.getTradingPartner(
        this.tradingPartnerGln,
      );

      this.tradingPartner.subscribe(
        (result) => {
          if (result) {
            this.currentRetailer = result;
            this.loadTradingPartner = false;
          }
        },
        () => {
          this.loadTradingPartner = false;
        },
      );
    });
  }
}
