import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Session } from "../models/session";
import { ManageUserModel, User } from "../models/types";
import { RepositoryFactory } from "../repositories/repository.factory";
import { UserRepository } from "../repositories/user-repository";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userRepo: UserRepository;

  constructor(repoFactory: RepositoryFactory, private session: Session) {
    this.userRepo = repoFactory.getUserRepository();
  }

  public add(userModel: ManageUserModel): Observable<User> {
    return this.userRepo.add(this.session.getSupplierGln(), userModel);
  }

  public delete(username: string): Observable<void> {
    return this.userRepo.delete(this.session.getSupplierGln(), username);
  }

  public edit(userModel: ManageUserModel): Observable<User> {
    return this.userRepo.edit(this.session.getSupplierGln(), userModel);
  }

  public get(userId: string): Observable<User> {
    return this.userRepo.get(this.session.getSupplierGln(), userId);
  }

  public search(tradingPartnerGln: string): Observable<User[]> {
    return this.userRepo.search(tradingPartnerGln);
  }

  public userLoggedIn(userId: string): Observable<void> {
    return this.userRepo.userLoggedIn(this.session.getSupplierGln(), userId);
  }
}
