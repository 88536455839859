<div id="line-commands" *ngIf="canEdit()" class="flex fx-row">
  <div class="width-90"></div>
  <p
    matRipple
    (click)="cancel()"
    class="nowrap hidden m-l-auto selectedItem"
    style="color: #00b0ca"
  >
    Reset
  </p>
  <p
    matRipple
    (click)="saveLines()"
    class="nowrap hidden m-l-auto selectedItem"
    style="color: #00b0ca"
  >
    Save
  </p>
</div>
<!--<form  [formGroup]="orderLineFormGroup">-->
<div
  class="scroll"
  style="border: 1px lightgray solid; border-radius: 10px !important"
>
  <table
    mat-table
    [dataSource]="order.lines"
    class="noWrap"
    style="width: 1250px !important"
  >
    <!-- Unit Column -->
    <ng-container matColumnDef="unit">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
        class=""
      >
        Pack Barcode<br />Unit Barcode<br />Product Code
      </th>
      <td mat-cell *matCellDef="let transaction">
        <p class="noWrap p-t noMargin">{{ choosePackBarCode(transaction) }}</p>
        <p class="noWrap p-t noMargin">{{ chooseUnitBarCode(transaction) }}</p>
        <p class="noWrap p-t noMargin">{{ chooseProductCode(transaction) }}</p>
      </td>

      <td mat-footer-cell *matFooterCellDef><p class="p-b">Total</p></td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        Description
      </th>
      <td mat-cell *matCellDef="let transaction">
        <p class="noWrap p-t noMargin">{{ transaction.productDescription }}</p>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- BarCode ProductCode Column -->
    <!-- <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'width-100': order.status !== 'Invoiced'}"> Code </th>
      <td mat-cell *matCellDef="let transaction">
        <p class="noWrap p-t noMargin ">{{chooseBarCode(transaction)}}</p>
        <p class="noWrap p-t noMargin ">{{transaction.productCode}}</p>
      </td>
      <td mat-footer-cell *matFooterCellDef>  </td>
    </ng-container> -->

    <!-- Qty Column -->
    <ng-container matColumnDef="quantity">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        Qty
      </th>
      <td mat-cell class="width-30" *matCellDef="let transaction">
        <div class="width-40" [ngClass]="{ blb: canEdit() }">
          <input
            class="width-50 alignRight"
            *ngIf="canEdit()"
            matInput
            placeholder="Qty"
            [(ngModel)]="transaction.quantity"
            (change)="updateTempTotal(transaction)"
          />
          <p *ngIf="!canEdit()" class="alignRight noWrap p-t noMargin width-50">
            {{ transaction.quantity }}
          </p>
        </div>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef
        class="width-30 noWrap p-t noMargin"
      >
        <div
          [ngClass]="
            order.status === 'Confirmed' || order.status === 'Acknowledged'
              ? 'width-40'
              : 'width-20'
          "
        >
          <p class="alignRight p-b">{{ getTotalQty() }}</p>
        </div>
      </td>
    </ng-container>

    <!-- Original Qty Column -->
    <ng-container matColumnDef="originalQuantity">
      <th mat-header-cell *matHeaderCellDef class="noWrap">Original Qty</th>
      <td mat-cell *matCellDef="let transaction">
        <div class="width-30">
          <p class="noWrap p-t noMargin">{{ transaction.originalQuantity }}</p>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Original vat Rate Column -->
    <ng-container
      *ngIf="showVatRate()"
      matColumnDef="vatRate"
      [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
    >
      <th mat-header-cell *matHeaderCellDef class="width-30 alignCenter">
        VAT Rate
      </th>
      <td
        mat-cell
        *matCellDef="let transaction"
        class="fx-jc-center fx-ai-center flex fx-row"
      >
        <mat-form-field class="m-l-20 p-20 height-100 width-50 noMargi n">
          <mat-select
            [value]="transaction.vat"
            (selectionChange)="vatRateChange($event.value, transaction)"
          >
            <mat-option *ngFor="let vatRate of vatRates" [value]="vatRate">
              {{ vatRate.rate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td mat-footer-cell *matFooterCellDef class="noWrap p-t noMargin"></td>
    </ng-container>

    <!-- costPrice Column -->
    <ng-container matColumnDef="costPrice">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap alignRight"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        Cost Price<br />excl VAT
      </th>
      <td
        mat-cell
        *matCellDef="let transaction"
        class="noWrap paragraph noMargin"
      >
        <p class="noWrap p-t noMargin alignRight">
          {{ transaction.itemCost.toFixed(2) }}
        </p>
      </td>
      <td mat-footer-cell *matFooterCellDef class=""></td>
    </ng-container>

    <!-- lineCostExclVat Column -->
    <ng-container matColumnDef="lineCostExclVat">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap alignRight"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        Line Cost<br />excl VAT
      </th>
      <td mat-cell *matCellDef="let transaction" class="noWrap">
        <p class="noWrap p-t noMargin alignRight">
          {{ (transaction.itemCost * transaction.quantity).toFixed(2) }}
        </p>
      </td>
      <td mat-footer-cell *matFooterCellDef class="noWrap">
        <p class="noWrap p-b noMargin alignRight">
          {{ getTotalLineCost().toFixed(2) }}
        </p>
      </td>
    </ng-container>

    <!-- lineCostIncVat Column -->
    <ng-container *ngIf="showVatRate()" matColumnDef="lineCostIncVat">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap alignRight"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        Line Cost<br />incl Vat
      </th>
      <td
        mat-cell
        *matCellDef="let transaction"
        class="noWrap paragraph noMargin"
      >
        <p class="noWrap p-t noMargin alignRight">
          {{ getLineCostIncVat(transaction) }}
        </p>
      </td>
      <td mat-footer-cell *matFooterCellDef class="">
        <p class="noWrap p-b noMargin alignRight">
          {{ getTotalLineCostIncVat().toFixed(2) }}
        </p>
      </td>
    </ng-container>

    <!-- narrative Column -->
    <ng-container matColumnDef="narrative">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        RSP
      </th>
      <td mat-cell *matCellDef="let transaction">
        <p>{{ transaction.lineNarrative }}</p>
      </td>
      <td mat-footer-cell *matFooterCellDef class=""></td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="packSize">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="noWrap"
        [ngClass]="{ 'width-100': order.status !== 'Invoiced' }"
      >
        Pack Size
      </th>
      <td mat-cell *matCellDef="let transaction">
        <p>{{ transaction.packSize }}</p>
      </td>
      <td mat-footer-cell *matFooterCellDef class=""></td>
    </ng-container>

    <!-- Disclaimer column -->
    <ng-container matColumnDef="disclaimer">
      <td mat-footer-cell *matFooterCellDef colspan="8">
        <!-- Please note that the cost of items displayed are completely and totally made up. -->
      </td>
    </ng-container>

    <!-- The table will render two header rows, one data row per data object, and two footer rows. -->
    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="example-first-header-row fw-900"
    ></tr>
    <!-- <tr mat-header-row *matHeaderRowDef="['item-description', 'cost-description']"
        class="example-second-header-row">
    </tr> -->

    <tr mat-row *matRowDef="let row; columns: displayedColumns" class=""></tr>

    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="example-first-footer-row fw-900 borderLines"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="['disclaimer']"
      class="example-second-footer-row"
    ></tr>
  </table>
</div>
<!--</form>-->
