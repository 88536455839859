import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  TradingPartner,
  TradingPartnerSummary,
} from "./../../../../../models/types";
import { DialogService } from "./../../../../../services/dialog.service";
import { NavigationService } from "./../../../../../services/navigation.service";
import { TradingPartnerService } from "./../../../../../services/trading-partner.service";

const ELEMENT_DATA: TradingPartnerSummary[] = [];
@Component({
  selector: "app-linked-retailer",
  templateUrl: "./linked-retailer.component.html",
  styleUrls: ["./linked-retailer.component.sass"],
})
export class LinkedRetailerComponent implements OnInit {
  displayedColumns: string[] = ["gln", "image", "name", "view"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() retailer: TradingPartner;
  tradingPartners: TradingPartner[] = [];
  linkedTradingPartnerForm: UntypedFormGroup;
  linkedTradingPartners: TradingPartnerSummary[] = [];
  loader = false;
  dataSource = new MatTableDataSource<TradingPartnerSummary>(ELEMENT_DATA);
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tradingPartnerService: TradingPartnerService,
    private navigate: NavigationService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.setLinkedRetailers();
    this.setupInputControls();
  }

  linkedTypesLabel(types: string) {
    const typesArray = {
      Retailer: "Stores",
      Supplier: "Retailers",
    };
    return typesArray[types];
  }

  setLinkedRetailers() {
    this.linkedTradingPartners = [];
    this.loader = true;

    const subscription = this.tradingPartnerService.getRetailers().subscribe(
      (allRetailers) => {
        this.retailer?.linkedTradingPartners.forEach((linkedRetailer, idx) => {
          const lr = allRetailers.find((x) => {
            return x.gln == linkedRetailer.gln;
          });
          if (lr) {
            this.linkedTradingPartners.push(lr);
          }
          subscription.unsubscribe();
          this.retailer?.linkedTradingPartners.length === idx + 1
            ? (this.loader = false)
            : {};
        });

        this.dataSource.data = this.linkedTradingPartners;
        this.dataSource.paginator = this.paginator;

        this.loader = false;
        subscription.unsubscribe();
      },
      () => {
        this.loader = false;
      },
    );
  }

  goToRetailer(gln: string) {
    this.tradingPartnerService.selectedTradingPartner.next(null);

    this.navigate.navigateToTradingPartnerDetails(gln);
  }

  setupInputControls() {
    this.linkedTradingPartnerForm = this.formBuilder.group({
      searchTradingPartner: ["", null],
    });
  }

  clearSearch() {
    this.linkedTradingPartnerForm.controls.searchTradingPartner.value == ""
      ? (this.search(""), (this.paginator = this.dataSource.paginator))
      : {};
  }

  search(filterValue: string) {
    this.dataSource.data = this.linkedTradingPartners.filter(
      (x) =>
        x.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
        x.gln.toLowerCase().includes(filterValue.trim().toLowerCase()),
    );
  }

  linkRetailers() {
    this.dialogService
      .linkRetailer(this.retailer)
      .afterClosed()
      .subscribe((response) => {
        if (response.response) {
          this.tradingPartnerService
            .getTradingPartner(response.tp.gln)
            .subscribe((res) => {
              if (res) {
                this.dataSource.data = res.linkedTradingPartners;
                this.dataSource.paginator = this.paginator;
              }
            });
        }
      });
  }
}
